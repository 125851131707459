import i18n from '@/i18n';
import { ACCOUNT_STATUS } from '@/constant';
import { newApi } from '@/utils/new-api';

export default {
    state: {
        lang: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'ru',
        chatInfo: [],
    },

    actions: {
        CHANGE_LOCAL({ commit }, lang) {
            i18n.locale = lang;

            localStorage.setItem('lang', lang);

            commit('SET_LOCAL', lang);
        },

        async LOAD_CHAT_INFO({ commit, getters }) {
            try {
                const isAuthenticated = getters.isAuthenticated && getters.isProfileLoaded;

                if (isAuthenticated && getters.accountStatus !== ACCOUNT_STATUS.NEW) {
                    const res = await newApi('getChatInfo');

                    commit('LOAD_CHAT_INFO', res);
                }
            } catch (err) {
                console.error(err);
            }
        },
    },

    mutations: {
        SET_LOCAL(state, data) {
            state.lang = data;
        },

        LOAD_CHAT_INFO(state, data) {
            state.chatInfo = data;
        },
    },
};
