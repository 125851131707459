<template>
    <div class="registration-entity-type">
        <div class="registration-entity-type__registration-form">
            <div class="registration-form__header">
                <reg-info-button />
                <div class="header__title">{{ $t('Тип контрагента') }}</div>
            </div>

            <div class="registration-form__content">
                <router-link to="/account/registration-legal" class="content__link-button">
                    <span>{{ $t('Юридическое лицо') }} → </span>
                </router-link>

                <router-link to="/account/registration-ind" class="content__link-button">
                    <span>{{ $t('Индивидуальный предприниматель') }} →</span>
                </router-link>

                <router-link to="/account/registration-noresident" class="content__link-button">
                    <span>{{ $t('Нерезидент') }} →</span>
                </router-link>
            </div>
        </div>

        <div class="registration-entity-type__footer">
            <button class="footer__link" href="#" @click="logout">{{ $t('Выход') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegistrationEntityType',

    methods: {
        logout() {
            this.$store.dispatch('AUTH_LOGOUT', { self: this });
        },
    },
};
</script>

<style lang="scss" scoped>
.registration-entity-type {
    width: 500px;

    &__registration-form {
        position: relative;
        background: white;
        padding: 20px 32px 32px;
        margin-bottom: 30px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
    }
}

.registration-form {
    &__header {
        padding-bottom: 30px;
    }
}

.header {
    &__title {
        font: 26px OpenSansBold, sans-serif;
        text-align: center;
        color: black;
    }
}

.content {
    &__link-button {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: CuprumBold, sans-serif;
        font-size: 18px;
        width: 100%;
        height: 45px;
        padding: 0px 20px;
        color: #ffffff;
        border: 1px solid transparent;
        border-radius: 2px;
        background-color: #db2b37;
        margin-bottom: 15px;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.footer {
    &__link {
        font: 20px CuprumBold, sans-serif;
        cursor: pointer;
        color: #989898;
        text-decoration: none;
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: solid 1px rgba(152, 152, 152, 0.3);
    }
}
</style>
