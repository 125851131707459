<template>
    <v-select
        class="non-resident-search"
        placeholder="Введите Tax ID / Наименование ЮЛ"
        :filterable="false"
        :options="options"
        :value="selectedValue"
        :disabled="disabled"
        @search="onSearch"
        @input="onSelect"
        @close="clearOptions"
        @search:blur="onUnfocus"
    >
        <template #list-header>
            <div class="non-resident-search__list-header">Выберите вариант или продолжите ввод</div>
        </template>

        <template #option="{ name, taxId, actualAddress, id }">
            <div :key="id" :title="name" class="select_company_title">
                {{ name }}
            </div>

            <div :title="actualAddress.city" class="select_company_info">
                {{ taxId }}, {{ actualAddress.city }}
            </div>
        </template>

        <template #selected-option="{ name }">
            <div>{{ name }}</div>
        </template>

        <template #no-options>
            {{ $t('Нет результатов поиска') }}
        </template>
    </v-select>
</template>

<script>
import { debounce, uniqueId } from 'lodash';

import { newApi } from '@/utils/new-api';

export default {
    name: 'NonResidentSearch',

    props: {
        disabled: { type: Boolean, default: false },
    },

    data() {
        return {
            options: [],
            selectedValue: null,
        };
    },

    methods: {
        onSelect(event) {
            if (!event) {
                this.clearOptions();
            }

            this.selectedValue = event;
            this.$emit('value-changed', event);
        },

        onUnfocus() {
            this.$emit('blur');
        },

        onSearch: debounce(function (event) {
            if (event.length > 3) {
                this.loadData(event);
            }
        }, 500),

        async loadData(search) {
            const params = {
                self: this,
                data: search,
            };

            try {
                const result = await newApi('searchAccountsNonResident', params);

                if (result) {
                    result.forEach((item) => {
                        item.id = uniqueId(item.taxId);
                    });

                    this.options = result;
                }
            } catch (err) {
                console.error(err);
                this.$emit('search-error');
            }
        },

        clearOptions() {
            this.options = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.non-resident-search {
    height: fit-content;

    --vs-dropdown-option--active-bg: #efdfe2;
    --vs-dropdown-option--active-color: #000;
    --vs-search-input-placeholder-color: rgba(60, 60, 60, 0.7);
    --vs-font-size: 14px;
    --vs-dropdown-min-width: 150px;
    --vs-dropdown-max-height: 300px;

    &__list-header {
        margin: 15px;
    }

    & .vs__dropdown-toggle {
        min-height: 55px;
    }
}
</style>
