<template>
    <div>
        <info-user :is-profile-info-editable="isProfileInfoEditable" />

        <template v-if="allData">
            <page-header
                :title="allData.name"
                class="mb-20"
            />

            <section class="container">
                <div class="grid-x grid-margin-x">
                    <div class="large-12 cell">
                        <registration-data
                            :all-data="allData"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div
                        v-if="!isNotResident"
                        class="cell"
                        :class="isLegal ? 'large-6' : 'large-12'"
                    >
                        <legal-address
                            :all-data="allData"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div v-if="isNotResident" class="cell large-12">
                        <actual-address
                            :all-data="allData"
                            :title="$t('Фактический адрес')"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div v-if="isLegal" class="cell large-6">
                        <legal-address-resident
                            :all-data="allData"
                            :v="$v"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div v-if="!isNotResident && allData" class="large-12 cell">
                        <contact-details
                            :v="$v"
                            :all-data.sync="allData"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div v-if="!isNotResident" class="large-12 cell">
                        <bank-details
                            :all-data="allData"
                            :block-input="hasContentDisabled"
                        />
                    </div>

                    <div v-if="!isNotResident && showErrContact" class="large-12 cell">
                        <div class="smallErrContact">
                            {{ $t('Заполните контакты') }}
                        </div>
                    </div>

                    <div v-if="!isNotResident" class="large-12 cell">
                        <div class="controls">
                            <ui-button
                                :disabled="hasContentDisabled"
                                type="full"
                                class="mr-20"
                                @click="init"
                            >
                                {{ $t('Отменить') }}
                            </ui-button>

                            <ui-button
                                :disabled="$v.$invalid || hasContentDisabled"
                                type="full"
                                @click="beforeSave"
                            >
                                {{ $t('Сохранить') }}
                            </ui-button>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
import {
    required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { indexCheck } from '@/validateRule/indexCheck';
import { phoneCheck } from '@/validateRule/phoneCheck';
import { urlCheck } from '@/validateRule/urlCheck';
import { TYPE_DIALOG_TITLE, TYPE_ERRORS } from '@/constant';
import clearReactive from '@/helpers/clearReactive';
import RegistrationData from '@/views/job-application/registration-data/registration-data';
import LegalAddress from '@/views/job-application/legal-address';
import ActualAddress from '@/views/job-application/actual-address';
import LegalAddressResident from '@/views/job-application/legal-address-resident';
import ContactDetails from '@/views/job-application/contact-details';
import BankDetails from '@/views/job-application/bank-details/bank-details';
import { newApi } from '@/utils/new-api';

export default {
    name: 'JobApplication',

    components: {
        RegistrationData,
        LegalAddress,
        ActualAddress,
        LegalAddressResident,
        ContactDetails,
        BankDetails,
    },

    validations() {
        let data = {
            legal: {
                postalAddress: {
                    index: {
                        indexCheck,
                    },
                },
            },
        };

        if (this.allData.data?.individual) {
            data = {
                individual: {},
            };
        }

        let rules;

        if (!this.isNotResident) {
            rules = {
                phones: {
                    $each: {
                        phoneCheck,
                        required,
                        minLength: minLength(1),
                        maxLength: maxLength(32),
                    },
                },
                emails: {
                    $each: {
                        email,
                        required,
                        minLength: minLength(1),
                        maxLength: maxLength(64),
                    },
                },
                phonesForTender: {
                    $each: {
                        phoneCheck,
                        required,
                        minLength: minLength(1),
                        maxLength: maxLength(32),
                    },
                },
                emailsForTender: {
                    $each: {
                        email,
                        required,
                        minLength: minLength(1),
                        maxLength: maxLength(64),
                    },
                },
                websites: {
                    $each: {
                        urlCheck,
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(256),
                    },
                },
            };
        }

        return {
            allData: {
                data,
                ...rules,
            },
        };
    },

    data() {
        return {
            load: false,
            allData: null,
        };
    },

    watch: {
        lang() {
            this.init();
        },
    },

    computed: {
        ...mapGetters([
            'isLegal',
            'isIndividual',
            'isNotResident',
            'hasSaveData',
        ]),

        lang() {
            return this.$store.state.common.lang;
        },

        isProfileInfoEditable() {
            return this.allData?.isProfileInfoEditable ?? true;
        },

        hasContentDisabled() {
            return !(this.hasSaveData && this.isProfileInfoEditable);
        },

        showErrContact() {
            if (this.$v && this.$v.allData) {
                const errPhone = this.$v.allData.phones.$invalid;
                const errMails = this.$v.allData.emails.$invalid;
                const errPhonesForTender = this.$v.allData.phonesForTender.$invalid;
                const errMailsForTender = this.$v.allData.emailsForTender.$invalid;
                const errWeb = this.$v.allData.websites.$invalid;

                if (errPhone || errMails || errPhonesForTender || errMailsForTender || errWeb) {
                    return {
                        errPhone,
                        errMails,
                        errPhonesForTender,
                        errMailsForTender,
                        errWeb,
                    };
                }
            }

            return false;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            try {
                const result = await newApi('getProfileForm', { self: this });

                this.allData = clearReactive(result);
            } catch (err) {
                console.error(err);
            }
        },

        beforeSave() {
            this.$modal.show('modal_window', {
                messages: [
                    'В период модерации редактирование Анкеты будет недоступно. Вы подтверждаете внесение всех необходимых изменений?',
                ],
                confirmBtnText: 'ДА, направить на модерацию',
                cancelBtnText: 'НЕТ, вернуться к редактированию',
                title: TYPE_DIALOG_TITLE.WARNING,
                resultFunction: this.save,
            });
        },

        async save() {
            try {
                const paramsIn = { self: this, sendData: this.allData };
                await newApi('updateProfileForm', paramsIn);

                const msg = 'Отправлен запрос на обновление анкетных данных.';

                this.$modal.show('info', {
                    type: TYPE_ERRORS.SUCCESS,
                    msg,
                    title: TYPE_DIALOG_TITLE.SUCCESS,
                });
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style scoped>
.controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-top: 20px;
}
</style>
