<template>
    <div :class="dopClass">
        <v-select
            v-if="drawEl"
            label="name"
            :placeholder="placeholder"
            :filterable="filterable"
            :filter="filteringName"
            :options="options"
            :value="value"
            :disabled="forceDisabled"
            class="search_select dictionary_select singleMode"
            @input="selectData($event)"
        >
            <template #list-header>
                <li class="top_select">
                    {{ $t('Выберите вариант или продолжите ввод') }}
                </li>
            </template>

            <template #option="{ name, id , isSeparate, isSeparateSecond }">
                <div
                    :key="id"
                    :title="name"
                    :disabled="isSeparate && isSeparateMode"
                    class="select_company_title singleShow"
                    :class="{
                        separateBlock: isSeparate && isSeparateMode,
                        separateSecond: isSeparateSecond
                    }"
                >
                    {{ name }}
                </div>
            </template>

            <template #selected-option="{ name }">
                <div>
                    {{ name }}
                </div>
            </template>

            <template #no-options>
                {{ $t('Нет результатов поиска') }}
            </template>
        </v-select>

        <div v-if="hasError" class="has-error">
            <p>
                {{ errorText }}
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SimpleDictionary',

    props: {
        value: {},

        vError: {
            type: Object,
            default: () => ({}),
        },

        parentCode: {
            type: Object,
            default: () => ({}),
        },

        options: {
            type: Array,
            default: () => [],
        },

        onChange: {
            type: Function,
            required: true,
        },

        errorText: {
            type: String,
            default: '',
        },

        dopClass: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: 'Введите валюту',
        },

        hasParentCode: {
            type: Boolean,
            default: false,
        },

        forceDisabled: {
            type: Boolean,
            default: false,
        },

        isSeparateMode: {
            type: Boolean,
            default: false,
        },

        filterable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            drawEl: true,
        };
    },

    computed: {
        lang() {
            return this.$store.state.common.lang;
        },

        hasError() {
            const { vError } = this;

            if (vError && vError.$dirty && vError.$error) {
                return true;
            }
            return false;
        },

        touch() {
            const { vError } = this;

            if (vError && vError.$dirty) {
                return true;
            }
            return false;
        },

        classesComp() {
            let res = this.classes;

            res += this.hasError ? ' error ' : this.touch ? ' success ' : '';

            return res;
        },
    },

    methods: {
        selectData(event) {
            this.onChange(event);
        },

        filteringName(options, search) {
            const arr = options.filter((item) => item.name.toLowerCase()
                .includes(search.toLowerCase()));
            return arr;
        },

        unFocus() {
            if (this.vError) {
                this.vError.$touch();
            }

            this.$emit('blur');
        },
    },
};
</script>

<style lang='scss' scoped>
.select_company_title {
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.singleShow {
    padding: 5px;
    margin: 0px;
}

.search_select {
    min-height: 48px;
    height: inherit;
    .vs__dropdown-toggle {
        min-height: 48px;
        height: inherit;
    }
}

.search_select.vs--open {
    .vs__dropdown-toggle {
        overflow: hidden;
    }
    .vs__selected-options {
        overflow: hidden;
    }
    .vs__dropdown-toggle {
        overflow: hidden;
    }
}

.singleMode {
    .vs__dropdown-menu {
        .vs__dropdown-option {
            margin: 0px !important;
        }
    }
}

.top_select {
    line-height: 1 !important;
}

.dictionary_select {
    border-radius: 0px !important;
    .vs__dropdown-toggle {
        border-radius: 0px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: -webkit-box;
        display: flex;
        height: 48px;
        border: solid 1px #000;
        white-space: normal;
    }
    .vs__actions {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 18px;
        position: relative;
    }
    .vs__selected-options {
        overflow: hidden;
    }
}
</style>
