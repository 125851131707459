<template>
    <li class="tabs-title" :class="{ 'is-active': isActive }">
        <a
            v-if="!forseDis"
            :data-tabs-target="idTab"
            :href="`#${idTab}`"
            @click="$emit('set-tab')"
        >
            {{ label }}</a>
        <a v-if="forseDis" href="#" disabled>{{ label }}</a>
    </li>
</template>

<script>
export default {
    name: 'TabMenuItem',
    props: {
        idTab: { type: String, default: 'panel1' },
        label: { type: String, default: 'Таб' },
        isActive: { type: Boolean, default: false },
        forseDis: { type: Boolean, default: false },
    },
};
</script>
