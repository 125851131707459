import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthLayout from '@/layout/AuthLayout.vue';
import Tender from '@/views/tender/Tender.vue';
import MainLayout from '@/layout/MainLayout.vue';
import NotPage from '@/views/NotPage.vue';
import Communication from '@/views/Communication.vue';
import NoPage from '@/views/NoPage.vue';
import Faq from '@/views/Faq.vue';
import Instruction from '@/views/infoPage/Instruction.vue';
import HowPay from '@/views/infoPage/HowPay.vue';
import { getAppConfig, appParams } from '@/utils/appConfig';
import { checkShowPageNoLogin } from '@/middleware/checkShowPageNoLogin';
import DocumentsStatus from '@/views/DocumentsStatus';
import ErrorLoudUser from '@/views/ErrorLoudUser';
import Relationships from '@/views/Relationships';
import UserAdmin from '@/views/modules/admin/user-admin.vue';
import Documents from '@/views/documents/documents.vue';
import AddDocuments from '@/views/documents/add-documents';
import JobApplication from '@/views/job-application/job-application.vue';
import RegistrationEntityType from '@/views/modules/registration/registration-entity-type.vue';
import RegistrationLegalEntity from '@/views/modules/registration/registration-legal-entity.vue';
import RegistrationIndividualEntity from '@/views/modules/registration/registration-individual-entity.vue';
import RegistrationNoResident from '@/views/modules/registration/registration-non-resident.vue';

import { ifAuthenticated } from '../middleware/ifAuthenticated';
import { LoadIfCanAuthentication } from '../middleware/LoadIfCanAuthenticated';
import MainPbiot from '../views/pbiot/MainPbiot.vue';
import Questionnaire from '../views/pbiot/Questionnaire.vue';
import DocForEP from '../views/pbiot/DocForEP.vue';
import DocForPbiot from '../views/pbiot/DocForPbiot.vue';
import Incidents from '../views/pbiot/Incidents.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: MainLayout,
        redirect: '/job-application',
        children: [
            {
                path: 'tender',
                name: 'Tender',
                component: Tender,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Тендер',
                    showPage: getAppConfig(appParams.SHOW_PAGE_TENDER),
                },
            },
            {
                path: 'job-application',
                name: 'job-application',
                component: JobApplication,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Анкета',
                },
            },
            {
                path: 'relationships',
                name: 'relationships',
                component: Relationships,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Взаимоотношения',
                },
            },
            {
                path: 'documents/',
                name: 'Documents',
                component: Documents,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Документы',
                },
            },
            {
                path: 'documents/create',
                name: 'DocumentsCreate',
                component: AddDocuments,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Новые документы',
                },
            },
            {
                path: 'documents/status',
                name: 'DocumentsStatus',
                component: DocumentsStatus,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Документы на проверке',
                },
            },

            {
                path: 'communication',
                name: 'Communication',
                component: Communication,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'Коммуникации',
                },
            },
            {
                path: 'faq',
                name: 'FAQ',
                component: Faq,
                beforeEnter: ifAuthenticated,
                meta: {
                    title: 'FAQ',
                },
            },
            {
                path: 'instruction',
                name: 'Instruction',
                component: Instruction,
                beforeEnter: LoadIfCanAuthentication,
                meta: {
                    title: 'Инструкция',
                },
            },

            {
                path: 'howpay',
                name: 'HowPay',
                component: HowPay,
                beforeEnter: LoadIfCanAuthentication,
                meta: {
                    title: 'Как оплатить доступ',
                },
            },
            {
                path: '/pbiot',
                component: MainPbiot,
                redirect: '/pbiot/incidents',
                children: [
                    {
                        path: 'form',
                        component: Questionnaire,
                        beforeEnter: ifAuthenticated,
                        meta: {
                            title: 'Анкета',
                        },
                    },
                    {
                        path: 'doc-ep',
                        component: DocForEP,
                        beforeEnter: ifAuthenticated,
                        meta: {
                            title: 'Документы по ООС',
                        },
                    },
                    {
                        path: 'doc-pbiot',
                        component: DocForPbiot,
                        beforeEnter: ifAuthenticated,
                        meta: {
                            title: 'Документы по ПБиОТ',
                        },
                    },
                    {
                        path: 'incidents',
                        component: Incidents,
                        beforeEnter: ifAuthenticated,
                        meta: {
                            title: 'Происшествия',
                        },
                    },
                ],
            },
            {
                path: '404',
                name: 'nopage',
                component: NoPage,
                beforeEnter: LoadIfCanAuthentication,
                meta: {
                    title: 'Ошибка 404',
                },
            },
        ],
    },
    {
        path: '/admin',
        component: MainLayout,
        redirect: '/user',
        children: [
            {
                path: 'user',
                name: 'User',
                component: UserAdmin,
                beforeEnter: ifAuthenticated,
                meta: {
                    isAdmin: true,
                    title: 'Пользователи',
                },
            },
        ],
    },
    {
        path: '/account',
        component: AuthLayout,
        redirect: '/account/registration',
        children: [
            {
                path: 'registration',
                name: 'RegistrationEntityType',
                component: RegistrationEntityType,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'registration-legal',
                name: 'RegistrationLegalEntity',
                component: RegistrationLegalEntity,
            },
            {
                path: 'registration-ind',
                name: 'RegistrationIndLegalEntity',
                component: RegistrationIndividualEntity,
            },
            {
                path: 'registration-noresident',
                name: 'RegistrationNoResident',
                component: RegistrationNoResident,
                beforeEnter: checkShowPageNoLogin,
                props: true,
                children: [
                    {
                        name: 'registration-noresident',
                        path: ':id',
                        component: RegistrationNoResident,
                        props: true,
                    },
                ],
            },
            {
                path: 'user-error',
                name: 'UserError',
                component: ErrorLoudUser,
                meta: {
                    title: 'Error',
                },
            },
            {
                path: '404',
                name: 'NotPage',
                component: NotPage,
                meta: {
                    title: '404',
                },
            },
        ],
    },

    {
        path: '*',
        name: 'noPage404',
        redirect: '/404',
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.afterEach((to, from) => {
    document.title = to.meta && to.meta.title ? `${to.meta.title}-'ЛКК'` : 'ЛКК';
});

export default router;
