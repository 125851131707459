<template>
    <div>
        <div class="communication_form_footer">
            <div class="input_wrap">
                <input
                    ref="input"
                    v-model.trim="msg"
                    type="text"
                    :placeholder="$t('Сообщение')"
                    @keyup.enter="send()"
                    @input="$emit('writing')"
                />
            </div>
            <ui-button :disabled="blockbtn" @click="send">
                {{ $t("Отправить") }}
            </ui-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            msg: '',
            demoP: 0,
            writing: false,
        };
    },
    computed: {
        blockbtn() {
            if (this.msg && this.msg.length > 0) {
                return false;
            }
            return true;
        },
    },
    methods: {
        send(e) {
            e.preventDefault();
            this.$emit('send', this.msg);
            this.msg = null;
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.input_wrap {
    .has-error {
        p {
            color: #d5a72d;
        }
    }
}
</style>
