<template>
    <div>
        <InfoUser />

        <PageHeader :title="$t('Вопросы и ответы')" />
        <section class="main_faq">
            <div class="container">
                <div class="grid-x grid-margin-x">
                    <div class="large-12 cell">
                        <AccordionBase id="howPay">
                            <AccordionItem
                                v-for="(text, index) in allText"
                                :key="index"
                                :title="$t(text.title)"
                                :is-active="index == 0"
                            >
                                <p v-html="$t(text.text)" />
                            </AccordionItem>
                        </AccordionBase>

                        <div class="contact_wrap">
                            <div class="contact_form">
                                <form>
                                    <div class="contact_form_header">
                                        {{ $t('Задать вопрос') }}
                                    </div>
                                    <div class="contact_form_body">
                                        <div class="contact_form_item input_wrap">
                                            <div class="contact_form_item_label">
                                                <label for="">{{ $t('Тема') }}</label>
                                            </div>
                                            <SearchDictionary
                                                :form="info"
                                                field-name="topicRef"
                                                :type-dictionary="listD.FAQ_TOPIC"
                                                dop-class="listselectFaq"
                                                :placeholder="$t('Выберите тему')"
                                                :error-text="$t('Выберите тему')"
                                                :v-error="$v.info.topicRef"
                                                no-code
                                            />
                                        </div>

                                        <div class="contact_form_item">
                                            <div class="contact_form_item_label">
                                                <label>{{ $t('Вопрос') }}</label>
                                            </div>
                                            <BaseInput
                                                v-model="question"
                                                name="address"
                                                placeholder="Напишите вопрос"
                                                rows="10"
                                                cols="30"
                                                textarea
                                                error-text="Напишите вопрос"
                                                :v-error="$v.question"
                                            />
                                        </div>
                                    </div>
                                    <div class="contact_form__action">
                                        <ui-button
                                            :disabled="$v.$invalid"
                                            type="full"
                                            class="contact_form__button"
                                            @click="send"
                                        >
                                            {{ $t('Отправить') }}
                                        </ui-button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import AccordionBase from '@/views/components/base/accordions/AccordionBase';
import AccordionItem from '@/views/components/base/accordions/AccordionItem';
import { DICTIONARY_LIST, TYPE_ERRORS, TYPE_DIALOG_TITLE } from '@/constant';
import SearchDictionary from '@/views/components/base/SearchDictionary';
import { getAppConfig, appParams } from '@/utils/appConfig';
import { newApi } from '@/utils/new-api';

const allText = [];
const COUNT_Q = 30;
for (let index = 1; index <= COUNT_Q; index += 2) {
    // faq5
    // faq21
    let canIn = true;
    if (`faq${index}` == 'faq5' && getAppConfig(appParams.FAQ_Q_5) == false) {
        canIn = false;
    }
    if (`faq${index}` == 'faq21' && getAppConfig(appParams.FAQ_Q_21) == false) {
        canIn = false;
    }
    if (canIn) {
        allText.push({ title: `faq${index}`, text: `faq${index + 1}` });
    }
}
export default {
    components: {
        AccordionBase,
        AccordionItem,
        SearchDictionary,
    },
    data() {
        return {
            listD: DICTIONARY_LIST,
            question: '',
            info: { topicRef: null },
            selected: 'Рубли',
            options: ['Рубли', 'Доллары', 'Фунты'],
            allText,
        };
    },
    validations: {
        info: {
            topicRef: {
                required,
            },
        },
        question: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(4096),
        },
    },
    methods: {
        async send() {
            const { info, question } = this;
            try {
                const paramsIn = {
                    self: this,
                    sendData: {
                        question,
                        topic: info.topicRef,
                    },
                };

                const result = await newApi('sendFaq', paramsIn);

                if (result) {
                    const msg = this.$t('Сообщение отправлено');
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.SUCCESS,
                        msg,
                        title: TYPE_DIALOG_TITLE.INFO,
                    });
                    this.question = null;
                    this.info.topicRef = null;
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.contact_form {
    &__action {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px 20px 0;
    }

    &__button {
        width: 260px;
    }
}

.input_wrap {
    .has-error {
        p {
            color: #d5a72d;
        }
    }
}

.listselectFaq {
    width: 100%;
}
</style>
