<template>
    <ve-table
        :columns="columns"
        :table-data="documents"
        border-x
    />
</template>

<script>
export default {
    name: 'DocumentsHistorySubcontractors',

    props: {
        columns: {
            type: Array,
            default: () => [],
        },

        documents: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
