<template>
    <div class="grid-x grid-margin-x">
        <div class="cell mb-12">
            <DocumentCreateV2Buttons
                :documents-in-specification="selectedDocSpec.documents"
                :get-confirmation-dialog-props="getConfirmationDialogProps"
                @update:documentsUserSelectedToAdd="
                    $emit('update:documentsUserSelectedToAdd', $event)
                "
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import DocumentUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentUploadForm.vue';
import { DocumentTypeEnum } from '@/constant';

const documentCodesForUploadCheck = [
    DocumentTypeEnum.Charter,
];

export default {
    name: 'SimpleDocumentUiHandler',

    components: {
        DocumentUploadForm,
        DocumentCreateV2Buttons,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            // DocumentSpecificationGroupDTO + group property
            type: Object,
        },
    },

    computed: {
        ...mapGetters(['existingDocumentInfoList']),
    },

    methods: {
        getConfirmationDialogProps(docSpecification) {
            if (documentCodesForUploadCheck.includes(docSpecification.code)) {
                const existingDocs = this.existingDocumentInfoList(docSpecification.code);
                if (_.isEmpty(existingDocs)) {
                    return null;
                }
                if (
                    existingDocs.some((uploadedDoc) => uploadedDoc.code === docSpecification.code)
                ) {
                    return {
                        nameDoc: docSpecification.name,
                        msg: [
                            'У вас уже загружен данный документ. Убедитесь, что собираетесь загрузить корректную версию.',
                        ],
                        confirmBtnText: 'Загрузить',
                        title: 'Внимание!',
                    };
                }
                return null;
            }
        },
    },
};
</script>
