<template>
    <ui-modal
        name="registration-link-subcontractor-modal"
        width="500"
        @before-open="beforeOpen"
        @before-close="reset"
    >
        <p>
            {{ `Поделитесь ссылкой с ${type} для его регистрации в Личном кабинете контрагагента:` }}
        </p>

        <base-input
            v-model="currentLink"
            name="link"
            class="registration-link-subcontractor__input"
            disabled
        />

        <ui-button
            :disabled="hasCopied"
            type="full"
            @click="copyLink"
        >
            <ui-icon :name="icons.roundFileCopy" width="30" height="30" />

            <span class="link-button__text">Скопировать ссылку</span>
        </ui-button>
    </ui-modal>
</template>

<script>
import icons from '@/helpers/icons';

export default {
    name: 'RegistrationLinkSubcontractorModal',

    props: {
        link: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            hasCopied: false,
            linkType: '',
            icons,
            currentLink: '',
        };
    },

    computed: {
        type() {
            return this.linkType === 'PRODUCER' ? 'производителем' : 'субподрядчиком';
        },
    },

    watch: {
        link: {
            handler(val) {
                if (val) {
                    this.currentLink = val;
                }
            },
        },
    },

    methods: {
        beforeOpen({ params: { linkType } }) {
            this.linkType = linkType;
        },

        copyLink() {
            navigator.clipboard.writeText(this.currentLink)
                .then(() => {
                    this.hasCopied = true;
                })
                .catch((err) => {
                    console.log('Copied error', err);
                });
        },

        reset() {
            this.hasCopied = false;
        },
    },
};
</script>

<style lang='scss' scoped>

.registration-link-subcontractor {

    &__input {
        margin-top: 10px;
    }
}

.link-button {

    &__text {
        margin-left: 10px;
    }
}
</style>
