<template>
    <ui-modal
        :title="title"
        name="subcontractor"
        width="500"
        @before-open="beforeOpen"
    >
        <ui-button
            type="full"
            class="mb-20"
            @click="setTypeSubcontractor(ACCOUNT_TYPE.LEGAL)"
        >
            <span>{{ $t('Юридическое лицо') }} →</span>
        </ui-button>

        <ui-button
            type="full"
            class="mb-20"
            @click="setTypeSubcontractor(ACCOUNT_TYPE.INDIVIDUAL)"
        >
            <span>{{ $t('Индивидуальный предприниматель') }} →</span>
        </ui-button>

        <ui-button
            type="full"
            @click="createNonResidents"
        >
            <span>{{ $t('Нерезидент РФ') }} →</span>
        </ui-button>
    </ui-modal>
</template>

<script>
import { ACCOUNT_TYPE, SUBCONTRACTOR_LINK_TYPE } from '@/constant';

export default {
    name: 'ModalSelectSubcontractor',

    data() {
        return {
            ACCOUNT_TYPE,
            subcontractorLinkType: '',
        };
    },

    computed: {
        title() {
            if (this.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.PRODUCER) {
                return this.$t('Тип производителя');
            }
            return this.$t('Тип субподрядчика');
        },
    },

    methods: {
        beforeOpen(event) {
            this.subcontractorLinkType = event.params.subcontractorLinkType;
        },

        setTypeSubcontractor(type) {
            this.$modal.hide('subcontractor');

            this.$modal.show('create_yur_subcontractor', {
                accountType: type,
                subcontractorLinkType: this.subcontractorLinkType,
            });
        },

        createNonResidents() {
            this.$modal.hide('subcontractor');

            this.$modal.show('NonResidentsModal', {
                accountType: this.subcontractorLinkType,
            });
        },
    },
};
</script>

<style lang='scss'></style>
