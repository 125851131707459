<template>
    <div class="empty_layout_content">
        <div class="simple_form_wrap">
            <div class="form_info">
                <div class="form_front">
                    <form>
                        <h3>{{ $t("Ошибка") }}</h3>
                        <p>
                            {{
                                $t(
                                    "Внутренняя ошибка не удалось загрузить данные о пользователе."
                                )
                            }}
                        </p>
                    </form>
                </div>
            </div>
        </div>

        <div class="additional_links">
            <router-link to="/">Главная</router-link>
            <a href="#" @click="logout">{{ $t("Выход") }}</a>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ErrorLoudUser',

    methods: {
        logout() {
            this.$store.dispatch('AUTH_LOGOUT', { self: this });
        },
    },
};
</script>
