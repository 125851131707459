<template>
    <ul :id="idTabs" class="tabs" data-tabs>
        <slot />
    </ul>
</template>

<script>
export default {
    name: 'TabsMenu',
    props: {
        idTabs: { type: String, default: 'tabs' },
    },
};
</script>
