import { YEAR_Q } from '@/utils/questScheme';

export const checkIncidentHelper = (nowIncident) => {
    let res = true;
    if (nowIncident && nowIncident.length > 0) {
        nowIncident.forEach((el) => {
            if (el.type == YEAR_Q) {
                el.children.forEach((elCh) => {
                    if (elCh.value == '' || elCh.value == null) {
                        res = false;
                    }
                });
            } else if (el.value === '' || el.value == null) {
                res = false;
            }
        });
    }
    return res;
};
