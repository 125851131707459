<template>
    <div>
        <PageHeader :title="$t('Как оплатить доступ')" />
        <section class="main_faq">
            <div class="container">
                <div class="grid-x grid-margin-x">
                    <div class="large-12 cell">
                        <AccordionBase id="howPay">
                            <AccordionItem
                                v-for="(text, index) in allText"
                                :key="index"
                                :title="$t(text.title)"
                                :is-active="index == 0"
                            >
                                <p v-html="$t(text.text)" />
                            </AccordionItem>
                        </AccordionBase>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import AccordionBase from '@/views/components/base/accordions/AccordionBase';
import AccordionItem from '@/views/components/base/accordions/AccordionItem';

export default {
    components: {
        AccordionBase,
        AccordionItem,
    },
    data() {
        return {
            allText: [
                { title: 'howPay1', text: 'howPay2' },
                { title: 'howPay3', text: 'howPay4' },
                { title: 'howPay5', text: 'howPay6' },
                { title: 'howPay7', text: 'howPay8' },
                { title: 'howPay9', text: 'howPay10' },
                { title: 'howPay11', text: 'howPay12' },
                { title: 'howPay13', text: 'howPay14' },
                { title: 'howPay15', text: 'howPay16' },
            ],
        };
    },
};
</script>
