<template>
    <ui-modal
        :title="title"
        name="confirm"
        width="600"
        @before-open="beforeOpen"
    >
        <div class="modal_confirm">
            <p :class="[{ modal_confirm__message_center: isCenterMode }]">
                <span v-for="(text, index) in msg" :key="`msg_${index}`">
                    {{ text }}<br />
                </span>
            </p>
            <div class="modal_confirm__buttons">
                <ui-button type="full" class="mr-20" @click="closeModal(false)">
                    Отменить
                </ui-button>
                <ui-button type="full" @click="closeModal(true)">
                    {{ confirmBtnText }}
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
export default {
    data() {
        return {
            beforeClose: null,
            msg: null,
            nameDoc: '',
            title: '',
            confirmBtnText: '',
            dopClass: '',
            isCenterMode: false,
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                resultFunction,
                msg,
                nameDoc,
                title,
                confirmBtnText,
                dopClass,
                isCenterMode,
            } = event.params;
            this.beforeClose = resultFunction || null;
            this.msg = msg;
            this.nameDoc = nameDoc;
            this.title = title || '';
            this.confirmBtnText = confirmBtnText;
            this.dopClass = dopClass || '';
            this.isCenterMode = isCenterMode || false;
        },
        closeModal(result = false) {
            this.$modal.hide('confirm');
            if (result && this.beforeClose) {
                this.beforeClose(this.nameDoc);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal_confirm {

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__message_center {
    text-align: center;
  }
}
</style>
