import store from '../store';

export const LoadIfCanAuthentication = (to, from, next) => {
    try {
        store
            .dispatch('AUTH_ME')
            .then((res) => {})
            .catch((err) => {
                console.log(err);
            });
    } catch (e) {
        console.error(e);
    }
    next();
};
