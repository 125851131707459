<template>
    <div class="documents_group_wrap">
        <div class="documents_group_header">
            {{ $t('Финансовые показатели') }}
        </div>

        <div class="documents_group_body">
            <div class="grid-x grid-margin-x mb-1">
                <div class="large-4 small-4 cell">
                    <label class="labelSimpleSelector labelSimpleSelector__finBlock">
                        Выберите отчетный год
                    </label>
                </div>
                <div class="large-8 small-8 cell mb-12">
                    <SimpleDictionary
                        :on-change="selectYear"
                        :value="year"
                        :options="optionsYear"
                        :error-text="$t('Необходимо выбрать')"
                        :placeholder="$t('Выберите отчетный год')"
                    />
                </div>
            </div>
        </div>

        <finance-block-table
            :finance-data="financeData"
            :year="year?.name"
            :income-tool-tip="$t('textFin1')"
            :tax-tool-tip="$t('textFin2')"
            @load-data="loadFinData"
            @save-finance-data="save"
        />
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';
import SimpleDictionary from '@/views/components/base/SimpleDictionary.vue';
import generateListYears from '@/helpers/generateListYears';
import { TYPE_ERRORS, TYPE_DIALOG_TITLE } from '@/constant';
import FinanceBlockTable from '@/views/modules/finance-block-table.vue';

export default {
    name: 'FinanceBlock',

    components: {
        SimpleDictionary,
        FinanceBlockTable,
    },

    data() {
        return {
            year: null,
            financeData: [],
        };
    },

    computed: {
        optionsYear() {
            const nowY = this.$moment().format('YYYY');
            return generateListYears(nowY - 1, 3);
        },
    },

    async created() {
        await this.loadFinData();
    },

    methods: {
        async loadFinData() {
            try {
                const res = await newApi('getFinance', { self: this });
                if (res) {
                    this.financeData = res;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async save(finData) {
            const paramsIn = {
                self: this,
                sendData: [finData],
            };

            try {
                const res = await newApi('saveFinance', paramsIn);
                if (res) {
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.SUCCESS,
                        msg: 'Данные загружены',
                        title: TYPE_DIALOG_TITLE.SUCCESS,
                    });
                }

                await this.loadFinData();
            } catch (e) {
                console.error(e);
            }
        },

        selectYear(val) {
            this.year = val;
        },
    },
};
</script>

<style lang='scss' scoped>
.mb-1 {
    margin-bottom: 12px;
}
</style>
