<template>
    <div class="pbiot">
        <InfoUser />

        <PageHeader
            title="Квалификационная анкета Претендента <br> на соответствие требованиям промышленной безопасности, <br> охраны труда и окружающей среды"
            html-title
            class="mb-20"
        />

        <section class="container">
            <div class="grid-x grid-margin-x">
                <p v-if="hasSuccessfulCheck" class="text-info">
                    В текущем году была успешная проверка.
                </p>

                <tab-menu-links v-if="isHasAccess && !hasSuccessfulCheck" />
                <router-view v-if="isHasAccess && !hasSuccessfulCheck" />
                <div
                    v-if="isHasAccess && !hasSuccessfulCheck"
                    class="worksheet_actions send-btn"
                >
                    <ui-button
                        :disabled="isDisabledButton"
                        :title="getSendTitle"
                        class="mr-20"
                        @click="sendData"
                    >
                        {{ $t('Отправить') }}
                    </ui-button>

                    <div v-if="checkData && !checkIncident" class="errorPbiot">
                        Внимание! Не все отмеченные файлы загружены или не заполнены инциденты.
                    </div>
                </div>
            </div>

            <div class="grid-x grid-margin-x">
                <div class="large-12 cell">
                    <div class="large-12 cell">
                        <div class="documents_group_wrap mt-24 mb-0">
                            <div class="documents_group_header">История проверок</div>
                            <div class="documents_group_body">
                                <history-package :key="history_reload" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { clearCashSelectDoc } from '@/helpers/pbiotHelper';
import TabMenuLinks from '@/views/components/pbiot/TabMenuLinks.vue';
import { STATUS_PBIOT, TYPE_ERRORS, TYPE_DIALOG_TITLE } from '@/constant';
import HistoryPackage from '@/views/components/pbiot/HistoryPackage.vue';
import { newApi } from '@/utils/new-api';

export default {
    name: 'MainPbiot',

    components: { TabMenuLinks, HistoryPackage },

    data() {
        return {
            history_reload: 0,
        };
    },

    computed: {
        hasSuccessfulCheck() {
            return this.$store.state.pbiot?.statusInfo?.successfulChecks ?? false;
        },

        checkIncident() {
            return this.$store.getters.checkIncident;
        },

        isHasAccess() {
            return this.$store.state.pbiot.status !== STATUS_PBIOT.OFF;
        },

        isDisabledButton() {
            return this.checkData || !this.checkIncident || this.disableSendByPackages;
        },

        pbiot() {
            return this.$store.state.pbiot.allData;
        },

        checkFile() {
            return this.$store.state.pbiot.documentChecks;
        },

        checkData() {
            const nowData = this.pbiot ? this.pbiot.docs : [];
            const loadCodeFiles = [];
            nowData.forEach((item) => {
                if (item.attributes[0].value !== 'incident') {
                    loadCodeFiles.push(item.attributes[0].value);
                }
            });

            let hasError = false;
            this.checkFile.forEach((item) => {
                if (item.value) {
                    const find = loadCodeFiles.find((el) => el.includes(item.code));
                    if (!find) {
                        hasError = true;
                    }
                }
            });

            return hasError;
        },

        disableSendByPackages() {
            return this.pbiot?.isSendBlockedByPackage;
        },

        getBlockReason() {
            return this.pbiot?.blockReason;
        },

        getSendTitle() {
            let title = '';

            if (this.checkData && !this.checkIncident) {
                title = 'Не все отмеченные файлы загружены или не заполнены инциденты.';
            }

            if (this.disableSendByPackages) {
                title = this.getBlockReason;
            }

            return title;
        },
    },

    async mounted() {
        await this.$store.dispatch('GET_STATUS_PBIOT', { self: this });

        if (this.isHasAccess) {
            await this.$store.dispatch('GET_PBIOT', { self: this });
        }
    },

    methods: {
        async sendData() {
            const result = await newApi('sendPbiot', { self: this });

            if (result) {
                const msg = 'Пакет документов успешно отправлен.';
                this.$modal.show('info', {
                    type: TYPE_ERRORS.SUCCESS,
                    msg,
                    title: TYPE_DIALOG_TITLE.SUCCESS,
                });

                await this.$store.dispatch('GET_PBIOT', { self: this });

                clearCashSelectDoc();

                this.history_reload++;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.send-btn {
    padding-left: 14px;
    align-items: center;
}
.asq_acc {
    .submit {
        width: 220px;
        margin-left: 18px;
    }
}
.text-info {
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #000;
    margin-bottom: 6px;
    display: block;
}

.mt-24 {
    margin-top: 24px;
}
.mb-0 {
    margin-bottom: 0px !important;
}
</style>
