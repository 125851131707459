<template>
    <div
        id="accordion3"
        class="accordion"
        data-accordion
        data-allow-all-closed="true"
    >
        <div class="accordion-item " data-accordion-item>
            <a href="#" class="accordion-title" data-cy="postadrr">
                <span class="ico_marker" />
                {{ $t('Фактический адрес') }}
                <span class="ico_accordion" />
            </a>

            <div class="accordion-content" data-tab-content>
                <template v-if="postalAddress && showData">
                    <BaseInput
                        main-class="inline_form_input"
                        label="Индекс"
                        name="postindex"
                        :value="postalAddress.index"
                        error-text="Неверный индекс. формат ######"
                        :mask-check="'######'"
                        :v-error="postIndexError"
                        :disabled="blockInput"
                        @input="updateField('index', $event)"
                    />

                    <div class="inline_form_input_select">
                        <label for="">{{ $t('Страна') }}</label>

                        <SearchDictionary
                            :form="postalAddress"
                            field-name="countryRef"
                            :type-dictionary="listD.COUNTRY"
                            dop-class="listselect"
                            :placeholder="$t('Введите страну')"
                            :force-disabled="blockInput"
                        />
                    </div>

                    <div class="inline_form_input_select">
                        <label for="">{{ $t('Регион') }}</label>

                        <SearchDictionary
                            :form="postalAddress"
                            field-name="regionRef"
                            :type-dictionary="listD.REGION"
                            hasparent-code
                            :parent-code="postalAddress.countryRef"
                            dop-class="listselect"
                            :placeholder="$t('Введите регион')"
                            :force-disabled="blockInput"
                        />
                    </div>

                    <BaseInput
                        main-class="inline_form_input"
                        label="Город"
                        name="postcity"
                        :value="postalAddress.city"
                        :disabled="blockInput"
                        @input="updateField('city', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Населенный пункт"
                        name="locality"
                        :value="postalAddress.locality"
                        :disabled="blockInput"
                        @input="updateField('locality', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Район"
                        name="disctrict"
                        :value="postalAddress.disctrict"
                        :disabled="blockInput"
                        @input="updateField('disctrict', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Улица"
                        name="poststreet"
                        :value="postalAddress.street"
                        :disabled="blockInput"
                        @input="updateField('street', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Дом"
                        name="posthome"
                        :value="postalAddress.building"
                        :disabled="blockInput"
                        @input="updateField('building', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Корпус"
                        name="postblock"
                        :value="postalAddress.block"
                        :disabled="blockInput"
                        @input="updateField('block', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Номер помещения"
                        name="postroom"
                        :value="postalAddress.room"
                        :disabled="blockInput"
                        @input="updateField('room', $event)"
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Этаж"
                        name="postfloor"
                        :value="postalAddress.floor"
                        :disabled="blockInput"
                        @input="updateField('floor', $event)"
                    />

                    <BaseCheckbox
                        :label="$t('Фактический адрес совпадает с юридическим адресом')"
                        name="postconfirm"
                        :value="isCopyMainAddr"
                        :disabled="blockInput"
                        @input="copyMainAddr"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { DICTIONARY_LIST } from '@/constant';
import SearchDictionary from '@/views/components/base/SearchDictionary';
import clearReactive from '@/helpers/clearReactive';

export default {
    name: 'LegalAddressResident',

    components: {
        SearchDictionary,
    },

    props: {
        allData: {
            type: Object,
            required: true,
        },

        v: {},

        blockInput: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            listD: DICTIONARY_LIST,
            showData: true,
        };
    },

    computed: {
        regData() {
            const { allData } = this;

            if (allData.data) {
                if (allData.data.legal) {
                    return allData.data.legal;
                }

                if (allData.data.individual) {
                    return allData.data.individual;
                }

                if (allData.data.nonResident) {
                    return allData.data.nonResident;
                }
            }

            return false;
        },

        postalAddress() {
            return this.regData ? this.regData.postalAddress : null;
        },

        isCopyMainAddr() {
            if (this.allData.data.legal.postalAddress && this.allData.data.legal.factAddress) {
                const postAddr = this.allData.data.legal.postalAddress;
                const { factAddress } = this.allData.data.legal;

                if (JSON.stringify(postAddr) === JSON.stringify(factAddress)) {
                    return true;
                }
            }

            return false;
        },

        postIndexError() {
            return this.v.allData.data.legal.postalAddress.index || this.v.allData.data.individual.postalAddress.index;
        },
    },

    async mounted() {
        const accordion3 = new Foundation.Accordion($('#accordion3'));
    },

    methods: {
        updateField(name, val) {
            this.regData.postalAddress[name] = val;
        },

        copyMainAddr() {
            try {
                if (this.allData.data.legal.factAddress) {
                    const copyField = clearReactive(this.allData.data.legal.factAddress);

                    this.allData.data.legal.postalAddress = copyField;

                    this.showData = false;

                    this.$nextTick(() => {
                        this.showData = true;
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>
