<template>
    <div>
        <div v-if="contractors.length">
            <ve-table
                :columns="columns"
                :table-data="tableData"
                border-x
            />

            <ve-pagination
                :page-index="paginator.pageNumber"
                :page-size="paginator.pageSize"
                :total="contractors.length"
                class="table-pagination"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>

        <div v-else class="empty-data">Нет данных.</div>
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'GeneralContractors',

    data() {
        return {
            contractorsData: [],
            paginator: {
                pageNumber: 1,
                pageSize: 10,
            },
            columns: [
                {
                    key: 'a',
                    title: 'КССС',
                    field: 'contractorCode',
                },
                {
                    key: 'b',
                    title: 'Наименование',
                    field: 'name',
                },
                {
                    key: 'c',
                    title: 'ИНН',
                    field: 'inn',
                },
                {
                    key: 'd',
                    title: 'КПП',
                    field: 'kpp',
                },
                {
                    key: 'e',
                    title: 'ОГРН/ОГРНИП',
                    field: 'ogrn',
                },
                {
                    key: 'f',
                    title: 'Личный кабинет',
                    renderBodyCell: ({ row }) => {
                        if (row.lkExists) {
                            return <div class="check_mark"></div>;
                        }
                    },
                },
                {
                    key: 'g',
                    title: 'Анкета ПБ, ОТ и ОС',
                    renderBodyCell: ({ row }) => {
                        if (row.pbiotComplete) {
                            return <div class="check_mark"></div>;
                        }
                    },
                },
                {
                    key: 'h',
                    title: 'Обязательные документы',
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        if (row.loadedAllRequiredDocuments) {
                            return <div class="check_mark"></div>;
                        }
                    },
                },
            ],
        };
    },

    computed: {
        contractors() {
            return this.contractorsData;
        },

        tableData() {
            const start = (this.paginator.pageNumber - 1) * this.paginator.pageSize;

            const end = start + this.paginator.pageSize;

            return this.contractorsData.slice(start, end);
        },
    },

    async created() {
        await this.loadData();
    },

    methods: {
        async loadData() {
            const paramsIn = {
                self: this,
            };

            try {
                this.contractorsData = await newApi('getGeneralContractors', paramsIn);
            } catch (err) {
                console.error(err);
            }
        },

        pageNumberChange(page) {
            this.paginator.pageNumber = page;
        },

        pageSizeChange(pageSize) {
            this.paginator.pageSize = pageSize;
        },
    },
};
</script>
