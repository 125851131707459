<template>
    <div>
        <div
            class="file_to_upload small"
            :class="{ error: error.length, success: !error.length }"
        >
            <button
                v-if="hasDelete"
                class="delete_file"
                @click="$emit('delfile', file)"
            />
            <div class="file_name" @click="downloadFile(file)">
                {{ file.name }}
            </div>
        </div>
        <template v-if="error.length">
            <div
                v-for="(err, index) in errorMessage"
                :key="`error-${index}`"
                class="error_text"
            >
                <div v-for="(message, idx) in err" :key="`message-${idx}`">
                    {{ message }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'ItemFile',
    props: {
        file: { type: Object, required: true }, // FileInfoDTO (id, name, size, uploadDate)
        error: { type: Array, default: () => [] },
        hasDelete: { type: Boolean, default: true },
        hasLoad: { type: Boolean, default: true },
    },

    data() {
        return {
            errorMessage: [],
        };
    },

    watch: {
        error(val) {
            this.errorMessage = val.map((it) => it.messages);
        },
    },

    methods: {
        async downloadFile(fileIn) {
            if (!this.hasLoad) {
                return;
            }
            try {
                const paramsIn = {
                    self: this,
                    id: fileIn.id,
                };
                const data = await newApi(
                    'getFile',
                    paramsIn,
                );
                const blob = new Blob([data], { type: 'application/octect-stream' });
                const fileName = fileIn.name;
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.file_name {
  cursor: pointer;
}
</style>
