<template>
    <div class="large-12 cell">
        <file-upload-progress-bar
            class="pbiot-progress-bar"
            :file-upload-statistic="fileUploadStatistic"
        />
        <div class="documents_group_wrap mt-24 mb-0">
            <div class="documents_group_header">
                {{ title }}
            </div>
            <div class="documents_group_body">
                <document-multi-upload-form
                    v-for="(doc, index) in docs"
                    :key="index"
                    :ugid="doc.scheme.ugid"
                    :accept-file="doc.scheme.typeDoc.fileExtensions"
                    :size="doc.scheme.typeDoc.maxSize * 0.00097656"
                    :label="doc.scheme.typeDoc.name"
                    :size-kb="doc.scheme.typeDoc.maxSize"
                    :max-count="doc.scheme.typeDoc.maxCount"
                    :has-files="doc.files"
                    :loader-p-b-i-t="loaderPBIT"
                    :is-mode-p-b-i-o-t="true"
                    :code-p-b-i-o-t="doc.codePBIOT"
                    :has-load="false"
                    :has-check-box="true"
                    :val-check="getValCheck(doc)"
                    :has-warning-info="false"
                    :disabled="disabled"
                    :warning-info="warningInfo"
                    :check-box-alert="doc.alertMessage"
                    @file-added="fileAdded"
                    @setCheck="setCheck(doc, $event)"
                    @setCheckAll="setCheckAll"
                    @deleteFile="deleteFile"
                />
            </div>
        </div>

        <div v-if="showLinkAnketa" class="linkAnketa">
            <ui-button
                :disabled="!checkIncident"
                tooltip="На шаблон анкеты влияют загруженные документы и инциденты"
                @click="loadIncident()"
            >
                Cкачать шаблон анкеты
            </ui-button>
        </div>
    </div>
</template>

<script>
import DocumentMultiUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentMultiUploadForm.vue';
import { convertFile } from '@/helpers/converterFilePBIOTtoDocFile';
import FileUploadProgressBar from '@/views/modules/pbiot/file-upload-progress-bar.vue';
import { newApi } from '@/utils/new-api';

export default {
    name: 'MainTemplateDocPbiot',

    components: { DocumentMultiUploadForm, FileUploadProgressBar },

    props: {
        nameField: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        showLinkAnketa: {
            type: Boolean,
            default: false,
        },
        warningInfo: {
            type: Array,
        },
    },

    data() {
        return {
            disabled: false,
            fileUploadStatistic: {
                totalSize: 0,
                maxTotalSize: 0,
                isThresholdReached: false,
            },
        };
    },

    computed: {
        docs() {
            return this.$store.getters.getFormPbiot[this.nameField];
        },

        checkIncident() {
            return this.$store.getters.checkIncident;
        },
    },

    async created() {
        await this.getFileStatistic();
        await this.$store.dispatch('GET_PBIOT', { self: this });
    },

    methods: {
        setCheckAll() {
            this.$store.commit('SET_CHECK_DOC_PBIOT_ALL');
        },

        setCheck(doc, value) {
            const { codePBIOT } = doc;
            this.$store.commit('SET_CHECK_DOC_PBIOT', {
                code: codePBIOT,
                value,
                getters: this.$store.getters,
            });
        },

        getValCheck(doc) {
            const { codePBIOT } = doc;
            const findCode = this.$store.state.pbiot.documentChecks.find(
                (item) => item.code == codePBIOT,
            );
            if (codePBIOT == 'questionnaire_1') {
                this.disabled = true;
            }
            return findCode ? findCode.value : false;
        },

        async getFileStatistic() {
            try {
                this.fileUploadStatistic = await newApi('getFileStatistics');
            } catch (err) {
                console.error(err);
            }
        },

        async deleteFile(file) {
            const paramsIn = {
                self: this,
                type: file.code,
            };
            await newApi('deletePbiotFile', paramsIn);
            await this.$store.dispatch('GET_PBIOT', { self: this });
            await this.getFileStatistic();
        },

        async fileAdded() {
            await this.$store.dispatch('GET_PBIOT', { self: this });
        },

        async loaderPBIT(paramIn, type) {
            try {
                const res = await newApi('loadFilePbiot', {
                    self: this,
                    sendData: {
                        file: paramIn.file[0],
                        type,
                    },
                });

                await this.getFileStatistic();

                return convertFile(res.file);
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async loadIncident() {
            const fileName = 'Incident.pdf';
            try {
                const data = await newApi('getIncidentPbiot', {
                    self: this,
                });
                const blob = new Blob([data], { type: 'application/octet-stream' });
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style lang="scss">
.pbiot-progress-bar {
    padding: 20px 0px 0px 0px;
}

.mt-24 {
    margin-top: 24px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
</style>
