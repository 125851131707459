<template>
    <div :class="dopClass">
        <v-select
            class="search_select"
            :placeholder="$t('Введите БИК/Наименование банка')"
            label="name"
            :filterable="false"
            :options="options"
            :value="form[fieldName]"
            :disabled="forceDisabled"
            @search="onSearch"
            @input="selectData($event)"
            @search:blur="unFocus"
        >
            <template #list-header>
                <li class="top_select">
                    {{ $t("Выберите вариант или продолжите ввод") }}
                </li>
            </template>
            <template #option="{ name, bik}">
                <div :key="bik" class="select_company_title" :title="name">
                    {{ name }}
                </div>
                <div :key="'_' + bik" class="select_company_info">
                    {{ bik }}
                </div>
            </template>

            <template #selected-option="{ name }">
                <div>{{ name }}</div>
            </template>
            <template #no-options>
                {{ $t("Нет результатов поиска") }}
            </template>
        </v-select>
        <div v-if="hasError" class="has-error">
            <p>{{ errorText }}</p>
        </div>
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'SearchBank',
    props: {
        form: { type: Object, required: true },
        fieldName: { type: String, required: true },
        errorText: { type: String, default: '' },
        vError: { type: Object },
        dopClass: { type: String, default: '' },
        forceDisabled: { type: Boolean, default: false },
    },
    data() {
        return {
            options: [],
        };
    },
    computed: {
        hasError() {
            const { vError } = this;
            if (vError && vError.$dirty && vError.$error) {
                return true;
            }
            return false;
        },
        touch() {
            const { vError } = this;
            if (vError && vError.$dirty) {
                return true;
            }
            return false;
        },
        classesComp() {
            let res = this.classes;
            res += this.hasError ? ' error ' : this.touch ? ' success ' : '';
            return res;
        },
    },

    methods: {
        selectData(event) {
            this.$set(this.form, this.fieldName, event);
        },
        unFocus() {
            if (this.vError) {
                this.vError.$touch();
            }
            this.$emit('blur');
        },

        onSearch(search) {
            if (search.length < 3) return false;
            this.search(search, this);
        },
        search: async (search, vm) => {
            const paramsIn = {
                self: vm,
                sendData: {
                    search,
                },
            };
            try {
                const res = await newApi('bankBikSearch', paramsIn);
                vm.options = res;
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.select_company_title {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_select {
    min-height: 48px;
    height: inherit;
    .vs__dropdown-toggle {
        min-height: 48px;
        height: inherit;
    }
}

.search_select.vs--open {
    .vs__dropdown-toggle {
        overflow: hidden;
    }
    .vs__selected-options {
        overflow: hidden;
    }
    .vs__dropdown-toggle {
        overflow: hidden;
    }
}

.top_select {
    line-height: 1 !important;
}
</style>
