<template>
    <div>
        <InfoUser />
        <PageHeader
            :title="selectedChat ? selectedChat.title : $t('Коммуникации')"
            :back-fun="closeChat"
        />

        <section class="communication_wrap">
            <div class="container">
                <div class="grid-x grid-margin-x">
                    <template v-if="selectedChat">
                        <div class="large-12 cell">
                            <form class="communication_form">
                                <div class="communication_form_header" @click="closeChat">
                                    {{ selectedChat.title }}
                                </div>
                                <MessageList ref="messageList" :messages="messages" />
                                <MesSend ref="sendForm" @send="send" @writing="sendWriting" />
                            </form>
                        </div>
                    </template>

                    <template v-else>
                        <div v-for="(item, ind) in chats" :key="ind" class="large-12 cell">
                            <div class="communication_form_header" @click="openChat(item)">
                                {{ item.title }}
                                <div class="communication_form_header__not">
                                    <span>{{
                                        countMessage && countMessage[item.id]
                                            ? countMessage[item.id]
                                            : 0
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { debounce } from 'lodash';

import MessageList from '@/views/components/community/MessageList';
import MesSend from '@/views/components/community/MesSend';
import { newApi } from '@/utils/new-api';
import clearReactive from '@/helpers/clearReactive';

export default {
    components: {
        MessageList,
        MesSend,
    },
    data() {
        return {
            messages: [],
            connected: false,
            historyAttempt: false,
            newChat: false,
            selectedChat: null,
            chats: [],
            createChatTitle: null,
            currentSubscriptions: [],
        };
    },
    computed: {
        countMessage() {
            const { chatInfo } = this.$store.state.common;
            if (chatInfo) {
                const res = {};
                chatInfo.forEach((element) => {
                    res[element.chatId] = element.count;
                });
                return res;
            }
            return [];
        },
    },
    methods: {
        sendLastRead: debounce(async (message, vm) => {
            // последнее не мое сообщение
            if (message) {
                const clearMess = clearReactive(message);
                const findNoRead = clearMess.filter((mes) => mes.author == vm.selectedChat.smbk && mes.isNew);
                if (findNoRead && findNoRead.length > 0) {
                    const converData = findNoRead.map((item) => item.id);
                    const paramsIn = {
                        self: vm,
                        sendData: converData,
                    };
                    try {
                        await newApi('markReady', paramsIn);
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        }, 1000),
        async initData() {
            try {
                const paramsIn = {
                    self: this,
                };
                const res = await newApi('getChats', paramsIn);
                this.chats = res;
            } catch (e) {
                console.error('ошибка в получение списка чатов пользователя');
            }
        },

        async loadChat(repeat = true) {
            try {
                if (this.selectedChat) {
                    const paramsIn = {
                        self: this,
                        chatId: this.selectedChat.id,
                    };
                    const res = await newApi('getChatHistory', paramsIn);

                    if (repeat) {
                        setTimeout(async () => {
                            await this.loadChat();
                        }, 60000);
                    }
                    this.messages = res;
                    this.sendLastRead(this.messages, this);
                }
            } catch (e) {
                console.error('ошибка в получение истории пользователя');
            }
        },

        async openChat(chat) {
            this.selectedChat = chat;
            this.messages = [];
            await this.loadChat();
        },
        closeChat() {
            this.selectedChat = null;
            this.messages = [];
        },
        sendWriting() {},

        async send(msg) {
            try {
                const paramsIn = {
                    self: this,
                    chatId: this.selectedChat.id,
                    sendData: {
                        author: this.selectedChat.pa,
                        payload: msg,
                        target: this.selectedChat.smbk,
                    },
                };
                await newApi('sendMessage', paramsIn);
                await this.loadChat(false);
            } catch (e) {
                console.error('ошибка отправки сообщения ');
            }
        },
    },
    mounted() {
        this.initData();
    },
    beforeDestroy() {
        this.closeChat();
    },
};
</script>

<style scoped lang='scss'>
.communication_wrap {
  .large-12{
    margin-bottom: 32px;
  }
}
</style>
