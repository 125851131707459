<template>
    <div class="registration-legal-entity">
        <div class="registration-legal-entity__registration-form">
            <div class="registration-form__header">
                <div class="header__title">{{ $t('Регистрация нерезидента') }}</div>

                <reg-info-button />
            </div>

            <div class="registration-form__content">
                <div v-if="showSearch" class="content__search">
                    <non-resident-search
                        :disabled="isManualMode"
                        @value-changed="onSearchChanged"
                        @blur="checkDuplicate"
                    />

                    <base-checkbox
                        v-model="isManualMode"
                        label="Поиск не дал результатов"
                    />
                </div>

                <section>
                    <base-input
                        v-model="selectedCompany.name"
                        label="Наименование*"
                        type="text"
                        name="name"
                        placeholder="Полное наименование контрагента"
                        :disabled="!isManualMode"
                        :v-error="$v.selectedCompany.name"
                        error-text="Введите Наименование"
                    />

                    <base-input
                        v-model="selectedCompany.taxId"
                        label="Tax ID*"
                        type="text"
                        name="taxId"
                        placeholder="Tax ID (код налогоплательщика)"
                        :disabled="!isManualMode"
                        :v-error="$v.selectedCompany.taxId"
                        error-text="Введите Tax ID. Макс 25 знаков"
                        :force-error="isDuplicate"
                        force-text-error="Контрагент с таким  Tax ID уже существует в системе."
                        @blur="checkDuplicate"
                    />

                    <base-input
                        v-model="selectedCompany.actualAddress.index"
                        label="Индекс*"
                        type="text"
                        name="index"
                        placeholder="Индекс"
                        :disabled="!isManualMode"
                        :v-error="$v.selectedCompany.actualAddress.index"
                        error-text="Введите Индекс"
                    />

                    <div class="registration-form__selector-block">
                        <div class="selector-block__label">{{ $t('Страна') }}</div>

                        <search-dictionary
                            :form="selectedCompany.actualAddress"
                            field-name="countryRef"
                            :type-dictionary="list.COUNTRY"
                            :placeholder="$t('Введите страну')"
                            :force-disabled="!isManualMode"
                            :on-change="checkDuplicate"
                            :v-error="$v.selectedCompany.actualAddress.countryRef"
                            :error-text="$t('Введите страну')"
                        />
                    </div>

                    <div class="registration-form__selector-block">
                        <div class="selector-block__label">{{ $t('Регион') }}</div>

                        <search-dictionary
                            :form="selectedCompany.actualAddress"
                            field-name="regionRef"
                            :type-dictionary="list.REGION"
                            :placeholder="$t('Введите регион')"
                            :force-disabled="!isManualMode"
                            :has-parent-code="isManualMode"
                            :parent-code="selectedCompany.actualAddress.countryRef"
                            :v-error="$v.selectedCompany.actualAddress.regionRef"
                            :error-text="$t('Введите регион')"
                        />
                    </div>

                    <base-input
                        v-model="selectedCompany.actualAddress.city"
                        label="Город*"
                        type="text"
                        name="city"
                        placeholder="Город"
                        :v-error="$v.selectedCompany.actualAddress.city"
                        error-text="Введите Город (Макс. 40 символов)"
                        :disabled="!isManualMode"
                    />

                    <base-input
                        v-model="selectedCompany.actualAddress.locality"
                        label="Населенный пункт*"
                        type="text"
                        name="locality"
                        placeholder="Населенный пункт"
                        :v-error="$v.selectedCompany.actualAddress.locality"
                        error-text="Введите Населенный пункт (Макс. 64 симв.)"
                        :disabled="!isManualMode"
                    />

                    <base-input
                        v-model="selectedCompany.actualAddress.disctrict"
                        label="Район"
                        type="text"
                        name="disctrict"
                        placeholder="Район"
                        :v-error="$v.selectedCompany.actualAddress.disctrict"
                        error-text="Введите Район (Макс. 64 симв.)"
                        :disabled="!isManualMode"
                    />

                    <base-input
                        v-model="selectedCompany.actualAddress.street"
                        label="Улица/Дом/Корпус/Номер помещения*"
                        type="text"
                        name="address"
                        placeholder="Улица/Дом/Корпус/Номер помещения"
                        :v-error="$v.selectedCompany.actualAddress.street"
                        error-text="Введите Улица/Дом/Корпус/Номер помещения (Макс. 64 симв.)"
                        :disabled="!isManualMode"
                    />
                </section>

                <section>
                    <upload-form-reg
                        :label="$t('Устав компании*')"
                        :add-file="addFiledCharter"
                        :single-file="true"
                        :has-files="files.dCharter ?? []"
                        :max-count="1"
                        :size="10"
                    />

                    <upload-form-reg
                        :label="$t('Свидетельство постановки на учёт*')"
                        :add-file="addFileCertificateOfRegistration"
                        :single-file="true"
                        :has-files="files.dCertificateOfRegistration1"
                        :max-count="1"
                        :size="10"
                    />

                    <upload-form-reg
                        :label="$t('Свидетельство регистрации*')"
                        :add-file="addFileCertificateOfRegistration2"
                        :single-file="true"
                        :has-files="files.dCertificateOfRegistration2"
                        :max-count="1"
                        :size="10"
                    />

                    <upload-form-reg
                        :label="$t('Письмо*')"
                        :add-file="addFileCompanyForm"
                        :single-file="true"
                        :has-files="files.dCompanyForm"
                        :max-count="1"
                        :size="10"
                        :has-quest="true"
                    >
                        <div class="info_block">
                            <p>
                                {{ $t('letter_text') }}
                            </p>
                        </div>
                    </upload-form-reg>
                </section>
            </div>

            <div v-if="isDuplicate" class="registration-form__duplicate-error">
                {{
                    $t(
                        'Контрагент с таким Tax ID уже существует в системе. Свяжитесь со службой поддержки'
                    )
                }}
            </div>

            <ui-button
                :disabled="$v.$invalid"
                type="full"
                @click="sendData"
            >
                {{ $t('Зарегистрироваться') }}
            </ui-button>
        </div>

        <div class="registration-legal-entity__footer">
            <router-link class="footer__link" to="/account/registration">
                {{ $t('Назад') }}
            </router-link>

            <button class="footer__link" href="#" @click="logout">{{ $t('Выход') }}</button>
        </div>
    </div>
</template>

<script>
import {
    required, requiredIf, maxLength, sameAs,
} from 'vuelidate/lib/validators';

import { TYPE_ERRORS, TYPE_DIALOG_TITLE, DICTIONARY_LIST } from '@/constant';
import { newApi } from '@/utils/new-api';
import UploadFormReg from '@/views/components/base/UploadFormReg';
import SearchDictionary from '@/views/components/base/SearchDictionary';
import NonResidentSearch from '@/views/common/non-resident-search.vue';

export default {
    name: 'RegistrationNoResident',

    components: {
        SearchDictionary,
        UploadFormReg,
        NonResidentSearch,
    },

    data() {
        return {
            list: DICTIONARY_LIST,
            showSearch: true,
            isManualMode: false,
            isDuplicate: false,
            selectedCompany: {
                taxId: null,
                name: null,
                actualAddress: {
                    index: null,
                    countryRef: null,
                    regionRef: null,
                    city: null,
                    locality: null,
                    disctrict: null,
                    street: null,
                },
            },
            files: {
                dCharter: null,
                dCertificateOfRegistration1: null,
                dCertificateOfRegistration2: null,
                dCompanyForm: null,
            },
        };
    },

    validations: {
        isDuplicate: {
            sameAs: sameAs(() => false),
        },
        selectedCompany: {
            taxId: {
                required,
                maxLength: maxLength(25),
            },
            name: {
                required,
            },
            actualAddress: {
                index: {
                    required,
                },
                countryRef: {
                    required,
                },
                city: {
                    maxLength: maxLength(40),
                    required: requiredIf(function () {
                        return !this.selectedCompany.actualAddress.locality;
                    }),
                },
                locality: {
                    maxLength: maxLength(64),
                    required: requiredIf(function () {
                        return !this.selectedCompany.actualAddress.city;
                    }),
                },
                disctrict: {
                    maxLength: maxLength(64),
                },
                street: {
                    required,
                    maxLength: maxLength(64),
                },
            },
        },

        files: {
            dCharter: {
                required,
            },
            dCertificateOfRegistration1: {
                required,
            },
            dCertificateOfRegistration2: {
                required,
            },
            dCompanyForm: {
                required,
            },
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.showSearch = false;
            this.isManualMode = true;
            this.loadNonResident(this.$route.params.id);
        }
    },

    methods: {
        logout() {
            this.$store.dispatch('AUTH_LOGOUT', { self: this });
        },

        onSearchChanged(event) {
            this.selectedCompany = event;
        },

        async sendData() {
            const paramsIn = {
                self: this,
                sendData: {
                    info: this.selectedCompany,
                    files: {
                        dCharter: this.files.dCharter[0],
                        dCertificateOfRegistration1: this.files.dCertificateOfRegistration1[0],
                        dCertificateOfRegistration2: this.files.dCertificateOfRegistration2[0],
                        dCompanyForm: this.files.dCompanyForm[0],
                    },
                    contractorCode: this.selectedCompany.contractorCode,
                    profileId: this.selectedCompany.profileId,
                },
            };

            try {
                await newApi('nonresidentRegistration', paramsIn);

                await this.$store.dispatch('AUTH_ME');

                this.$modal.show('info', {
                    type: TYPE_ERRORS.SUCCESS,
                    msg: this.$t('Регистрация завершена'),
                    title: TYPE_DIALOG_TITLE.INFO,
                    closeEvent: () => {
                        this.$router.push('/');
                    },
                });
            } catch (err) {
                console.error(err);
            }
        },

        async loadNonResident(id) {
            try {
                const params = {
                    self: this,
                    id,
                };

                const result = await newApi('getNonResidentInRegistration', params);

                if (result) {
                    this.selectedCompany = result;

                    this.checkDuplicate();
                }
            } catch (e) {
                console.error(e);
            }
        },

        async checkDuplicate() {
            if (
                !this.selectedCompany.taxId
                || !this.selectedCompany.actualAddress?.countryRef?.code
            ) {
                return;
            }

            const paramsIn = {
                self: this,
                taxId: this.selectedCompany.taxId,
                countryCode: this.selectedCompany.actualAddress.countryRef.code,
            };

            const res = await newApi('duplicateCheck', paramsIn);

            this.isDuplicate = res ? !res.isFree : false;

            return res?.isFree;
        },

        addFiledCharter(file) {
            this.files.dCharter = file;
        },

        addFileCertificateOfRegistration(file) {
            this.files.dCertificateOfRegistration1 = file;
        },

        addFileCertificateOfRegistration2(file) {
            this.files.dCertificateOfRegistration2 = file;
        },

        addFileCompanyForm(file) {
            this.files.dCompanyForm = file;
        },
    },
};
</script>

<style lang="scss" scoped>
.registration-legal-entity {
    width: 500px;

    &__registration-form {
        position: relative;
        background: white;
        padding: 70px 32px 32px;
        margin-bottom: 30px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
    }
}

.registration-form {
    &__header {
        padding-bottom: 30px;
    }

    &__content {
        padding-bottom: 30px;
    }

    &__selector-block {
        margin-bottom: 15px;
    }

    &__duplicate-error {
        padding-bottom: 30px;
        color: #db2b37;
    }
}

.selector-block {
    &__label {
        display: inline-block;
        font-size: 20px;
        color: #989898;
        margin-bottom: 12px;
    }
}

.header {
    &__title {
        font: 26px OpenSansBold, sans-serif;
        text-align: center;
        color: black;
    }
}

.content {
    &__search {
        margin-bottom: 20px;
    }
}

.footer {
    &__link {
        font: 20px CuprumBold, sans-serif;
        cursor: pointer;
        color: #989898;
        text-decoration: none;
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: solid 1px rgba(152, 152, 152, 0.3);
    }
}
</style>
