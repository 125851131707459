<template>
    <div>
        <InfoUser />

        <PageHeader
            :title="'Взаимоотношения'"
            class="mb-20"
        />

        <section class="relationships__warning mb-20">
            <div class="container">
                Если субподрядчик еще не зарегистрирован в личном кабинете, то необходимо связаться с
                ним и уведомить о необходимости регистрации личного кабинета контрагента (в отношении
                производителей указанное действие является необязательным).
            </div>
        </section>

        <section class="container">
            <AccordionBase id="actualSubcontractors">
                <AccordionItem
                    :background-color="'#db2b37'"
                    :text-color="'white'"
                    :has-minus="false"
                    :has-icon="false"
                    force-show
                    :title="'Действующие'"
                >
                    <ActualRelationships />
                </AccordionItem>
            </AccordionBase>

            <AccordionBase id="supposedSubcontractors">
                <AccordionItem
                    :background-color="'#db2b37'"
                    :text-color="'white'"
                    :has-minus="false"
                    :has-icon="false"
                    force-show
                    :title="'Предполагаемые для участия в тендерах'"
                >
                    <Subcontractor />
                </AccordionItem>
            </AccordionBase>
        </section>
    </div>
</template>

<script>
import ActualRelationships from '@/views/components/relations/ActualRelationships';
import AccordionBase from '@/views/components/base/accordions/AccordionBase.vue';
import AccordionItem from '@/views/components/base/accordions/AccordionItem';
import Subcontractor from '@/views/components/subcontractor/Subcontractor';

export default {
    name: 'Relationships',

    components: {
        Subcontractor,
        AccordionBase,
        AccordionItem,
        ActualRelationships,
    },
};
</script>

<style lang="scss" scoped>
.relationships {

    &__warning {
        color: #474a51;
        background: rgba(213, 167, 45, 0.19);
        padding: 30px 60px;
        font-size: 18px;
        font-weight: bold;
    }
}
</style>
