<template>
    <div
        id="accordion5"
        class="accordion mb_0"
        data-accordion
        data-allow-all-closed="true"
    >
        <div class="accordion-item is-active" data-accordion-item>
            <a href="#" class="accordion-title" data-cy="bankdata">
                <span class="ico_town" />
                {{ $t('Реквизиты банка') }}
                <span class="ico_accordion" />
            </a>

            <div class="accordion-content" data-tab-content>
                <div class="bank_account">
                    <div class="input_label">
                        {{ $t('БИК/Наименование банка') }}
                    </div>

                    <div class="bank_account_content">
                        <SearchBank
                            :form="searchData"
                            field-name="search"
                            dop-class="bank_search"
                            :error-text="$t('Необходимо найти банк')"
                            :v-error="$v.searchData.search"
                            :force-disabled="blockInput"
                        />
                    </div>
                </div>

                <div class="bank_account">
                    <div class="input_label">
                        {{ $t('Валюта счёта') }}
                    </div>

                    <div class="bank_account_content">
                        <SearchDictionary
                            :form="searchData"
                            field-name="searchCurr"
                            dop-class="currency_wrapper"
                            :error-text="$t('Необходимо выбрать валюту')"
                            :v-error="$v.searchData.searchCurr"
                            :placeholder="$t('Введите валюту')"
                            :force-disabled="blockInput"
                        />

                        <ui-button
                            :disabled="$v.$invalid || hasDuplicate"
                            :title="$v.$invalid || hasDuplicate
                                ? $t('Ошибка валидации или есть дубли')
                                : ''"
                            @click="addNewAccount"
                        >
                            {{ $t('Добавить банк') }}
                        </ui-button>
                    </div>
                </div>

                <div class="bank_account_item">
                    <div class="bank_account_item_label">
                        {{ $t('Добавленные банки') }}
                    </div>

                    <bank-info
                        v-for="accountBank in bankDetails"
                        :key="accountBank.id"
                        :account-bank="accountBank"
                        @delAccount="delAccount"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import SearchBank from '@/views/components/base/SearchBank';
import SearchDictionary from '@/views/components/base/SearchDictionary';

import BankInfo from './bank-info';

export default {
    name: 'BankDetails',

    components: {
        BankInfo,
        SearchBank,
        SearchDictionary,
    },

    props: {
        allData: {
            type: Object,
            required: true,
        },

        blockInput: {
            type: Boolean,
            default: false,
        },
    },

    validations: {
        searchData: {
            search: {
                required,
            },
            searchCurr: {
                required,
            },
        },
    },

    data() {
        return {
            searchData: {
                search: null,
                searchCurr: null,
            },
        };
    },

    computed: {
        bankDetails() {
            const { allData } = this;

            if (allData.data && allData.bankDetails) {
                return allData.bankDetails;
            }
            return false;
        },

        hasDuplicate() {
            const { search, searchCurr } = this.searchData;

            const template = {
                bankInfo: search,
                currencyCode: searchCurr ? searchCurr.code : null,
            };

            return this.findDuplicate(template);
        },
    },

    mounted() {
        const accordion5 = new Foundation.Accordion($('#accordion5'));
    },

    methods: {
        delAccount(accountBankDel) {
            const { allData } = this;

            let now = allData.bankDetails;

            now = now.filter((item) => !(item.bankInfo.bik == accountBankDel.bankInfo.bik
                && item.currencyCode == accountBankDel.currencyCode));
            this.allData.bankDetails = now;
        },

        addNewAccount() {
            const { search, searchCurr } = this.searchData;

            const template = {
                id: null,
                bankInfo: search,
                currencyCode: searchCurr.code,
            };

            this.allData.bankDetails.push(template);
        },

        findDuplicate(template) {
            const { bankDetails } = this.allData;

            const find = bankDetails && bankDetails.length > 0
                ? bankDetails.find((item) => item.bankInfo.bik == template.bankInfo.bik && item.currencyCode == template.currencyCode)
                : false;

            return find;
        },
    },
};
</script>

<style lang='scss'>
.bank_search {
    width: 100%;
}
</style>
