<template>
    <div>
        <InfoUser />

        <PageHeader :title="$t('Доступ в ИСУ Снабжение')" />
        <template>
            <section v-if="status == tender_status.NOT_CONFIRMED" class="main_trend">
                <div class="container">
                    <div class="grid-x grid-margin-x">
                        <accept-tender
                            class="large-12 cell"
                            :accept="acceptSend"
                            :confirm-val="confirm"
                            :set-confirm="setConfirm"
                            :has-save-data="hasSaveData"
                        />
                    </div>
                </div>
            </section>
            <section v-else class="main_trend">
                <div class="container">
                    <div class="grid-x grid-margin-x">
                        <div class="large-12 cell">
                            <TenderInfo :tender-info="tenderInfo" :status="status" />
                            <!-- TODO: LK-1554 Скрыто до 01.05.2024 <TenderStopFactors v-show="showTenderStopFactors && isShowStopFactors" /> -->

                            <accept-tender
                                v-if="dataShow.showDopAccept && !haslicenceAcceptedDate"
                                class="mb-16"
                                :accept="acceptSend"
                                :confirm-val="confirm"
                                :set-confirm="setConfirm"
                                :has-save-data="hasSaveData"
                            />

                            <div
                                v-if="
                                    dataShow.showRuleBlock &&
                                        ((dataShow.showDopAccept && haslicenceAcceptedDate) ||
                                            !dataShow.showDopAccept)
                                "
                                class="tender_main_wrap"
                            >
                                <div class="tender_main_wrap_header info">
                                    {{ $t('Условия получения доступа') }}
                                </div>
                                <div class="tender_main_wrap_body">
                                    <p>
                                        {{ dataShow.text }}
                                    </p>
                                </div>

                                <div class="tender_main_wrap_footer">
                                    <div v-if="showChouseCur" class="text_first">
                                        Оплатить в валюте
                                    </div>
                                    <div v-if="showChouseCur" class="select_wrap">
                                        <div id="app">
                                            <SearchDictionary
                                                :form="searchData"
                                                field-name="searchCurr"
                                                dop-class="currency_wrapper"
                                                :v-error="$v.searchData.searchCurr"
                                                :placeholder="$t('Введите валюту')"
                                                :has-seach="false"
                                                :filter-load-data="filterLoadData"
                                                has-filter
                                            />
                                        </div>
                                    </div>
                                    <div v-if="dataShow.showLoadBtn" class="mr-20">
                                        <ui-button
                                            :disabled="$v.$invalid"
                                            @click="loadFile()"
                                        >
                                            {{ $t('Скачать платежное поручение') }}
                                        </ui-button>
                                    </div>

                                    <div v-if="dataShow.showHowPayBtn" class="link_info">
                                        <a href="/howpay" target="_blank">{{
                                            $t('Как оплатить доступ?')
                                        }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>

        <div v-if="modeTest">
            <input v-model="forTestTem" type="radio" :value="tender_status.NOT_CONFIRMED" />
            NOT_CONFIRMED
            <input v-model="forTestTem" type="radio" :value="tender_status.PENDING" />
            PENDING
            <input v-model="forTestTem" type="radio" :value="tender_status.BLOCKED" />BLOCKED
            <input v-model="forTestTem" type="radio" :value="tender_status.OPEN" />OPEN
            <input v-model="forTestTem" type="radio" :value="tender_status.TEMPORARY" />TEMPORARY
            <input v-model="forTestDate" type="text" />
        </div>
    </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';

import { TENDER_STATUS, ACCOUNT_TYPE } from '@/constant';
import TenderInfo from '@/views/components/tender/TenderInfo';
import TenderStopFactors from '@/views/components/tender/TenderStopFactors';
import SearchDictionary from '@/views/components/base/SearchDictionary';
import clearReactive from '@/helpers/clearReactive';
import AcceptTender from '@/views/components/tender/AcceptTender.vue';
import { newApi } from '@/utils/new-api';

export default {
    name: 'Tender',

    components: {
        TenderInfo,
        SearchDictionary,
        AcceptTender,
        TenderStopFactors,
    },

    data() {
        return {
            confirm: false,
            tender_status: TENDER_STATUS,
            searchData: {
                searchCurr: null,
            },

            forTestTem: TENDER_STATUS.NOT_CONFIRMED,
            forTestDate: null,
            modeTest: false,
            tenderInfo: {},
        };
    },

    mounted() {
        this.loadTenderInfo();
    },

    validations: {
        searchData: {
            searchCurr: {
                required: requiredIf(function (nestedModel) {
                    return this.showChouseCur;
                }),
            },
        },
    },

    computed: {
        showChouseCur() {
            const profileType = this.$store.getters.accountType;
            return profileType == ACCOUNT_TYPE.NONRESIDENT;
        },

        status() {
            if (this.modeTest) {
                return this.forTestTem;
            }

            return this.tenderInfo && this.tenderInfo.accessStatus
                ? this.tenderInfo.accessStatus
                : TENDER_STATUS.NOT_CONFIRMED;
        },

        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },

        haslicenceAcceptedDate() {
            if (this.modeTest) {
                return !!this.forTestDate;
            }
            return !!this.tenderInfo.licenceAcceptedDate;
        },

        dataShow() {
            const { status } = this;
            let res = null;
            switch (status) {
            case TENDER_STATUS.PENDING: {
                res = {
                    showRuleBlock: false,
                    showDopAccept: false,
                    text: '',
                    showLoadBtn: false,
                    showHowPayBtn: false,
                };
                break;
            }
            case TENDER_STATUS.BLOCKED: {
                res = {
                    showRuleBlock: true,
                    showDopAccept: true,
                    text: this.$t('tender_text7'),
                    showLoadBtn: true,
                    showHowPayBtn: true,
                };
                break;
            }
            case TENDER_STATUS.OPEN: {
                res = {
                    showRuleBlock: true,
                    showDopAccept: false,
                    text: this.$t('tender_text8'),
                    showLoadBtn: true,
                    showHowPayBtn: true,
                };
                break;
            }
            case TENDER_STATUS.TEMPORARY: {
                res = {
                    showRuleBlock: true,
                    showDopAccept: false,
                    text: this.$t('tender_text9'),
                    showLoadBtn: true,
                    showHowPayBtn: true,
                };
                break;
            }
            }
            return res;
        },

        isShowStopFactors() {
            return this.status != TENDER_STATUS.PENDING;
        },
    },

    methods: {
        setConfirm(val) {
            this.confirm = val;
        },

        filterLoadData(val) {
            return val;
        },

        acceptSend() {
            this.acceptTender();
            this.loadTenderInfo();
        },

        async loadFile() {
            try {
                const paramsIn = {
                    self: this,
                    currencyCode: clearReactive(this.searchData.searchCurr),
                    type: this.$store.getters.accountType,
                };
                const data = await newApi('getTenderPayDoc', paramsIn);

                const blob = new Blob([data], { type: 'application/octect-stream' });
                const fileName = 'Платежное поручение.pdf';
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = window.document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    window.document.body.appendChild(link);
                    link.click();
                }
            } catch (err) {
                console.error(err);
            }
        },

        async acceptTender() {
            try {
                await newApi('acceptTender', { self: this });
            } catch (e) {
                console.error(e);
            }
        },

        async loadTenderInfo() {
            try {
                const result = await newApi('loadTenderInfo', { self: this });
                this.tenderInfo = result ?? {};
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mb-16 {
    margin-bottom: 16px;
}
</style>
