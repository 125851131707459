export const SIMPLE_Q = 0; // простой вопрос
export const YEAR_Q = 1; // вопрос с 3 годами
export const PBIOT_CODE = Object.freeze({
    QUESTIONNAIRE: [
        {
            name: 'Квалификационная анкета',
            code: 'questionnaire_1',
        },
    ],
    PBIOT: [
        {
            name: '0001 | Руководящий документ по системе управления охраной труда',
            code: 'pbiot_0001',
        },
        {
            name:
        '0002 | Наличие документированной процедуры контроля состояния рабочего места, применяемого оборудования, инструментов и безопасного выполнения работ',
            code: 'pbiot_0002',
        },
        {
            name: '0003 | Планы, мероприятия по улучшению условий и охране труда',
            code: 'pbiot_0003',
        },
        {
            name:
        '0004 | Протокол проверки знаний по охране труда или удостоверение по охране труда руководителя организации, на которого возложено общее руководство и организация работ по охране труда',
            code: 'pbiot_0004',
        },
        {
            name:
        '0005 | Протокол аттестации по промышленной безопасности руководителя организации, на которого возложено общее руководство и организация работ по промышленной безопасности',
            code: 'pbiot_0005',
        },
        {
            name:
        '0006 | Выписка из штатного расписания, подтверждающая наличие в организации специалиста(ов) по охране труда или приказ о приеме на работу специалиста(ов) по охране труда (обязательно при численности работников организации, превышающей 50 человек)',
            code: 'pbiot_0006',
            alert: 'Нельзя снять из-за указанного количества человек в Анкете претендента – более 50',
        },
        {
            name:
        '0007 | Приказ о назначении уполномоченного работника по охране труда или гражданско-правовой договор об оказании услуг в области охраны труда',
            code: 'pbiot_0007',
        },
        {
            name:
        '0008 | Протокол проверки знаний по охране труда у специалиста(ов) или уполномоченного работника по охране труда',
            code: 'pbiot_0008',
        },
        {
            name:
        '0009 | Удостоверение по охране труда у специалиста(ов) или уполномоченного работника по охране труда',
            code: 'pbiot_0009',
        },
        {
            name:
        '0010 | Документы, отражающие суть претензии или иска, относящиеся к управлению в области ПБ и ОТ за последние три года',
            code: 'pbiot_0010',
        },
        {
            name:
        '0011 | Документы, подтверждающие устранение (урегулирование) претензии/иска',
            code: 'pbiot_0011',
        },
        {
            name:
        '0012 | Опыт выполнения работ (оказания услуг) без аварий, пожаров, инцидентов и травм для организаций Группы «ЛУКОЙЛ» за последние три года (исключая текущий)',
            code: 'pbiot_0012',
        },
    ],
    DOC_EP: [
        {
            name:
        '0001 | Руководящий документ по системе управления охраной окружающей среды',
            code: 'ep_0001',
        },
        {
            name: '0002 | Планы, программы, мероприятия по охране окружающей среды',
            code: 'ep_0002',
        },
        {
            name: '0003 | Приказ о создании службы ООС',
            code: 'ep_0003',
        },
        {
            name: '0004 | Приказ о назначении ответственных работников по ООС',
            code: 'ep_0004',
        },
        {
            name:
        '0005| Приказ о назначении представителя высшего руководства по обеспечению ООС',
            code: 'ep_0005',
        },
        {
            name:
        '0006| Должностная инструкция руководителя, на которого возложено обеспечение ООС',
            code: 'ep_0006',
        },
        {
            name:
        '0007| Документы, отражающие суть претензии/иска, относящиеся к управлению в области ООС за последние три года',
            code: 'ep_0007',
        },
        {
            name:
        '0008| Документы, подтверждающие устранение (урегулирование) претензии/иска',
            code: 'ep_0008',
        },
        {
            name:
        '0009| Согласованный с Росприроднадзором нормативный документ, регламентирующий процесс управления отходами производства и потребления',
            code: 'ep_0009',
        },
        {
            name: '0010| Программа производственного экологического контроля',
            code: 'ep_0010',
        },
        {
            name:
        '0011| Приказ о назначении лиц, ответственных за организацию и осуществление производственного экологического контроля',
            code: 'ep_0011',
        },
    ],
    INCIDENTS: [
        {
            name: 'Списочная численность работающих в организации',
            type: SIMPLE_Q,
            code: 'q1',
            placeholder: 'чел',
            hint: 'Укажите списочную численность работающих на момент подачи тендерного предложения.',
        },
        {
            name:
        'Количество несчастных случаев со смертельным исходом за последние три года',
            type: YEAR_Q,
            code: 'q2',
            children: generateYearList('q2'),
        },
        {
            name: 'Количество несчастных случаев за последние три года',
            type: YEAR_Q,
            code: 'q3',
            children: generateYearList('q3'),
        },
        {
            name:
        'Значение коэффициента частоты несчастных случаев за последний год',
            type: SIMPLE_Q,
            code: 'q4',
            placeholder: '',
            hint: 'Заполните информацию',
        },
        {
            name: 'Количество аварий за последние три года ',
            type: YEAR_Q,
            code: 'q5',
            children: generateYearList('q5'),
        },

        {
            name: 'Количество инцидентов за последние три года ',
            type: YEAR_Q,
            code: 'q6',
            children: generateYearList('q6'),
        },

        {
            name: 'Количество пожаров за последние три года ',
            type: YEAR_Q,
            code: 'q7',
            children: generateYearList('q7'),
        },
    ],
});

function generateYearList(code) {
    const now = new Date();
    const currentYear = now.getFullYear();
    const result = [];

    for (let year = currentYear - 3; year < currentYear; year++) {
        result.push({
            name: `${year}г.`,
            code: `${code}_${year}`,
        });
    }

    return result;
}
