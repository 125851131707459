<template>
    <main-template-doc-pbiot
        :title="$t('Документы по охране труда')"
        name-field="doc_pbiot"
        :warning-info="warningInfo"
    />
</template>

<script>
import MainTemplateDocPbiot from '@/views/components/pbiot/MainTemplateDocPbiot.vue';

export default {
    name: 'DocForPbiot',
    components: { MainTemplateDocPbiot },
    data() {
        return {
            warningInfo: [
                {
                    code: 'pbiot_0012',
                    text: 'Перечислите все номера договоров с датами начала и завершения работ (оказания услуг) с указанием организаций Группы «ЛУКОЙЛ», для которых они выполнялись (оказывались), за последние три года (исключая текущий). Заполняется только в случае если в указанный период при выполнении работ для организаций Группы «ЛУКОЙЛ» не было допущено аварий, пожаров, инцидентов и травм',
                },
            ],
        };
    },
};
</script>
