<template>
    <div>
        <section class="main_subcontractors">
            <AccordionBase id="typeSubcontractors">
                <AccordionItem :has-icon="false" :title="'Субподрядчики'">
                    <div class="container">
                        <div class="grid-x grid-margin-x">
                            <div class="container__controls">
                                <div class="container__controls--button">
                                    <ui-button
                                        :disabled="!hasSaveData"
                                        type="full"
                                        @click="
                                            openSubcontractorModal(
                                                SUBCONTRACTOR_LINK_TYPE.SUBCONTRACTOR
                                            )
                                        "
                                    >
                                        Добавить субподрядчика
                                    </ui-button>
                                </div>

                                <span style="font-weight: 600; margin-left: 20px">
                                    {{ infoText('субподрядчика') }}
                                    <a
                                        class="info-link"
                                        href="mailto:lkk@Lukoil.com"
                                    >
                                        lkk@lukoil.com.
                                    </a>
                                </span>
                            </div>

                            <div class="large-12 cell">
                                <table-subcontractor
                                    :rows="subcontractors"
                                    :subcontractor-link-type="SUBCONTRACTOR_LINK_TYPE.SUBCONTRACTOR"
                                    @open-registration-link-modal="openRegistrationLinkModal"
                                    @reload="init"
                                />
                            </div>
                        </div>
                    </div>
                </AccordionItem>
            </AccordionBase>

            <AccordionBase id="typeProducers">
                <AccordionItem :has-icon="false" :title="'Производители'">
                    <div class="container">
                        <div class="grid-x grid-margin-x">
                            <div class="container__controls">
                                <div class="container__controls--button">
                                    <ui-button
                                        :disabled="!hasSaveData"
                                        type="full"
                                        @click="
                                            openSubcontractorModal(SUBCONTRACTOR_LINK_TYPE.PRODUCER)
                                        "
                                    >
                                        Добавить производителя
                                    </ui-button>
                                </div>

                                <span style="font-weight: 600; margin-left: 20px">
                                    {{ infoText('производителя') }}
                                    <a
                                        class="info-link"
                                        href="mailto:lkk@Lukoil.com"
                                    >
                                        lkk@lukoil.com.
                                    </a>
                                </span>
                            </div>

                            <div class="large-12 cell">
                                <table-subcontractor
                                    :rows="producers"
                                    :subcontractor-link-type="SUBCONTRACTOR_LINK_TYPE.PRODUCER"
                                    @open-registration-link-modal="openRegistrationLinkModal"
                                    @reload="init"
                                />
                            </div>
                        </div>
                    </div>
                </AccordionItem>
            </AccordionBase>
        </section>

        <ModalSelectSubcontractor />

        <ModalCreateSubcontractor
            :subcontractors="subcontractors"
            :producers="producers"
            @reload="init"
        />

        <ModalDelete />

        <NonResidentsModal @reload="init" />

        <registration-link-subcontractors-modal
            :link="registrationLink"
        />
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';
import { SUBCONTRACTOR_LINK_TYPE } from '@/constant';
import ModalSelectSubcontractor from '@/views/components/shared/ModalSelectSubcontractor';
import ModalCreateSubcontractor from '@/views/components/shared/ModalCreateSubcontractor';
import AccordionBase from '@/views/components/base/accordions/AccordionBase';
import AccordionItem from '@/views/components/base/accordions/AccordionItem';
import NonResidentsModal from '@/views/components/shared/non-residents-modal';

import RegistrationLinkSubcontractorsModal from './registration-link-subcontractor-modal';
import ModalDelete from './ModalDelete';
import TableSubcontractor from './TableSubcontractor';

export default {
    name: 'Subcontractor',

    components: {
        AccordionItem,
        AccordionBase,
        ModalSelectSubcontractor,
        ModalCreateSubcontractor,
        TableSubcontractor,
        ModalDelete,
        NonResidentsModal,
        RegistrationLinkSubcontractorsModal,
    },

    data() {
        return {
            SUBCONTRACTOR_LINK_TYPE,
            registrationLink: '',
            subcontractors: [],
            producers: [],
        };
    },

    computed: {
        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            try {
                const { subcontractors, producers } = await newApi('loadSubcontractors', { self: this });
                this.subcontractors = subcontractors;
                this.producers = producers;
            } catch (err) {
                console.error(err);
            }
        },

        openSubcontractorModal(linkType) {
            this.$modal.show('subcontractor', {
                subcontractorLinkType: linkType,
            });
        },

        openRegistrationLinkModal({ link, linkType }) {
            this.registrationLink = link;

            this.$modal.show('registration-link-subcontractor-modal', { linkType });
        },

        infoText(type) {
            return `В случае невозможности заведения ${type}
                       или обнаружения неточности в данных – обратитесь в службу поддержки`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/_variables";

.container {
    max-width: 1800px;
    padding: 0 20px;
    margin: 0 auto;

    &__controls {
        display: flex;
        align-items: center;
        margin: 0 0 12px 15px;
        width: 100%;

        &--button {
            width: 250px;
        }
    }
}

.info-link {
    color: $link;
}
</style>
