<template>
    <div
        class="upload_file"
        :class="{ hasFileError: error, composite_file: isCompositeMode }"
    >
        <label>{{ convertLabel(label) }}</label>

        <span
            v-if="delType"
            class="upload_file__delete"
            @click="delType(ugid)"
        />

        <transition-group name="list">
            <ItemFile
                v-for="file in upLoadFiles"
                :key="file.id"
                :file="file"
                class="list-item"
                :error="getErrorFile(file.id)"
                :has-delete="hasDelete"
                :has-load="hasLoad"
                @delfile="delfile"
            />
        </transition-group>

        <label v-if="!errorMaxCount" class="empty_file">
            <input
                v-if="drawInput && !errorMaxCount && hasSaveData"
                type="file"
                :accept="acceptFilesCommaSeparated"
                :size="sizeKb * 1024"
                @change="fileUp"
            />
            <div class="ico_upload" />

            <div class="empty_file_description">
                {{ $t('Перетяните или') }}
                <span>{{ $t('выберите') }} {{ acceptFilesCommaSeparated }}</span>
                {{ $t('файл до') }}
                {{
                    checkSize(sizeKb)
                        ? `${sizeKb} Кбайт`
                        : `${(Number(sizeKb) / 1024).toFixed(0)} Мб`
                }}.

                <div v-if="showInfoMsgPdf" class="msgPdfInfo" v-html="$t('infoDocPdf')">
                    Необходимо загрузить документ <b>одним</b> файлом
                </div>

                <div
                    v-if="showInfoMsgPdf"
                    class="msgPdfInfo"
                    v-html="$t('infoReadDocPdf')"
                >
                    Документ должен быть <b>читаемым</b>.
                </div>

                <slot name="fileDescription" />

                <span v-if="!hasSaveData">
                    {{ $t('Возможность редактирования ограничена!') }}
                </span>
            </div>
        </label>

        <slot />
    </div>
</template>

<script>
import { TYPE_ERRORS, TYPE_DIALOG_TITLE } from '@/constant';
import { newApi } from '@/utils/new-api';
import ItemFile from '@/views/components/base/UploadFile/ItemFile';
import { replaceYear, replacePeriod } from '@/helpers/createDocumentHelper';
import { appParams, getAppConfig } from '@/utils/appConfig';

export default {
    name: 'DocumentUploadForm',

    components: {
        ItemFile,
    },

    props: {
        isCompositeMode: { type: Boolean, default: false },
        hasFiles: {
            type: Array,
            default: () => [] // FileInfoDTO (id, name, size, uploadDate)
            ,
        },
        acceptFile: {
            type: Array,
            default: () => ['pdf'],
        },
        sizeKb: { type: Number },
        label: {
            type: String,
            default: 'Скан образ Устава со всеми изменениями и дополнениями *',
        },
        addFile: { type: Function },

        maxCount: { type: Number, default: 99 },
        error: { type: Array, default: () => [] },
        hasDelete: { type: Boolean, default: true },
        ugid: { type: String, required: false },
        delType: { type: Function },
        hasLoad: { type: Boolean, default: true },
        attrDoc: { type: Object },
    },

    data() {
        return {
            load: false,
            drawInput: true,
            upLoadFiles: [], // FileInfoDTO (id, name, size, uploadDate)
            minFileSize: 0,
        };
    },

    computed: {
        errorMaxCount() {
            const files = this.upLoadFiles;
            return files.length >= this.maxCount;
        },
        acceptFilesCommaSeparated() {
            return this.acceptFile.map((i) => `.${i}`).join(',');
        },
        acceptFilesLowerCase() {
            return this.acceptFile.map((i) => i.toLowerCase());
        },
        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },
        showInfoMsgPdf() {
            return this.acceptFilesLowerCase.indexOf('pdf') >= 0;
        },
    },

    created() {
        this.upLoadFiles = [...[], ...this.hasFiles];
        this.minFileSize = getAppConfig(appParams.MIN_FILE_SIZE_BYTE);
    },

    methods: {
        convertLabel(label) {
            let convertLabel = label;
            if (this.attrDoc) {
                const {
                    reportingQuarter = null,
                    reportingYear = null,
                    name = null,
                    customName = null,
                } = this.attrDoc;
                if (name) {
                    convertLabel = name;
                }

                if (!name && reportingQuarter) {
                    convertLabel = replacePeriod(convertLabel, reportingQuarter, reportingYear);
                }
                if (!name && reportingYear) {
                    convertLabel = replaceYear(convertLabel, reportingYear);
                }
                if (customName) {
                    convertLabel = customName;
                }
            }
            return convertLabel;
        },
        checkSize(sizeKb) {
            return sizeKb && sizeKb < 1024;
        },
        async fileUp(event) {
            const files = Array.from(event.target.files);
            if (!files) {
                return;
            }
            const paramsIn = {
                self: this,
                file: files,
            };
            try {
                const fullType = event.target.files[0].type;
                const findslesh = fullType.indexOf('/');
                const type = fullType.substr(findslesh + 1, fullType.length);

                const fullNameFile = event.target.files[0].name;
                const findPoint = fullNameFile.lastIndexOf('.');
                let findTypeInName = false;
                if (findPoint > -1) {
                    const typeInName = fullNameFile.substr(
                        findPoint + 1,
                        fullNameFile.length - findPoint,
                    );
                    findTypeInName = this.acceptFilesLowerCase.indexOf(typeInName) < 0;
                }
                if (this.acceptFilesLowerCase.indexOf(type) < 0 && findTypeInName) {
                    const msg = this.$t('Файл не соответствует требованиям. Неверный формат.');
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.WARNING,
                        msg,
                        title: TYPE_DIALOG_TITLE.WARNING,
                    });
                    throw new Error('Ошибка формата');
                }

                if (event.target.files[0].size < this.minFileSize) {
                    const msg = this.$t(`Файл не соответствует требованиям. Минимальный размер файла ${Number(this.minFileSize)} байт`);
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.WARNING,
                        msg,
                        title: TYPE_DIALOG_TITLE.WARNING,
                    });
                    throw new Error('Ошибка размера');
                }

                if (event.target.files[0].size > this.sizeKb * 1024) {
                    const msg = this.$t('Файл не соответствует требованиям.');
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.WARNING,
                        msg,
                        title: TYPE_DIALOG_TITLE.WARNING,
                    });
                    throw new Error('Ошибка размера');
                }

                const res = await newApi('uploadFile', paramsIn);

                this.upLoadFiles.push(res);

                this.drawInput = false;
                this.$nextTick(() => {
                    this.drawInput = true;
                });

                this.addFile({ ugid: this.ugid, upLoadFiles: this.upLoadFiles });
            } catch (err) {
                console.error(err);
                this.drawInput = false;
                this.$nextTick(() => {
                    this.drawInput = true;
                });
                if (!err) {
                    const msg = this.$t('Не смог загрузить файл. Ошибка!');
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.WARNING,
                        msg,
                        title: TYPE_DIALOG_TITLE.WARNING,
                    });
                }
            }
        },
        delfile(file) {
            this.upLoadFiles = this.upLoadFiles.filter((item) => item.id !== file.id);
            this.addFile({ ugid: this.ugid, upLoadFiles: this.upLoadFiles });
        },
        getErrorFile(fileId) {
            if (!this.error) {
                return;
            }

            if (this.error.files) {
                return this.error.files.filter((el) => el.fileId === fileId);
            }

            return this.error.filter((el) => el.fileId === fileId);
        },
    },
};
</script>

<style lang='scss' scoped>
.checkBoxUploadFile {
    position: absolute;
    right: 0;
    top: 0;
    background: #ffffff;
    height: 40px;
    width: 40px;
    padding-left: 7px;
}

.checkAll {
    position: absolute;
    transition-duration: 0.2s;
    // transition-delay: .5s;
    right: 32px;
    top: 4px;
    font-size: 10px;
    z-index: 99;
    width: 81px;
    text-transform: uppercase;
    background: #000;
    height: 34px;
    padding: 8px;
    opacity: 0;

    &__text {
        color: #fff;
        cursor: pointer;
        font-size: 12px;
    }
}

.checkBoxUploadFile {
    &:hover {
        .checkAll {
            opacity: 1;
        }
    }
}

.ico_upload {
    background: url(~@/assets/img/ico-upload.svg) 0 0/cover;
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto 20px
}

.upload_file {
    &__delete {
        cursor: pointer;
        position: absolute;
        background: url(~@/assets/ico/ico-close-gray.svg);
        right: 24px;
        top: 27px;
        background-size: contain;
        width: 12px;
        height: 12px;
        display: block;
    }
}

</style>
