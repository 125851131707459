<template>
    <ui-modal
        :closeable="false"
        name="NewUserAgreement"
        width="800"
    >
        <div class="new-user-agreement">
            <div class="new-user-agreement__title">
                <p>Уважаемый партнер организаций Группы «ЛУКОЙЛ»!</p>
            </div>

            <p>
                Уведомляем Вас, что мы обновили
                <a href="/pdf/PersonalDataAgreement.pdf" target="_blank" download>
                    Условия предоставления персональных данных пользователя в Личный кабинет
                    контрагента
                </a>
                и
                <a href="/pdf/ConfidentialInformationAgreement.pdf" target="_blank" download>
                    Соглашение о конфиденциальности информации, размещаемой пользователем в
                    Личном кабинете контрагента.
                </a>
            </p>

            <p>
                При проставлении отметки в соответствующем поле Вы принимаете положения,
                которые указаны в обновленных Условиях предоставления персональных данных и
                Соглашении о конфиденциальности информации.
            </p>

            <p>
                Для продолжения работы в Личном кабинете контрагента необходимо ознакомиться
                и принять новые положения.
            </p>

            <p>Благодарим за сотрудничество с организациями Группы «ЛУКОЙЛ»!</p>

            <base-checkbox
                v-model="argumentPersonalData"
                label="Согласен с Условиями предоставления персональных данных пользователя в Личный кабинет контрагента"
            />

            <base-checkbox
                v-model="agreementConfidentialInformation"
                label="Согласен с условиями Соглашения о конфиденциальности информации, размещаемой пользователем в Личном кабинете контрагента"
            />

            <div class="new-user-agreement__buttons-wrapper">
                <ui-button
                    :disabled="!argumentPersonalData || !agreementConfidentialInformation"
                    @click="acceptUserAgreementStatus"
                >
                    {{ $t('Сохранить') }}
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'NewUserAgreementModal',

    data() {
        return {
            argumentPersonalData: false,
            agreementConfidentialInformation: false,
        };
    },

    methods: {
        async acceptUserAgreementStatus() {
            try {
                const params = {
                    self: this,
                    contractorCode: this.$store.state.user.profile.companyCode,
                };

                await newApi('acceptUserAgreementStatus', params);

                this.$modal.hide('NewUserAgreement');
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/common/_variables";

.new-user-agreement {

    p {
        font-size: 16px;
        margin-bottom: 15px;
    }

    a {
        color: $link;
    }

    &__title {
        text-align: center;
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: center;
        margin: 20px 0px;
    }
}
</style>
