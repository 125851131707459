import store from '@/store';
import { ifAuthenticatedAdminPage } from './ifAuthenticatedAdminPage';
import { ACCOUNT_STATUS } from '@/constant';

const checkCom = async () => {
    await store.dispatch('LOAD_CHAT_INFO');
};

const routeCallback = (to, from, next) => {
    if (store.getters.isAdmin) {
        if (ifAuthenticatedAdminPage(to, from, next)) {
            next();
        } else {
            next('/admin/user');
        }
    } else {
        /**
         * проверка на возможность сохранять данные , запрет перехода если нет возможности сохранить
         */

        if (!ifAuthenticatedAdminPage(to, from, next)) {
            if (
                !store.getters.hasSaveData
                && to.path !== '/job-application'
                && to.fullPath !== '/account/registration'
            ) {
                checkCom();
                next('/job-application');
            } else {
                //---------
                // проверка блокировки роута
                if (to && to.meta && 'showPage' in to.meta && to.meta.showPage == false) {
                    next('/');
                } else if (
                    store.getters.accountStatus === ACCOUNT_STATUS.NEW
                        && to.fullPath !== '/account/registration'
                ) {
                    next('/account/registration');
                } else {
                    checkCom();
                    next();
                }
                //---------
            }
        } else {
            next('/');
        }
    }
};
/**
 * Миделвара для проверки авторизации
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export const ifAuthenticated = async (to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;
    const isProfileLoaded = store.getters.isProfileLoaded;

    try {
        if (isAuthenticated && isProfileLoaded) {
            routeCallback(to, from, next);
        } else {
            const res = await store.dispatch('AUTH_ME');

            if (res) {
                routeCallback(to, from, next);
            } else {
                next({ name: 'UserError' });
            }
        }
    } catch (err) {
        console.error('Ошибка при аутентификации. Выход из системы', err);

        await store.dispatch('AUTH_LOGOUT');

        next({ name: 'UserError' });
    }
};