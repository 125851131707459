<template>
    <div class="grid-x grid-margin-x">
        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Выберите отчетный год</label>
        </div>

        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :value="year"
                :on-change="selectYear"
                :options="optionsYear"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('Выберите отчетный год')"
                :force-disabled="disable"
            />
        </div>

        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Система налогообложения</label>
        </div>

        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :value="taxRegime"
                :on-change="selectTaxRegime"
                :options="optionsTaxRegime"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('selectDocumentTaxRegime')"
                :force-disabled="disable"
            />
        </div>

        <template v-if="isSelectedTaxRegimePatent">
            <div class="large-4 small-4 cell">
                <label class="labelSimpleSelector"> Укажите дату начала действия Патента </label>
            </div>

            <div class="large-8 small-8 cell mb-12">
                <date-picker
                    v-model="patentDateStart"
                    class="fullYear"
                    format="DD.MM.YYYY"
                    lang="ru"
                    value-type="format"
                    :disabled="disable"
                    :disabled-date="disabledStartDateBySelectedYear"
                    :default-value="defaultPatentDate"
                />
            </div>

            <div class="large-4 small-4 cell">
                <label class="labelSimpleSelector"> Укажите дату окончания Патента </label>
            </div>

            <div class="large-8 small-8 cell mb-12">
                <date-picker
                    v-model="patentDateEnd"
                    class="fullYear"
                    format="DD.MM.YYYY"
                    lang="ru"
                    value-type="format"
                    :disabled="disable || !patentDateStart"
                    :disabled-date="disabledPatentEndDate"
                    :default-value="defaultPatentDate"
                />
            </div>
        </template>

        <template v-if="isSelectedTaxRegimeNpd">
            <div class="large-4 small-4 cell">
                <label class="labelSimpleSelector"> Укажите дату начала периода применения </label>
            </div>

            <div class="large-8 small-8 cell mb-12">
                <date-picker
                    v-model="nalogProfPeriodDateStart"
                    class="fullYear"
                    format="DD.MM.YYYY"
                    lang="ru"
                    value-type="format"
                    :disabled="disable"
                    :disabled-date="disabledStartDateBySelectedYear"
                    :default-value="defaultPatentDate"
                />
            </div>

            <div class="large-4 small-4 cell">
                <label class="labelSimpleSelector">
                    Укажите дату окончания периода применения
                </label>
            </div>

            <div class="large-8 small-8 cell mb-12">
                <date-picker
                    v-model="nalogProfPeriodDateEnd"
                    class="fullYear"
                    format="DD.MM.YYYY"
                    lang="ru"
                    value-type="format"
                    :disabled="disable || !nalogProfPeriodDateStart"
                    :disabled-date="disabledProfNalogEndDate"
                    :default-value="defaultPatentDate"
                />
            </div>
        </template>

        <template
            v-if="isSelectedTaxRegimeCommon || isSelectedTaxRegimeEshn || isSelectedTaxRegimeEnvd"
        >
            <div class="large-4 small-4 cell">
                <label class="labelSimpleSelector">{{
                    isSelectedTaxRegimeEnvd ? 'Период' : 'Период (для декларации по НДС)'
                }}</label>
            </div>

            <div class="large-8 small-8 cell mb-12">
                <SimpleDictionary
                    dop-class="selectorDocument"
                    :on-change="selectPeriod"
                    :value="period"
                    :options="optionPeriod"
                    :error-text="$t('Необходимо выбрать')"
                    :placeholder="$t('Выберите период')"
                    :force-disabled="disable"
                />
            </div>

            <div class="large-12 cell mb-12">
                <base-checkbox
                    v-if="isSelectedTaxRegimeCommon && isNotIndividual && isNotBranchOfForeignOrg"
                    v-model="kgn"
                    label="Являюсь участником КГН"
                    :disabled="disable"
                />
                <base-checkbox
                    v-if="
                        !kgn &&
                            isSelectedPastYear &&
                            isSelectedTaxRegimeCommon &&
                            isNotIndividual &&
                            isNotBranchOfForeignOrg
                    "
                    v-model="incomeTaxExemption"
                    label="Применяю освобождение от исполнения обязанности плательщика налога на прибыль организации"
                    :disabled="disable"
                />
                <base-checkbox
                    v-if="
                        !kgn &&
                            !incomeTaxExemption &&
                            isSelectedPastYear &&
                            isSelectedTaxRegimeCommon &&
                            isNotIndividual &&
                            isNotBranchOfForeignOrg
                    "
                    v-model="incomeTaxPaperFormat"
                    label="Подаю декларацию по налогу на прибыль организаций в налоговые органы в бумажном формате"
                    :disabled="disable"
                />
                <base-checkbox
                    v-if="isNotBranchOfForeignOrg"
                    v-model="vatExemption"
                    label="Применяю освобождение от исполнения обязанности плательщика НДС"
                    :disabled="disable"
                />
            </div>
        </template>

        <template v-if="isSelectedTaxRegimeUsn && isSelectedPastYear">
            <div class="large-12 cell mb-12">
                <base-checkbox
                    v-model="usnTaxPaperFormat"
                    label="Подаю декларацию в связи с применением УСН в налоговые органы в бумажном формате"
                    :disabled="disable"
                />
            </div>
        </template>

        <template v-if="isSelectedTaxRegimeEshn && isSelectedPastYear">
            <div class="large-12 cell mb-12">
                <base-checkbox
                    v-model="eshnTaxPaperFormat"
                    label="Подаю декларацию по ЕСХН в налоговые органы в бумажном формате"
                    :disabled="disable"
                />
            </div>
        </template>

        <template v-if="isSelectedTaxRegimeEnvd && year">
            <div class="large-12 cell mb-12">
                <base-checkbox
                    v-model="envdTaxPaperFormat"
                    label="Подаю декларацию по ЕНВД в налоговые органы в бумажном формате"
                    :disabled="disable"
                />
            </div>
        </template>

        <template v-if="documentsApplicableToAddFiltered">
            <div class="large-12 cell mb-12">
                <DocumentCreateV2Buttons
                    :documents-in-specification="documentsApplicableToAddFiltered"
                    :documents-user-selected-to-add.sync="documentsUserSelectedToAdd"
                    @update:documentsUserSelectedToAdd="
                        $emit('update:documentsUserSelectedToAdd', $event)
                    "
                >
                    <!--
                        // TODO: Выключено до момента переработки отправки документов
                         <template v-slot:controls>
                        <finance-block-table
                            v-if="isDocSelectToAdd(documentTypeEnum.AutoUsnStatePdf)"
                            class="large-12 cell mb-12"
                            :financeData="financeData"
                            :year="year?.name"
                            :incomeToolTip="$t('autoUsnIncomeTooltip')"
                            :taxToolTip="$t('autoUsnTaxTooltip')"
                            @load-data="loadFinData"
                            @save-finance-data="saveFindData"
                        ></finance-block-table>
                    </template> -->
                    <template #uploadRule>
                        <div v-if="!isSelectedAutoUSN">
                            <div
                                v-for="( { text }, idx ) in additionalNotifications"
                                :key="idx"
                                v-html="taxNotifications[text]"
                            />
                        </div>
                    </template>
                    <template #fileDescription="slotProps">
                        <warning-information-file
                            :type-doc="slotProps.file"
                            :registration-date-prop="registrationDate"
                        />
                    </template>
                </DocumentCreateV2Buttons>
            </div>
        </template>

        <ui-button
            v-if="showButton"
            type="full"
            @click="saveNpdWithoutDoc"
        >
            {{ $t('Отправить на проверку') }}
        </ui-button>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

import SimpleDictionary from '@/views/components/base/SimpleDictionary.vue';
import {
    optionPeriodFilterByCurrentDate,
    replacePeriod,
    replaceYear,
} from '@/helpers/createDocumentHelper';
import {
    DocumentTypeEnum,
    TYPE_DIALOG_TITLE,
    TYPE_ERRORS,
    UNIVERSAL_FORMAT_DATE,
} from '@/constant';
import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import WarningInformationFile from '@/views/components/documents/WarningInformationFile.vue';
import { newApi } from '@/utils/new-api';
import FinanceBlockTable from '@/views/modules/finance-block-table.vue';
import { taxNotifications } from '@/notificationTemplates/documentNotifications';

export default {
    name: 'TaxDocumentUiHandler',

    components: {
        WarningInformationFile,
        DocumentCreateV2Buttons,
        SimpleDictionary,
        DatePicker,
        FinanceBlockTable,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            year: null,
            taxRegime: null,
            period: null,
            kgn: false,
            incomeTaxExemption: false,
            incomeTaxPaperFormat: false,
            vatExemption: false,
            usnTaxPaperFormat: false,
            eshnTaxPaperFormat: false,
            envdTaxPaperFormat: false,
            patentDateStart: null,
            patentDateEnd: null,
            nalogProfPeriodDateStart: null,
            nalogProfPeriodDateEnd: null,
            financeData: [],
            documentsUserSelectedToAdd: [],
            documentTypeEnum: DocumentTypeEnum,
            taxNotifications,
        };
    },

    computed: {
        isNotIndividual() {
            return !this.selectedDocSpec.uiHandlingOptions?.isIe;
        },

        isBranchOfForeignOrg() {
            return this.selectedDocSpec.uiHandlingOptions?.isBranchOfForeignOrg;
        },

        isNotBranchOfForeignOrg() {
            return !this.selectedDocSpec.uiHandlingOptions?.isBranchOfForeignOrg;
        },

        additionalNotifications() {
            return this.selectedDocSpec?.bottomNotificationBar?.content ?? [];
        },

        registrationDate() {
            return this.selectedDocSpec.uiHandlingOptions?.registrationDate;
        },

        optionsYear() {
            const nowY = this.$moment().format('YYYY');
            const listYear = [nowY, nowY - 1, nowY - 2, nowY - 3];
            return listYear.map((item, index) => ({
                id: index,
                code: item,
                name: item,
            }));
        },

        dateNow() {
            // для теста
            return this.$moment();
        },

        showButton() {
            return this.isSelectedTaxRegimeNpd && this.nalogProfPeriodDateStart && this.nalogProfPeriodDateEnd;
        },

        isSelectedCurrentYear() {
            const nowYear = this.dateNow.year();
            return this.year?.code == nowYear;
        },

        isSelectedPastYear() {
            const nowYear = this.dateNow.year();
            /*
                т.к. в списке выбора годов выводятся только текущий и предыдущие года,
                то != nowYear означает, что выбран один из прошедших годов
            */
            return this.year?.code != nowYear;
        },

        optionPeriod() {
            const { year, dateNow } = this;
            return year
                ? optionPeriodFilterByCurrentDate(year.code, dateNow.format(UNIVERSAL_FORMAT_DATE))
                : [];
        },

        optionsTaxRegime() {
            let { optionsTaxRegime } = this.selectedDocSpec.uiHandlingOptions;
            if (this.year?.code > 2020) {
                optionsTaxRegime = optionsTaxRegime.filter((r) => r.code !== 'ENVD');
            }

            if (this.year?.code < 2022 || this.year?.code > 2027) {
                optionsTaxRegime = optionsTaxRegime.filter((r) => r.code !== 'AUSN');
            }

            return optionsTaxRegime;
        },

        isSelectedTaxRegimeCommon() {
            return this.taxRegime?.code === 'Common';
        },

        isSelectedTaxRegimeUsn() {
            return this.taxRegime?.code === 'USN';
        },

        isSelectedTaxRegimeEshn() {
            return this.taxRegime?.code === 'ESHN';
        },

        isSelectedTaxRegimeEnvd() {
            return this.taxRegime?.code === 'ENVD';
        },

        isSelectedTaxRegimePatent() {
            return this.taxRegime?.code === 'Patent';
        },

        isSelectedTaxRegimeNpd() {
            return this.taxRegime?.code === 'NPD';
        },

        isSelectedAutoUSN() {
            return this.taxRegime?.code === 'AUSN';
        },

        defaultPatentDate() {
            return new Date().setFullYear(this.year.code);
        },

        patentDateStartIso() {
            return this.patentDateStart
                ? this.$moment(this.patentDateStart, 'DD.MM.YYYY').format('YYYY-MM-DD')
                : null;
        },
        patentDateEndIso() {
            return this.patentDateEnd
                ? this.$moment(this.patentDateEnd, 'DD.MM.YYYY').format('YYYY-MM-DD')
                : null;
        },

        nalogProfPeriodDateStartIso() {
            return this.nalogProfPeriodDateStart
                ? this.$moment(this.nalogProfPeriodDateStart, 'DD.MM.YYYY').format('YYYY-MM-DD')
                : null;
        },
        nalogProfPeriodDateEndIso() {
            return this.nalogProfPeriodDateEnd
                ? this.$moment(this.nalogProfPeriodDateEnd, 'DD.MM.YYYY').format('YYYY-MM-DD')
                : null;
        },

        /**
         * @returns DocumentSpecificationDocDTO with resolved names and additional document attributes (like year, period, etc)
         */
        documentsApplicableToAddFiltered() {
            const docTypeCodesToInclude = [];
            if (
                (this.isSelectedTaxRegimeCommon || this.isSelectedTaxRegimeEshn)
                && !this.vatExemption
                && this.year
                && this.period
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.Vat);
                docTypeCodesToInclude.push(DocumentTypeEnum.VatQuarter_IP);
            }
            if (
                (this.isSelectedTaxRegimeCommon || this.isSelectedTaxRegimeEshn)
                && this.vatExemption
                && this.year
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.VatExemption);
            }
            if (this.isSelectedTaxRegimeEnvd && this.year && !this.envdTaxPaperFormat) {
                docTypeCodesToInclude.push(DocumentTypeEnum.Envd);
            }
            if (
                this.isSelectedTaxRegimeEnvd
                && this.year
                && this.period
                && this.envdTaxPaperFormat
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.EnvdPDF);
            }
            if (
                this.isSelectedTaxRegimeEshn
                && this.isSelectedPastYear
                && !this.eshnTaxPaperFormat
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.Eshn);
            }
            if (
                this.isSelectedTaxRegimeEshn
                && this.isSelectedPastYear
                && this.eshnTaxPaperFormat
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.EshnPDF);
            }
            if (
                !this.kgn
                && this.isSelectedTaxRegimeCommon
                && this.period
                && this.year
                && this.isSelectedPastYear
                && !this.incomeTaxExemption
                && !this.incomeTaxPaperFormat
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.IncomeTaxReturnQuarter);
            }
            if (
                this.isSelectedTaxRegimeCommon
                && this.year
                && this.isSelectedPastYear
                && this.isBranchOfForeignOrg
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.IncomeTaxForeignOrgPDF);
            }
            if (
                !this.kgn
                && this.isSelectedTaxRegimeCommon
                && this.year
                && !this.incomeTaxExemption
                && this.incomeTaxPaperFormat
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.IncomeTaxReturnQuarterPDF);
            }
            if (
                !this.kgn
                && this.isSelectedTaxRegimeCommon
                && this.period
                && this.year
                && this.incomeTaxExemption
            ) {
                docTypeCodesToInclude.push(DocumentTypeEnum.TaxExemptionPDF);
            }
            if (this.isSelectedTaxRegimeUsn && this.year) {
                docTypeCodesToInclude.push(DocumentTypeEnum.TaxTypeConfirmationUSN);
            }
            if (this.isSelectedTaxRegimeEshn && this.year) {
                docTypeCodesToInclude.push(DocumentTypeEnum.TaxTypeConfirmationESHN);
            }
            if (this.isSelectedTaxRegimeUsn && this.isSelectedPastYear && !this.usnTaxPaperFormat) {
                docTypeCodesToInclude.push(DocumentTypeEnum.Usn);
            }
            if (this.isSelectedTaxRegimeUsn && this.isSelectedPastYear && this.usnTaxPaperFormat) {
                docTypeCodesToInclude.push(DocumentTypeEnum.UsnPDF);
            }
            if (this.isSelectedTaxRegimePatent && this.patentDateStart && this.patentDateEnd) {
                docTypeCodesToInclude.push(DocumentTypeEnum.Patent);
            }
            if (this.isSelectedAutoUSN) {
                docTypeCodesToInclude.push(DocumentTypeEnum.AutoUsnPDF);

                if (this.isSelectedPastYear) {
                    docTypeCodesToInclude.push(DocumentTypeEnum.AutoUsnStatePdf);
                }
            }
            return this.selectedDocSpec.documents
                .filter((it) => _.includes(docTypeCodesToInclude, it.code))
                .map((it) => {
                    const doc = _.cloneDeep(it);
                    doc.name = this.resolvePlaceholders(doc.name);
                    doc.files.forEach((f) => (f.name = this.resolvePlaceholders(f.name)));
                    doc.documentAttributes = {
                        reportingYear: this.year.code,
                        reportingQuarter:
                            this.isSelectedTaxRegimeCommon
                            || this.isSelectedTaxRegimeEshn
                            || this.isSelectedTaxRegimeEnvd
                                ? this.period.code
                                : null,
                        patentDateStart: this.isSelectedTaxRegimePatent
                            ? this.patentDateStartIso
                            : null,
                        patentDateEnd: this.isSelectedTaxRegimePatent
                            ? this.patentDateEndIso
                            : null,
                    };
                    return doc;
                });
        },
    },

    mounted() {
        this.selectYear(this.optionsYear[0]);
        if (this.optionsTaxRegime.length === 1) {
            this.selectTaxRegime(this.optionsTaxRegime[0]);
        }
    },

    methods: {
        selectYear(val) {
            this.kgn = false;
            this.incomeTaxExemption = false;
            this.incomeTaxPaperFormat = false;
            this.vatExemption = false;
            this.usnTaxPaperFormat = false;
            this.eshnTaxPaperFormat = false;
            this.envdTaxPaperFormat = false;
            this.patentDateStart = null;
            this.patentDateEnd = null;
            this.nalogProfPeriodDateStart = null;
            this.nalogProfPeriodDateEnd = null;
            this.taxRegime = null;

            this.year = val;
            this.period = this.optionPeriod[this.optionPeriod.length - 1];
        },

        selectTaxRegime(val) {
            this.taxRegime = val;
            this.$emit('tax-regime-change', val);
        },

        selectPeriod(val) {
            this.period = val;
        },

        resolvePlaceholders(val) {
            let res = val;
            const { year, period, taxRegime } = this;
            if (year) {
                res = replaceYear(res, year.code);
            }
            if (year && period) {
                res = replacePeriod(res, period.code, year.code);
            }
            return res;
        },

        disabledStartDateBySelectedYear(date) {
            const minDate = new Date(this.year.code, 0, 1, 0, 0, 0, 0);
            const maxDate = new Date(this.year.code, 11, 31, 0, 0, 0, 0);

            return date < minDate || date > maxDate;
        },

        disabledEndDateBySelectedYear(date, startDate) {
            if (!startDate) {
                return true;
            }

            const minDate = this.$moment(startDate, 'DD.MM.YYYY').toDate();
            const maxDate = new Date(this.year.code, 11, 31, 0, 0, 0, 0);

            return date < minDate || date > maxDate;
        },

        disabledPatentEndDate(date) {
            return this.disabledEndDateBySelectedYear(date, this.patentDateStart);
        },

        disabledProfNalogEndDate(date) {
            return this.disabledEndDateBySelectedYear(date, this.nalogProfPeriodDateStart);
        },

        async saveNpdWithoutDoc() {
            const sendData = {
                reportingYear: this.year.code,
                nalogProfPeriodDateStart: this.nalogProfPeriodDateStartIso,
                nalogProfPeriodDateEnd: this.nalogProfPeriodDateEndIso,
            };

            const paramsIn = {
                self: this,
                sendData,
            };

            try {
                const result = await newApi('saveNpd', paramsIn);
                if (result) {
                    const msg = 'Данные загружены';
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.SUCCESS,
                        msg,
                        title: TYPE_DIALOG_TITLE.SUCCESS,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },

        async loadFinData() {
            try {
                const res = await newApi('getFinance', { self: this, isLegal: true });
                if (res) {
                    this.financeData = res;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async saveFindData(finData) {
            const paramsIn = {
                self: this,
                isLegal: true,
                sendData: [finData],
            };

            try {
                const res = await newApi('saveFinance', paramsIn);
                if (res) {
                    this.$modal.show('info', {
                        type: TYPE_ERRORS.SUCCESS,
                        msg: 'Данные загружены',
                        title: TYPE_DIALOG_TITLE.SUCCESS,
                    });
                }

                await this.loadFinData();
            } catch (e) {
                console.error(e);
            }
        },

        isDocSelectToAdd(docCode) {
            return this.documentsUserSelectedToAdd.map((item) => item.code).includes(docCode);
        },
    },
};
</script>
