<template>
    <section class="top_header">
        <div class="wrapper">
            <div class="container">
                <div class="top_header__wrapper">
                    <div class="top_header__title-wrapper">
                        <div>{{ $t('Личный кабинет контрагента') }}</div>

                        <div>{{ profile.companyName }}</div>
                    </div>

                    <div class="top_header__list">
                        <ul class="flex justify-space-between align-end">
                            <li class="list__text mr-20"> Код КССС: {{ profile.companyCode }}</li>

                            <li class="pointer mr-20 ">
                                <div class="flex align-center" @click="showSupport">
                                    <img
                                        width="20"
                                        height="20"
                                        src="~@/assets/ico/quastion-white.png"
                                        class="mr-10"
                                    />

                                    <div class="list__text">{{ $t('Поддержка') }} </div>
                                </div>
                            </li>

                            <li
                                v-if="hasLogin"
                                class="pointer"
                            >
                                <div
                                    class="flex align-center relative"
                                    @click="openDropDownContent"
                                >
                                    <img
                                        src="~@/assets/img/user_ico.svg"
                                        class="mr-10"
                                    />
                                    <div class="list__text">{{ $t('Профиль') }}</div>
                                </div>

                                <div
                                    v-if="showDropDownContent"
                                    class="dropdown__content flex flex-column"
                                >
                                    <button
                                        class="dropdown__btn"
                                        :disabled="!hasSaveData"
                                        @click="getAccountManagement"
                                    >
                                        {{ $t('Данные') }}
                                    </button>

                                    <button
                                        class="dropdown__btn"
                                        @click="logout"
                                    >
                                        {{ $t('Выход') }}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TopHeader',

    data() {
        return {
            showMenu: false,
            showProfileMenu: false,
            showDropDownContent: false,
        };
    },

    computed: {
        profile() {
            return this.$store.state.user.profile;
        },

        hasLogin() {
            return this.$store.getters.isAuthenticated && this.$store.getters.isProfileLoaded;
        },

        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },
    },

    methods: {
        showSupport() {
            this.$modal.show('modalSupport');
        },

        async logout() {
            try {
                await this.$store.dispatch('AUTH_LOGOUT', { self: this });
            } catch (err) {
                console.log(err);
            } finally {
                await this.$router.push({ path: '/' });
                await this.$store.dispatch('CHANGE_LOCAL', 'ru');
            }
        },

        async getAccountManagement() {
            await this.$keycloak.accountManagement();
        },

        openDropDownContent() {
            this.showDropDownContent = !this.showDropDownContent;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.top_header {
    padding: 20px 0;
    background-color: $primary;

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-family: CuprumBold, sans-serif;
        font-size: 18px;
        color: $white;
    }

    &__list {
        display: flex;
        justify-content: flex-end;
    }
}

.list {

    &__text {
        font-family: CuprumBold, sans-serif;
        font-size: 16px;
        color: $white;
    }
}

.dropdown {

    &__content {
        position: absolute;
        top: 80px;
        z-index: 100;
    }

    &__btn {
        width: 100px;
        font-family: CuprumBold, sans-serif;
        color: $white;
        background-color: $primary;
        padding: 20px;
        cursor: pointer;

        &:hover {
            background-color: $black;
        }
    }
}
</style>
