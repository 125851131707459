import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import common from './modules/common';
import documents from './modules/documents';
import pbiot from './modules/pbiot';
import loader from './modules/loader/loader';
import errorNotifications from './modules/error-notifications/error-notifications';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        user,
        common,
        documents,
        pbiot,
        loader,
        errorNotifications,
    },
    strict: debug,
});
