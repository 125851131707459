import { ACCOUNT_TYPE } from '@/constant';

import HttpClientLocal from './HttpClient';

export const newApi = (method, params = {}) => {
    HttpClientLocal.self = params.self;

    let url = '';

    switch (method) {
    /* Получение рискиндикаторов */
    case 'getFileStatistics': {
        url = '/pbiot/fileStatistics';

        HttpClientLocal.setMsgError('Ошибка загрузки статистики по файлам ПБИОТ');

        return HttpClientLocal.getRequest(url);
    }

    case 'getPbiotHistoryData': {
        url = '/pbiot/history?limit=100';

        HttpClientLocal.setMsgError('Ошибка загрузки истории документов по ПБИОТ');

        return HttpClientLocal.getRequest(url);
    }

    case 'cleanModeration': {
        url = `/profile/${params.contractorCode}/clean/moderation`;

        HttpClientLocal.setMsgError('Ошибка снятия КА с модерации');

        return HttpClientLocal.postRequest(url);
    }

    case 'getAllProfiles': {
        url = '/profiles/forms';

        HttpClientLocal.setMsgError('Ошибка получения списка всех пользователей');

        return HttpClientLocal.getRequest(url, params);
    }

    case 'resetIsuUser': {
        url = `/profiles/${params.id}/resend-snabgenie`;

        HttpClientLocal.setMsgError('Повторная отправка в ИСУ "Снабжение" не удалась');

        return HttpClientLocal.postRequest(url);
    }

    case 'deleteProfile': {
        url = `/profiles/${params.id}`;

        HttpClientLocal.setMsgError('Удалить пользователя не удалось');

        return HttpClientLocal.deleteRequest(url);
    }

    case 'updateProfileStatus': {
        url = `/profiles/status/${params.id}`;

        HttpClientLocal.setMsgError('Обновление статуса не удалось');

        return HttpClientLocal.postRequest(url);
    }

    case 'setProfileKsss': {
        url = `/profiles/code/${params.id}/${params.ksss}`;

        HttpClientLocal.setMsgError('Обновление КССС не удалось');

        return HttpClientLocal.postRequest(url);
    }

    case 'openPbiot': {
        url = `/profiles/${params.contractorCode}/open/pbiot`;

        HttpClientLocal.setMsgError('Не удалось открыть анкету ПБиОТ');

        return HttpClientLocal.postRequest(url);
    }

    /* Поиск нерезидентов */
    case 'searchAccountsResident': {
        url = '/search/searchAccounts';

        const localParams = {
            search: params.search,
            type: params.type,
        };

        HttpClientLocal.setMsgError('Ошибка загрузки списка резидентов');

        return HttpClientLocal.getRequest(url, localParams);
    }

    /* Поиск нерезидентов */
    case 'searchAccountsNonResident': {
        url = '/search/searchAccountsNonResident';

        const localParams = {
            search: params.data,
            hideNonRegistered: params.hideNonRegistered,
        };

        HttpClientLocal.setMsgError('Ошибка загрузки списка нерезидентов');

        return HttpClientLocal.getRequest(url, localParams);
    }

    case 'addNonResidents': {
        url = '/supposedSubcontractorsNonResident';

        HttpClientLocal.setMsgError('Ошибка добавления нерезидента');

        return HttpClientLocal.postRequest(url, params.data);
    }

    case 'getNonResidentInRegistration': {
        url = `/search/getAccountsNonResidentById/${params.id}`;

        HttpClientLocal.setMsgError('Ошибка получения данных о резиденте');

        return HttpClientLocal.getRequest(url);
    }

    /* Генерация ссылки для регистрации субподрядчика */
    case 'getRegistrationLink': {
        url = `/supposedSubcontractorsNonResident/registrationLink/${params.id}`;

        HttpClientLocal.setMsgError('Ошибка формирования ссылки для регистрации Субподрядчика');

        return HttpClientLocal.getRequest(url);
    }

    case 'replaceNonResident': {
        url = `/supposedSubcontractorsNonResident/replace/${params.currentProfileId}?replaceWithId=${params.newProfileId}`;

        HttpClientLocal.setMsgError('Ошибка замены нерезидента');

        return HttpClientLocal.putRequest(url);
    }

    case 'getDocumentSpec': {
        url = '/profiles/documents/specv2';

        HttpClientLocal.setMsgError('Ошибка получение спецификации документов');

        return HttpClientLocal.getRequest(url);
    }

    case 'getValidDocument': {
        url = '/profiles/documents/v2/portions';

        HttpClientLocal.setMsgError('Ошибка получения документов на валидации');
        // TODO переработать возвращаемую структуру данных, т.к. documentPortion устарел
        return HttpClientLocal.getRequest(url);
    }

    case 'getFinance': {
        if (params.isLegal) {
            url = '/profiles/financialIndicatorsAutoUsn';
        } else {
            url = '/profiles/financialIndicators';
        }

        return HttpClientLocal.getRequest(url);
    }

    case 'saveFinance': {
        if (params.isLegal) {
            url = '/profiles/financialIndicatorsAutoUsn';
        } else {
            url = '/profiles/financialIndicators';
        }

        return HttpClientLocal.putRequest(url, params.sendData);
    }

    case 'loadFilePbiot': {
        url = '/pbiot/doc';

        const formData = new FormData();

        formData.append('file', params.sendData.file);
        formData.append('type', params.sendData.type);

        HttpClientLocal.setMsgError('Ошибка загрузки файла');

        return HttpClientLocal.postRequestFile(url, formData);
    }

    case 'saveIncidents': {
        url = '/pbiot/incidents';

        HttpClientLocal.setMsgError('Не удалось сохранить инциденты');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'getIncidentPbiot': {
        url = '/pbiot/current/generate-questionnaire';

        return HttpClientLocal.getFile(url);
    }

    case 'sendPbiot': {
        url = '/pbiot/accept';

        HttpClientLocal.setMsgError('Не удалось отправить данные на проверку');

        return HttpClientLocal.postRequest(url);
    }

    case 'getStatusPbiot': {
        url = '/pbiot/access';

        return HttpClientLocal.getRequest(url);
    }

    case 'getPbiot': {
        url = '/pbiot/current';

        HttpClientLocal.setMsgError('Не удалось получить актуальную информацию по ПБиОТ');

        return HttpClientLocal.getRequest(url);
    }

    case 'legalRegistration': {
        url = '/registration/resident/initialize';

        HttpClientLocal.setMsgError('Ошибка регистрации ЮЛ');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'individualRegistration': {
        url = '/registration/resident/initialize';

        HttpClientLocal.setMsgError('Ошибка регистрации ИП');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'nonresidentRegistration': {
        url = '/registration/nonresident/initialize';

        HttpClientLocal.setMsgError('Ошибка регистрации нерезидента');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'loadTenderInfo': {
        url = '/tenders/info';

        HttpClientLocal.setMsgError('Ошибка получение информации о тендере');

        return HttpClientLocal.getRequest(url);
    }

    case 'acceptTender': {
        url = '/tenders/accept';

        HttpClientLocal.setMsgError('Ошибка принятие условий в тендере');

        return HttpClientLocal.postRequest(url);
    }

    case 'logon': {
        url = '/account/me';

        HttpClientLocal.setMsgError('Ошибка получение профиля');

        return HttpClientLocal.getRequest(url);
    }

    case 'sendFaq': {
        url = '/faqs/askaquestion';

        HttpClientLocal.setMsgError('Ошибка при посылке запрос в поддержку');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'getChatInfo': {
        url = '/messages/countNew';

        return HttpClientLocal.getRequest(url, null, false);
    }

    case 'loadSubcontractors': {
        url = '/supposedSubcontractors';

        HttpClientLocal.setMsgError('Не удалось получить список субподрядчика');

        return HttpClientLocal.getRequest(url);
    }

    case 'addSubcontractors': {
        url = '/supposedSubcontractors';

        HttpClientLocal.setMsgError('Ошибка добавление субподрядчика');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'deleteSubcontractors': {
        url = `/supposedSubcontractors/${params.subcontractorLinkType}/${params.id}`;

        HttpClientLocal.setMsgError('Ошибка удаление субподрядчика');

        return HttpClientLocal.deleteRequest(url);
    }

    case 'duplicateCheck': {
        url = '/search/credentials';

        const sendData = {
            inn: params.inn,
            taxId: params.taxId,
            countryCode: params.countryCode,
            kpp: params.kpp,
        };

        HttpClientLocal.setMsgError('Ошибка проверки на дубли');

        return HttpClientLocal.getRequest(url, sendData);
    }

    case 'bankBikSearch': {
        url = '/search/bik';

        HttpClientLocal.setMsgError('Ошибка получения БИК банка');

        return HttpClientLocal.getRequest(url, params.sendData);
    }

    case 'getDictionary': {
        url = '/dictionaries/entries';

        HttpClientLocal.setMsgError('Ошибка получения справочника');

        return HttpClientLocal.getRequest(url, params.sendData);
    }

    case 'getProfileForm': {
        url = '/profiles/form';

        HttpClientLocal.setMsgError('Ошибка получение анкеты');

        return HttpClientLocal.getRequest(url);
    }

    case 'updateProfileForm': {
        url = '/profiles/form';

        HttpClientLocal.setMsgError('Ошибка обновления анкеты');

        return HttpClientLocal.putRequest(url, params.sendData);
    }

    case 'uploadFile': {
        url = '/files';

        const formData = new FormData();

        formData.append('file', params.file[0]);

        HttpClientLocal.setMsgError('Ошибка отправки файла');

        return HttpClientLocal.postRequestFile(url, formData);
    }

    case 'getFile': {
        url = `/files/${params.id}`;

        HttpClientLocal.setMsgError('Ошибка скачивание файла');

        return HttpClientLocal.getFile(url);
    }

    case 'getStopFactors': {
        url = '/tenders/stopFactors';

        HttpClientLocal.setMsgError('Ошибка принятие условий в тендере');

        return HttpClientLocal.getRequest(url);
    }

    case 'getTenderPayDoc': {
        url = '/tenders/paymentOrders';

        if (params.type == ACCOUNT_TYPE.NONRESIDENT) {
            url = '/tenders/paymentDetails';

            if (params.currencyCode?.code) {
                url += `?currencyCode=${params.currencyCode.code}`;
            }
        }

        HttpClientLocal.setMsgError('Ошибка скачивание платежной информации');

        return HttpClientLocal.getFile(url);
    }

    case 'validateSavingDocuments': {
        url = '/profiles/documents/v2/create';

        HttpClientLocal.setMsgError('Ошибка отправки на проверку документов');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'saveNpd': {
        url = '/profiles/npd';

        HttpClientLocal.setMsgError('Ошибка отправки на проверку документов');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'getChats': {
        url = '/chat/list';

        HttpClientLocal.setMsgError('Не удалось получить список чатов');

        return HttpClientLocal.getRequest(url);
    }

    case 'getChatHistory': {
        url = `/chat/${params.chatId}/history`;

        HttpClientLocal.setMsgError('Не удалось получить историю чата');

        return HttpClientLocal.getRequest(url);
    }

    case 'sendMessage': {
        url = `/chat/${params.chatId}/new-message`;

        HttpClientLocal.setMsgError('Ошибка отправки сообщения');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'markReady': {
        url = `/messages/markRead/${params.messageId ?? ''}`;

        HttpClientLocal.setMsgError('Не удалось проставить флаг прочтения сообщения');

        return HttpClientLocal.putRequest(url, params.sendData);
    }

    case 'getPbiotFile': {
        url = `/pbiot/history/${params.packageId}/zip/${params.partition}`;

        return HttpClientLocal.getFile(url);
    }

    case 'deletePbiotFile': {
        url = `/pbiot/deletedoc/${params.type}`;

        return HttpClientLocal.postRequest(url);
    }

    case 'actualDocumentAccept': {
        url = '/profiles/documents/v2/portions/resend';

        HttpClientLocal.setMsgError('Не смог подтвердить актуальность текущего документа');

        return HttpClientLocal.postRequest(url, params.sendData);
    }

    case 'getGeneralContractors': {
        url = '/subcontractor/prime';

        return HttpClientLocal.getRequest(url);
    }

    case 'getSubContractors': {
        url = '/subcontractor/get';

        return HttpClientLocal.getRequest(url);
    }

    case 'getProducers': {
        url = '/subcontractor/producers';

        return HttpClientLocal.getRequest(url);
    }

    case 'acceptTender': {
        url = '/tenders/accept';

        HttpClientLocal.setMsgError('Ошибка принятие условий в тендере');

        return HttpClientLocal.postRequest(url);
    }

    case 'loadUserAgreementStatus': {
        url = `/profile/${params.contractorCode}/user-agreement-status`;

        return HttpClientLocal.getRequest(url);
    }

    case 'acceptUserAgreementStatus': {
        url = `/profile/${params.contractorCode}/user-agreement-status`;

        return HttpClientLocal.postRequest(url);
    }

    default: {
        console.error('Нет такого АПИ');
        return false;
    }
    }
};
