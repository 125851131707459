<template>
    <div class="empty_layout_content">
        <div class="simple_form_wrap">
            <div class="form_info">
                <div class="form_front">
                    <form>
                        <h3>404 такой страницы нет</h3>
                    </form>
                </div>
            </div>
        </div>

        <div class="additional_links">
            <router-link to="/">Главная</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotPage',
};
</script>
