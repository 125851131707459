<template>
    <div class="accordion-content" data-tab-content data-cy="notres">
        <BaseShowText
            label="Уникальный код в справочнике КССС"
            :value="allData && allData.code ? allData.code : ''"
        />

        <BaseShowText
            label="Полное наименование контрагента"
            :value="allData && allData.name ? allData.name : ''"
        />

        <BaseShowText
            label="Tax ID (код налогоплательщика)"
            :value="regData && regData.taxId ? regData.taxId : null"
        />

        <BaseShowText
            label="Код регистрации"
            :value="regData && regData.registrationCode
                ? regData.registrationCode
                : null"
        />

        <UploadForm
            :label="$t('Устав (учредительный договор), содержащий информацию о полном и кратком наименованиях и юридическом адресе организации')"
            :add-file="addFile"
            :single-file="true"
            :has-files="regData && 'charterInfo' in regData && regData.charterInfo
                ? [regData.charterInfo]
                : []"
            :max-count="1"
            :size="10"
            :has-delete="false"
            :force-disabled="blockInput"
        />

        <UploadForm
            :label="$t('Свидетельство регистрации юридического лица')"
            :add-file="addFile"
            :single-file="true"
            :has-files="regData && 'certificateOfRegistration2' in regData && regData.certificateOfRegistration2
                ? [regData.certificateOfRegistration2]
                : []"
            :max-count="1"
            :size="10"
            :has-delete="false"
            :force-disabled="blockInput"
        />

        <UploadForm
            :label="$t('Свидетельство постановки на учет в налоговом органе')"
            :add-file="addFile"
            :single-file="true"
            :has-files="regData && 'certificateOfRegistration1' in regData && regData.certificateOfRegistration1
                ? [regData.certificateOfRegistration1]
                : []"
            :max-count="1"
            :size="10"
            :has-delete="false"
            :force-disabled="blockInput"
        />

        <UploadForm
            :label="$t('Письмо на официальном бланке организации, содержащее данные о банковских реквизитах, а так же информацию об адресах, наименованиях, регистрационных и налоговых кодах')"
            :add-file="addFile"
            :single-file="true"
            :has-files="regData && 'companyForm' in regData && regData.companyForm
                ? [regData.companyForm]
                : []"
            :max-count="1"
            :size="10"
            :has-delete="false"
            :force-disabled="blockInput"
        />
    </div>
</template>

<script>
import UploadForm from '@/views/components/base/UploadForm';

export default {
    name: 'NoLegalUser',

    components: {
        UploadForm,
    },

    props: {
        allData: {
            type: Object,
            required: true,
        },

        regData: {
            type: Object,
            required: true,
        },

        addFile: { required: true },

        blockInput: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
