<template>
    <div>
        <DocumentFilterBtnV2
            v-for="docSpecification in documentsInSpecification"
            :key="docSpecification.code"
            :doc-specification="docSpecification"
            :on-add-doc="addDocumentButtonPressed"
            :disable-button="haveAddedDocumentOfType(docSpecification.code)"
        />

        <slot v-if="showUploadRule" name="uploadRule" />

        <slot name="controls" />

        <div v-if="anyDocumentsUserSelectedToAdd" class="warningMes">
            Удалите документ для редактирования параметров
        </div>

        <div v-if="!hideUpload && anyDocumentsUserSelectedToAdd" class="grid-x grid-margin-x">
            <div class="large-12 cell">
                <div class="separateDocs" />

                <div class="documents_group_wrap">
                    <div class="documents_group_header">Документы</div>

                    <div class="documents_group_body">
                        <transition-group name="list">
                            <document-submit
                                v-for="documentToAdd in documentsUserSelectedToAdd"
                                :key="documentToAdd.code"
                                :document-to-add-prop="documentToAdd"
                                @removeDocument="removeDocument"
                            >
                                <template #fileDescription="slotProps">
                                    <slot name="fileDescription" :file="slotProps.file" />
                                </template>
                                <template #fileFooter="slotProps">
                                    <slot name="fileFooter" :file="slotProps.file" />
                                </template>
                            </document-submit>
                        </transition-group>
                    </div>
                </div>

                <div class="separateDocs" />
            </div>
        </div>

        <modal-confirmation />
    </div>
</template>

<script>
import ModalConfirmation from '@/views/components/shared/ModalConfirmation.vue';

import DocumentFilterBtnV2 from './DocumentFilterBtnV2.vue';
import DocumentSubmit from './DocumentUploadForm/DocumentSubmit.vue';

export default {
    name: 'DocumentCreateV2Buttons',
    components: {
        ModalConfirmation,
        DocumentSubmit,
        DocumentFilterBtnV2,
    },
    props: {
        documentsUserSelectedToAddProp: {
            // Array of DocumentSpecificationDocDTO
            type: Array,
            default() {
                return [];
            },
        },
        documentsInSpecification: {},
        hideUpload: { type: Boolean, default: false },
        getConfirmationDialogProps: {
            /**
             * If this function is defined, it should return object suitable to pass to this.$modal.show as a second parameter
             * If the function returns null, no confirmation dialog will be shown
             *
             * The function accepts 1 parameter: docSpecification
             */
            type: Function,
            required: false,
        },
    },
    data() {
        return {
            documentsUserSelectedToAdd: this.documentsUserSelectedToAddProp,
        };
    },
    computed: {
        showUploadRule() {
            return _.isEmpty(this.documentsUserSelectedToAdd);
        },
        anyDocumentsUserSelectedToAdd() {
            return !_.isEmpty(this.documentsUserSelectedToAdd);
        },
    },
    watch: {
        documentsUserSelectedToAdd: {
            deep: true,
            handler() {
                this.$emit('update:documentsUserSelectedToAdd', this.documentsUserSelectedToAdd);
            },
        },
    },
    methods: {
        addDocumentButtonPressed(docSpecification) {
            if (this.getConfirmationDialogProps) {
                const propsModal = this.getConfirmationDialogProps(docSpecification);
                if (propsModal) {
                    const nextCall = () => this.addDocument(docSpecification);
                    propsModal.resultFunction = nextCall;
                    this.$modal.show('confirm', propsModal);
                } else {
                    this.addDocument(docSpecification);
                }
            } else {
                this.addDocument(docSpecification);
            }
        },
        addDocument(docSpecification) {
            this.documentsUserSelectedToAdd.push(_.cloneDeep(docSpecification));
        },
        removeDocument(docSpecification) {
            this.documentsUserSelectedToAdd = this.documentsUserSelectedToAdd.filter(
                (it) => it != docSpecification,
            );
        },
        haveAddedDocumentOfType(code) {
            return !!this.documentsUserSelectedToAdd.find((doc) => doc.code === code);
        },
    },
};
</script>

<style lang="scss" scoped>

.separateDocs {
    margin-bottom: 32px;
}
</style>
