<template>
    <ui-modal
        :icon="icons.alertBox"
        title="Информационное окно"
        name="MainNotificationModal"
        width="800"
        @before-open="beforeOpen"
        @closed="closed"
    >
        <div class="main-notification-modal">
            <div
                class="main-notification-modal__content"
                v-html="message"
            />
        </div>
    </ui-modal>
</template>
<script>
import { mapActions } from 'vuex';

import icons from '@/helpers/icons';

export default {
    name: 'MainNotificationModal',

    data() {
        return {
            icons,
            message: 'Система загружена. Попробуйте позже.',
            beforeClose: () => {},
            files: [],
        };
    },

    watch: {
        files(val) {
            this.addFiles(val);
        },
    },

    methods: {
        ...mapActions(['addFiles']),

        beforeOpen({ params }) {
            this.message = params.message || this.message;
            this.beforeClose = params.beforeClose || this.beforeClose;
            this.files = params.files || this.files;
        },

        closeModal() {
            this.$modal.hide('MainNotificationModal');
        },

        closed() {
            this.beforeClose();
        },
    },
};
</script>

<style lang="scss" scoped>
.main-notification-modal {
    &__content {
        text-align: center;
    }
}
</style>
