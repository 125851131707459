import Vue from 'vue';
import VModal from 'vue-js-modal';
import FloatingVue from 'floating-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import VueEasytable, { VeLocale } from 'vue-easytable';
import Vuelidate from 'vuelidate';
import Keycloak from 'keycloak-js';

import i18n from '@/i18n';
import store from '@/store';
import router from '@/router';
import App from '@/App.vue';

/** Custom components */
import BaseInput from '@/views/components/base/BaseInput.vue';
import BaseCheckbox from '@/views/components/base/BaseCheckbox.vue';
import BaseShowText from '@/views/components/base/BaseShowText.vue';
import InfoUser from '@/views/components/shared/InfoUser.vue';
import RegistrationInfoButton from '@/views/modules/registration/registration-info-button.vue';
import PageHeader from '@/views/components/shared/PageHeader.vue';
import TabsBody from '@/views/components/base/tabs/TabsBody.vue';
import TabItem from '@/views/components/base/tabs/TabItem.vue';
import TabsMenu from '@/views/components/base/tabs/TabsMenu.vue';
import TabMenuItem from '@/views/components/base/tabs/TabMenuItem.vue';
import UiModal from '@/ui-components/ui-modal.vue';
import UiButton from '@/ui-components/ui-button.vue';
import UiIcon from '@/ui-components/ui-icon.vue';
import '@/directive/v-price';
import '@/assets/styles/main.scss';
import 'vue-easytable/libs/theme-default/index.css';

import 'floating-vue/dist/style.css';

import { appParams, getAppConfig } from '@/utils/appConfig';

Vue.prototype.$moment = moment;
moment.locale('ru', {
    longDateFormat: {
        LL: 'DD-MM-YYYY',
        LLL: 'DD-MM-YYYY HH:mm',
    },
});

Vue.use(Vuelidate);
Vue.use(VModal);
Vue.use(VueEasytable);
Vue.component('VSelect', vSelect);
Vue.component('UiDatepicker', DatePicker);
Vue.component(BaseInput.name, BaseInput);
Vue.component(BaseCheckbox.name, BaseCheckbox);
Vue.component(BaseShowText.name, BaseShowText);
Vue.component(InfoUser.name, InfoUser);
Vue.component('RegInfoButton', RegistrationInfoButton);
Vue.component(PageHeader.name, PageHeader);
Vue.component(TabsBody.name, TabsBody);
Vue.component(TabItem.name, TabItem);
Vue.component(TabsMenu.name, TabsMenu);
Vue.component(TabMenuItem.name, TabMenuItem);

// new component
Vue.component('UiModal', UiModal);
Vue.component('UiIcon', UiIcon);
Vue.component('UiButton', UiButton);
Vue.config.productionTip = false;

Vue.use(FloatingVue, {
    themes: {
        tooltip: {
            html: true,
            $resetCss: true,
        },
    },
});

const lang = {
    pagination: {
        itemsPerPage: ' / стр',
        total: (_total) => `Общее кол-во: ${_total ?? 0}`,
        goto: 'Перейти на:',
    },
};

VeLocale.update(lang);
// полифил for ie11
if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        }

        return this.indexOf(search, start) !== -1;
    };
}

const keycloak = new Keycloak({
    realm: getAppConfig(appParams.KEYCLOAK_REALM),
    url: getAppConfig(appParams.KEYCLOAK_AUTH_HOST, `${window.location.origin}/auth`),
    clientId: getAppConfig(appParams.KEYCLOAK_CLIENT_ID),
});

keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: true
}).then(async (authenticated) => {
    if (authenticated) {
        Vue.prototype.$keycloak = keycloak;
        Vue.prototype.$keycloak.userName = keycloak.tokenParsed?.preferred_username || '';

        // Логика автообновления токена
        setInterval(() => {
            keycloak.updateToken(70).catch((error) => {
                console.error('Failed to refresh token', error);
                keycloak.login();
            });
        }, 120000);

        Vue.prototype.$logout = async () => {
            const logoutOptions = {
                redirectUri: getAppConfig(appParams.KEYCLOAK_REDIRECT_URI, `${window.location.origin}`),
            };

            keycloak.logout(logoutOptions).then(() => {
                console.log('Logout successful');
            }).catch((error) => {
                console.error('Logout error:', error);
            });
        };

        new Vue({
            router,
            store,
            i18n,
            render: (h) => h(App),
        }).$mount('#app');
    } else {
        console.warn('Not authenticated');
        window.location.reload();
    }
}).catch((error) => {
    console.error('Keycloak initialization error:', error);
});