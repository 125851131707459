import { render, staticRenderFns } from "./registration-entity-type.vue?vue&type=template&id=41cb2608&scoped=true"
import script from "./registration-entity-type.vue?vue&type=script&lang=js"
export * from "./registration-entity-type.vue?vue&type=script&lang=js"
import style0 from "./registration-entity-type.vue?vue&type=style&index=0&id=41cb2608&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41cb2608",
  null
  
)

export default component.exports