<template>
    <div class="grid-x grid-margin-x">
        <div class="cell mb-12">
            <DocumentCreateV2Buttons
                :documents-in-specification="selectedDocSpec.documents"
                :get-confirmation-dialog-props="getConfirmationDialogProps"
                @update:documentsUserSelectedToAdd="
                    $emit('update:documentsUserSelectedToAdd', $event)
                "
            >
                <template v-if="isNotBranchOfForeignOrg" #fileDescription="slotProps">
                    <div class="warninText">
                        <br />
                        Необходимо загрузить <b>следующие</b> документы: <br />
                        - <b>Решение</b> собрания участников (единственного участника) общества; <br />
                        - <b>Приказ</b> о назначении генерального директора (исполнительного органа).
                    </div>
                </template>
            </DocumentCreateV2Buttons>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import DocumentUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentUploadForm.vue';

export default {
    name: 'DirectorAppointmentOrderUiHandler',

    components: {
        DocumentUploadForm,
        DocumentCreateV2Buttons,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            // DocumentSpecificationGroupDTO + group property
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters(['existingDocumentInfoList']),

        isNotBranchOfForeignOrg() {
            return !this.selectedDocSpec.uiHandlingOptions?.isBranchOfForeignOrg;
        },

    },

    methods: {
        getConfirmationDialogProps(docSpecification) {
            const existingDocs = this.existingDocumentInfoList(docSpecification.code);
            if (_.isEmpty(existingDocs)) {
                return null;
            }
            if (
                existingDocs.some((uploadedDoc) => uploadedDoc.code === docSpecification.code)
            ) {
                return {
                    nameDoc: docSpecification.name,
                    msg: [
                        'У вас уже загружен данный документ. Убедитесь, что собираетесь загрузить корректную версию.',
                    ],
                    confirmBtnText: 'Загрузить',
                    title: 'Внимание!',
                };
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
.warninText b {
    color: #da2b36;
}
</style>
