<template>
    <div class="accordion-content" data-tab-content data-cy="indblock">
        <BaseShowText
            label="Уникальный код в справочнике КССС"
            :value="code"
        />

        <BaseShowText
            label="Полное наименование <br/>контрагента согласно уставу"
            :value="name"
        />

        <BaseShowText
            label="ИНН"
            :value="inn"
        />

        <BaseShowText
            label="ОГРНИП"
            :value="ogrnip"
        />

        <BaseShowText
            v-if="registrationState"
            label="Статус регистрации"
            :value="registrationStateName"
        />

        <BaseShowText
            v-if="closeDate"
            label="Дата закрытия"
            :value="convertData(allData.closeDate)"
        />

        <BaseShowText
            label="Организационно-правовая <br />форма"
            :value="opf"
        />

        <div class="worksheet-info-item">
            <div class="worksheet-info-item__label">Данные о системе налогообложения</div>

            <div class="worksheet-info-item__text">
                <div class="select_wrap">

                    <ve-table
                        :columns="columns"
                        :table-data="taxRegimes"
                        border-x
                    />

                    <div
                        v-if="showEmpty"
                        class="empty-data-min"
                    >
                        Нет данных.
                    </div>

                </div>
            </div>
        </div>

        <div class="worksheet-warning">
            <ui-icon
                :name="icons.roundError"
                class="worksheet-warning__icon"
            />

            <div class="worksheet-warning__message">
                <span class="worksheet-warning__message--margin">
                    Применение специального налогового режима необходимо подтвердить в разделе
                    "Документы" в категории "Налоговая отчетность".
                </span>

                <span class="worksheet-warning__message--margin">
                    За все отраженные периоды необходимо загрузить налоговую отчетность в
                    соответствии с применяемым режимом налогообложения в обозначенные сроки.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { DICTIONARY_LIST } from '@/constant';
import icons from '@/helpers/icons';

export default {
    name: 'IndividualUser',

    props: {
        allData: {
            type: Object,
            required: true,
        },

        regData: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            icons,
            dicList: DICTIONARY_LIST,

            columns: [
                {
                    key: 'a',
                    title: 'Год',
                    field: 'callYear',
                },
                {
                    key: 'b',
                    title: 'Система налогообложения',
                    field: 'taxRegime',
                },
                {
                    key: 'c',
                    title: 'Источник данных',
                    field: 'taxSource',
                },
            ],
        };
    },

    computed: {
        inn() {
            return this.regData?.inn;
        },

        ogrn() {
            return this.regData?.ogrn;
        },

        ogrnip() {
            return this.regData?.ogrnip;
        },

        opf() {
            return this.regData?.opf;
        },

        code() {
            return this.allData?.code ?? '';
        },

        name() {
            return this.allData?.name ?? '';
        },

        closeDate() {
            return this.allData?.closeDate;
        },

        registrationState() {
            return this.allData?.registrationState;
        },

        registrationStateName() {
            return this.allData?.registrationState?.name;
        },

        taxRegimes() {
            const taxRegimes = this.regData?.taxes ?? [];

            return taxRegimes.sort((a, b) => Number(b.callYear) - Number(a.callYear));
        },

        showEmpty() {
            return this.taxRegimes.length === 0;
        },
    },

    methods: {
        convertData(val) {
            let res = '';

            try {
                res = this.$moment(val).format('DD.MM.YYYY');
            } catch (e) {
                console.error(e);
            }

            return res;
        },
    },
};
</script>

<style lang='scss' scoped>
.worksheet-info-item {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &__label {
        flex: 1;
        max-width: 400px;
        font-size: 20px;
        display: block;
        letter-spacing: 0.17px;
        color: #989898;
    }

    &__text {
        width: 100%;
        max-width: 500px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.17px;
        color: #000000;
    }
}

.worksheet-warning {
    display: flex;
    align-items: center;

    &__icon {
        color: #db2b37;
        padding-right: 15px;
        width: 90px;
        height: 90px;
    }

    &__message {
        display: flex;
        flex-direction: column;
        color: #d5a72d;

        &--margin {
            margin: 5px 0px 5px 0px;
        }
    }
}
</style>
