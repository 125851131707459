<template>
    <div
        id="accordion1"
        class="accordion"
        data-accordion
        data-allow-all-closed="true"
    >
        <div class="accordion-item is-active" data-accordion-item>
            <a href="#" class="accordion-title" data-cy="regdata">
                <span class="ico_pen" />{{ $t('Регистрационные данные') }}
                <span class="ico_accordion" />
            </a>

            <legal-user
                v-if="isLegal"
                :all-data="allData"
                :reg-data="regData"
                :block-input="blockInput"
            />

            <individual-user
                v-if="isIndividual"
                :all-data="allData"
                :reg-data="regData"
                :block-input="blockInput"
            />

            <no-legal-user
                v-if="isNotResident"
                :add-file="addFile"
                :all-data="allData"
                :reg-data="regData"
                :block-input="blockInput"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LegalUser from '@/views/job-application/registration-data/legal-user';
import NoLegalUser from '@/views/job-application/registration-data/no-legal-user';

import IndividualUser from './individual-user';

export default {
    name: 'RegistrationData',

    components: {
        LegalUser,
        NoLegalUser,
        IndividualUser,
    },

    props: {
        allData: {
            type: Object,
            required: true,
        },

        blockInput: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        const accordion1 = new Foundation.Accordion($('#accordion1'));
    },

    computed: {
        ...mapGetters(['isLegal', 'isIndividual', 'isNotResident']),

        regData() {
            const { allData } = this;

            if (allData.data.legal) {
                return allData.data.legal;
            }

            if (allData.data.individual) {
                return allData.data.individual;
            }

            if (allData.data.nonResident) {
                return allData.data.nonResident;
            }

            return {};
        },
    },

    methods: {
        addFile(files) {
            if (this.allData.data.legal) {
                const fileId = files?.[0].id ?? null;

                this.$set(this.allData.data.legal, 'charterId', fileId);
            }
        },
    },
};
</script>
