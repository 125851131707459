<template>
    <div v-if="isShow" class="toast_wrap">
        <div class="container">
            <div class="grid-x">
                <div class="large-12 cell">
                    <div v-if="!hasSaveData && !isInfoDocuments" class="toast_body">
                        {{ $t("Внимание! Возможность редактирования ограничена: данные учетной записи находятся на проверке.") }}
                    </div>
                    <div v-else-if="!isProfileInfoEditable" class="toast_body">
                        {{ $t("textModer") }}
                    </div>
                    <h2 v-show="!isProfileInfoEditable || (!hasSaveData && !isInfoDocuments)">
                        {{ $t("textModerSub") }} <a style="color: #0645ad" href="mailto: lkk@lukoil.com">lkk@lukoil.com</a>
                    </h2>
                    <div
                        v-if="isInfoDocuments"
                        class="toast_body toast_body__documents"
                    >
                        <div>Обращаем Ваше внимание:</div>
                        <div>
                            В зависимости от целей заключаемого договора, часть документов
                            может быть затребована у контрагента Ответственным сотрудником от
                            ОГЛ в качестве обязательных документов.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoUser',

    props: {
        isSubcontractor: { type: Boolean, default: false },
        subcontractorIsEdit: { type: Boolean, default: false },
        isProfileInfoEditable: { type: Boolean, default: true },
        isInfoDocuments: { type: Boolean, default: false },
    },

    computed: {
        hasSaveData() {
            if (this.isSubcontractor) {
                return this.subcontractorIsEdit;
            }
            return this.$store.getters.hasSaveData;
        },
        isShow() {
            return !this.hasSaveData || !this.isProfileInfoEditable || this.isInfoDocuments;
        },
    },
};
</script>
<style scoped>
.toast_wrap {
  margin-top: 40px;
}
h2 {
  font-size: 15px;
}
</style>
