<template>
    <label v-if="drawEl" class="checkbox_container">
        <input
            :id="id"
            type="checkbox"
            :checked="value"
            :disabled="disabled"
            @change="changeData"
        />
        <span class="checkmark" />

        <slot />

        <template v-if="link">
            <a
                :href="link"
                :title="link"
                class="nolink"
                target="_blank"
            >
                {{ label }}
            </a>
        </template>

        <template v-if="!link">
            {{ label }}
        </template>

        <transition name="fade" mode="out-in">
            <div v-if="hasError" class="has-error">
                <p>{{ errorText }}</p>
            </div>
        </transition>
    </label>
</template>

<script>
export default {
    name: 'BaseCheckbox',

    props: {
        value: {},
        id: {
            type: String,
            default: 'remember_check',
        },
        name: { type: String },
        label: { type: String },
        link: { type: String },
        errorText: {
            type: String,
            default: '',
        },
        vError: { type: Object },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            drawEl: true,
        };
    },

    computed: {
        hasError() {
            const { vError } = this;
            if (vError && vError.$dirty && vError.$error) {
                return true;
            }
            return false;
        },
    },

    methods: {
        changeData() {
            this.$emit('input', !this.value);
            this.drawEl = false;
            this.$nextTick(() => {
                this.drawEl = true;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.nolink {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.3px;
  color: #000;
  text-decoration: none;
}
.has-error {
  margin-left: -32px;
}
.checkbox_container a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #000;
}
</style>
