<template>
    <div class="add-documents">
        <info-user />

        <page-header
            :title="$t('Новые документы')"
            link-back="/documents/"
            class="mb-20"
        />

        <section
            v-if="mainNotifications.length"
            class="add-documents__wrapper mb-20"
        >
            <div class="container">
                <div
                    v-for="( { text }, idx ) in mainNotifications"
                    :key="`content-${idx}`"
                    v-html="addDocumentsNotifications[text]"
                />
            </div>
        </section>

        <documents-create
            :selected-doc-spec.sync="selectedDocSpec"
            :tax-regime.sync="taxRegime"
            class="container"
        />

    </div>
</template>

<script>
import { addDocumentsNotifications } from '@/notificationTemplates/documentNotifications';

import DocumentsCreate from './documents-create';

export default {
    name: 'AddDocuments',

    components: {
        DocumentsCreate,
    },

    data() {
        return {
            load: false,
            selectedDocSpec: null,
            taxRegime: null,
            addDocumentsNotifications,
        };
    },

    computed: {
        mainNotifications() {
            const content = this.selectedDocSpec?.topNotificationBar?.content ?? [];
            const taxRegimeCode = this.taxRegime?.code;

            return content.filter(({ options }) => {
                const isDefaultOption = options[0] === 'default';

                if (taxRegimeCode !== 'AUSN') {
                    return isDefaultOption;
                }

                return !isDefaultOption;
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/common/_variables";
.add-documents {

    &__wrapper {
        font-family: CuprumBold, sans-serif;
        font-size: 26px;
        font-weight: 600;
        text-align: justify;
        color: #474a51;
        background: rgba(213, 167, 45, 0.19);
        padding: 25px 0px;

        & h3 {
            text-transform: uppercase;
        }

        & p {
            font-size: 24px;
            margin-bottom: 10px;
        }

        & ul {
            list-style: auto;
            font-size: 20px;
            line-height: 1.7;
            padding: 0 20px;

            ul {
                list-style: disc;
            }
        }
    }
}
</style>
