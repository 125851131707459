import { uniq } from 'lodash';

export default {
    props: {

        /**
         * Тип
         */
        type: null,
    },

    data() {
        return {
            baseClassName: '',
        };
    },

    computed: {
        typeClasses() {
            return this.typeAsArray.map((type) => `${this.baseClassName}--${type}`).join(' ');
        },

        typeAsArray() {
            return this.typeToArray(this.type);
        },
    },

    methods: {
        typeIs(type) {
            const typeAsArray = this.typeToArray(type);

            for (let i = 0; i < typeAsArray.length; i += 1) {
                if (this.typeAsArray.includes(typeAsArray[i])) {
                    return true;
                }
            }

            return false;
        },

        typeToArray(type) {
            let types = [];

            if (type instanceof Array) {
                types = type.reduce((carry, item) => carry.concat(this.typeToArray(item)), []);
            } else if (typeof type === 'object' && type !== null) {
                types = Object.keys(type).filter((key) => {
                    if (typeof type[key] === 'function') {
                        return type[key]();
                    }

                    return type[key];
                });
            } else if (typeof type === 'string') {
                types = type.split(' ');
            }

            return uniq(types.filter((item) => !!item));
        },
    },
};
