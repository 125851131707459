<template>
    <div v-if="isThereFactors" class="stop_factors">
        <div class="trend_top_wrap denied">
            <div class="text_block">
                <h3>Участие в тендере ограничено</h3>
            </div>
            <div v-if="limitedDate" class="block_date">
                {{ formattedDate }}
            </div>
        </div>
        <ul>
            <li v-if="missingRequiredDocuments">
                Необходимо загрузить (актуализировать) документы в <a href="/documents/">Личном кабинете контрагента</a>
            </li>
            <li v-if="blockingRiskIndicators">
                Наличие обстоятельств, предусмотренных пунктом 2.1  Инструкции поставщику ТРУ.
            </li>
            <div v-if="blockingRiskIndicators && hasBlockingRisks" class="blocking_risks">
                <li v-for="item in blockingRiskIndicatorsList">
                    {{ item.text }}
                    <ul class="links_list">
                        <li v-for="link in item.links">
                            <a :href="link.url">{{ link.title }}</a>
                        </li>
                    </ul>
                </li>
            </div>
        </ul>
        <div class="recommendation">
            В случае возникновения вопросов по ограничению на участие в тендере просим Вас направлять их на почту: <<a style="color: #0645ad" href="mailto: lkk@Lukoil.com">lkk@lukoil.com</a>
        </div>
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'TenderStopFactors',

    data() {
        return {
            stopFactors: null,
        };
    },

    computed: {
        isThereFactors() {
            return this.missingRequiredDocuments || this.blockingRiskIndicators;
        },
        missingRequiredDocuments() {
            return this.stopFactors?.missingRequiredDocuments;
        },
        blockingRiskIndicators() {
            return this.stopFactors?.blockingRiskIndicators;
        },
        limitedDate() {
            return this.stopFactors?.limitDate;
        },
        formattedDate() {
            return `с ${this.$moment(this.limitedDate).format('DD.MM.YYYY')}`;
        },
        blockingRiskIndicatorsList() {
            return this.stopFactors?.blockingRiskIndicatorsList;
        },
        hasBlockingRisks() {
            return this.blockingRiskIndicatorsList
      && this.blockingRiskIndicatorsList.length > 0;
        },
    },

    async created() {
        await this.loadData();
    },

    methods: {
        async loadData() {
            const paramsIn = {
                self: this,
            };
            try {
                const result = await newApi('getStopFactors', paramsIn);
                this.stopFactors = result;
            } catch (error) {
                console.error(`Fail to load stop factors. Inner exception: ${error}`);
            }
        },
    },
};
</script>

<style scoped>
.stop_factors {
  border: 4px solid;
  margin-bottom: 30px;
}
ul {
  list-style: square;
  margin: 0 40px;
}
a {
  color: #db2b37;
}
.recommendation {
  margin: 0 40px;
}
.links_list {
  list-style: none;
  padding-left: 20px;
}
.blocking_risks {
  margin-left: 15px;
  list-style: none;
}
</style>
