<template>
    <ui-modal
        :title="title"
        :icon="icons.alertBox"
        name="ErrorModal"
        width="1000"
        @before-open="beforeOpen"
    >
        <div>
            <div class="error-modal__content">
                <div class="error-modal__row">Дата и время: {{ date }}</div>

                <div v-if="url" class="error-modal__row">API: {{ url }}</div>

                <div v-if="requestUid" class="error-modal__row">Request UID: {{ requestUid }}</div>

                <div class="error-modal__row">
                    <span class="message-box__title">Текст ошибки</span>

                    <div class="message-box__content">
                        {{ errorMessage }}
                    </div>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import icons from '@/helpers/icons';

export default {
    name: 'ErrorModal',

    data() {
        return {
            icons,
            errorMessage: null,
            title: null,
            url: null,
            date: null,
            requestUid: null,
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                errorMessage, title, url, requestUid,
            } = event.params;
            this.errorMessage = errorMessage;
            this.url = url;
            this.title = title;
            this.requestUid = requestUid;
            this.date = this.$moment().format('DD.MM.YYYY|HH:mm:ss');
        },
    },
};
</script>

<style lang="scss" scoped>
.error-modal {
    &__content {
        font-size: 16px;
        margin-bottom: 20px;
    }

    &__row {
        margin-top: 10px;
    }
}

.message-box {
    &__title {
        font-family: 'OpenSansBold', sans-serif;
    }

    &__content {
        background: #fff4f5;
        border: 1px solid #db2b37;
        color: #000000;
        padding: 15px;
        min-height: 100px;
    }
}
</style>
