<template>
    <ui-modal
        name="NonResidentsModal"
        :title="title"
        width="500"
        max-height
        scrollable
        @before-open="opened"
        @before-close="reset"
    >
        <section class="non-residents">
            <form class="non-residents__form">
                <section>
                    <h3 class="form__title">
                        {{ title }}
                        <span style="display: block">(ЮЛ-Нерезидент РФ)</span>
                    </h3>

                    <div class="form__info-text">
                        {{ linkTypeTitle }} <a class="info-link" href="mailto:lkk@Lukoil.com">lkk@lukoil.com.</a>
                    </div>

                    <v-select
                        v-model="search"
                        label="id"
                        :filterable="false"
                        :options="nonResidentsData"
                        :disabled="isSearchData"
                        placeholder="Введите Tax ID / Наименование ЮЛ"
                        class="search_select"
                        @search="onSearch"
                    >
                        <template #option="{ name, taxId, actualAddress, generateId }">
                            <div
                                :key="generateId"
                                :title="name"
                                class="select_company_title"
                            >
                                {{ name }}
                            </div>

                            <div
                                :title="actualAddress.city"
                                class="select_company_info"
                            >
                                {{ taxId }}, {{ actualAddress.city }}
                            </div>
                        </template>

                        <template #selected-option="{ name }">
                            <div>{{ name }}</div>
                        </template>

                        <template #no-options>
                            {{ $t('Нет результатов поиска') }}
                        </template>
                    </v-select>

                    <base-checkbox
                        v-if="!isReplaceMode"
                        v-model="isSearchData"
                        label="Поиск не дал результатов"
                    />
                </section>

                <section>
                    <base-input
                        v-model="searchData.name"
                        label="Наименование*"
                        placeholder="Наименование"
                        :disabled="!isSearchData"
                    />

                    <div class="input_wrap">
                        <label>Страна присвоения TAXID*</label>

                        <search-dictionary
                            :form="searchData.actualAddress"
                            field-name="countryRef"
                            :type-dictionary="list.COUNTRY"
                            :placeholder="$t('Введите страну')"
                            :force-disabled="!isSearchData"
                        />
                    </div>

                    <base-input
                        v-model="searchData.taxId"
                        label="Tax ID*"
                        name="taxId"
                        placeholder="Tax ID"
                        :disabled="!isSearchData"
                    />

                    <base-input
                        v-model="searchData.actualAddress.index"
                        label="Индекс*"
                        name="index"
                        placeholder="Индекс"
                        :disabled="!isSearchData"
                    />

                    <div class="input_wrap">
                        <label>Регион*</label>

                        <search-dictionary
                            v-if="!isSearchData"
                            :form="searchData.actualAddress"
                            field-name="regionRef"
                            :type-dictionary="list.REGION"
                            :placeholder="$t('Введите регион')"
                            :force-disabled="!isSearchData"
                        />

                        <search-dictionary
                            v-else
                            :form="searchData.actualAddress"
                            field-name="regionRef"
                            :type-dictionary="list.REGION"
                            :placeholder="$t('Введите регион')"
                            :parent-code="searchData.actualAddress.countryRef"
                            :force-disabled="!isSearchData"
                            has-parent-code
                        />
                    </div>

                    <base-input
                        v-model="searchData.actualAddress.city"
                        label="Город*"
                        name="city"
                        placeholder="Город"
                        :disabled="!isSearchData"
                    />

                    <base-input
                        v-model="searchData.actualAddress.locality"
                        label="Населённый пункт*"
                        name="locality"
                        placeholder="Населённый пункт"
                        :disabled="!isSearchData"
                    />

                    <base-input
                        v-model="searchData.actualAddress.disctrict"
                        label="Район*"
                        name="disctrict"
                        placeholder="Район"
                        :disabled="!isSearchData"
                    />

                    <base-input
                        v-model="searchData.actualAddress.street"
                        label="Улица/Дом/Корпус/Номер помещения*"
                        name="street"
                        placeholder="Улица/Дом/Корпус/Номер помещения"
                        :disabled="!isSearchData"
                    />

                    <base-checkbox
                        v-if="!isReplaceMode"
                        v-model="isProducer"
                        label="Является производителем"
                    />

                    <base-checkbox
                        v-if="!isReplaceMode"
                        v-model="isSubContractor"
                        label="Является субподрядчиком"
                    />
                </section>

                <ui-button
                    :disabled="hasDisabled"
                    type="full"
                    @click="sendForm"
                >
                    {{ currentActionName }}
                </ui-button>
            </form>
        </section>
    </ui-modal>
</template>

<script>
import { debounce, uniqueId } from 'lodash';

import { newApi } from '@/utils/new-api';
import { DICTIONARY_LIST } from '@/constant';
import BaseCheckbox from '@/views/components/base/BaseCheckbox.vue';
import SearchDictionary from '@/views/components/base/SearchDictionary.vue';

export default {
    name: 'NonResidentsModal',

    components: {
        BaseCheckbox,
        SearchDictionary,
    },

    data() {
        return {
            list: DICTIONARY_LIST,
            profileId: null,
            nonResidentsData: [],
            isSearchData: false,
            isReplaceMode: false,
            search: {},
            searchData: {
                name: '',
                taxId: '',
                actualAddress: {
                    city: '',
                    countryRef: {
                        code: '',
                        id: 0,
                        name: '',
                    },
                    disctrict: '',
                    index: '',
                    locality: '',
                    regionRef: {
                        code: '',
                        id: 0,
                        name: '',
                    },
                    street: '',
                },
                accountType: null,
            },
            isProducer: false,
            isSubContractor: false,
        };
    },

    computed: {
        hasDisabled() {
            if (this.isProducer
                && this.searchData.name
                && this.searchData.taxId
            ) {
                return false;
            }

            if (this.isSubContractor
                && this.searchData.name
                && this.searchData.taxId
            ) {
                return false;
            }

            return true;
        },

        title() {
            return this.isReplaceMode ? 'Замена записи' : 'Добавление контрагента';
        },

        linkTypeTitle() {
            return `В случае невозможности заведения ${this.isProducer ? 'производителя' : 'субподрядчика'}
            или обнаружения неточности в данных – обратитесь в службу поддержки`;
        },

        currentActionName() {
            return this.isReplaceMode ? 'Заменить контрагента' : 'Добавить контрагента';
        },
    },

    watch: {
        search(val) {
            if (val) {
                Object.assign(this.searchData, val);
            }
        },

        isSearchData() {
            this.defaultData();
        },
    },

    methods: {
        // TODO: написать mixin
        reset() {
            this.profileId = null;
            this.nonResidentsData = [];
            this.isSearchData = false;
            this.isReplaceMode = false;
            this.isProducer = false;
            this.isSubContractor = false;
            this.defaultData();
        },

        defaultData() {
            this.search = {};
            this.searchData = {
                name: '',
                taxId: '',
                actualAddress: {
                    city: '',
                    countryRef: {
                        code: '',
                        id: 0,
                        name: '',
                    },
                    disctrict: '',
                    index: '',
                    locality: '',
                    regionRef: {
                        code: '',
                        id: 0,
                        name: '',
                    },
                    street: '',
                },
                accountType: null,
            };
        },

        opened({ params }) {
            if (params.accountType === 'PRODUCER') {
                this.isProducer = true;
            }

            if (params.accountType === 'SUBCONTRACTOR') {
                this.isSubContractor = true;
            }

            if (params.profileId) {
                this.profileId = params.profileId;
            }

            if (params.isReplaceMode) {
                this.isReplaceMode = params.isReplaceMode;
            }
        },

        async onSearch(search, loading) {
            if (search.length) {
                loading(true);

                await this.searchNonResidents(loading, search, this);
            }
        },

        searchNonResidents: debounce(async (loading, search, vm) => {
            try {
                const params = {
                    self: vm,
                    data: search,
                    hideNonRegistered: vm.isReplaceMode,
                };

                const body = await newApi('searchAccountsNonResident', params);

                vm.nonResidentsData = body.map((item) => ({
                    ...item,
                    generateId: uniqueId(item.taxId),
                }));
            } catch (e) {
                console.error(e);
            } finally {
                loading(false);
            }
        }, 350),

        async sendForm() {
            const sendMethod = this.isReplaceMode ? this.replaceData : this.sendData;

            await sendMethod();
        },

        async sendData() {
            try {
                const params = {
                    self: this,
                    data: {
                        contractorCode: this.searchData?.contractorCode,
                        info: {
                            actualAddress: this.searchData.actualAddress,
                            name: this.searchData.name,
                            taxId: this.searchData.taxId,
                        },
                        isProducer: this.isProducer || null,
                        isSubContractor: this.isSubContractor || null,
                        profileId: this.searchData?.profileId,
                    },
                };

                await newApi('addNonResidents', params);

                this.$modal.hide('NonResidentsModal');

                this.$emit('reload');
            } catch (e) {
                console.error(e);
            }
        },

        async replaceData() {
            try {
                const params = {
                    self: this,
                    currentProfileId: this.profileId,
                    newProfileId: this.search.profileId,
                };

                await newApi('replaceNonResident', params);

                this.$modal.hide('NonResidentsModal');

                this.$emit('reload');
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.non-residents {

    .info-link {
        color: $link;
    }

    &__info-text {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.input_wrap {
    .has-error {
        p {
            color: #d5a72d;
        }
    }
}

.search_select {
    min-height: 48px;
    height: inherit;
    .vs__dropdown-toggle {
        min-height: 48px;
        height: inherit;
    }
}

.search_select.vs--open {
    .vs__dropdown-toggle {
        overflow: hidden;
    }
    .vs__selected-options {
        overflow: hidden;
    }
    .vs__dropdown-toggle {
        overflow: hidden;
    }
}

</style>
