<template>
    <section>
        <div v-if="rows.length">
            <ve-table
                :columns="columns"
                :table-data="tableData"
                :cell-style-option="cellStyleOption"
                border-x
            />

            <ve-pagination
                :page-index="paginator.pageNumber"
                :page-size="paginator.pageSize"
                :total="rows.length"
                class="table-pagination"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>

        <div v-else class="empty-data">Нет данных.</div>
    </section>
</template>

<script>
import { uniqueId } from 'lodash';

import icons from '@/helpers/icons';
import { newApi } from '@/utils/new-api';

export default {
    props: {
        rows: {
            type: Array,
            default: () => [],
        },
        subcontractorLinkType: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            cellStyleOption: {
                bodyCellClass: ({ row }) => (row.isOnDelete || row.isOnReplace ? 'highlight' : ''),
            },
            paginator: {
                pageNumber: 1,
                pageSize: 10,
            },
            icons,
        };
    },

    computed: {
        columns() {
            return [
                {
                    key: uniqueId('КССС'),
                    title: this.$t('КССС'),
                    field: 'code',
                    width: '100px',
                },
                {
                    key: uniqueId('name'),
                    title: this.$t('Полное наименование контрагента согласно уставу'),
                    field: 'name',
                    width: '320px',
                },

                {
                    key: uniqueId('inn'),
                    title: 'КОД СТРАНЫ | ИНН | TaxID',
                    field: 'inn',
                    width: '250px',
                    renderBodyCell: ({ row }) => (
                        <div>
                            {row.countryName && (
                                <div>
                                    <span
                                        v-tooltip={row.countryName}
                                        style={{ cursor: 'help' }}
                                    >
                                        {row.countryCode}
                                    </span>
                                    <span style={{ margin: '0 5px' }}
                                    >|</span>
                                    <span>{row.inn}</span>
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    key: uniqueId('kpp'),
                    title: this.$t('КПП'),
                    field: 'kpp',
                    width: '110px',
                },
                {
                    key: uniqueId('ogrn'),
                    title: this.$t('ОГРН/ОГРНИП'),
                    field: 'ogrn',
                    width: '150px',
                },
                {
                    key: uniqueId('loadedAllRequiredDocuments'),
                    title: 'Обязательные документы',
                    field: 'loadedAllRequiredDocuments',
                    renderBodyCell: ({ row }) => (
                        <div>
                            {row.loadedAllRequiredDocuments && (
                                <ui-icon
                                    name={icons.checkIcon}
                                    width="25"
                                    height="25"
                                />
                            )}
                        </div>
                    ),
                },
                {
                    key: uniqueId('pbiotComplete'),
                    title: 'Анкета ПБ, ОТ и ОС',
                    field: 'pbiotComplete',
                    width: '250px',
                    renderBodyCell: ({ row }) => (
                        <div>
                            {row.pbiotComplete && (
                                <ui-icon
                                    name={icons.checkIcon}
                                    width="25"
                                    height="25"
                                />
                            )}
                        </div>
                    ),
                },
                {
                    key: uniqueId('resident'),
                    title: 'Резидент РФ',
                    field: 'resident',
                    renderBodyCell: ({ row }) => (
                        <div>
                            {row.isResident && (
                                <ui-icon
                                    name={icons.checkIcon}
                                    width="25"
                                    height="25"
                                />
                            )}
                        </div>
                    ),
                },
                {
                    key: uniqueId('action'),
                    title: '',
                    field: 'action',
                    width: '200px',
                    renderBodyCell: ({ row }) => (
                        <div>
                            {row.showLinkButton && (
                                <ui-button
                                    class="subcontractor__action-button"
                                    onClick={() => this.copyLink(row.id)}
                                >
                                    { this.registerLabel }
                                </ui-button>
                            )}
                            {row.showReplaceButton && (
                                <ui-button
                                    class="subcontractor__action-button"
                                    onClick={() => this.replaceNonResident(row.id)}
                                >
                                    { this.changeRecordLabel }
                                </ui-button>
                            )}
                        </div>
                    ),
                },
                {
                    key: uniqueId('delete'),
                    title: '',
                    field: 'delete',
                    width: '50px',
                    selected: false,
                    renderBodyCell: ({ row }) => (
                        <div>
                            {!row.isOnDelete && (
                                <button
                                    disabled={!this.hasSaveData}
                                    class="del_subcontractor_btn"
                                    onClick={() => this.showDeleteDialog(row.id)}
                                ></button>
                            )}
                        </div>
                    ),
                },
            ];
        },

        tableData() {
            const start = (this.paginator.pageNumber - 1) * this.paginator.pageSize;

            const end = start + this.paginator.pageSize;

            return this.rows.slice(start, end);
        },

        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },

        changeRecordLabel() {
            return `Заменить запись по ${
                this.$props.subcontractorLinkType === 'SUBCONTRACTOR'
                    ? 'субподрядчику'
                    : 'производителю'
            }`;
        },

        registerLabel() {
            return `Ссылка для регистрации ${
                this.$props.subcontractorLinkType === 'SUBCONTRACTOR'
                    ? 'субподрядчика'
                    : 'производителя'
            }`;
        },
    },
    watch: {
        lang() {
            this.init();
        },
    },

    methods: {
        pageNumberChange(page) {
            this.paginator.pageNumber = page;
        },

        pageSizeChange(pageSize) {
            this.paginator.pageSize = pageSize;
        },

        showDeleteDialog(id) {
            this.$modal.show('confirm_delete', {
                subcontractorLinkType: this.$props.subcontractorLinkType,
                resultFunction: () => this.delSubcontractor(id),
            });
        },

        async copyLink(id) {
            try {
                const params = {
                    self: this,
                    id,
                };

                const link = await newApi('getRegistrationLink', params);

                this.$emit('open-registration-link-modal', { link, linkType: this.$props.subcontractorLinkType });
            } catch (error) {
                console.log(error);
            }
        },

        async delSubcontractor(id) {
            const paramsIn = {
                self: this,
                id,
                subcontractorLinkType: this.$props.subcontractorLinkType,
            };

            try {
                await newApi('deleteSubcontractors', paramsIn);
                this.$emit('reload');
            } catch (err) {
                console.error(err);
            }
        },

        replaceNonResident(rowId) {
            this.$modal.show('NonResidentsModal', {
                accountType: this.subcontractorLinkType,
                isReplaceMode: true,
                profileId: rowId,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/styles/common/_variables';

.highlight {
    background-color: #f5eedb !important;
}

.subcontractor {
    &__action-button {
        width: 250px;
        margin: 5px 0px;
    }
}
</style>
