<template>
    <div class="bottom-header">
        <div class="wrapper">
            <div class="container">
                <div class="bottom-header__wrapper">
                    <router-link to="/job-application">
                        <img
                            v-if="lang === 'en'"
                            src="~@/assets/img/logo-en.png"
                            alt="logo"
                            width="227"
                            height="58"
                        />

                        <img
                            v-else
                            src="~@/assets/img/logo.png"
                            alt="logo"
                            width="227"
                            height="58"
                        />
                    </router-link>

                    <ul class="flex justify-end align-center">
                        <li class="bottom-header__item mr-20">
                            <router-link to="/job-application">
                                {{ $t('Анкета') }}
                            </router-link>
                        </li>

                        <li v-if="showPbiot" class="bottom-header__item bottom-header__item--noUpper mr-20">
                            <router-link to="/pbiot">
                                {{ $t('pbiotMenu') }}
                            </router-link>
                        </li>

                        <li class="bottom-header__item mr-20">
                            <router-link :to="documentLink" :disabled="!hasSaveData">
                                {{ $t('Документы') }}
                            </router-link>
                        </li>

                        <li v-if="showLinkTender" class="bottom-header__item mr-20">
                            <router-link to="/tender" :disabled="!hasSaveData">
                                {{ $t('Тендеры') }}
                            </router-link>
                        </li>

                        <li class="bottom-header__item mr-20">
                            <router-link to="/relationships" :disabled="!hasSaveData">
                                Взаимоотношения
                            </router-link>
                        </li>

                        <li v-if="profile" class="bottom-header__item relative">
                            <router-link to="/communication">
                                <img src="~@/assets/img/chat.svg" />
                            </router-link>

                            <div class="bottom-header__counter">{{ countMessage }}</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-if="showTechnicalWorkWarning" class="bottom-header__warning-text">
                Обращаем внимание, в связи с проведением технических работ Личный Кабинет будет
                недоступен 24.07.2023 в период с 17:00 до 19:00
            </div>
        </div>
    </div>
</template>

<script>
import { appParams, getAppConfig } from '@/utils/appConfig';

export default {
    name: 'BottomHeader',

    data() {
        return {
            showLinkTender: true,
            documentLink: '/documents/',
            showPbiot: false,
            showTechnicalWorkWarning: false,
        };
    },

    computed: {
        showLinkDoc() {
            return this.$store.getters.accessToDoc;
        },

        profile() {
            return this.$store.state.user.profile;
        },

        lang() {
            return this.$store.state.common.lang;
        },

        hasSaveData() {
            return this.$store.getters.hasSaveData;
        },

        countMessage() {
            const { chatInfo } = this.$store.state.common;
            if (chatInfo) {
                let res = 0;
                chatInfo.forEach((element) => {
                    res += element.count;
                });
                return res;
            }
            return 0;
        },
    },

    created() {
        this.showPbiot = getAppConfig(appParams.SHOW_PBIOT, false);
        this.showLinkTender = getAppConfig(appParams.SHOW_PAGE_TENDER);
        this.showTechnicalWorkWarning = getAppConfig(appParams.SHOW_TECHNICAL_WORK_WARNING, false);
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.bottom-header {
    padding: 20px 0;
    background-color: $white;

    &__wrapper {
        display: grid;
        grid-template-columns: 300px 1fr;
        align-items: center;
        gap: 20px;
    }

    &__item > a {
        font-family: CuprumBold, sans-serif;
        font-size: 24px;
        text-transform: uppercase;
        color: $black;
    }

    &__item--noUpper > a {
        text-transform: none;
    }

    &__counter {
        position: absolute;
        top: 0;
        right: -14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $primary;
        color: $white;
    }

    &__warning-text {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        background-color: $gold;
        padding: 20px 0;
    }
}
</style>
