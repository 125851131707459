<template>
    <div class="grid-x grid-margin-x">
        <div class="large-12 cell">
            <p class="textInfo">
                Документы в формате xml принимаются системой автоматически. Документы в
                формате pdf проверяются сотрудником организации Группы "ЛУКОЙЛ" при
                возникновении необходимости оценки рисков при анализе благонадежности
                контрагентов.
            </p>
        </div>

        <div class="large-12 cell">
            <DocumentStatusFilter
                :is-new="isNew"
                :is-accept="isAccept"
                :is-reject="isReject"
                :is-progres="isProgres"
                :search="search"
                :set-new="setNew"
                :set-accept="setAccept"
                :set-reject="setReject"
                :set-progres="setProgres"
                :set-search="setSearch"
            />
            <transition-group name="list">
                <template
                    v-for="{
                        comment,
                        documents,
                        id,
                        status,
                        publicId,
                    } in localShemeDocFilter"
                >
                    <div :key="id + '_docs'" class="documents_group_wrap">
                        <div class="documents_group_header">
                            {{ $t("Задача") }} <span> #{{ publicId }}</span>
                            {{ $t("Статус") }} <span>{{ status ? status.name : "" }}</span>
                        </div>
                        <div v-if="comment" class="documents_group_comment">
                            {{ $t("Причина отказа:") }} <span> {{ comment }} </span>
                        </div>
                        <div class="documents_group_body">
                            <template
                                v-for="{
                                    typeDoc,
                                    ugid,
                                    compositeTypeDoc,
                                    hasFiles,
                                    attrDoc,
                                } in documents"
                            >
                                <DocumentUploadForm
                                    v-if="isSimple(typeDoc.type)"
                                    :key="ugid"
                                    :ugid="ugid"
                                    :accept-file="typeDoc.fileExtensions"
                                    :label="typeDoc.name"
                                    :size-kb="typeDoc.maxSize"
                                    :max-count="typeDoc.maxCount"
                                    :has-files="hasFiles"
                                    :has-delete="false"
                                    :add-file="() => {}"
                                    :attr-doc="attrDoc"
                                />
                                <DocumentComplexUploadForm
                                    v-if="isComposite(typeDoc.type)"
                                    :key="ugid"
                                    :ugid="ugid"
                                    :label="typeDoc.name"
                                    :composite-doc="compositeTypeDoc"
                                    :has-delete="false"
                                    :add-file="() => {}"
                                    :attr-doc="attrDoc"
                                />
                            </template>
                        </div>
                    </div>
                </template>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { TYPE_DOCS, STATUS_DOCUMENTS } from '@/constant';
import { generateUUID } from '@/helpers/generateUUID';
import clearReactive from '@/helpers/clearReactive';

import DocumentUploadForm from './DocumentUploadForm/DocumentUploadForm';
import DocumentComplexUploadForm from './DocumentUploadForm/DocumentComplexUploadForm';
import DocumentStatusFilter from './documentBlock/DocumentStatusFilter';

export default {
    name: 'DocumentBlockStatus',
    components: {
        DocumentUploadForm,
        DocumentComplexUploadForm,
        DocumentStatusFilter,
    },
    props: {
        isSubcontractor: { type: Boolean, default: false },
        idSubcontractor: { default: null },
        subcontractorIsEdit: { type: Boolean, default: false },
        onReload: { type: Function, requierd: true },
    },
    data() {
        return {
            search: '',
            isNew: true,
            isAccept: false,
            isReject: false,
            isProgres: false,
        };
    },
    watch: {
        async lang() {
            try {
                await this.onReload();
            } catch (err) {
                console.error(err);
            }
        },
    },
    computed: {
        ...mapState({
            documents: (state) => state.documents.documentStatus,
            lang: (state) => state.common.lang,
        }),
        hasSaveData() {
            if (this.isSubcontractor) {
                return this.subcontractorIsEdit;
            }
            return this.$store.getters.hasSaveData;
        },
        showLinkDoc() {
            return this.$store.getters.accessToDoc;
        },

        localShemeDoc() {
            let statusDocNow = clearReactive(this.documents);
            if (statusDocNow) {
                statusDocNow = statusDocNow.map((statusDocs) => ({
                    ...statusDocs,
                    documents: this.getlocalShemeDoc(statusDocs.documents),
                }));
                return statusDocNow;
            }
            return [];
        },

        localShemeDocFilter() {
            const {
                isNew, isAccept, isReject, isProgres, search,
            } = this;

            let result = this.localShemeDoc;

            if (result.length) {
                result = result.sort((a, b) => {
                    if (a.id < b.id) {
                        return -1;
                    }

                    if (a.id > b.id) {
                        return 1;
                    }

                    return 0;
                });

                if (isNew) {
                    result = result.reverse();
                }

                if (isAccept || isReject || isProgres) {
                    result = result.filter((item) => {
                        const code = item.status?.code;

                        if (isAccept && code === STATUS_DOCUMENTS.DVS_ACCEPTED) {
                            return true;
                        }

                        if (isReject && code === STATUS_DOCUMENTS.DVS_REJECTED) {
                            return true;
                        }

                        if (isProgres && code === STATUS_DOCUMENTS.DVS_IN_PROGRESS) {
                            return true;
                        }

                        return false;
                    });
                }

                if (search) {
                    result = result.filter((item) => this.searchByPublicId(item, search) || this.searchByName(item, search));
                }
            }

            return result;
        },
    },
    created() {
        if (this.showLinkDoc == false) {
            this.$router.push({ path: '/404' });
        }
    },
    methods: {
        setNew(val) {
            this.isNew = val;
        },
        setAccept(val) {
            this.isAccept = val;
        },
        setReject(val) {
            this.isReject = val;
        },
        setProgres(val) {
            this.isProgres = val;
        },
        setSearch(val) {
            this.search = val.target.value;
        },
        isSimple(type) {
            return type == TYPE_DOCS.SIMPLE;
        },
        isComposite(type) {
            return type == TYPE_DOCS.COMPOSITE;
        },

        getlocalShemeDoc(nowDoc) {
            const res = [];
            if (nowDoc) {
                nowDoc.forEach((doc) => {
                    const {
                        reportingQuarter, reportingYear, name, customName,
                    } = doc;
                    {
                        if (this.isSimple(doc.documentType.type)) {
                            const convert = {
                                typeDoc: doc.documentType,
                                ugid: generateUUID(),
                                compositeTypeDoc: null,
                                hasFiles: [doc.fileInfo],
                                attrDoc: {
                                    reportingQuarter,
                                    reportingYear,
                                    name,
                                    customName,
                                },
                            };
                            res.push(convert);
                        }
                        if (this.isComposite(doc.documentType.type)) {
                            const compositeTypeDoc = [];
                            doc.subDocuments.forEach((subdoc) => {
                                const {
                                    reportingQuarter: reportingQuarterSub,
                                    reportingYear: reportingYearSub,
                                    name: nameSub,
                                    customName: customNameSub,
                                } = subdoc;
                                const convert = {
                                    typeDoc: subdoc.documentType,
                                    ugid: generateUUID(),
                                    compositeTypeDoc: null,
                                    hasFiles: [subdoc.fileInfo],
                                    attrDoc: {
                                        reportingQuarter: reportingQuarterSub,
                                        reportingYear: reportingYearSub,
                                        name: nameSub,
                                        customName: customNameSub,
                                    },
                                };
                                compositeTypeDoc.push(convert);
                            });
                            const convert = {
                                typeDoc: doc.documentType,
                                ugid: generateUUID(),
                                compositeTypeDoc,
                                hasFiles: null,
                                attrDoc: {
                                    reportingQuarter,
                                    reportingYear,
                                    name,
                                    customName,
                                },
                            };
                            res.push(convert);
                        }
                    }
                });
            }
            return res;
        },

        searchByPublicId(document, search) {
            return document?.publicId?.includes(search);
        },

        searchByName(document, search) {
            return document.documents.some((item) => item.typeDoc.name.toUpperCase().includes(search.toUpperCase()));
        },
    },
};
</script>

<style lang='scss' scoped>

.documents_group_comment{
    padding-left: 20px;
    background: #f4f7f9;
    padding-right: 20px;
    color: #000;
    font: normal bold 14px/2 OpenSansBold,sans-serif;
    span{
        color: #da2b36;
    }
}

.documents_group_header{
    span{
        color: #da2b36;
    }
}
</style>
