<template>
    <svg v-bind="$attrs">
        <use :xlink:href="`${basePath}#${name}`" />
    </svg>
</template>

<script>
export default {
    name: 'UiIcon',
    props: {
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            basePath: '',
        };
    },

    async beforeMount() {
        const resource = await import('@/assets/ico/icons.svg');
        this.basePath = resource.default;
    },
};
</script>
