<template>
    <div :class="dopClass">
        <v-select
            v-if="drawEl"
            class="search_select dictionary_select"
            :class="{ singleMode: noCode }"
            :placeholder="placeholder"
            label="name"
            :filterable="false"
            :options="optionsFilter"
            :value="form[fieldName]"
            :disabled="isDisabled || forceDisabled || isOneOption"
            :searchable="hasSeach"
            @search="onSearch"
            @input="selectData($event)"
            @search:blur="unFocus"
        >
            <template #list-header>
                <li class="top_select">
                    {{ $t("Выберите вариант или продолжите ввод") }}
                </li>
            </template>
            <template #option="{ name, id, code }">
                <div
                    :key="id"
                    class="select_company_title"
                    :class="{ singleShow: noCode }"
                    :title="name"
                >
                    {{ name }}
                </div>
                <div v-if="!noCode" :key="'_' + code" class="select_company_info">
                    {{ code }}
                </div>
            </template>

            <template #selected-option="{ name }">
                <div>{{ name }}</div>
            </template>
            <template #no-options>
                {{ $t("Нет результатов поиска") }}
            </template>
        </v-select>
        <div v-if="hasError" class="has-error">
            <p>{{ errorText }}</p>
        </div>
    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';
import { DICTIONARY_LIST } from '@/constant';
import clearReactive from '@/helpers/clearReactive';

export default {
    name: 'SearchDictionary',

    props: {
        form: { type: Object, required: true },
        fieldName: { type: String, required: true },
        errorText: { type: String, default: '' },
        vError: { type: Object },
        dopClass: { type: String, default: '' },
        typeDictionary: { type: String, default: DICTIONARY_LIST.CURRENCY },
        placeholder: { type: String, default: 'Введите валюту' },
        hasParentCode: { type: Boolean, default: false },
        parentCode: { type: Object },
        noCode: { type: Boolean, default: false },
        hasSeach: { type: Boolean, default: true },
        filterLoadData: { type: Function },
        hasFilter: { type: Boolean, default: false },
        forceDisabled: { type: Boolean, default: false },
        filterOption: { type: Function },
        isOneOption: { type: Boolean, default: false },
        onChange: { type: Function, default: () => {} },
    },

    data() {
        return {
            options: [],
            drawEl: true,
            initialized: false,
        };
    },

    computed: {
        optionsFilter() {
            return this.filterOption ? this.filterOption(this.options) : this.options;
        },
        lang() {
            return this.$store.state.common.lang;
        },
        isDisabled() {
            if (this.hasParentCode) {
                if (this.parentCode && this.parentCode.code) {
                    return false;
                }
                return true;
            }
            return false;
        },
        hasError() {
            const { vError } = this;
            if (vError && vError.$dirty && vError.$error) {
                return true;
            }
            return false;
        },
        touch() {
            const { vError } = this;
            if (vError && vError.$dirty) {
                return true;
            }
            return false;
        },
        classesComp() {
            let res = this.classes;
            res += this.hasError ? ' error ' : this.touch ? ' success ' : '';
            return res;
        },
    },

    watch: {
        parentCode(val) {
            if (this.hasParentCode) {
                if (this.initialized) {
                    this.$set(this.form, this.fieldName, null);
                }
                this.search(() => {
                }, null, this);
            }
        },
        lang() {
            this.search(() => {}, null, this).then(() => {
                this.loadInit();

                this.drawEl = false;
                this.$nextTick(() => {
                    this.drawEl = true;
                });
            });
        },
    },

    created() {
        this.search(null, this).then(() => {
            this.loadInit();
        });
    },

    methods: {
        selectData(event) {
            this.$set(this.form, this.fieldName, event);
            this.onChange(event);
        },
        unFocus() {
            if (this.vError) {
                this.vError.$touch();
            }
            this.$emit('blur');
        },

        onSearch(search) {
            if (this.hasSeach == false) return false;
            if (search.length < 3) return false;

            this.search(search, this);
        },

        async search(search) {
            const paramsIn = {
                self: this,
                sendData: {
                    search: search ?? '',
                    dictionary: this.typeDictionary,
                    parentCode: this.parentCode?.code,
                },
            };

            try {
                const res = await newApi('getDictionary', paramsIn);

                this.options = this.hasFilter ? this.filterLoadData(res.content) : res.content;

                if (this.isOneOption) {
                    this.selectData(this.options[0]);
                }
            } catch (err) {
                console.error(err);
            }
        },

        loadInit() {
            const code = this.form[this.fieldName];
            if (code && code.code) {
                let preRes = clearReactive(this.options);
                preRes.push(clearReactive(code));
                preRes = preRes.filter((el, index) => {
                    const findIn = preRes.findIndex((elin) => el.code == elin.code);
                    return findIn == index;
                });
                this.options = preRes;
            }
            this.initialized = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.select_company_title {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleShow {
  padding: 12px;
  margin: 0px;
}

.search_select {
    min-height: 48px;
    height: inherit;
    .vs__dropdown-toggle {
        min-height: 48px;
        height: inherit;
    }
}

.search_select.vs--open {
    .vs__dropdown-toggle {
        overflow: hidden;
    }
    .vs__selected-options {
        overflow: hidden;
    }
    .vs__dropdown-toggle {
        overflow: hidden;
    }
}

.singleMode {
    .vs__dropdown-menu {
        .vs__dropdown-option {
            margin: 0px !important;
        }
    }
}

.top_select {
    line-height: 1 !important;
}

.dictionary_select {
    border-radius: 0px !important;
    .vs__dropdown-toggle {
        border-radius: 0px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: -webkit-box;
        display: flex;
        height: 48px;
        border: solid 1px #000;
        white-space: normal;
    }
    .vs__actions {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0 18px;
        position: relative;
    }
    .vs__selected-options {
        overflow: hidden;
    }
}
</style>
