<template>
    <div class="documents">
        <div class="grid-x grid-margin-x">
            <div class="large-12 cell mb-20">
                <SimpleDictionary
                    dop-class="selectorDocument createDocSelector"
                    :on-change="onSelectedDocSpec"
                    :is-separate-mode="true"
                    :options="documentOptions"
                    :error-text="$t('Необходимо выбрать документ')"
                    :placeholder="$t('Выберите документ')"
                    :force-disabled="hasDisabled"
                />
            </div>
        </div>

        <template v-if="!!selectedDocSpec">
            <!-- Components from DocumentSpecificationGroupDTO.UiHandlingEnum -->
            <component
                :is="component[selectedDocSpec.uiHandling]"
                :selected-doc-spec="selectedDocSpec"
                :disable="hasDisabled"
                @tax-regime-change="onTaxRegimeChange"
                @update:documentsUserSelectedToAdd="updateSelectedDocs"
            />
        </template>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import SimpleDictionary from '@/views/components/base/SimpleDictionary.vue';
import TaxDocumentUiHandler from '@/views/components/documents/documentUiHandlers/TaxDocumentUiHandler.vue';
import OwnerChainDocumentUiHandler from '@/views/components/documents/documentUiHandlers/OwnerChainDocumentUiHandler.vue';
import BalanceSheetDocumentUiHandler from '@/views/components/documents/documentUiHandlers/BalanceSheetDocumentUiHandler.vue';
import CalculateSvDocumentUiHandler from '@/views/components/documents/documentUiHandlers/CalculateSvDocumentUiHandler.vue';
import SimpleDocumentUiHandler from '@/views/components/documents/documentUiHandlers/SimpleDocumentUiHandler.vue';
import DirectorAppointmentOrderUiHandler from '@/views/components/documents/documentUiHandlers/DirectorAppointmentOrderUiHandler.vue';
import OtherDocumentUiHandler from '@/views/components/documents/documentUiHandlers/OtherDocumentUiHandler.vue';
import NoTaxArrearsCertificateDocumentUiHandler from '@/views/components/documents/documentUiHandlers/NoTaxArrearsCertificateDocumentUiHandler.vue';

const REQUIRED_GROUP = 'Required';
const ADDITIONAL_GROUP = 'Additional';

export default {
    name: 'DocumentsCreate',

    components: {
        SimpleDictionary,
        TaxDocumentUiHandler,
        OwnerChainDocumentUiHandler,
        BalanceSheetDocumentUiHandler,
        CalculateSvDocumentUiHandler,
        SimpleDocumentUiHandler,
        OtherDocumentUiHandler,
        NoTaxArrearsCertificateDocumentUiHandler,
        DirectorAppointmentOrderUiHandler,
    },

    data() {
        return {
            component: {
                TaxDocumentUiHandler,
                OwnerChainDocumentUiHandler,
                BalanceSheetDocumentUiHandler,
                CalculateSvDocumentUiHandler,
                SimpleDocumentUiHandler,
                OtherDocumentUiHandler,
                NoTaxArrearsCertificateDocumentUiHandler,
                DirectorAppointmentOrderUiHandler,
            },
            selectedDocSpec: null, // DocumentSpecificationGroupDTO + group property
            documentsUserSelectedToAdd: [], // Array of DocumentSpecificationDocDTO
        };
    },

    computed: {
        specification() {
            return this.$store.state.documents.specification;
        },

        hasDisabled() {
            return Boolean(this.documentsUserSelectedToAdd.length);
        },

        /**
         * Returns array of DocumentSpecificationGroupDTO + group property
         */
        documentOptions() {
            const res = [];
            const spec = this.specification;
            let isSeparateSecond = false;

            if (spec?.required) {
                res.push({ isSeparate: true, name: this.$t('REQUIRED_GROUP'), isSeparateSecond });

                spec.required.forEach((doc) => {
                    res.push({ ...doc, group: REQUIRED_GROUP });
                    isSeparateSecond = true;
                });
            }

            if (spec?.additional) {
                res.push({ isSeparate: true, name: this.$t('ADDITIONAL_GROUP'), isSeparateSecond });

                spec.additional.forEach((doc) => {
                    res.push({ ...doc, group: ADDITIONAL_GROUP });
                    isSeparateSecond = true;
                });
            }

            return res;
        },
    },

    async mounted() {
        await this.$store.dispatch('LOAD_DOCUMENT_SPEC', { self: this });
    },

    methods: {
        updateSelectedDocs(documents) {
            this.documentsUserSelectedToAdd = documents;
        },

        onSelectedDocSpec(item) {
            this.selectedDocSpec = item;

            this.$emit('update:selectedDocSpec', item);

            this.$emit('update:taxRegime', {});
        },

        onTaxRegimeChange($event) {
            this.$emit('update:taxRegime', $event);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/common/_variables";

.documents {
    &__info--header {
        position: relative;
        background-color: $grey-300;
        padding: 14px 14px 14px 55px;
        margin-bottom: 20px;
        font-family: "OpenSansBold", sans-serif;
        font-size: 14px;
        line-height: 2;
        color: #000000;
        &:before {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            display: block;
            width: 25px;
            height: 25px;
            background-size: cover;
        }
    }

    &__info--body {
        border: 1px solid $grey-300;
        margin: 0 -10px;
        font-size: 20px;
    }

    &__info--list {
        list-style: auto;
        text-transform: initial;
        line-height: 1.7;
        padding: 0 20px;
    }

    &__info--list_disc {
        list-style: disc;
    }

    &__info--with-icon {
        &:before {
            top: 15px;
            background-image: url(~@/assets/ico/ico-info.svg);
            background-size: cover;
        }
    }

    &__info--link {
        color: $link;
    }
}
</style>
