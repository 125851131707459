<template>
    <div class="warning-plate">
        <ui-icon
            :name="icons.roundError"
            class="warning-plate__icon"
        />
        <div class="warning-plate__message">
            <span class="icon-warning__message--margin">
                <slot />
            </span>
        </div>
    </div>
</template>

<script>
import icons from '@/helpers/icons';

export default {
    name: 'WarningPlate',

    data() {
        return {
            icons,
        };
    },
};
</script>

<style lang="scss" scoped>
.warning-plate {
    display: flex;
    align-items: center;
    margin: 10px;

    &__icon {
        color: #db2b37;
        padding-right: 15px;
        width: 90px;
        height: 90px;
    }

    &__message {
        display: flex;
        flex-direction: column;
        color: #d5a72d;

        &--margin {
            margin: 5px 0px 5px 0px;
        }
    }
}
</style>
