<template>
    <section v-if="status" id="progress_section" class="progress_section">
        <div class="container">
            <div class="grid-x">
                <div class="large-12 cell">
                    <div class="progress_main_container">
                        <div class="progress_wrap">
                            <div class="progress_container">
                                <div class="progress_item">
                                    <div class="ico_pen_gray" />
                                    <div
                                        class="progress_line w100"
                                        :class="`w${status.profileInfoP}`"
                                    />
                                    <div class="progress_number">100%</div>
                                </div>

                                <div class="progress_item">
                                    <div class="ico_doc_gray" />
                                    <div
                                        class="progress_line "
                                        :class="`w${status.profileDocumentP}`"
                                    />
                                    <div class="progress_number">
                                        {{ status.profileDocumentP }}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box">
                            <div class="box_title">
                                Заполнение анкеты
                            </div>
                            <div class="wrap_labels">
                                <div class="labels_container">
                                    <div class="label_item">
                                        Регистрационные данные
                                    </div>
                                    <div class="label_item">
                                        Документы
                                    </div>
                                </div>
                            </div>
                            <div class="wrap_box_progress" />
                            <div class="box_info">
                                Для того, чтобы ваш Индивидуальный Индекс Благонадежности
                                повысился и вы смогли принимать участие в тендерах, вам
                                необходимо заполнить все обязательные поля анкеты и прикрепить
                                обязательные документы в течение 21 рабочего дня с момента
                                регистрации в Личном кабинете контрагента. В противном случае
                                ваша учетная запись будет удалена.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        status() {
            const { profile } = this.$store.state.user;
            if (profile && profile.status) {
                return profile.status;
            }
            return false;
        },
    },

    mounted() {
        function scrollFunction(self) {
            const header = 'progress_section';
            const offset = 200;
            const shrink_class = 'shrink';
            const element = document.getElementById(header);
            if (
                document.body.scrollTop > offset
        || document.documentElement.scrollTop > offset
            ) {
                if (element) {
                    element.classList.add(shrink_class);
                }
            } else if (element) {
                element.classList.remove(shrink_class);
            }
        }
        window.onscroll = function () {
            scrollFunction(this);
        }.bind(this);
    },
    destroyd() {
        window.onscroll = null;
    },
};
</script>

<style lang="scss" scoped>
.progress_main_container {
  transform: all 1s;
}
.progress_wrap:hover + .box {
  display: block;
}
</style>
