<template>
    <div class="users-admin">
        <div class="users-admin__page-title">
            <div class="page-title__main">Пользователи</div>
            <div class="page-title__sub">Управление пользователями</div>
        </div>

        <div class="users-admin__header-wrapper">
            <div class="header-wrapper__search">
                <input
                    v-model="searchLine"
                    class="search__input"
                    type="search"
                    placeholder="Введите ИНН, наименование или КССС контрагента"
                    @keyup.enter="search"
                />
                <div class="search__button" @click="search">Найти</div>
            </div>

            <ui-button
                :disabled="!selectedUser"
                class="header-wrapper__edit-button"
                @click="openActionModal"
            >
                Редактировать
            </ui-button>
        </div>

        <div class="users-admin__users-table">

            <ve-table
                v-if="hasTableData"
                :columns="columns"
                :table-data="usersData"
                :row-style-option="rowStyleOption"
                :radio-option="radioOption"
                :cell-selection-option="cellSelectionOption"
                :event-custom-option="eventCustomOption"
                row-key-field-name="id"
            />

            <div v-else class="empty-data">Нет данных.</div>

            <ve-pagination
                v-if="hasTableData"
                class="users-table-pagination"
                :total="totalPages"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>

        <users-admin-modal
            @reset-isu-user="resetIsuUser"
            @delete-user="deleteUser"
            @set-kss="setKsssProfile"
            @update-status="updateStatus"
            @clean-moderation="cleanModeration"
            @open-pbiot-for-edit="openPbiotForEdit"
        >
            <template #title>
                <div class="user-edit-modal__title">
                    <div>Редактирование</div>
                    <div>{{ selectedUser?.name }}</div>
                </div>
            </template>
        </users-admin-modal>
    </div>
</template>

<script>
import { uniqueId } from 'lodash';

import { newApi } from '@/utils/new-api';
import UsersAdminModal from '@/views/modules/modals/users-admin-modal.vue';

const CONTRACTOR_TYPES = {
    INDIVIDUAL: 'ИП',
    LEGAL: 'ЮЛ',
    NONRESIDENT: 'Нерезидент',
};

export default {
    name: 'UserAdmin',

    components: {
        UsersAdminModal,
    },

    data() {
        return {
            totalPages: 1,
            pageSize: 10,
            pageNumber: 0,
            searchLine: '',
            selectedUser: null,
            usersData: [],
            columns: [
                {
                    key: uniqueId('radioColumn'),
                    type: 'radio',
                    width: 50,
                    align: 'center',
                },
                {
                    key: uniqueId('id'),
                    title: 'ИД',
                    renderBodyCell: ({ row }) => row.id ?? 'Н/Д',
                },
                {
                    key: uniqueId('login'),
                    title: 'Логин',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.login ?? 'Н/Д',
                },
                {
                    key: uniqueId('name'),
                    title: 'Название организации',
                    width: 700,
                    align: 'left',
                    renderBodyCell: ({ row }) => row.name ?? 'Н/Д',
                },
                {
                    key: uniqueId('type'),
                    title: 'Тип КА',
                    align: 'left',
                    renderBodyCell: ({ row }) => CONTRACTOR_TYPES[row.typeName] ?? 'Н/Д',
                },
                {
                    key: uniqueId('code'),
                    title: 'Код КССС',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.code ?? 'Н/Д',
                },
                {
                    key: uniqueId('regStatus'),
                    title: 'Статус Регистрации',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.registrationStateName ?? 'Н/Д',
                },
                {
                    key: uniqueId('info'),
                    title: 'ИНН/TaxID',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.taxId ?? 'Н/Д',
                },
                {
                    key: uniqueId('kpp'),
                    title: 'КПП',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.kpp ?? 'Н/Д',
                },
                {
                    key: uniqueId('date'),
                    title: 'ОГРН/огрнип',
                    align: 'left',
                    renderBodyCell: ({ row }) => row.ogrn ?? 'Н/Д',
                },
            ],
            rowStyleOption: {
                clickHighlight: false,
                hoverHighlight: false,
            },
            radioOption: {
                selectedRowKey: '',
                selectedRowChange: ({ row }) => {
                    this.changeSelectedUser(row);
                },
            },
            cellSelectionOption: {
                enable: false,
            },
            eventCustomOption: {
                bodyRowEvents: ({ row }) => ({
                    click: () => {
                        if (this.radioOption.selectedRowKey === row.id) {
                            this.resetSelectedUser();
                        } else {
                            this.changeSelectedUser(row);
                        }
                    },
                }),
            },
        };
    },

    computed: {
        hasTableData() {
            return this.usersData.length > 0;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        async loadData() {
            const paramsIn = {
                search: this.searchLine,
                offset: this.pageNumber ? this.pageNumber - 1 : 0,
                limit: this.pageSize,
            };

            try {
                const { content, count } = await newApi('getAllProfiles', paramsIn);

                this.totalPages = count * this.pageSize;

                this.usersData = content;
            } catch (err) {
                console.error(err);
            }
        },

        pageNumberChange(pageIndex) {
            this.pageNumber = pageIndex;

            this.loadData();
        },

        pageSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.loadData();
        },

        search() {
            this.pageNumber = 0;

            this.loadData();
        },

        openActionModal() {
            this.$modal.show('UsersAdminModal');
        },

        closeActionModal() {
            this.$modal.hide('UsersAdminModal');
            this.resetSelectedUser();
        },

        resetSelectedUser() {
            this.selectedUser = null;
            this.radioOption.selectedRowKey = '';
        },

        changeSelectedUser(row) {
            this.selectedUser = row;
            this.radioOption.selectedRowKey = row.id;
        },

        async resetIsuUser() {
            if (!window.confirm('Уверены?')) {
                return;
            }

            const paramsIn = {
                self: this,
                id: this.selectedUser.id,
            };

            this.closeActionModal();

            try {
                await newApi('resetIsuUser', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },

        async deleteUser() {
            if (!window.confirm('Уверены?')) {
                return;
            }

            const paramsIn = {
                self: this,
                id: this.selectedUser.id,
            };

            this.closeActionModal();

            try {
                await newApi('deleteProfile', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },

        async updateStatus() {
            if (!window.confirm('Уверены?')) {
                return;
            }

            const paramsIn = {
                self: this,
                id: this.selectedUser.id,
            };

            this.closeActionModal();

            try {
                await newApi('updateProfileStatus', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },

        async cleanModeration() {
            if (!window.confirm('Уверены?')) {
                return;
            }

            const paramsIn = {
                self: this,
                contractorCode: this.selectedUser.code,
            };

            this.closeActionModal();

            try {
                await newApi('cleanModeration', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },

        async setKsssProfile(newKsss) {
            if (!window.confirm('Продолжить?')) {
                return;
            }

            const paramsIn = {
                self: this,
                id: this.selectedUser.id,
                ksss: newKsss,
            };

            this.closeActionModal();

            try {
                await newApi('setProfileKsss', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },

        async openPbiotForEdit() {
            if (!window.confirm('Продолжить?')) {
                return;
            }

            const paramsIn = {
                self: this,
                contractorCode: this.selectedUser.code,
            };

            this.closeActionModal();

            try {
                await newApi('openPbiot', paramsIn);
                await this.loadData();
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.users-admin {
    padding-top: 200px;

    &__page-title {
        background: #f5f7f9;
        padding: 20px 30px;
        margin: 0 0 10px 0;
    }

    &__header-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__users-table {
        padding: 0px 30px;
    }
}

.header-wrapper {
    &__search {
        display: flex;
        padding: 20px 30px;
    }

    &__edit-button {
        margin: 20px 30px;
    }
}

.page-title {
    &__main {
        text-transform: uppercase;
        font-family: CuprumBold, sans-serif;
        font-size: 40px;
    }

    &__sub {
        font-family: CuprumBold, sans-serif;
        font-size: 20px;
    }
}

.search {
    &__input {
        height: 50px;
        width: 600px;
        padding-left: 20px;
    }

    &__button {
        padding: 10px 30px;
        background-color: #db2b37;
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
    }
}

.users-table {
    &__pagination {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 20px 0 !important;

        &.ve-pagination .ve-pagination-li:hover {
            border-color: #db2b37 !important;

            & a {
                color: red !important;
            }
        }

        & li,
        input {
            border-color: #989898 !important;

            &.ve-pagination-li-active {
                border-color: #db2b37 !important;
                color: red !important;
            }

            &.ve-pagination-li-active a {
                color: #db2b37 !important;
            }
        }

        & dt .ve-dropdown-dt-selected {
            border-color: #989898 !important;
        }
    }
}

.user-edit-modal {
    &__title {
        display: flex;
        flex-direction: column;
    }
}
</style>
