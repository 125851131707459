<template>
    <div v-show="loading" class="loader-wrapper">
        <div class="loader">
            <div class="loader__inner" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Loader',

    computed: {
        ...mapState('loader', ['loading']),
    },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
    background-color: rgba(36, 47, 63, 0.38823529411764707);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0px;
    left: 0px;
    text-align: center;
}

$colorFill: #db2b37;
.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid $colorFill;
    top: 50%;
    animation: loader 2s infinite ease;

    &__inner {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        background-color: $colorFill;
        animation: loader-inner 2s infinite ease-in;
    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}
</style>
