export const MAIN_HOST = process.env.VUE_APP_MAIN_HOST;

export const NAME_TOKEN = 'main-token';

export const TYPE_ERRORS = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    DEF_TYPE: '',
};
export const TYPE_DIALOG_TITLE = {
    INFO: 'Информация',
    DANGER: 'Ошибка',
    SUCCESS: 'Успешно!',
    WARNING: 'Внимание',
    DOC_SAVE: 'Документы сохранены.',
    WARNING_SMALL: 'ВАЖНО',
};

export const DICTIONARY_LIST = {
    CURRENCY: 'CURRENCY',
    DOCUMENT_TYPE: 'DOCUMENT_TYPE',
    COUNTRY: 'COUNTRY',
    REGION: 'REGION',
    COMPANY_TYPE: 'COMPANY_TYPE',
    TAX_TYPE: 'TAX_TYPE',
    ACCOUNTING_FORM_TYPE: 'ACCOUNTING_FORM_TYPE',
    FAQ_TOPIC: 'FAQ_TOPIC',
};

export const STATUS_EMAIL = {
    CONFIRMED: 'CONFIRMED',
};

export const TENDER_STATUS = {
    NOT_CONFIRMED: 'NOT_CONFIRMED',
    PENDING: 'PENDING',
    BLOCKED: 'BLOCKED',
    OPEN: 'OPEN',
    TEMPORARY: 'TEMPORARY',
};

export const COLOR_TENDER_STATUS = {
    PENDING: 'denied',
    BLOCKED: 'denied',
    OPEN: 'red',
    TEMPORARY: 'pale',
};

export const MSG_TYPE_CLASS = {
    FROM_ME: 'from_me',
    DEFAULT: '',
    WARNING: 'warning',
};
export const MSG_TYPE = {
    FROM_ME: 'FROM_ME',
    DEFAULT: 'DEFAULT',
    WARNING: 'WARNING',
};

export const ACCOUNT_STATUS = {
    /**
   * @deprecated
   */
    CREATED: 'CREATED',
    /**
   * @deprecated
   */
    CONFIRMED: 'CONFIRMED',

    REGISTERED: 'REGISTERED',
    NEW: 'NEW',
    REGISTERING: 'REGISTERING',
};

export const ACCOUNT_TYPE = {
    ADMIN: 'ADMIN',
    LEGAL: 'LEGAL',
    INDIVIDUAL: 'INDIVIDUAL',
    NONRESIDENT: 'NONRESIDENT',
};

export const SUBCONTRACTOR_LINK_TYPE = {
    SUBCONTRACTOR: 'SUBCONTRACTOR',
    PRODUCER: 'PRODUCER',
};

export const SMALLBUSINESS = 'SmallBusiness';
export const NPO = 'NPO';

export const TYPE_DOCS = {
    GROUP: 'GROUP',
    SIMPLE: 'SIMPLE',
    COMPOSITE: 'COMPOSITE',
};

export const TAX_TYPE = {
    TYPE3NDFL: '3NDFL',
    Common: 'Common',
};

export const ROLE_ADMIN = 'ROLE_ADMIN';

export const STATUS_DOCUMENTS = {
    DVS_IN_PROGRESS: 'DVS_IN_PROGRESS', // name: "На проверке"}
    DVS_ACCEPTED: 'DVS_ACCEPTED', // name: "Принято"}
    DVS_REJECTED: 'DVS_REJECTED', // name: "Отклонено"}
};

export const I_READ_MSG_NEW_CONTACT = 'I_READ_MSG_NEW_CONTACT00';

export const STATUS_PBIOT = Object.freeze({
    OFF: 0, // Отображение раздела выкл
    CONTRACTOR: 1, // Раздел был вкл контрагентом
    CONTRACTOR_ACCEPTED: 2, // Раздел был вкл контрагентом, и подтвержден из СМБК
    SNABGENIE: 3, // Раздел был вкл запросом из ИСУ
    SMBK: 4, // Раздел был вкл куратором из СМБК
});

export const UNIVERSAL_FORMAT_DATE = 'DD.MM.YYYY';
export const DEF_FORMAT_DATA_TIME = 'DD.MM.YYYY HH:mm';

export const I_READ_INFO_TIME_WORK = 'I_READ_INFO_TIME_WORK';

export const statusDoc = {
    DEFAULT: 'DEFAULT', // - поле разблокировано
    ON_VERIFICATION: 'ON_VERIFICATION', // - поле заблокировано, показываем уведомление
    CONFIRMED: 'CONFIRMED', // - поле заблокировано
    NOT_CONFIRMED: 'NOT_CONFIRMED', // - поле разблокировано
};

export const DocumentTypeGroupEnum = {
    TaxReports: 'TaxReports',
    NoTaxArrearsCertificate: 'NoTaxArrearsCertificate',
};

export const DocumentTypeEnum = {
    Vat: 'Vat',
    VatQuarter_IP: 'VatQuarter_IP',
    VatExemption: 'VatExemption',
    Envd: 'Envd',
    EnvdPDF: 'EnvdPDF',
    Eshn: 'Eshn',
    EshnPDF: 'EshnPDF',
    IncomeTaxReturnQuarter: 'IncomeTaxReturnQuarter',
    IncomeTaxReturnQuarterPDF: 'IncomeTaxReturnQuarterPDF',
    IncomeTaxForeignOrgPDF: 'IncomeTaxForeignOrgPDF',
    TaxExemptionPDF: 'TaxExemptionPDF',
    TaxTypeConfirmationESHN: 'TaxTypeConfirmationESHN',
    TaxTypeConfirmationUSN: 'TaxTypeConfirmationUSN',
    Usn: 'Usn',
    UsnPDF: 'UsnPDF',
    Patent: 'Patent',

    BalanceSheetQuarter: 'BalanceSheetQuarter',
    SimpleBalanceSheetQuarter: 'SimpleBalanceSheetQuarter',

    Charter: 'Charter',
    DirectorAppointmentOrder: 'DirectorAppointmentOrder',

    AutoUsnPDF: 'AutoUsnPDF',
    AutoUsnStatePdf: 'AutoUsnStatePdf',
};
