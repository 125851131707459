<template>
    <div class="communication_form_body">
        <div class="messages_wrap">
            <div class="messages_item">
                <div class="message_item_img">
                    <img src="@/assets/img/mini_logo.svg" alt="" />
                </div>
                <div class="message_item_body">
                    <strong>Добро пожаловать в раздел Коммуникации!</strong>
                </div>
            </div>

            <transition-group name="list">
                <MsgItem
                    v-for="(msg, index) in messages"
                    :key="index + 'msg'"
                    :msg="msg"
                    class="list-item"
                />
            </transition-group>

            <div v-if="wr" class="messages_item">
                <div class="message_item_body">
                    <div class="bubblingG">
                        <span id="bubblingG_1" />
                        <span id="bubblingG_2" />
                        <span id="bubblingG_3" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MsgItem from './MsgItem';

export default {
    components: {
        MsgItem,
    },
    props: {
        messages: Array,
    },
    data() {
        return {
            wr: false,
            timer: null,
        };
    },

    watch: {
    // messages: {
    //   immediate: true,
    //   deep: false,
    //   handler(newValue, oldValue) {
    //     if (this.timer) {
    //       clearTimeout(this.timer);
    //     }
    //     this.wr = false;
    //   }
    // }
    },
    methods: {
        writing() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.wr = true;
            this.timer = setTimeout(() => (this.wr = false), 2000);
        },
    },
};
</script>

<style lang="scss" scoped>
.communication_form_body {
    padding: 16px 16px 24px 16px
}

@media print, screen and (min-width: 64em) {
    .communication_form_body {
        padding: 32px 48px 78px 48px
    }
}

.communication_form_header__not {
    position: absolute;
    right: 12px;
    top: 20px;
    width: 60px;
    span{
        font: 14px/28px OpenSansBold,sans-serif;
        background: #db2b37;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        position: absolute;
        right: 0;
        top: -2px;
        border: 2px solid #fff;
    }
    &:before {
        background: url(~@/assets/img/chat.svg) 0 0/cover;
        content: "";
        display: block;
        width: 36px;
        height: 34px;
    }
}

.communication_form_body {
    overflow-y: scroll;
    max-height: 500px;
}
</style>
