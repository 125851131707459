<template>
    <div
        :id="id"
        class="accordion faq_accordion"
        data-accordion
        data-allow-all-closed="true"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AccordionBase',

    props: {
        id: { type: String, default: 'accordion1' },
    },

    mounted() {
        const accordion1 = new Foundation.Accordion($(`#${this.id}`));
    },
};
</script>
