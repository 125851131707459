<template>
    <div
        id="accordion7"
        class="accordion"
        data-accordion
        data-allow-all-closed="true"
    >
        <div class="accordion-item" data-accordion-item>
            <a href="#" class="accordion-title" data-cy="email">
                <span class="ico_contact" />
                {{ title }}
                <span class="ico_accordion" />
            </a>

            <div class="accordion-content" data-tab-content>
                <BaseInput
                    name="phones"
                    main-class="inline_form_input"
                    label="Телефон контрагента для связи"
                    :value="phone"
                    :disabled="blockInput"
                    mask-check="+7##########"
                    :v-error="getError('phones', 0)"
                    error-text="Введите корректный телефон"
                    @input="updateField('phones', 0, $event)"
                />

                <BaseInput
                    v-tooltip="emailTooltip"
                    name="email"
                    main-class="inline_form_input"
                    label="E-mail контрагента для связи"
                    :value="email"
                    :v-error="getError('emails', 0)"
                    error-text="Введите корректный Еmail"
                    disabled
                    @input="updateField('emails', 0, $event)"
                />

                <div
                    v-for="(phoneForTender, index) in phonesForTender"
                    :key="'phoneForTender-' + index"
                    class="contact-details__wrapper"
                >
                    <BaseInput
                        name="phoneForTender"
                        main-class="inline_form_input"
                        label="Телефон контрагента по вопросам тендера"
                        :has-index="index > 0"
                        :dop-index="index"
                        :value="phoneForTender"
                        :disabled="blockInput"
                        mask-check="+7##########"
                        :v-error="getError('phonesForTender', index)"
                        error-text="Введите корректный телефон"
                        @input="updateField('phonesForTender', index, $event)"
                    />
                </div>

                <div
                    v-for="(emailForTender, index) in emailsForTender"
                    :key="'emailForTender-' + index"
                    class="contact-details__wrapper"
                >
                    <BaseInput
                        name="emailForTender"
                        main-class="inline_form_input"
                        label="E-mail контрагента по вопросам тендера"
                        :has-index="index > 0"
                        :dop-index="index"
                        :value="emailForTender"
                        :disabled="blockInput"
                        :v-error="getError('emailsForTender', index)"
                        error-text="Введите корректный Еmail"
                        @input="updateField('emailsForTender', index, $event)"
                    />
                </div>

                <div
                    v-for="(website, index) in websites"
                    :key="'website-' + index"
                    class="contact-details__wrapper"
                >
                    <BaseInput
                        name="website"
                        main-class="inline_form_input"
                        label="Веб сайт"
                        :has-index="index > 0"
                        :dop-index="index"
                        :value="website"
                        :disabled="blockInput"
                        :v-error="getError('websites', index)"
                        error-text="Введите корректный website например https://site.ru"
                        @input="updateField('websites', index, $event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactDetails',

    props: {
        v: {},

        allData: {
            type: Object,
            required: true,
        },

        blockInput: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        phone() {
            return this.allData.phones.length ? this.allData.phones[0] : '';
        },

        email() {
            return this.allData.emails.length ? this.allData.emails[0] : '';
        },

        phonesForTender() {
            return this.allData.phonesForTender.length ? this.allData.phonesForTender : [''];
        },

        emailsForTender() {
            return this.allData.emailsForTender.length ? this.allData.emailsForTender : [''];
        },

        websites() {
            return this.allData.websites.length ? this.allData.websites : [''];
        },

        title() {
            const { accountType } = this.$store.getters;

            switch (accountType) {
            case 'LEGAL': {
                return this.$t('Контакты юридического лица');
            }
            case 'INDIVIDUAL': {
                return this.$t('Контакты Индивидуального предпринимателя');
            }
            default: {
                return '';
            }
            }
        },

        emailTooltip() {
            return 'Данное поле доступно к редактированию в разделе "Профиль" - "Данные"';
        },
    },

    mounted() {
        const accordion7 = new Foundation.Accordion($('#accordion7'));
    },

    methods: {
        updateField(field, index, val) {
            this.$set(this.allData[field], index, val);
        },

        getError(field, index) {
            return this.v.allData[field]?.$each?.$iter?.[index];
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-details {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        & .inline_form_input {
            width: calc(100% - 0px);
        }
    }
}
</style>
