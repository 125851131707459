<template>
    <div class="messages_item" :class="classMsg">
        <div class="message_item_img">
            {{ msg.author == me ? "Я" : "" }}
            <img v-if="msg.author !== me" src="@/assets/img/mini_logo.svg" alt="" />
        </div>
        <div class="message_item_body">
            <p>
                {{ msg.payload }}
            </p>
        </div>
        <div class="message_item_date">
            {{ msg.createdAt | moment("DD.MM.YYYY HH:mm") }}
        </div>
    </div>
</template>

<script>
import { MSG_TYPE_CLASS, MSG_TYPE } from '@/constant';

export default {
    props: {
        msg: { required: true },
    },

    data() {
        return {
            me: null,
        };
    },

    computed: {
        classMsg() {
            const typemsg = this.msg.author == this.me ? MSG_TYPE.FROM_ME : MSG_TYPE.DEFAULT;
            switch (typemsg) {
            case MSG_TYPE.FROM_ME: {
                return MSG_TYPE_CLASS.FROM_ME;
            }
            case MSG_TYPE.DEFAULT: {
                return MSG_TYPE_CLASS.DEFAULT;
            }
            case MSG_TYPE.WARNING: {
                return MSG_TYPE_CLASS.WARNING;
            }
            default: {
                return '';
            }
            }
        },
    },

    created() {
        this.me = this.$store.state.user.profile.companyCode;
    },
};
</script>
