<template>
    <div class="grid-x grid-margin-x">
        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">
                Выберите дату, на которую в справке подтверждено наличие / отсутствие задолженности.
            </label>
        </div>

        <div class="large-8 small-8 cell mb-12">
            <date-picker
                v-model="reportingDate"
                :disabled-date="notAfterToday"
                class="fullYear"
                format="DD.MM.YYYY"
                lang="ru"
                value-type="format"
                :disabled="disable"
            />
        </div>

        <template v-if="documentsApplicableToAddFiltered">
            <div class="cell mb-12">
                <DocumentCreateV2Buttons
                    :documents-in-specification="documentsApplicableToAddFiltered"
                    :get-confirmation-dialog-props="getConfirmationDialogProps"
                    @update:documentsUserSelectedToAdd="
                        $emit('update:documentsUserSelectedToAdd', $event)
                    "
                >
                    <template #uploadRule>
                        <warning-plate>
                            <div
                                v-for="( { text }, key ) in additionalNotifications"
                                :key="key"
                                v-html="noTaxArrearsNotifications[text]"
                            />
                        </warning-plate>
                    </template>
                    <template #fileDescription>
                        <div class="msgPdfInfo" v-html="$t('infoDocMess')" />
                    </template>
                </DocumentCreateV2Buttons>
            </div>
        </template>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters } from 'vuex';

import { noTaxArrearsNotifications } from '@/notificationTemplates/documentNotifications';
import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import DocumentUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentUploadForm.vue';
import { replaceFullYear } from '@/helpers/createDocumentHelper';
import WarningPlate from '@/views/components/shared/WarningPlate.vue';

export default {
    name: 'NoTaxArrearsCertificateDocumentUiHandler',

    components: {
        WarningPlate,
        DocumentUploadForm,
        DocumentCreateV2Buttons,
        DatePicker,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            reportingDate: null,
            noTaxArrearsNotifications,
        };
    },

    computed: {
        ...mapGetters(['existingDocumentInfoList']),

        reportingDateIso() {
            return this.reportingDate
                ? this.$moment(this.reportingDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
                : null;
        },

        additionalNotifications() {
            return this.selectedDocSpec?.bottomNotificationBar.content ?? [];
        },

        /**
         * @returns DocumentSpecificationDocDTO with resolved names and additional document attributes (like year, period, etc)
         */
        documentsApplicableToAddFiltered() {
            if (!this.reportingDate) {
                return [];
            }

            return this.selectedDocSpec.documents.map((document) => {
                const clonedDocument = _.cloneDeep(document);
                clonedDocument.name = this.resolvePlaceholders(clonedDocument.name);

                clonedDocument.files = clonedDocument.files.map((file) => ({
                    ...file,
                    name: this.resolvePlaceholders(file.name),
                }));

                clonedDocument.documentAttributes = {
                    reportingDate: this.reportingDateIso,
                };

                return clonedDocument;
            });
        },

    },
    methods: {
        resolvePlaceholders(val) {
            return replaceFullYear(val, this.reportingDate);
        },

        notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },

        getConfirmationDialogProps(docSpecification) {
            const existingDocs = this.existingDocumentInfoList(docSpecification.code);
            if (_.isEmpty(existingDocs)) {
                return null;
            }
            const selectedReportingDateDate = this.$moment(this.reportingDate, 'DD.MM.YYYY');
            if (
                existingDocs.some((uploadedDoc) => this.$moment(uploadedDoc.reportingDate).isSame(selectedReportingDateDate, 'day'))
            ) {
                return {
                    nameDoc: docSpecification.name,
                    msg: [
                        'У вас уже загружен данный документ. Убедитесь, что собираетесь загрузить корректную версию.',
                    ],
                    confirmBtnText: 'Загрузить',
                    title: 'Внимание!',
                };
            }
            return null;
        },
    },
};
</script>
