var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x grid-margin-x"},[_vm._m(0),_c('div',{staticClass:"large-12 cell"},[_c('DocumentStatusFilter',{attrs:{"is-new":_vm.isNew,"is-accept":_vm.isAccept,"is-reject":_vm.isReject,"is-progres":_vm.isProgres,"search":_vm.search,"set-new":_vm.setNew,"set-accept":_vm.setAccept,"set-reject":_vm.setReject,"set-progres":_vm.setProgres,"set-search":_vm.setSearch}}),_c('transition-group',{attrs:{"name":"list"}},[_vm._l((_vm.localShemeDocFilter),function({
                    comment,
                    documents,
                    id,
                    status,
                    publicId,
                }){return [_c('div',{key:id + '_docs',staticClass:"documents_group_wrap"},[_c('div',{staticClass:"documents_group_header"},[_vm._v(" "+_vm._s(_vm.$t("Задача"))+" "),_c('span',[_vm._v(" #"+_vm._s(publicId))]),_vm._v(" "+_vm._s(_vm.$t("Статус"))+" "),_c('span',[_vm._v(_vm._s(status ? status.name : ""))])]),(comment)?_c('div',{staticClass:"documents_group_comment"},[_vm._v(" "+_vm._s(_vm.$t("Причина отказа:"))+" "),_c('span',[_vm._v(" "+_vm._s(comment)+" ")])]):_vm._e(),_c('div',{staticClass:"documents_group_body"},[_vm._l((documents),function({
                                typeDoc,
                                ugid,
                                compositeTypeDoc,
                                hasFiles,
                                attrDoc,
                            }){return [(_vm.isSimple(typeDoc.type))?_c('DocumentUploadForm',{key:ugid,attrs:{"ugid":ugid,"accept-file":typeDoc.fileExtensions,"label":typeDoc.name,"size-kb":typeDoc.maxSize,"max-count":typeDoc.maxCount,"has-files":hasFiles,"has-delete":false,"add-file":() => {},"attr-doc":attrDoc}}):_vm._e(),(_vm.isComposite(typeDoc.type))?_c('DocumentComplexUploadForm',{key:ugid,attrs:{"ugid":ugid,"label":typeDoc.name,"composite-doc":compositeTypeDoc,"has-delete":false,"add-file":() => {},"attr-doc":attrDoc}}):_vm._e()]})],2)])]})],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-12 cell"},[_c('p',{staticClass:"textInfo"},[_vm._v(" Документы в формате xml принимаются системой автоматически. Документы в формате pdf проверяются сотрудником организации Группы \"ЛУКОЙЛ\" при возникновении необходимости оценки рисков при анализе благонадежности контрагентов. ")])])
}]

export { render, staticRenderFns }