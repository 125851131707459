import { PBIOT_CODE, SIMPLE_Q, YEAR_Q } from '@/utils/questScheme';
import { getSaveSelectDoc } from '@/helpers/pbiotHelper';
import { getSchemeAndFile } from '@/helpers/converterFilePBIOTtoDocFile';
import { checkIncidentHelper } from '@/helpers/checkIncidentHelper';

import { mutations } from './mutations';
import { actions } from './actions';

const getListDocCheck = () => {
    const data = [
        ...PBIOT_CODE.QUESTIONNAIRE,
        ...PBIOT_CODE.PBIOT,
        ...PBIOT_CODE.DOC_EP,
    ];
    return data.map((i) => ({
        code: i.code,
        value: true,
    }));
};
const readDocCheckFromCash = getSaveSelectDoc([]);
const state = {
    statusInfo: null,
    status: 0,
    allData: null,
    documentChecks: readDocCheckFromCash.length > 0 ? readDocCheckFromCash : getListDocCheck(),
    history: [],
};
const getDefQ = (name, code, type, value = '', children = null, tabindex, placeholder, hint) => ({
    name,
    code,
    type,
    value,
    children,
    tabindex,
    placeholder,
    hint,
});

const getters = {
    getFormPbiot: (state) => {
        const docs = state.allData ? state.allData.docs : [];
        const questionnaire = getSchemeAndFile(PBIOT_CODE.QUESTIONNAIRE, docs);
        const doc_pbiot = getSchemeAndFile(PBIOT_CODE.PBIOT, docs);
        const doc_ep = getSchemeAndFile(PBIOT_CODE.DOC_EP, docs);
        return {
            questionnaire,
            doc_pbiot,
            doc_ep,
        };
    },
    getIncidents: (state) => {
        const docs = state.allData ? state.allData.docs : [];
        let allIncidents = [];
        if (docs) {
            const findDoc = docs.find((doc) => {
                const find = doc.attributes.find((attr) => {
                    if (attr.name == 'type' && attr.value == 'incident') {
                        return true;
                    }
                });
                return !!find;
            });

            allIncidents = findDoc ? findDoc.attributes : [];
            allIncidents = allIncidents.filter((item) => item.name !== 'type');
        }
        const incidents = [];
        let tabindex = 1;
        PBIOT_CODE.INCIDENTS.forEach((quest) => {
            const { name, code, type } = quest;

            if (type == SIMPLE_Q) {
                let value = '';
                const { placeholder } = quest;
                const { hint } = quest;
                if (allIncidents.length > 0) {
                    const findVal = allIncidents.find((item) => item.name == quest.code);
                    value = findVal ? findVal.value : value;
                }

                incidents.push(getDefQ(name, code, type, value, null, tabindex, placeholder, hint));
                tabindex += 1;
            }
            if (type == YEAR_Q && quest.children) {
                const childrenQ = [];
                quest.children.forEach((_childQ) => {
                    const { name, code, type } = _childQ;

                    let value = '';
                    if (allIncidents.length > 0) {
                        const findVal = allIncidents.find(
                            (item) => item.name == _childQ.code,
                        );
                        value = findVal ? findVal.value : value;
                    }

                    childrenQ.push(getDefQ(name, code, type, value, null, tabindex));
                    tabindex += 1;
                });

                incidents.push(getDefQ(name, code, type, null, childrenQ, tabindex));
                tabindex += 1;
            }
        });
        return incidents;
    },
    checkIncident: (state, getters) => {
        const nowIncident = getters.getIncidents;
        const res = checkIncidentHelper(nowIncident);
        return res;
    },
};
export default {
    state,
    actions,
    mutations,
    getters,
};
