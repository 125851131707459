<template>
    <main-template-doc-pbiot
        :title="$t('Документы')"
        name-field="questionnaire"
        :show-link-anketa="true"
    />
</template>

<script>
import MainTemplateDocPbiot from '@/views/components/pbiot/MainTemplateDocPbiot.vue';

export default {
    name: 'Questionnaire',
    components: { MainTemplateDocPbiot },
};
</script>
