<template>
    <div class="tabs-content" :data-tabs-content="idTabs">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TabsBody',
    props: {
        idTabs: { type: String, default: 'tabs' },
    },
    mounted() {
        try {
            setTimeout(() => {
                const tabs = new Foundation.Tabs($(`#${this.idTabs}`));
            }, 500);
        } catch (e) {
            console.error(e);
        }
    },
};
</script>
