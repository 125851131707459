import { newApi } from '@/utils/new-api';

export default {
    state() {
        return {
            documents: [],
            documentStatus: [],
            specification: {}, // See DocumentSpecificationDTO in the backend
        };
    },

    actions: {
        // ------- Новая реализация документов -------------------------
        /**
         * получение статус документов
         */
        async GET_VALIDATE_DOCUMENTS({ commit }, params) {
            try {
                const result = await newApi('getValidDocument', params);
                commit('SET_VALIDATE_DOCUMENTS', result);
            } catch (e) {
                console.error(e);
                return false;
            }
        },

        async LOAD_DOCUMENT_SPEC({ commit }, params) {
            try {
                const result = await newApi('getDocumentSpec', params);
                commit('SET_SPECIFICATION', result);
            } catch (error) {
                console.log(error);
            }
        },
    },

    mutations: {
        CLEAR_DOCUMENTS(state) {
            state.documents = [];
        },

        SET_VALIDATE_DOCUMENTS(state, data) {
            state.documentStatus = data;
        },

        SET_SPECIFICATION(state, value) {
            state.specification = value;
        },
    },

    getters: {
        /**
         * Use this getter instead of this.selectedDocSpec to get the freshest existingDocumentInfoList
         * as this.selectedDocSpec might not be updated yet
         *
         * @return array of ExistingDocumentInfoDTO
         */
        existingDocumentInfoList: (state) => (docTypeGroupCode) => {
            const combinedList = [
                ...state.specification.required,
                ...state.specification.additional,
            ];

            const matchingItem = combinedList.find(
                ({ groupCode }) => groupCode === docTypeGroupCode,
            );

            return matchingItem?.existingDocumentInfoList ?? null;
        },
    },
};
