<template>
    <div v-if="documents" class="grid-x grid-margin-x">

        <documents-history-link />

        <div class="large-12 cell">
            <div v-if="documents.length">
                <ve-table
                    :columns="columns"
                    :table-data="tableData"
                    :expand-option="expandOption"
                    :cell-selection-option="cellSelectionOption"
                    :max-height="800"
                    fixed-header
                    border-x
                    row-key-field-name="rowKey"
                />

                <ve-pagination
                    :page-index="paginator.pageNumber"
                    :page-size="paginator.pageSize"
                    :total="documents.length"
                    class="table-pagination"
                    @on-page-number-change="pageNumberChange"
                    @on-page-size-change="pageSizeChange"
                />
            </div>

            <div v-else class="empty-data">Нет данных.</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { newApi } from '@/utils/new-api';
import { TYPE_DOCS } from '@/constant';
import DocumentsHistoryLink from '@/views/documents/documents-history-link';
import DocumentsHistorySubcontractors from '@/views/documents/documents-history-subcontractors.vue';

export default {
    name: 'DocumentsHistory',

    components: {
        DocumentsHistoryLink,
        DocumentsHistorySubcontractors,
    },

    data() {
        return {
            paginator: {
                pageNumber: 1,
                pageSize: 10,
            },
            columns: [
                {
                    key: 'j',
                    field: '',
                    type: 'expand',
                    title: '',
                },
                {
                    key: 'a',
                    title: 'Наименование',
                    field: 'name',
                    width: '20%',
                },
                {
                    key: 'b',
                    title: 'Актуальность',
                    field: 'relevanceStatus',
                },
                {
                    key: 'c',
                    title: 'Дата загрузки',
                    field: 'uploadDate',
                },
                {
                    key: 'd',
                    title: 'Статус',
                    field: 'status',
                },
                {
                    key: 'e',
                    title: 'Дата принятия/отклонения',
                    field: 'lastModifiedDate',
                },
                {
                    key: 'f',
                    title: 'Комментарий',
                    align: 'center',
                    renderBodyCell: ({ row }, h) => {
                        if (row.comment) {
                            return row.comment;
                        }

                        if (row.decisionReasonComments) {
                            if (row.decisionReasonComments.length === 1) {
                                return row.decisionReasonComments[0];
                            }

                            if (row.decisionReasonComments.length > 1) {
                                return this.convertToHtmlList(row.decisionReasonComments, h);
                            }
                        }

                        return 'Н/Д';
                    },
                },
                {
                    key: 'g',
                    title: 'Действие',
                    field: 'action',
                    renderBodyCell: ({ row }) => {
                        if (row.isSimple) {
                            return (
                                <ui-button
                                    type="table"
                                    on-click={() => {
                                        this.downloadFile(row.fileId, row.fileName);
                                    }}
                                >
                                    Скачать
                                </ui-button>
                            );
                        }
                    },
                },
            ],

            expandOption: {
                expandable: ({ row }) => !row.isSimple,
                render: ({ row }) => (
                    <documents-history-subcontractors
                        columns={this.columns.slice(1)} // Первый столбец служебный для функции раскрытия
                        documents={row.children}
                    ></documents-history-subcontractors>
                ),
            },

            cellSelectionOption: {
                enable: false,
            },

            showEmpty: false,
        };
    },

    async created() {
        if (this.showLinkDoc == false) {
            await this.$router.push({ path: '/404' });
        }
    },

    computed: {
        ...mapState({
            lang: (state) => state.common.lang,
            documentStatus: (state) => state.documents.documentStatus,
        }),

        tableData() {
            const start = (this.paginator.pageNumber - 1) * this.paginator.pageSize;

            const end = start + this.paginator.pageSize;

            return this.documents.slice(start, end);
        },

        documents() {
            return this.convertDocumentStatus(this.documentStatus);
        },
    },

    methods: {
        convertToHtmlList(decisionReasonComments, h) {
            return h(
                'ul',
                {
                    class: {
                        'decision-reason-comments_list': true,
                    },
                },
                decisionReasonComments.map((comment) => h('li', {}, comment)),
            );
        },

        pageNumberChange(page) {
            this.paginator.pageNumber = page;
        },

        pageSizeChange(pageSize) {
            this.paginator.pageSize = pageSize;
        },

        async downloadFile(fileId, fileName) {
            try {
                const paramsIn = {
                    self: this,
                    id: fileId,
                };
                const data = await newApi('getFile', paramsIn);
                const blob = new Blob([data], { type: 'application/octect-stream' });
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (err) {
                console.error(err);
            }
        },

        emptyDataStub(value) {
            return value || 'Н/Д';
        },

        convertDocumentStatus(documents) {
            const convertedDocs = [];
            const lastDocuments = documents.filter((item) => item.haveLastDocuments);

            lastDocuments.forEach((item) => {
                const status = this.emptyDataStub(item?.status?.name);
                const momentDate = this.$moment(item?.lastModifiedDate);
                const lastModifiedDate = momentDate.isValid()
                    ? momentDate.format('YYYY-MM-DD')
                    : 'Н/Д';

                item.documents.forEach((doc) => {
                    if (this.isSimple(doc.documentType.type)) {
                        const simpleDoc = {
                            rowKey: doc.id,
                            fileId: doc.fileInfo.id,
                            fileName: doc.fileInfo.name,
                            name: this.emptyDataStub(doc?.name),
                            relevanceStatus: doc.statusFromSmbk,
                            uploadDate: this.emptyDataStub(doc?.fileInfo?.uploadDate),
                            status,
                            lastModifiedDate,
                            comment: item?.comment,
                            decisionReasonComments: item?.decisionReasonComments,
                            isSimple: true,
                        };
                        convertedDocs.push(simpleDoc);
                    } else if (this.isComposite(doc.documentType.type)) {
                        const mappedSubDoc = doc.subDocuments.map((subDocument) => ({
                            fileId: subDocument.fileInfo.id,
                            fileName: subDocument.fileInfo.name,
                            name: this.emptyDataStub(subDocument?.name),
                            relevanceStatus: subDocument.statusFromSmbk,
                            uploadDate: this.emptyDataStub(subDocument?.fileInfo?.uploadDate),
                            status,
                            lastModifiedDate,
                            comment: item?.comment,
                            decisionReasonComments: item?.decisionReasonComments,
                            isSimple: true,
                        }));
                        const compositeDoc = {
                            rowKey: doc.id,
                            name: doc.name,
                            children: mappedSubDoc,
                            relevanceStatus: doc.statusFromSmbk,
                        };
                        convertedDocs.push(compositeDoc);
                    } else {
                        throw new Error();
                    }
                });
            });

            return convertedDocs;
        },

        isSimple(type) {
            return type == TYPE_DOCS.SIMPLE;
        },

        isComposite(type) {
            return type == TYPE_DOCS.COMPOSITE;
        },
    },
};
</script>

<style lang="scss">

.decision-reason-comments_list {
    margin: 0px 0px 0px 15px;

    li {
        width: fit-content;
    }
}
</style>
