<template>
    <ui-modal
        :title="$t(title) + afterTitle"
        name="info"
        width="800"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <p v-if="url" class="url">
            <span>API:{{ url }}</span>
        </p>
        <p v-if="!isHtmlMode">{{ msg }}</p>
        <p v-if="isHtmlMode" v-html="msg" />
    </ui-modal>
</template>

<script>
import { TYPE_ERRORS } from '@/constant';

export default {
    name: 'MainModalInfo',

    data() {
        return {
            def_msg: 'Укажите адрес электронной почты, которые использовали при создании аккаунта в Личном кабинете ПАО Лукойл. Письмо с инструкциями на электронной почте.',
            msg: '',
            def_title: 'Доступ запрещён',
            title: '',
            type: TYPE_ERRORS.INFO,
            closeEvent: false,
            url: '',
            isHtmlMode: false,
            dopClass: '',
            afterTitle: '',
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                type, msg, title, closeEvent, url, msgError, isHtmlMode = false, dopClass = '', afterTitle = '',
            } = event.params;
            this.msg = msg || this.def_msg;
            this.url = url || '';
            this.type = type || TYPE_ERRORS.INFO;
            this.title = title || this.def_title;
            this.closeEvent = closeEvent || false;
            this.title = msgError || this.title;
            this.isHtmlMode = isHtmlMode || false;
            this.dopClass = dopClass;
            this.afterTitle = afterTitle || '';
        },
        beforeClose() {
            if (this.closeEvent) {
                this.closeEvent();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/_variables";

.url {
    color: $link;
}
</style>
