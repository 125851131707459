/**
 * конвертирует файлы ПБИОТ к старндартному формату
 * @param {*} file
 * @returns
 */
import { YEAR_Q, SIMPLE_Q } from '@/utils/questScheme';

export const convertFile = (file, code = null) => {
    const fileConvert = {
        id: file.id,
        name: file.filename,
        size: file.size,
        uploadDate: file.updated_at,
        code,
    };
    return fileConvert;
};

/**
 * получить схему
 * @param {*} label
 * @returns
 */
export const getDefScheme = (label) => {
    const ext = ['pdf', 'zip', 'rar', 'doc', 'docx', '7z', 'gzip', 'odt', 'odm', 'pages', 'txt'];
    return {
        acceptFile: ext,
        label,
        maxCount: 1,
        multiple: false,
        size: 9.9999744,
        sizeKb: 10240,
        typeDoc: {
            code: '',
            fileExtensions: ext,
            group: 'Required',
            maxCount: 1,
            maxSize: 10240,
            minCount: 1,
            name: label,
            subDocuments: null,
            type: 'SIMPLE',
        },
        ugid: '1',
    };
};

export const getSchemeAndFile = (schemeFiles = [], docsIn = []) => {
    try {
        const docs = [];
        schemeFiles.forEach((_doc) => {
            const findfile = [];
            if (docsIn) {
                docsIn.forEach((doc) => {
                    if (doc.attributes && doc.attributes[0].value.includes(_doc.code)) {
                        findfile.push(convertFile(doc.file, doc.attributes[0].value));
                    }
                });
            }
            docs.push({
                scheme: getDefScheme(_doc.name),
                files: findfile,
                codePBIOT: _doc.code,
                alertMessage: _doc.alert,
            });
        });

        return docs;
    } catch (e) {
        console.error('ошибка в конверторе документов ПБИОТ', e);
        return [];
    }
};
const getDefConvert = (code, value) => ({
    name: code,
    value,
});
/**
 * Конвертирует схему инфидинтов для сохранния
 * @param {*} schemeIn
 * @returns
 */
export const convertDataToSaveInc = (schemeIn) => {
    const schemeOut = [];
    schemeIn.forEach((quest) => {
        if (quest.type == SIMPLE_Q) {
            schemeOut.push(getDefConvert(quest.code, quest.value));
        }
        if (quest.type == YEAR_Q) {
            quest.children.forEach((childQ) => {
                schemeOut.push(getDefConvert(childQ.code, childQ.value));
            });
        }
    });

    return schemeOut;
};
