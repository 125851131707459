<template>
    <div
        class="accordion-item"
        :class="{ 'is-active': isActive }"
        data-accordion-item
    >
        <a :style="cssVars" class="accordion-title titleUpper" @click="setShowData">
            <span v-if="hasIcon" class="ico_info_gray" />
            {{ title }}
            <span v-if="hasMinus" class="ico_accordion" />
        </a>

        <div
            class="accordion-content"
            data-tab-content
            :class="{ forseShow: forceShow }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionItem',

    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        setShowData: {
            type: Function,
            default: () => {},
        },
        hasMinus: {
            type: Boolean,
            default: true,
        },
        hasIcon: {
            type: Boolean,
            default: true,
        },
        forceShow: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: '#f5f7f9',
        },
        textColor: {
            type: String,
            default: 'black',
        },
    },

    computed: {
        cssVars() {
            return {
                'background-color': this.backgroundColor,
                color: this.textColor,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.forseShow {
  display: block;
}
</style>
