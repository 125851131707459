const LOAD_RULES_HEADER = '<div class="documents__info--header documents__info--with-icon">Правила загрузки</div>';

export const addDocumentsNotifications = {
    NO_TAX_ARREARS_CERTIFICATE_TOP_NOTIFICATION_BAR:
        `<div>
            <h3>Внимание!</h3>
            <p>Справку необходимо подгружать по форме КНД 1120101.</p>
            <p>
                Дата, по состоянию на которую представлена информация об
                отсутствии/наличии задолженности, не должна превышать 90 дней при
                загрузке в личный кабинет.
            </p>
            <p>
                Справка должна быть подписана руководителем (заместителем
                руководителя) налогового органа (подпись и печать для сканированного
                образа бумажного носителя, данные сертификата ЭЦП для электронного
                документа).
            </p>
        </div>`,

    TAX_REPORTS_TOP_NOTIFICATION_BAR_DEFAULT:
        `<div>
            <h3>Сроки Обновления Отчетности*</h3>
            <ul>
                <li>
                    Декларацию по налогу на прибыль необходимо обновлять ежегодно и
                    в срок до 30 апреля загрузить декларацию за предыдущий год
                </li>
                <li>
                    Декларацию по НДС необходимо обновлять ежеквартально:
                </li>
                <ul>
                    <li>за 1 квартал необходимо загрузить до 31 мая</li>
                    <li>за 2 квартал необходимо загрузить до 31 августа</li>
                    <li>за 3 квартал необходимо загрузить до 30 ноября</li>
                    <li>
                        за 4 квартал необходимо загрузить до 28 февраля следующего
                        года
                    </li>
                </ul>
            </ul>
            <p>
                * Подробнее в Таблице № 2 Инструкции пользователя (см. «Поддержка»).
            </p>
        </div>`,

    TAX_REPORTS_TOP_NOTIFICATION_BAR_TAX_REGIME_AUSN:
        `<div>
            <h3>Внимание!</h3>
            <p>
                Справку о применении автоматизированной упрощенной системы
                налогообложения необходимо подгружать по форме КНД 1120503.
            </p>
            <p>
                Справку о состоянии расчетов (доходах) по налогу, уплачиваемому в
                связи с применением автоматизированной упрощенной системы
                налогообложения необходимо подгружать по форме КНД 1120504.
            </p>
            <p>
                Справки должны содержать электронную подпись ФНС России.
            </p>
        </div>`,
};

export const taxNotifications = {
    LOAD_RULES_HEADER,

    TAX_REPORTS_BOTTOM_NOTIFICATION_FOREIGN_ORG_BRANCH:
        `<div class="documents__info--body">
            <span>
                Обращаем внимание, что загружаемые файлы должны соответствовать
                предоставляемым в налоговые органы, иметь расширение xml
                и следующие наименования 
                (содержание, расширение и наименование не подлежат ручному
                изменению):
            </span>
            <ul class="documents__info--list">
               <li>
                    "Налоговая декларация по налогу на добавленную стоимость за
                    последний отчетный период" - «NONDS.xml», квитанция к ней -
                    "KVNONDS.xml".
                </li>
            </ul>
        </div>`,

    TAX_REPORTS_BOTTOM_NOTIFICATION_BAR_RESIDENT:
        `<div class="documents__info--body">
            <span>
                Обращаем внимание, что загружаемые файлы должны соответствовать
                предоставляемым в налоговые органы, иметь расширение xml и
                следующие наименования (содержание, расширение и наименование не
                подлежат ручному изменению):
            </span>
            <ul class="documents__info--list">
                <li>
                    "Налоговая декларация по налогу на прибыль организаций за
                    последний отчетный и налоговый период" - «NOPRIB.xml»,
                    квитанция к ней - "KVNOPRIB.xml";
                </li>
                <li>
                    "Налоговая декларация по налогу на добавленную стоимость за
                    последний отчетный период" - «NONDS.xml», квитанция к ней -
                    "KVNONDS.xml";
                </li>
                <li>
                    "Налоговая декларация по УСН" - «NOUSN.xml», квитанция к ней
                    - "KVNOUSN.xml".»
                </li>
            </ul>
            <br />
            <span>
                Если в соответствии с законодательством следующие декларации
                и/или документы были представлены в налоговый орган на бумажном
                носителе, то доступна их загрузка в pdf формате:
            </span>
            <ul class="documents__info--list documents__info--list_disc">
                <li>УСН</li>
                <li>ЕСХН</li>
                <li>ЕНВД (до 2020 года)</li>
                <li>Декларация по налогу на прибыль</li>
                <li>
                    Освобождение от исполнения обязанности плательщика налога на
                    прибыль организации
                </li>
                <li>Освобождение от исполнения обязанности плательщика НДС</li>
            </ul>
        </div>`,
};

export const noTaxArrearsNotifications = {
    LOAD_RULES_HEADER,

    NO_TAX_ARREARS_CERTIFICATE_BOTTOM_NOTIFICATION_BAR:
        `<div>
            При загрузке документа “Справка об исполнении налогоплательщиком
            (плательщиком сбора, налоговым агентом) обязанности по уплате налогов,
            сборов, пеней, штрафов, процентов” убедитесь, что выбрана корректная
            дата.В случае ошибки при выборе даты обратитесь в службу поддержки
            <a class="documents__info--link" href="mailto:lkk@lukoil.com">lkk@lukoil.com</a>
        </div>`,
};

export const accountingReportsNotifications = {
    LOAD_RULES_HEADER,

    ACCOUNTING_REPORTS_BOTTOM_NOTIFICATION_BAR:
    `<div class="documents__info--body">
        <span>
            Обращаем внимание, что загружаемые файлы должны соответствовать
            предоставляемым в налоговые органы, иметь расширение xml и
            следующие наименования (содержание, расширение и наименование не
            подлежат ручному изменению):
        </span>
        <ul class="documents__info--list">
            <li>
                "Бухгалтерская (финансовая) отчетность за последний отчетный
                период" - «NOBUCHOTCH.xml», квитанция к ней -
                "KVNOBUCHOTCH.xml";
            </li>
            <li>
                "Упрощенная бухгалтерская (финансовая) отчетность за
                последний отчетный период" - «NOBOUPR.xml», квитанция к ней
                - "KVNOBOUPR.xml".
            </li>
        </ul>
    </div>`,
};
