import Vue from 'vue';

import { ACCOUNT_STATUS, ACCOUNT_TYPE, ROLE_ADMIN } from '@/constant';
import { newApi } from '@/utils/new-api';

export default {
    state: {
        profile: {},
    },

    mutations: {
        SET_PROFILE(state, resp) {
            state.profile = resp;
        },
    },

    actions: {
        async AUTH_LOGOUT({ commit }) {
            try {
                await Vue.prototype.$keycloak.logout();
            } catch (err) {
                console.error('Logout error:', err);
            } finally {
                commit('SET_PROFILE', {});
            }
        },

        async AUTH_ME({ commit, dispatch }) {
            try {
                const res = await newApi('logon');

                commit('SET_PROFILE', res);

                dispatch('CHANGE_LOCAL', res.prefferedLanguage.toLowerCase());

                return true;
            } catch (err) {
                console.error('Error fetching user data', err);

                return false;
            }
        },
    },

    getters: {
        getProfile: (state) => state.profile,

        isAuthenticated: (state) => !!Vue.prototype.$keycloak.token,

        isProfileLoaded: (state) => !!state.profile.login,

        isAdmin: (state) => {
            if (state.profile?.authorities) {
                return state.profile.authorities.indexOf(ROLE_ADMIN) > 0;
            }
        },

        hasSaveData: (state) => state?.profile?.isEditable ?? false,

        accessToDoc: (state, getters) => getters.accountType != ACCOUNT_TYPE.NONRESIDENT,

        accountType: (state) => state.profile?.accountType?.code ?? ACCOUNT_TYPE.LEGAL,

        accountStatus: (state) => (state.profile.accountStatus ? state.profile.accountStatus : ACCOUNT_STATUS.NEW),

        isLegal: (state, getters) => getters.accountType == ACCOUNT_TYPE.LEGAL,

        isIndividual: (state, getters) => getters.accountType == ACCOUNT_TYPE.INDIVIDUAL,

        isNotResident: (state, getters) => getters.accountType == ACCOUNT_TYPE.NONRESIDENT,
    },
};
