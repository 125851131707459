<template>
    <div class="registration-individual-entity">
        <div class="registration-individual-entity__registration-form">
            <div class="registration-form__header">
                <div class="header__title">Регистрация индивидуального предпринимателя</div>

                <reg-info-button />
            </div>

            <div class="registration-form__content">
                <div class="content__search">
                    <SearchEgrul
                        :form="searchData"
                        field-name="search"
                        :error-text="$t('Необходимо найти организацию')"
                        :v-error="$v.searchData.search"
                        :type="accountType.INDIVIDUAL"
                        force-text-error="Контрагент с таким ИНН уже существует в системе."
                        @blur="checkDuplicate"
                    />
                </div>

                <div>
                    <base-input
                        v-model="searchData.inn"
                        label="ИНН"
                        name="inn"
                        placeholder="ИНН"
                        disabled
                    />

                    <base-input
                        v-model="searchData.ogrn"
                        label="ОГРНИП"
                        name="ogrn"
                        placeholder="ОГРНИП"
                        disabled
                    />

                    <base-input
                        v-model="searchData.address"
                        label="Юридический адрес"
                        name="address"
                        placeholder="Адрес"
                        classes="no_margin"
                        textarea
                        disabled
                    />
                </div>
            </div>

            <div v-if="isDuplicate" class="registration-form__duplicate-error">
                {{
                    $t(
                        'Контрагент с таким ИНН уже существует в системе. Свяжитесь со службой поддержки'
                    )
                }}
            </div>

            <ui-button
                :disabled="$v.$invalid"
                type="full"
                @click="sendData"
            >
                {{ $t('Зарегистрироваться') }}
            </ui-button>
        </div>

        <div class="registration-individual-entity__footer">
            <router-link class="footer__link" to="/account/registration">
                {{ $t('Назад') }}
            </router-link>

            <button class="footer__link" href="#" @click="logout">{{ $t('Выход') }}</button>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import SearchEgrul from '@/views/components/base/SearchEgrul';
import { TYPE_ERRORS, TYPE_DIALOG_TITLE, ACCOUNT_TYPE } from '@/constant';
import { newApi } from '@/utils/new-api';

export default {
    name: 'RegistrationIndividualEntity',

    components: { SearchEgrul },

    data() {
        return {
            accountType: ACCOUNT_TYPE,
            isDuplicate: false,

            searchData: {
                search: null,
                inn: null,
                address: null,
                kpp: null,
                ogrn: null,
            },
        };
    },

    validations: {
        searchData: {
            search: {
                required,
            },
            inn: {
                required,
                maxLength: maxLength(12),
                minLength: minLength(12),
            },
            ogrn: {
                required,
                maxLength: maxLength(15),
                minLength: minLength(15),
            },
        },
    },

    methods: {
        async sendData() {
            const paramsIn = {
                self: this,
                sendData: {
                    type: ACCOUNT_TYPE.INDIVIDUAL,
                    inn: this.searchData.inn,
                    ogrn: this.searchData.ogrn,
                },
            };

            try {
                const res = await newApi('individualRegistration', paramsIn);

                if (res) {
                    await this.$store.dispatch('AUTH_ME');

                    this.$modal.show('info', {
                        type: TYPE_ERRORS.SUCCESS,
                        msg: this.$t('Регистрация завершена'),
                        title: TYPE_DIALOG_TITLE.INFO,
                        closeEvent: () => {
                            this.$router.push('/');
                        },
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },

        checkDuplicate: debounce(async function () {
            const paramsIn = {
                self: this,
                inn: this.searchData.inn,
            };

            const res = await newApi('duplicateCheck', paramsIn);

            this.isDuplicate = res ? !res.isFree : false;

            return res?.isFree;
        }, 500),

        logout() {
            this.$store.dispatch('AUTH_LOGOUT', { self: this });
        },
    },

    watch: {
        'searchData.search': function (val) {
            if (val) {
                this.searchData.inn = val.inn;
                this.searchData.ogrn = val.ogrn;
                this.searchData.address = val.address;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.registration-individual-entity {
    width: 500px;

    &__registration-form {
        position: relative;
        background: white;
        padding: 70px 32px 32px;
        margin-bottom: 30px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
    }
}

.registration-form {
    &__header {
        padding-bottom: 30px;
    }

    &__content {
        padding-bottom: 30px;
    }

    &__duplicate-error {
        padding-bottom: 30px;
        color: #db2b37;
    }
}

.header {
    &__title {
        font: 26px OpenSansBold, sans-serif;
        text-align: center;
        color: black;
    }
}

.content {
    &__search {
        margin-bottom: 20px;
    }
}

.footer {
    &__link {
        font: 20px CuprumBold, sans-serif;
        cursor: pointer;
        color: #989898;
        text-decoration: none;
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: solid 1px rgba(152, 152, 152, 0.3);
    }
}
</style>
