import { render, staticRenderFns } from "./main-notification-modal.vue?vue&type=template&id=1fae3246&scoped=true"
import script from "./main-notification-modal.vue?vue&type=script&lang=js"
export * from "./main-notification-modal.vue?vue&type=script&lang=js"
import style0 from "./main-notification-modal.vue?vue&type=style&index=0&id=1fae3246&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fae3246",
  null
  
)

export default component.exports