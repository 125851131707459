/**
 * ограничения связанные с периодом
 */
import moment from 'moment';

export const TEMPLATE_YEAR = 'XXXX';
export const TEMPLATE_PERIOD = 'Y';
export const TEMPLATE_FULL_YEAR = 'dd.MM.yyyy';
export const TEMPLATE_PERIOD2 = 'ZZZZ';
export const NEW_DOC = 'OtherDocument';

export const replaceYear = (nameDoc, year) => nameDoc.replace(new RegExp(TEMPLATE_YEAR, 'g'), year);
export const replaceFullYear = (nameDoc, year) => nameDoc.replace(new RegExp(TEMPLATE_FULL_YEAR, 'g'), year);
export const replacePeriod = (nameDoc, period, year) => {
    if (nameDoc.includes(TEMPLATE_PERIOD2)) {
        const code = period;
        const findReplace = periodCalculateSv.find((item) => item.code == code);
        if (findReplace) {
            return nameDoc.replace(
                new RegExp(TEMPLATE_PERIOD2, 'g'),
                findReplace.withYear(year),
            );
        }
    }
    return nameDoc.replace(new RegExp(TEMPLATE_PERIOD, 'g'), period);
};

export const optionPeriod = [
    {
        id: 0,
        code: 1,
        name: '1 квартал',
    },
    {
        id: 1,
        code: 2,
        name: '2 квартал',
    },
    {
        id: 2,
        code: 3,
        name: '3 квартал',
    },
    {
        id: 3,
        code: 4,
        name: '4 квартал',
    },
];

export const periodCalculateSv = [
    {
        id: 0,
        code: 1,
        name: '1 квартал',
        withYear(year) {
            return `${this.name} ${year} года`;
        },
    },
    {
        id: 1,
        code: 2,
        name: 'полугодие',
        withYear(year) {
            return `${this.name} ${year} года`;
        },
    },
    {
        id: 2,
        code: 3,
        name: '9 месяцев',
        withYear(year) {
            return `${this.name} ${year} года`;
        },
    },
    {
        id: 3,
        code: 4,
        name: 'год',
        withYear(year) {
            return `${year} ${this.name}`;
        },
    },
];
const DEF_FORMAT_DATE = 'DD.MM.YYYY';
export const optionPeriodFilterByCurrentDate = (
    selectYear = currentDate.format('YYYY'),
    currentDateIn = moment().format(DEF_FORMAT_DATE),
    optionPeriodIn = optionPeriod,
    format = DEF_FORMAT_DATE,
) => {
    const optionPeriod = optionPeriodIn;
    const currentDate = moment(currentDateIn, format);
    const currentYear = parseInt(selectYear);
    const options = [];
    if (
        currentDate.diff(moment(`01.03.${currentYear}`, DEF_FORMAT_DATE), 'days')
    > 0
    ) {
        options.push(optionPeriod[0]);
    }
    if (
        currentDate.diff(moment(`01.06.${currentYear}`, DEF_FORMAT_DATE), 'days')
    > 0
    ) {
        options.push(optionPeriod[1]);
    }

    if (
        currentDate.diff(moment(`01.09.${currentYear}`, DEF_FORMAT_DATE), 'days')
    > 0
    ) {
        options.push(optionPeriod[2]);
    }

    if (
        currentDate.diff(moment(`01.12.${currentYear}`, DEF_FORMAT_DATE), 'days')
    > 0
    ) {
        options.push(optionPeriod[3]);
    }
    // До 01.03 текущего года не может быть представлена налоговая декларация за 1 квартал текущего года.
    // До 01.06 текущего года не может быть представлена налоговая декларация за 2 квартал текущего года.
    // До 01.09 текущего года не может быть представлена налоговая декларация за 3 квартал текущего года.
    // До 01.12 текущего года не может быть представлена налоговая декларация за 4 квартал текущего года.

    return options;
};
