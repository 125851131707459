<template>
    <div class="upload_file compositeFile">
        <span class="compositeFile__uploadCount">{{ $t("Загружено:") }} {{ fileLoad }} из {{ countFiles }}</span>
        <span
            v-if="delType"
            class="upload_file__delete"
            @click="delType(ugid)"
        />
        <DocumentUploadForm
            v-for="{ typeDoc, ugid, hasFiles, attrDoc } in compositeDoc"
            :key="ugid"
            is-composite-mode
            :ugid="ugid"
            :accept-file="typeDoc.fileExtensions"
            :label="typeDoc.name"
            :size-kb="typeDoc.maxSize"
            :max-count="typeDoc.maxCount"
            :add-file="addFile"
            :has-files="hasFiles ? hasFiles : []"
            :has-delete="hasDelete"
            :attr-doc="attrDoc"
        >
            <template #fileDescription><slot name="fileDescription" /></template>
            <slot />
        </DocumentUploadForm>
    </div>
</template>

<script>
import DocumentUploadForm from './DocumentUploadForm';

export default {
    name: 'DocumentComplexUploadForm',
    components: {
        DocumentUploadForm,
    },
    props: {
        ugid: { type: String, required: true },
        label: {},
        compositeDoc: {},
        addFile: { type: Function, required: true },
        delType: { type: Function },
        hasDelete: { type: Boolean, required: true },
        loudFiles: { type: Array },
    },
    data() {
        return {
            typeDocSheme: [],
            countFiles: 0,
        };
    },
    computed: {
        fileLoad() {
            const { compositeDoc } = this;
            let count = 0;
            compositeDoc.forEach((item) => {
                if (item.hasFiles) {
                    count += item.hasFiles.length;
                }
            });
            if (this.loudFiles) {
                compositeDoc.forEach((item) => {
                    const find = this.loudFiles.filter((file) => file.ugid === item.ugid);
                    count += find ? find.length : 0;
                });
            }
            return count;
        },
    },
    created() {
        this.compositeDoc.forEach((item) => {
            this.countFiles += item.typeDoc.maxCount;
        });
    },
    methods: {},
};
</script>

<style lang='scss' scoped>
.compositeFile {
    position: relative;
    .composite_file {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__uploadCount {
        font: normal normal 13px/1.43 OpenSansRegular, sans-serif;
        font-stretch: normal;
        letter-spacing: 0.26px;
        color: #989898;
        z-index: 9;
        right: 43px;
        top: 26px;
        font-size: 14px;
        display: inline-block;
        position: absolute;
        margin-bottom: 0;
        text-align: right;
    }

    .upload_file__delete {
        z-index: 9;
        top: 30px;
    }
}

.upload_file {
    &__delete {
        cursor: pointer;
        position: absolute;
        background: url("~@/assets/ico/ico-close-gray.svg");
        right: 24px;
        top: 27px;
        background-size: contain;
        width: 12px;
        height: 12px;
        display: block;
    }
}
</style>
