<template>
    <div>
        <page-header :title="$t('Документы')" />

        <info-user is-info-documents />

        <section class="main_documents">
            <div class="container">
                <TabsMenu id-tabs="tabs">
                    <TabMenuItem
                        id-tab="panel1"
                        :label="$t('Документы')"
                        is-active
                        @set-tab="activeTab = 'doc'"
                    />

                    <TabMenuItem
                        id-tab="panel2"
                        :label="$t('История загрузки документов')"
                        @set-tab="activeTab = 'statusDoc'"
                    />

                    <TabMenuItem
                        v-show="showFin"
                        id-tab="panel3"
                        :label="$t('Финансовые показатели')"
                    />
                </TabsMenu>

                <TabsBody>
                    <TabItem id-tab="panel1" is-active>
                        <documents-history v-if="activeTab === 'doc'" />
                    </TabItem>

                    <TabItem id-tab="panel2">
                        <document-block-status
                            v-if="activeTab === 'statusDoc'"
                            :on-reload="loadValidateDocument"
                        />
                    </TabItem>

                    <TabItem id-tab="panel3">
                        <finance-block
                            v-if="showFin"
                        />
                    </TabItem>
                </TabsBody>
            </div>
        </section>
    </div>
</template>

<script>
import ProgressSection from '@/views/components/shared/ProgressSection.vue';
import DocumentBlockStatus from '@/views/components/documents/DocumentBlockStatus';
import FinanceBlock from '@/views/components/documents/FinanceBlock';
import { newApi } from '@/utils/new-api';

import DocumentsHistory from './documents-history';

export default {
    name: 'Documents',

    components: {
        ProgressSection,
        FinanceBlock,
        DocumentBlockStatus,
        DocumentsHistory,
    },

    data() {
        return {
            tabId: 'tabId',
            activeTab: 'doc',
            load: false,
            worksheet: null,
        };
    },

    computed: {
        showFin() {
            return this.worksheet?.data?.individual;
        },
    },

    async created() {
        this.$store.commit('CLEAR_DOCUMENTS');

        try {
            this.worksheet = await newApi('getProfileForm', { self: this });
        } catch (e) {
            console.error(e);
        }

        await this.loadValidateDocument();
    },

    destroyed() {
        this.$store.commit('CLEAR_DOCUMENTS');
    },

    methods: {
        async loadValidateDocument() {
            try {
                await this.$store.dispatch('GET_VALIDATE_DOCUMENTS', {
                    self: this,
                });
            } catch (err) {
                console.error(err);
            }
        },
    },
};
</script>
