const SELECT_DOCS_USER = 'SELECT_DOCS_USER';
export const saveSelectDoc = (data = []) => {
    localStorage.setItem(SELECT_DOCS_USER, JSON.stringify(data));
};
export const getSaveSelectDoc = (defData = []) => {
    const readData = localStorage.getItem(SELECT_DOCS_USER);
    if (readData) {
        return JSON.parse(readData);
    }
    return defData;
};
export const clearCashSelectDoc = () => {
    localStorage.removeItem(SELECT_DOCS_USER);
};
