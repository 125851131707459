<template>
    <modal
        ref="modal"
        :name="name"
        :adaptive="adaptive"
        :draggable="draggable"
        :scrollable="scrollable"
        :reset="reset"
        :click-to-close="closeable ? clickToClose : false"
        :classes="classes$"
        :width="width"
        :height="height"
        :shift-x="shiftX"
        :shift-y="shiftY"
        :class="{ 'ui-modal--stashed': isStashed }"
        :style="{ zIndex: 1000 + priorityPosition }"
        @before-open="beforeOpen"
        @opened="handleOpened"
        @before-close="beforeClose"
        @closed="closed"
    >
        <section>
            <div class="ui-modal__header">
                <h3 class="ui-modal__header-title">
                    <ui-icon
                        v-if="icon"
                        :name="icon"
                        width="23"
                        height="23"
                        class="mr-10"
                    />
                    {{ title }}
                </h3>

                <button
                    v-if="closeable"
                    class="ui-modal__button-close"
                    type="button"
                    @click="() => close()"
                >
                    <ui-icon :name="icons.roundClose" width="25" height="25" />
                </button>
            </div>

            <div
                class="ui-modal__content"
                :class="{&quot;ui-modal__content--max-height&quot;: maxHeight}"
            >
                <slot />
            </div>
        </section>
    </modal>
</template>

<script>
import icons from '@/helpers/icons';

/**
 * В плагине, выполнение event.stop никак не отражается на статусе самого event-a,
 * несмотря на наличие параметра canceled. Хардкодим епта.
 * На данный момент версия плагина в package.json - ^1.3.34.
 * В версии 2.0 - косяк до сих пор не пофикшен.
 */
function prepareEvent(event) {
    event.stop = () => {
        event.isStopped = true;
        event.cancel();
    };
}

export default {
    props: {
        title: null,
        name: null,
        adaptive: {
            type: Boolean,
            default: true,
        },
        draggable: {
            type: [Boolean, String],
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: true,
        },
        reset: {
            type: Boolean,
            default: true,
        },
        confirmToClose: {
            type: Boolean,
            default: false,
        },
        /**
         * Для loader-a
         */
        closeable: {
            type: Boolean,
            default: true,
        },
        clickToClose: null,
        classes: {
            type: Array,
            required: false,
        },
        icon: null,
        width: null,
        height: {
            type: [Number, String],
            default: 'auto',
        },
        maxHeight: {
            type: Boolean,
            default: false,
        },
        shiftX: null,
        shiftY: null,
        zIndex: [Number, String],
        cascade: {
            type: Boolean,
            default: true,
        },
        noStashed: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            icons,
            isStashed: false,
            priorityPosition: 0,
            shown: false,
        };
    },

    computed: {
        classes$() {
            const classes = this.classes instanceof Array ? this.classes : [this.classes];

            return [...classes, 'ui-modal--transparent-background'];
        },
    },

    watch: {
        shown(val) {
            if (val) {
                this.$emit('input', val);
            }
        },
    },

    beforeDestroy() {
        if (this.shown) {
            this.beforeClose({
                params: {
                    canClose: true,
                },
            });
        }
    },

    methods: {
        show() {
            this.$modal.show(this.name);
        },

        close(isForce = false) {
            this.$modal.hide(this.name, isForce && {
                canClose: true,
                closeConfirmed: true,
            });
        },

        beforeClose(event) {
            prepareEvent(event);

            if (!this.confirmToClose || (event.params && event.params.canClose)) {
                this.$emit('before-close', event);

                this.$modal.openModals
                    .splice(this.$modal.openModals.findIndex((modal) => modal === this), 1);

                this.recalculatePriority();

                return;
            }

            event.stop();

            if (!event.ref) {

            }
        },

        beforeOpen(event) {
            prepareEvent(event);

            this.$emit('before-open', event);

            if (event.isStopped) {
                return;
            }

            if (this.$modal.openModals) {
                this.$modal.openModals.push(this);
            } else {
                this.$modal.openModals = [this];
            }
            this.recalculatePriority();
            this.shown = true;
        },

        closed(event) {
            this.$emit('closed', event);
            this.shown = false;
        },

        handleOpened(event) {
            this.$emit('opened', event);
        },

        /**
         * Расчёт приоритета отображения модальных окон.
         *
         * Окно с атрибутом z-index открывается поверх всех.
         * Чем выше значение тем выше приоритет.
         *
         * Каждое новое открытое окно скрывает предыдущие.
         * Исключения:
         *  - Модальное окно с атрибутом cascade не скрывается.
         *  - Модальное окно с атрибутом noStashed не скрывает следующие окно
         */
        recalculatePriority() {
            const sortModal = [...this.$modal.openModals]
                .sort((a, b) => {
                    if (b.zIndex) {
                        return a.zIndex ? a.zIndex - b.zIndex : -1;
                    }

                    return 0;
                }).reverse();

            sortModal.forEach((el, id) => {
                el.priorityPosition = sortModal.length - id;

                if (!id) {
                    el.isStashed = false;

                    return;
                }

                if (!el.cascade) {
                    el.isStashed = false;

                    return;
                }

                const beforeEl = sortModal[id - 1];

                el.isStashed = beforeEl.isStashed
                    ? true
                    : !beforeEl.noStashed;
            });
        },
    },
};
</script>

<style lang='scss'>
.v {

    &--modal-overlay {
        background: rgba(0, 0, 0, 0.6);
    }

    .ui-modal--stashed {
        width: 0;
        overflow: hidden;
    }
}

.ui-modal {

    &__content {
        overflow: auto;
        background-color: #ffffff;
        padding: 20px;

        &--max-height {
            max-height: 1000px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        color: #ffffff;
        background-color: #da2b36;
    }

    &__header-title {
        font-family: CuprumBold, sans-serif;
        font-size: 20px;
        display: flex;
    }

    &__button-close {
        cursor: pointer;
    }

    &--transparent-background {
        background-color: transparent !important;
    }
}

</style>

