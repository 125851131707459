<template>
    <ui-modal
        name="UsersAdminModal"
        width="700"
        @before-open="beforeOpen"
    >
        <div class="users-admin-modal">
            <div class="users-admin-modal__title">
                <slot name="title" />
            </div>
            <input
                v-model="newKsss"
                class="users-admin-modal__new-ksss"
                type="text"
                placeholder="Новый код КССС"
            />
            <div v-if="!$v.newKsss.numeric">Это должно быть число</div>

            <div class="users-admin-modal__actions">
                <ui-button
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('reset-isu-user')"
                >
                    Повторная отправка в ИСУ "Снабжение"
                </ui-button>

                <ui-button
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('delete-user')"
                >
                    Удалить
                </ui-button>

                <ui-button
                    :disabled="$v.$invalid"
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('set-kss', newKsss)"
                >
                    Изменить КССС
                </ui-button>

                <ui-button
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('update-status')"
                >
                    Обновить статус
                </ui-button>

                <ui-button
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('clean-moderation')"
                >
                    Снять с модерации
                </ui-button>

                <ui-button
                    type="full"
                    class="users-admin-modal__actions--button"
                    @click="emitAction('open-pbiot-for-edit')"
                >
                    Открыть анкету ПБ, ОТ и ОС на редактирование
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';

export default {
    name: 'UsersAdminModal',

    data() {
        return {
            newKsss: null,
        };
    },

    validations: {
        newKsss: {
            required,
            numeric,
        },
    },

    methods: {
        beforeOpen() {
            this.newKsss = null;
        },

        emitAction(action, params) {
            this.$emit(action, params);
        },
    },
};
</script>

<style lang="scss" scoped>
.users-admin-modal {
    position: relative;

    &__title {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 0px 0px 10px 0px;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--button {
            margin: 10px 0;
            width: 400px;
        }
    }

    &__new-ksss {
        height: 50px;
        width: 100%;
        padding-left: 20px;
    }
}
</style>
