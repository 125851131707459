<template>
    <div class="large-12 cell">
        <ul class="pbiot__menu">
            <li>
                <router-link class="tabLink" to="/pbiot/incidents">
                    {{
                        $t("Происшествия")
                    }}
                </router-link>
            </li>

            <li>
                <router-link class="tabLink" to="/pbiot/doc-pbiot">
                    {{ $t("Документы по ПБиОТ") }}
                </router-link>
            </li>
            <li>
                <router-link class="tabLink" to="/pbiot/doc-ep">
                    {{ $t("Документы по ООС") }}
                </router-link>
            </li>
            <li>
                <router-link class="tabLink" to="/pbiot/form">
                    {{
                        $t("Анкета")
                    }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TabMenuLinks',
};
</script>

<style lang="scss" scoped>
.pbiot {
    &__menu {
        list-style: none;
        padding: 0;
        li {
            margin-right: 64px;
            float: left;
        }

        .tabLink {
            font-family: CuprumRegular, sans-serif;
            display: block;
            padding: 0 0 4px 0;
            color: #989898;
            text-decoration: none;
            text-transform: uppercase;
            border-bottom: 4px solid transparent;
            font-size: 32px;
            line-height: 37px;
            letter-spacing: 0.533333px;
            text-transform: uppercase;

            color: #989898;

            &:hover {
                color: #000 !important;
            }
        }
        .router-link-active {
            color: #000 !important;
            border-color: #db2b37;
        }
    }
}
</style>
