<template>
    <div v-if="docSpecification" class="grid-x grid-margin-x">
        <div class="large-8 small-8 cell mb-12">
            <div class="addDoc">
                {{ documentName }}
            </div>
        </div>

        <div class="large-4 small-4 cell">
            <ui-button
                :disabled="disableButton"
                type="full"
                @click="onAddDoc(docSpecification)"
            >
                {{ $t("Добавить") }}
            </ui-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentFilterBtnV2',

    props: {
        docSpecification: {}, // DocumentSpecificationDocDTO
        onAddDoc: { type: Function },
        disableButton: { type: Boolean },
    },

    computed: {
        documentName() {
            return this.docSpecification.name ?? '';
        },
    },
};
</script>
