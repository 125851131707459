export const appParams = {
    SHOW_PAGE_TENDER: 'SHOW_PAGE_TENDER',
    FAQ_Q_5: 'FAQ_Q_5', // вопрос 1
    FAQ_Q_21: 'FAQ_Q_21', // вопрос 1
    LINK_DOC_OLD: 'LINK_DOC_OLD',

    KEYCLOAK_CLIENT_ID: 'KEYCLOAK_CLIENT_ID',
    KEYCLOAK_AUTH_HOST: 'KEYCLOAK_AUTH_HOST',
    KEYCLOAK_REALM: 'KEYCLOAK_REALM',
    KEYCLOAK_REDIRECT_URI: 'KEYCLOAK_REDIRECT_URI',
    TENDER_LINK: 'TENDER_LINK',
    SHOW_PBIOT: 'SHOW_PBIOT',
    SHOW_WARNING_MES: 'SHOW_WARNING_MES',
    SHOW_CHECKBOX_SHOW_ALL: 'SHOW_CHECKBOX_SHOW_ALL',

    SHOW_PBIOT_INSTRUCTION: 'SHOW_PBIOT_INSTRUCTION',

    LOAD_DOC_HDS_IF_ESHN: 'LOAD_DOC_HDS_IF_ESHN',
    SHOW_TECHNICAL_WORK_WARNING: 'SHOW_TECHNICAL_WORK_WARNING',
    MIN_FILE_SIZE_BYTE: 'MIN_FILE_SIZE_BYTE',
};
/**
 * Выдает конфиг для приложения
 * @param {*} nameParams
 */
export const getAppConfig = (nameParams = null, defVal = true) => {
    const config = window && window.appConfig ? window.appConfig : null;
    return config && nameParams in config ? config[nameParams] : defVal;
};
