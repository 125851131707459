import { newApi } from '@/utils/new-api';

export const actions = {
    async GET_STATUS_PBIOT({ commit }, { self }) {
        try {
            const res = await newApi('getStatusPbiot', { self });

            commit('SET_STATUS_PBIOT', res);
        } catch (e) {
            console.log(e);
        }
    },

    async GET_PBIOT({ commit }, { self }) {
        try {
            const res = await newApi('getPbiot', { self });

            commit('SET_PBIOT', res);
        } catch (e) {
            console.log(e);
        }
    },
};
