<template>
    <div class="auth-layout">
        <Loader />
        <div class="auth-layout__logo">
            <img
                v-if="lang === 'en'"
                src="@/assets/img/logo-en.png"
                alt="logo"
                width="227"
                height="58"
            />

            <img
                v-else
                src="@/assets/img/logo.png"
                alt="logo"
                width="227"
                height="58"
            />
        </div>

        <div class="auth-layout__content">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.path" />
            </transition>
        </div>

        <div class="auth-layout__footer">
            <p>&copy; {{ nowYear }} Лукойл</p>
        </div>
    </div>
</template>

<script>
import Loader from '@/views/components/shared/Loader.vue';

export default {
    name: 'AuthLayout',

    components: {
        Loader,
    },

    computed: {
        lang() {
            return this.$store.state.common.lang;
        },

        nowYear() {
            return this.$moment().format('YYYY');
        },
    },
};
</script>

<style lang="scss" scoped>
.auth-layout {
    background: #f5f7f9;
    display: flex;
    min-height: 100vh;
    border-bottom: 8px solid #000;
    flex-direction: column;

    &__logo {
        text-align: center;
        margin-bottom: auto;
        padding: 50px 0px;
    }

    &__content {
        margin: 0 auto;
    }

    &__footer {
        text-align: center;
        margin-top: auto;
        padding-bottom: 10px;
    }
}
</style>
