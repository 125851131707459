<template>
    <div>
        <router-view />
        <MainModalInfo />
        <modal-support />
        <modal-window />
        <new-user-agreement-modal />
        <error-modal />
        <main-notification-modal />

        <!--<happy-new-year-modal></happy-new-year-modal>-->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { newApi } from '@/utils/new-api';
import MainModalInfo from '@/views/components/shared/MainModalInfo.vue';
import ModalSupport from '@/views/components/shared/ModalSupport.vue';
import ModalWindow from '@/views/components/shared/ModalWindow.vue';
import NewUserAgreementModal from '@/views/modules/modals/new-user-agreement-modal.vue';
import ErrorModal from '@/views/modules/modals/error-modal.vue';
import MainNotificationModal from '@/views/modules/modals/main-notification-modal.vue';
// import HappyNewYearModal from '@/views/modules/modals/happy-new-year-modal.vue'

export default {
    components: {
        MainModalInfo,
        ModalSupport,
        ModalWindow,
        NewUserAgreementModal,
        ErrorModal,
        MainNotificationModal,
        // HappyNewYearModal,
    },

    computed: {
        ...mapGetters(['getProfile']),
    },

    watch: {
        getProfile: {
            handler(profile) {
                if (profile.companyCode) {
                    this.loadUserAgreementStatus();
                }
            },
            immediate: true,
        },
    },

    // mounted() {
    //     this.$modal.show('HappyNewYear')
    // },

    methods: {
        async loadUserAgreementStatus() {
            try {
                const params = {
                    self: this,
                    contractorCode: this.getProfile.companyCode,
                };

                const result = await newApi('loadUserAgreementStatus', params);

                if (result) {
                    this.$modal.show('NewUserAgreement');
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
