<template>
    <div class="registration-info-button">
        <ui-icon :name="icons.informationSlabBox" width="35" height="35" />

        <div class="registration-info-button__options">
            <a
                v-for="link in links"
                :key="link.id"
                :href="link.url"
                class="options__item"
                target="_blank"
            >
                {{ link.name }}
            </a>
        </div>
    </div>
</template>

<script>
import icons from '@/helpers/icons';

export default {
    name: 'RegistrationInfoButton',

    data() {
        return {
            icons,
            links: [
                {
                    id: 1,
                    name: 'ru',
                    url: '/api/public/files/usermanual?lang=RU',
                },
                {
                    id: 2,
                    name: 'en',
                    url: '/api/public/files/usermanual?lang=EN',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.registration-info-button {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &:hover {
        .registration-info-button__options {
            display: block;
        }
    }

    &__options {
        display: none;
        position: absolute;
        top: 32px;
        right: 0;
        background: #db2b37;
        font-family: CuprumBold, sans-serif;
        font-size: 16px;
    }
}

.options {
    &__item {
        display: block;
        width: 50px;
        height: 40px;
        padding-top: 10px;
        cursor: pointer;
        text-align: center;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            background: black;
        }
    }
}
</style>
