<template>
    <div class="bank_account_item_body">
        <button
            class="bank_account_delete"
            @click="$emit('delAccount', accountBank)"
        />

        <div class="account_item_body_label">БИК банка</div>

        <div v-if="accountBank && accountBank.bankInfo" class="bank_account_info">
            <div class="bank_account_info_number">
                {{ accountBank.bankInfo.bik }}
            </div>

            <div class="bank_account_info_content">
                {{ accountBank.bankInfo.name }}, к/с
                {{ accountBank.bankInfo.correspondentAccount }}
            </div>

            <div class="bank_account_info_currency">
                {{ accountBank.currencyCode }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BankInfo',

    props: {
        accountBank: {
            type: Object,
            required: true,
        },
    },
};
</script>
