import axios from 'axios';

import { errorHandler } from './error-handler';
import { setupInterceptors } from './httpInterceptors';

const PREFIX_API = '/api';
class HttpClient {
    msgError = '';

    constructor() {
        const client = axios.create();
        setupInterceptors(client);
        this.HTTP = client;
    }

    set self(vm) {
        this.vm = vm;
    }

    setMsgError(msg) {
        this.msgError = msg;
    }

    getRequest(url, params = null, showError = true) {
        return new Promise((resolve, reject) => {
            this.HTTP.get(PREFIX_API + url, { params })
                .then((res) => {
                    resolve(res.data && res.data.response ? res.data.response : res.data);
                })
                .catch((res) => {
                    if (showError) {
                        errorHandler({
                            res,
                            msgError: this.msgError,
                        });
                    }

                    let result = false;
                    if ('data' in res) {
                        result = res.data && res.data.response ? res.data.response : res.data;
                    }
                    reject(result);
                });
        });
    }

    getFile(url) {
        return new Promise((resolve, reject) => {
            this.HTTP.get(PREFIX_API + url, {
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    resolve(
                        response.data && response.data.response
                            ? response.data.response
                            : response.data,
                    );
                })
                .catch((res) => {
                    errorHandler({ res, msgError: this.msgError });
                    let result = false;
                    if ('data' in res) {
                        result = res.data && res.data.response ? res.data.response : res.data;
                    }
                    reject(result);
                });
        });
    }

    postRequest(url, data = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.post(PREFIX_API + url, data)
                .then((res) => {
                    resolve(res.data && res.data.response ? res.data.response : res.data);
                })
                .catch((res) => {
                    errorHandler({ res, msgError: this.msgError });
                    if (res.response) {
                        reject(
                            res.response && res.response.data ? res.response.data : res.response,
                        );
                    }
                    reject(res.data && res.data.response ? res.data.response : res.data);
                });
        });
    }

    putRequest(url, data = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.put(PREFIX_API + url, data)
                .then((res) => {
                    resolve(res.data && res.data.response ? res.data.response : res.data);
                })
                .catch((res) => {
                    errorHandler({ res, msgError: this.msgError });
                    reject(res.data && res.data.response ? res.data.response : res.data);
                });
        });
    }

    postRequestFile(url, formData) {
        return new Promise((resolve, reject) => {
            this.HTTP.post(PREFIX_API + url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res) => {
                    resolve(res.data && res.data.response ? res.data.response : res.data);
                })
                .catch((res) => {
                    errorHandler({ res, msgError: this.msgError });
                    reject(res.data && res.data.response ? res.data.response : res.data);
                });
        });
    }

    deleteRequest(url, params = null) {
        return new Promise((resolve, reject) => {
            this.HTTP.delete(PREFIX_API + url, { params })
                .then((res) => {
                    resolve(res.data && res.data.response ? res.data.response : res.data);
                })
                .catch((res) => {
                    errorHandler({ res, msgError: this.msgError });
                    let result = false;
                    if ('data' in res) {
                        result = res.data && res.data.response ? res.data.response : res.data;
                    }
                    reject(result);
                });
        });
    }
}

const HttpClientLocal = new HttpClient();
export default HttpClientLocal;
