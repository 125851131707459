import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=a3f85da0&scoped=true"
import script from "./ModalDelete.vue?vue&type=script&lang=js"
export * from "./ModalDelete.vue?vue&type=script&lang=js"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=a3f85da0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f85da0",
  null
  
)

export default component.exports