<template>
    <div>
        <v-select
            class="search_select dictionary_egrul"
            :placeholder="placeHolder"
            label="name"
            :filterable="false"
            :options="options"
            :value="form[fieldName]"
            @search="onSearch"
            @input="selectSearch($event)"
            @search:blur="unFocus"
        >
            <template #list-header>
                <li class="top_select">
                    {{ $t("Выберите вариант или продолжите ввод") }}
                </li>
            </template>
            <template #option="{ name, inn, ogrn , kpp, address}">
                <div :key="inn" class="select_company_title" :title="name">
                    {{ name }}
                </div>
                <div :key="inn + kpp" class="select_company_info" :title="address">
                    {{ inn }}, {{ address }}
                </div>
            </template>

            <template #selected-option="{ name }">
                <div>{{ name }}</div>
            </template>
            <template #no-options>
                {{ $t("Нет результатов поиска") }}
            </template>
        </v-select>
        <div v-if="hasError" class="has-error">
            <p v-if="showMainError">{{ errorText }}</p>
            <p v-if="forceError">{{ $t(forceTextError) }}</p>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';

import { ACCOUNT_TYPE } from '@/constant';
import { newApi } from '@/utils/new-api';

export default {
    name: 'SearchEgrul',

    props: {
        form: { type: Object, required: true },
        fieldName: { type: String, required: true },
        errorText: { type: String, default: '' },
        vError: { type: Object },
        type: { type: String, required: true },
        forceError: { type: Boolean, default: false },
        forceTextError: { type: String, default: '' },
    },

    data() {
        return {
            options: [],
            showMainError: true,
        };
    },

    computed: {
        hasError() {
            const { vError } = this;
            if (this.forceError || (vError && vError.$dirty && vError.$error)) {
                return true;
            }
            return false;
        },
        touch() {
            const { vError } = this;
            if (vError && vError.$dirty) {
                return true;
            }
            return false;
        },
        classesComp() {
            let res = this.classes;
            res += this.hasError ? ' error ' : this.touch ? ' success ' : '';
            return res;
        },
        placeHolder() {
            if (this.type == ACCOUNT_TYPE.INDIVIDUAL) {
                return this.$t('Введите ИНН/ОГРНИП/ФИО');
            }
            return this.$t('Введите ИНН/ОГРН/Наименование ЮЛ');
        },
    },

    methods: {
        unFocus() {
            if (this.vError) {
                this.vError.$touch();
            }
            this.$emit('blur');
        },

        selectSearch(event) {
            this.$set(this.form, this.fieldName, event);
            this.$nextTick(() => {
                this.$emit('blur');
            });
        },

        onSearch(search) {
            if (search.length < 3) return false;

            this.search(search, this);
        },
        search: debounce(async (search, vm) => {
            const paramsIn = {
                self: vm,
                search,
                type: vm.type,
            };
            try {
                const res = await newApi('searchAccountsResident', paramsIn);
                vm.options = res;
            } catch (err) {
                console.error(err);
            }
        }, 350),
    },
};
</script>

<style lang="scss" scoped>
.select_company_title {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_select {
    min-height: 48px;
    height: inherit;
    .vs__dropdown-toggle {
        min-height: 48px;
        height: inherit;
    }
}

.search_select.vs--open {
    .vs__dropdown-toggle {
        overflow: hidden;
    }
    .vs__selected-options {
        overflow: hidden;
    }
    .vs__dropdown-toggle {
        overflow: hidden;
    }
}

.dictionary_egrul {
    .vs__dropdown-toggle {
        border-radius: 0px !important;
        display: flex;
        border: solid 1px #000;
    }
}

.top_select {
    line-height: 1 !important;
}
</style>
