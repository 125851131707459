<template>
    <div>
        <InfoUser />

        <PageHeader
            :title="$t('Документы на проверке')"
            :link-back="linkback"
        />

        <ProgressSection v-if="false" />

        <section class="main_documents">
            <div class="container">
                <DocumentBlockStatus
                    :is-subcontractor="isSubcontractor"
                    :id-subcontractor="idSubcontractor"
                />
            </div>
        </section>
    </div>
</template>

<script>
import ProgressSection from '@/views/components/shared/ProgressSection.vue';
import DocumentBlockStatus from '@/views/components/documents/DocumentBlockStatus';

export default {
    name: 'DocumentsStatus',

    components: {
        ProgressSection,
        DocumentBlockStatus,
    },

    data() {
        return {
            worksheet: null,
            isSubcontractor: false,
            idSubcontractor: null,
            linkback: '/documents/',
        };
    },

    created() {
        const { params } = this.$route;
        if (params && params.id) {
            this.isSubcontractor = true;
            this.idSubcontractor = params.id;
            this.linkback = `/subcontractor/${this.idSubcontractor}`;
        } else {
            this.isSubcontractor = false;
        }
    },
};
</script>

<style lang="scss" scoped></style>
