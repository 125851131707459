<template>
    <div>
        <div class="tender_main_wrap">
            <div class="tender_main_wrap_header">
                {{ $t("Условия получения доступа") }}
            </div>
            <div class="tender_main_wrap_body">
                <p v-html="$t('tender_text1')" />
                <ul>
                    <li>
                        {{ $t("tender_text2") }}
                        <a
                            href="/pdf/LicenseAgreement.pdf"
                            target="_blank"
                        >{{ $t("лицензионного договора") }}</a>
                        <p v-html="$t('tender_text3')" />
                    </li>
                    <li v-html="$t('tender_text4')" />
                    <li v-html="$t('tender_text5')" />
                </ul>
            </div>
        </div>
        <div class="trend_bottom_wrap">
            <div class="trend_bottom_submit_block">
                <BaseCheckbox v-model="confirm" :label="$t('tender_text6')" />

                <ui-button
                    :disabled="!confirm || !hasSaveData"
                    @click="accept"
                >
                    {{ $t("Подтвердить") }}
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AcceptTender',

    props: {
        accept: { type: Function, required: true },
        confirmVal: {},
        setConfirm: {},
        hasSaveData: { type: Boolean },
    },

    computed: {
        confirm: {
            set(val) {
                this.setConfirm(val);
            },
            get() {
                return this.confirmVal;
            },
        },
    },
};
</script>
