<template>
    <ui-modal
        :title="title"
        name="modal_window"
        width="900px"
        @before-open="beforeOpen"
    >
        <div class="modal_window">
            <p :class="[{ modal_window__message_center: isCenterMode }]">
                <span v-for="(text, index) in messages" :key="`msg_${index}`">
                    {{ text }}<br />
                </span>
            </p>
            <div class="modal_window__buttons">
                <ui-button type="full" class="mr-20" @click="closeModal()">
                    {{ cancelBtnText }}
                </ui-button>
                <ui-button type="full" @click="closeModal(true)">
                    {{ confirmBtnText }}
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
export default {
    name: 'ModalWindow',

    data() {
        return {
            beforeClose: () => {},
            messages: null,
            nameDoc: '',
            title: '',
            dopClass: '',
            isCenterMode: false,
            confirmBtnText: '',
            cancelBtnText: '',
        };
    },

    methods: {
        beforeOpen(event) {
            const {
                resultFunction,
                messages,
                title,
                dopClass,
                isCenterMode,
                confirmBtnText,
                cancelBtnText,
            } = event.params;
            this.beforeClose = resultFunction;
            this.messages = messages ?? [];
            this.title = title ?? '';
            this.dopClass = dopClass ?? '';
            this.isCenterMode = isCenterMode ?? false;
            this.confirmBtnText = confirmBtnText ?? $t('Подтвердить');
            this.cancelBtnText = cancelBtnText ?? $t('Отменить');
        },
        closeModal(result = false) {
            this.$modal.hide('modal_window');
            if (result && this.beforeClose) {
                this.beforeClose();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal_window {

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__message_center {
    text-align: center;
  }
}
</style>
