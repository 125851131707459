<template>
    <div>
        <div v-if="historyData.length">

            <ve-table
                :columns="columns"
                :table-data="tableData"
                :sort-option="sortOption"
                class="history-table"
            />

            <ve-pagination
                :page-index="paginator.pageNumber"
                :page-size="paginator.pageSize"
                :total="historyData.length"
                class="table-pagination"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
            />

        </div>

        <div v-else class="empty-data">Нет данных.</div>

    </div>
</template>

<script>
import { newApi } from '@/utils/new-api';

export default {
    name: 'HistoryPackage',

    data() {
        return {
            paginator: {
                pageNumber: 1,
                pageSize: 10,
            },
            sortOption: {
                sortAlways: true,
                sortChange: (params) => {
                    this.sortChange(params);
                },
            },
            columns: [
                {
                    key: 'a',
                    title: 'Дата отправки',
                    field: 'sendDate',
                    sortBy: 'desc',
                    renderBodyCell: ({ row }, h) => this.formatDate(row.sendDate),
                },
                {
                    key: 'b',
                    title: 'Дата завершения',
                    field: 'completionDate',
                    renderBodyCell: ({ row }, h) => this.formatDate(row.completionDate),
                },
                {
                    key: 'c',
                    title: 'Статус',
                    field: 'status',
                    align: 'left',
                    renderBodyCell: ({ row }, h) => (
                        <div>
                            <span class="history-table__status">{row.status}</span>

                            {row.reason?.visibleForContractor ? (
                                <div class="history-table__reason">
                                    <span>
                                        <b>Причина:</b> {row.reason.description}
                                    </span>
                                    <br />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ),
                },
                {
                    key: 'd',
                    title: 'Срок действия проверки',
                    field: 'expirationDate',
                    renderBodyCell: ({ row }, h) => this.formatDate(row.expirationDate, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    key: 'e',
                    title: 'Действие',
                    renderBodyCell: ({ row }, h) => (
                        <ui-button
                            type='table'
                            onClick={() => this.load(row)}
                        >
                                Скачать документы
                        </ui-button>
                    ),
                },
            ],
            historyData: [],
        };
    },

    computed: {
        tableData() {
            const start = (this.paginator.pageNumber - 1) * this.paginator.pageSize;

            const end = start + this.paginator.pageSize;

            return this.historyData.slice(start, end);
        },
    },

    async created() {
        await this.loadHistoryData();

        const params = {
            sendDate: this.columns[0].sortBy,
        };
        this.sortChange(params);
    },

    methods: {

        pageNumberChange(page) {
            this.paginator.pageNumber = page;
        },

        pageSizeChange(pageSize) {
            this.paginator.pageSize = pageSize;
        },

        convertHistoryData(data) {
            //  0=0000, 1=0001, 2=0010, 3=0011, 4=0100, 5=0101, 6=0110, 7=0111, 8=1000 и 9=1001.

            const STATUS_SHAPED = 1; // Пакет сформирован
            const STATUS_ASSIGNED = 2; // Проверка пакета назначена пользователю
            const STATUS_COMPLETED = 4; // Проверка завершена
            const STATUS_APPROVED = 8; // Результат проверки - одбрено (если не одобрено - проверка завершена, но этого бита нет)
            const STATUS_SEND = 16; // Пакет ушел в смбк

            if (data?.length > 0) {
                return data.map((item) => {
                    let { status } = item;
                    let comment = '';
                    if (item.status & STATUS_SHAPED) {
                        status = 'Пакет сформирован';
                    }
                    if (item.status & STATUS_ASSIGNED) {
                        status = 'Проверка пакета назначена пользователю';
                    }
                    if (item.status & STATUS_COMPLETED) {
                        status = ' Проверка завершена';
                    }
                    if (item.status & STATUS_APPROVED) {
                        status = 'Результат проверки - одобрено';
                    }
                    if (item.status & STATUS_SEND) {
                        status = 'Пакет отправлен';
                    }
                    if (item.status & STATUS_COMPLETED && !(item.status & STATUS_APPROVED)) {
                        status = 'Результат проверки - не одобрено';
                        comment = this.getComment(item.data);
                    }

                    return {
                        ...item,
                        status,
                        comment,
                        sendDate: item.sendDate
                            ? this.$moment(item.sendDate, 'YYYY-MM-DDTHH:mm:ss')
                            : null,
                        completionDate: item.completionDate
                            ? this.$moment(item.completionDate, 'YYYY-MM-DDTHH:mm:ss')
                            : null,
                        expirationDate: item.expirationDate
                            ? this.$moment(item.expirationDate, 'YYYY-MM-DDTHH:mm:ss')
                            : null,
                    };
                });
            }

            return [];
        },

        async loadHistoryData() {
            try {
                const paramsIn = { self };
                const result = await newApi('getPbiotHistoryData', paramsIn);

                this.historyData = this.convertHistoryData(result);
                this.$store.commit('SET_HISTORY', result);
            } catch (error) {
                console.log(error);
            }
        },

        async load(row) {
            const packageId = row.id;
            await this.downloadFile(packageId, 'questionnaire', 'Анкета.zip');
            await this.downloadFile(packageId, 'pbiot', 'Документы_по_ПБиОТ.zip');
            await this.downloadFile(packageId, 'ep', 'Документы_по_ООС.zip');
        },

        async downloadFile(packageId, partition, fileName) {
            try {
                const paramsIn = {
                    self: this,
                    packageId,
                    partition,
                };
                const data = await newApi('getPbiotFile', paramsIn);
                const blob = new Blob([data], { type: 'application/octect-stream' });
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            } catch (err) {
                console.error(err);
            }
        },

        getComment(data) {
            let comment = null;
            try {
                comment = JSON.parse(data).comment;
            } catch {
                comment = null;
            }
            return comment;
        },

        formatDate(date, format = 'YYYY-MM-DD HH:mm') {
            return date ? this.$moment(date, 'YYYY-MM-DDTHH:mm:ss').format(format) : '';
        },

        sortChange(params) {
            this.historyData.sort((a, b) => {
                if (params.sendDate) {
                    if (params.sendDate === 'asc') {
                        return a.sendDate > b.sendDate ? 1 : -1;
                    } if (params.sendDate === 'desc') {
                        return a.sendDate < b.sendDate ? 1 : -1;
                    }
                    return 0;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.history-table {
    &__status {
        padding: 7px 10px;
        background-color: #f1f4f7;
    }

    &__reason {
        margin: 10px 0px 0px 0px;
    }

    &__action-btn {
        height: 30px;
        padding: 0px 20px;
        background: #db2b37;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
    }
}
</style>
