<template>
    <div class="large-12 cell">
        <div class="grid-x grid-margin-x">
            <div class="large-3 cell large-offset-9">
                <ui-button
                    type="full"
                    class="mb-20"
                    @click="addDocument"
                >
                    {{ $t('Добавить документы') }}
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentsHistoryLink',
    data() {
        return {
            idSubcontractor: 0,
            isSubcontractor: false,
        };
    },

    methods: {
        addDocument() {
            if (this.isSubcontractor) {
                this.$router.push(`/subcontractor/${this.idSubcontractor}/documents/v2/create`);
            } else {
                this.$router.push('/documents/create');
            }
        },
    },
};
</script>

<style lang='scss' scoped></style>
