<template>
    <div class="large-12 cell">
        <div class="documents_group_wrap mt-24 mb-0">
            <div class="documents_group_header">
                {{ $t('Охрана труда и окружающей среды') }}
            </div>

            <div v-if="localForm" class="documents_group_body clearfix">
                <template v-for="(quest, index) in localForm">
                    <div v-if="quest.type == 0" :key="index + '_cf'" class="clearfix" />
                    <one-element-info
                        v-if="quest.type == 0"
                        :key="index + '_s'"
                        :tabindex="quest.tabindex"
                        :label-text="quest.name"
                        :placeholder-text="quest.placeholder"
                        :val="quest.value"
                        :info-text="quest.hint"
                        @change="quest.value = parseInt($event)"
                    />

                    <year-info
                        v-if="quest.type == 1"
                        :key="index + '_y'"
                        :label-text="quest.name"
                        :quest="quest"
                    />
                </template>
            </div>
        </div>
        <div class="worksheet_actions">
            <ui-button class="mr-20" @click="cancel">
                {{ $t('Отменить') }}
            </ui-button>

            <ui-button class="mr-20" :disabled="!checkIncident" @click="save">
                {{ $t('Сохранить') }}
            </ui-button>

            <div v-if="!checkIncident" class="errorPbiot">
                Внимание! Не все инциденты заполнены.
            </div>
        </div>
    </div>
</template>

<script>
import OneElementInfo from '@/views/components/pbiot/OneElementInfo.vue';
import YearInfo from '@/views/components/pbiot/YearInfo.vue';
import clearReactive from '@/helpers/clearReactive';
import { checkIncidentHelper } from '@/helpers/checkIncidentHelper';
import { convertDataToSaveInc } from '@/helpers/converterFilePBIOTtoDocFile';
import { newApi } from '@/utils/new-api';

export default {
    name: 'Incidents',

    components: {
        YearInfo,
        OneElementInfo,
    },

    data() {
        return {
            localForm: null,
        };
    },

    computed: {
        getIncidents() {
            return this.$store.getters.getIncidents;
        },

        checkIncident() {
            return checkIncidentHelper(this.localForm);
        },
    },

    watch: {
        getIncidents() {
            this.localForm = clearReactive(this.getIncidents);
        },
    },

    mounted() {
        this.localForm = clearReactive(this.getIncidents);
    },

    methods: {
        async save() {
            await newApi('saveIncidents', {
                self: this,
                sendData: convertDataToSaveInc(this.localForm),
            });

            await this.$store.dispatch('GET_PBIOT', { self: this });

            this.$store.commit('SET_CHECK_DOC_PBIOT', {
                code: 'pbiot_0006',
                value: true,
                getters: this.$store.getters,
            });
        },

        cancel() {
            this.localForm = clearReactive(this.getIncidents);
        },
    },
};
</script>

<style lang='scss' scoped>
.errorPbiot {
    color: #d5a72d;

    font-size: 15px;
    padding-top: 13px;
}

.mt-24 {
    margin-top: 24px;
}
.mb-0 {
    margin-bottom: 0px !important;
}

.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    overflow:hidden;
}

.clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}
</style>
