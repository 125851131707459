<template>
    <div
        id="accordion2"
        class="accordion"
        data-accordion
        data-allow-all-closed="true"
    >
        <div class="accordion-item " data-accordion-item>
            <a href="#" class="accordion-title" data-cy="form2">
                <span class="ico_marker" />
                {{ $t(title) }}
                <span class="ico_accordion" />
            </a>

            <div class="accordion-content" data-tab-content>
                <template v-if="factAddress">
                    <BaseInput
                        main-class="inline_form_input"
                        label="Индекс"
                        name="index"
                        :value="factAddress.index"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Страна"
                        name="country"
                        :value="currentCountryValue"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Регион"
                        name="region"
                        :value="currentRegionValue"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Город"
                        name="city"
                        :value="factAddress.city"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Населенный пункт"
                        name="locality"
                        :value="factAddress.locality"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Район"
                        name="disctrict"
                        :value="factAddress.disctrict"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Улица"
                        name="street"
                        :value="factAddress.street"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Дом"
                        name="home"
                        :value="factAddress.building"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Корпус"
                        name="block"
                        :value="factAddress.block"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Номер помещения"
                        name="room"
                        :value="factAddress.room"
                        disabled
                    />

                    <BaseInput
                        main-class="inline_form_input"
                        label="Этаж"
                        name="floor"
                        :value="factAddress.floor"
                        disabled
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
const def_data = {
    fullAddress: null,
    index: null,
    countryCode: null,
    regionCode: null,
    city: null,
    locality: null,
    disctrict: null,
    street: null,
    building: null,
    block: null,
    floor: null,
    room: null,
};

export default {
    name: 'LegalAddress',

    props: {
        allData: {
            type: Object,
            required: true,
        },

        title: {
            type: String,
            default: 'Юридический адрес',
        },

        blockInput: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        regData() {
            const { allData } = this;

            if (allData.data) {
                if (allData.data.legal) {
                    return allData.data.legal;
                }

                if (allData.data.individual) {
                    return allData.data.individual;
                }

                if (allData.data.nonResident) {
                    return allData.data.nonResident;
                }
            }

            return false;
        },

        factAddress() {
            return this.regData ? this.regData.factAddress : def_data;
        },

        currentCountryValue() {
            return this.factAddress.countryRef?.name || '';
        },

        currentRegionValue() {
            return this.factAddress.regionRef?.name || '';
        },
    },

    mounted() {
        const accordion2 = new Foundation.Accordion($('#accordion2'));
    },
};
</script>
