export default {
    namespaced: true,

    state: {
        loading: false,
        requestsPending: 0,
        suppress: false,
    },

    mutations: {
        pending(state) {
            if (state.requestsPending === 0) {
                state.loading = true;
            }

            state.requestsPending++;
        },

        done(state) {
            if (state.requestsPending >= 1) {
                state.requestsPending--;
            }

            if (state.requestsPending <= 0) {
                state.loading = false;
            }
        },
    },

    actions: {
        pending({ commit }) {
            commit('pending');
        },

        done({ commit }) {
            commit('done');
        },
    },
};
