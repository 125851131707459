<template>
    <div class="progress-bar">
        <div
            class="progress-bar__container"
            :class="{ 'progress-bar__container-scroll': isScrollView }"
        >
            <span> Объем загруженных файлов: </span>

            <div class="container">
                <div class="container__body">
                    <div
                        class="container__body--scale"
                        :style="{ width: `${progress}%`, background: backgroundColor }"
                    />
                </div>
                <div>{{ progressText }}</div>
            </div>
        </div>

        <div v-if="isThresholdReached" class="progress-bar__threshold-warning">
            <ui-icon
                :name="icons.roundError"
                class="threshold-warning__icon"
            />
            <span class="threshold-warning__text">
                Достигнут лимит объема загруженных файлов. В случае, если не все файлы были
                загружены, необходимо сжать pdf-файлы или сформировать архивы данных.
            </span>
        </div>

        <span v-else class="progress-bar__upload-warning">
            Обращаем внимание, что допустимый объем загружаемых файлов до {{ maxTotalSize }} МБ.
        </span>
    </div>
</template>

<script>
import icons from '@/helpers/icons';

const HundredPercent = 100;
const ScrollPositionAnchor = 300;

export default {
    name: 'PbiotFileUploadProgressBar',

    props: {
        fileUploadStatistic: { type: Object, required: true },
        normalUploadColor: { type: String, default: '#16bc62' },
        hundredPercentColor: { type: String, default: '#fdd4d3' },
    },

    data() {
        return {
            icons,
            scrollPosition: 0,
        };
    },

    computed: {
        isThresholdReached() {
            return this.fileUploadStatistic?.isThresholdReached;
        },

        progress() {
            return Math.round((this.totalSize / this.maxTotalSize) * 100);
        },

        totalSize() {
            return Number(this.fileUploadStatistic?.totalSize);
        },

        maxTotalSize() {
            return Number(this.fileUploadStatistic?.maxTotalSize);
        },

        backgroundColor() {
            return this.progress === HundredPercent
                ? this.hundredPercentColor
                : this.normalUploadColor;
        },

        progressText() {
            return this.progress === HundredPercent
                ? `${this.maxTotalSize} МБ`
                : `${this.totalSize} / ${this.maxTotalSize} МБ`;
        },

        isScrollView() {
            return this.scrollPosition > ScrollPositionAnchor;
        },
    },

    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },

    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-bar {
    &__container {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0px 0px 10px 0px;
    }

    &__container-scroll {
        position: fixed;
        display: block;
        font-size: 15px;
        z-index: 100;

        .container__body {
            margin-right: 10px;
        }
    }

    &__upload-warning {
        font-weight: bold;
    }

    &__threshold-warning {
        display: flex;
        align-items: center;
    }
}

.threshold-warning {
    &__text {
        font-weight: bold;
    }

    &__icon {
        color: "black";
        padding-right: 15px;
        width: 80px;
        height: 80px;
    }
}

.container {
    display: flex;
    align-items: center;

     &__body {
        height: 30px;
        width: 150px;
        border: 1px solid #aaa;
        display: flex;
        border-radius: 5px;
        margin: 0 10px 0 -10px;

        &--scale {
            border-radius: inherit;
        }
    }
}
</style>
