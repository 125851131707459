<template>
    <div>
        <div :class="[ { hasFileError: validationError }, 'upload_file', 'compositeFile' ]">
            <span v-if="documentToAdd.files.length > 1" class="compositeFile__uploadCount">
                {{ $t('Загружено:') }}
                {{ getNumberOfLoadedFilesForDocument() }} из {{ documentToAdd.files.length }}
            </span>

            <span class="upload_file__delete" @click="removeDocument" />

            <DocumentUploadForm
                v-for="file in documentToAdd.files"
                :key="file.name"
                :accept-file="file.fileExtensions"
                :label="file.name"
                :size-kb="file.maxSizeKB"
                :max-count="1"
                :add-file="buildAddFileHandler(file)"
                :error="validationError"
                :has-files="file.uploadedFiles"
                :has-delete="true"
            >

                <template #fileDescription>
                    <slot name="fileDescription" :file="file" />
                </template>

                <slot name="fileFooter" :file="file" />
            </DocumentUploadForm>
        </div>

        <template v-if="validationError && validationError.errorCodes">
            <div
                v-for="errorCode in validationError.errorCodes"
                class="errorCompositeDoc error_text"
            >
                {{ $t(errorCode) }}
            </div>
        </template>

        <div :key="documentToAdd.code + '_btn'">
            <ui-button
                :disabled="areAllFilesLoadedForDocument() || validationError"
                type="full"
                @click="validateFilesAndCreateDocument"
            >
                {{ $t('Отправить на проверку') }}
            </ui-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { newApi } from '@/utils/new-api';
import { TYPE_DIALOG_TITLE, TYPE_ERRORS } from '@/constant';

import DocumentUploadForm from './DocumentUploadForm.vue';

export default {
    name: 'DocumentSubmit',

    components: {
        DocumentUploadForm,
    },

    props: {
        documentToAddProp: { type: Object, required: true }, // DocumentSpecificationDocDTO
    },

    data() {
        return {
            documentToAdd: this.documentToAddProp,
            validationError: null, // DocumentCreateValidationErrorsDTO
        };
    },

    computed: {
        ...mapGetters(['getFiles']),
    },

    methods: {
        removeDocument() {
            this.$emit('removeDocument', this.documentToAdd);
        },

        getNumberOfLoadedFilesForDocument() {
            return this.documentToAdd.files.filter((it) => !_.isEmpty(it.uploadedFiles)).length;
        },

        areAllFilesLoadedForDocument() {
            return this.getNumberOfLoadedFilesForDocument() !== this.documentToAdd.files.length;
        },

        buildAddFileHandler(fileDescriptor) {
            const self = this;
            return (addFileEvent) => {
                fileDescriptor.uploadedFiles = addFileEvent.upLoadFiles;
                self.validationError = null;
                self.$forceUpdate(); // to force update value of getNumberOfLoadedFilesForDocument() method
            };
        },

        /**
         * @returns DocumentCreateRequestDTO
         */
        buildDocumentCreateRequest() {
            const d = this.documentToAdd;
            const attrs = this.documentToAdd.documentAttributes ?? {};
            return {
                code: d.code,
                reportingDate: attrs.reportingDate,
                reportingQuarter: attrs.reportingQuarter,
                reportingYear: attrs.reportingYear,
                patentDateStart: attrs.patentDateStart,
                patentDateEnd: attrs.patentDateEnd,
                customName: attrs.customName,
                files: d.files.map((f) => ({
                    code: f.code,
                    fileId: f.uploadedFiles[0].id,
                })),
            };
        },
        async validateFilesAndCreateDocument() {
            try {
                const documentCreateRequest = this.buildDocumentCreateRequest();

                const paramsIn = {
                    self: this,
                    sendData: documentCreateRequest,
                };
                await newApi('validateSavingDocuments', paramsIn).catch();

                // update list of loaded documents (reload doc specification) to make confirmation warnings work correctly
                await this.$store.dispatch('LOAD_DOCUMENT_SPEC', { self: this });

                const msg = 'Документы загружены';
                this.$modal.show('info', {
                    type: TYPE_ERRORS.SUCCESS,
                    msg,
                    title: TYPE_DIALOG_TITLE.SUCCESS,
                });

                this.removeDocument();
            } catch (e) {
                this.validationError = this.getFiles;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.compositeFile {
    position: relative;
    .composite_file {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__uploadCount {
        font: normal normal 13px/1.43 OpenSansRegular, sans-serif;
        font-stretch: normal;
        letter-spacing: 0.26px;
        color: #989898;
        z-index: 9;
        right: 43px;
        top: 26px;
        font-size: 14px;
        display: inline-block;
        position: absolute;
        margin-bottom: 0;
        text-align: right;
    }

    .upload_file__delete {
        z-index: 9;
        top: 30px;
    }
}

.upload_file {
    &__delete {
        cursor: pointer;
        position: absolute;
        background: url(~@/assets/ico/ico-close-gray.svg);
        right: 24px;
        top: 27px;
        background-size: contain;
        width: 12px;
        height: 12px;
        display: block;
    }
}
</style>
