import { sortBy } from 'lodash';

import { saveSelectDoc } from '@/helpers/pbiotHelper';

export const mutations = {
    SET_PBIOT(state, data) {
        const tempData = data;
        if (tempData.docs) {
            tempData.docs = sortBy(tempData.docs, ['id']);
        }
        state.allData = tempData;
    },

    SET_STATUS_PBIOT(state, status) {
        state.statusInfo = status;
        state.status = status.status;
    },

    SET_CHECK_DOC_PBIOT_ALL(state) {
        state.documentChecks = state.documentChecks.map((item) => {
            if (item.code !== 'questionnaire_1') {
                return {
                    ...item,
                    value: false,
                };
            }
            return {
                ...item,
            };
        });
    },

    SET_HISTORY(state, data = []) {
        state.history = data;
    },

    SET_CHECK_DOC_PBIOT(state, { code, value, getters }) {
        if (code == 'questionnaire_1') {
            return;
        }

        const findIndex = state.documentChecks.findIndex((item) => item.code == code);

        const codeInc = 'q1';
        const findValueInc = getters.getIncidents.find((item) => item.code == codeInc);

        if (findValueInc.value >= 51 && code == 'pbiot_0006') {
            state.documentChecks[findIndex].value = true;
        } else {
            state.documentChecks[findIndex].value = value;
        }

        saveSelectDoc(state.documentChecks);
    },
};
