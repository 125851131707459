<template>
    <ui-modal
        :title="title"
        name="create_yur_subcontractor"
        width="500"
        @before-open="beforeOpen"
    >
        <form>
            <div class="form_block">
                <SearchEgrul
                    :form="searchData"
                    field-name="search"
                    :v-error="$v.searchData.search"
                    :type="accountType"
                    :force-error="!checkFree && hasCheck"
                    force-text-error="Контрагент с таким ИНН уже добавлен."
                    :show-main-error="false"
                    @blur="checkDublData"
                />
            </div>
            <div class="form_block">
                <BaseInput
                    v-model="searchData.inn"
                    label="ИНН"
                    name="inn"
                    placeholder="ИНН"
                    disabled
                />
                <BaseInput
                    v-model="searchData.ogrn"
                    label="ОГРН"
                    name="ogrn"
                    placeholder="ОГРН"
                    disabled
                />
                <BaseInput
                    v-if="accountType === 'LEGAL'"
                    v-model="searchData.kpp"
                    label="КПП"
                    name="kpp"
                    placeholder="КПП"
                    :disabled="
                        (searchData.search && searchData.tempkpp) ||
                            (!searchData.search && !searchData.tempkpp)
                    "
                    error-text="Введите КПП"
                    :v-error="$v.searchData.kpp"
                    :mask-check="'#########'"
                    @blur="checkDublData"
                />
                <BaseInput
                    v-model="searchData.address"
                    label="Юридический адрес"
                    name="address"
                    placeholder="Адрес"
                    classes="no_margin"
                    textarea
                    disabled
                />
                <base-checkbox
                    v-model="searchData.isProducer"
                    label="Является производителем"
                    name="isProducer"
                    class="no_margin"
                />
                <base-checkbox
                    v-model="searchData.isSubContractor"
                    label="Является субподрядчиком"
                    name="isSubContractor"
                    class="no_margin"
                />
            </div>
            <ui-button
                :disabled="!canSubmit"
                type="full"
                @click="sendData"
            >
                {{ $t("Добавить контрагента") }}
            </ui-button>
        </form>
    </ui-modal>
</template>

<script>
// TODO допилитит это что бы по красоте было
import { required, maxLength, minLength } from 'vuelidate/lib/validators';

import { ACCOUNT_TYPE, SUBCONTRACTOR_LINK_TYPE } from '@/constant';
import SearchEgrul from '@/views/components/base/SearchEgrul';
import BaseCheckbox from '@/views/components/base/BaseCheckbox.vue';
import { newApi } from '@/utils/new-api';

export default {
    name: 'ModalCreateSubcontractor',

    components: {
        SearchEgrul,
        BaseCheckbox,
    },

    props: {
        subcontractors: {
            type: Array,
        },
        producers: {
            type: Array,
        },
    },

    data() {
        return {
            searchData: {
                search: null,
                inn: '',
                address: null,
                kpp: null,
                ogrn: null,
                tempkpp: null,
                isSubContractor: false,
                isProducer: false,
            },
            checkFree: false,
            hasCheck: false,
            accountType: '',
            subcontractorLinkType: '',
        };
    },

    validations: {
        searchData: {
            search: {
                required,
            },
            kpp: {
                required,
                maxLength: maxLength(9),
                minLength: minLength(9),
            },
        },
    },

    computed: {
        title() {
            return `${this.$t('Добавление контрагента')} (${this.accountType === 'LEGAL' ? this.$t('ЮЛ') : this.$t('ИП')})`;
        },
        canSubmit() {
            return this.checkFree
          && (
              (this.searchData.kpp && this.accountType === ACCOUNT_TYPE.LEGAL)
              || this.accountType === ACCOUNT_TYPE.INDIVIDUAL
          )
          && (this.searchData.isSubContractor || this.searchData.isProducer);
        },
    },

    watch: {
        'searchData.search': function (val) {
            if (val) {
                this.searchData.inn = val.inn;
                this.searchData.kpp = val.kpp;
                this.searchData.tempkpp = val.kpp;
                this.searchData.ogrn = val.ogrn;
                this.searchData.address = val.address;
            }
        },
    },

    methods: {
        resetData() {
            this.searchData = {
                search: null,
                inn: '',
                address: null,
                kpp: null,
                ogrn: null,
                tempkpp: null,
                isSubContractor: false,
                isProducer: false,
            };
        },
        beforeOpen(event) {
            this.resetData();
            this.accountType = event.params.accountType;
            this.subcontractorLinkType = event.params.subcontractorLinkType;
            if (event.params.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.SUBCONTRACTOR) {
                this.searchData.isSubContractor = true;
                this.searchData.isProducer = false;
            }
            if (event.params.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.PRODUCER) {
                this.searchData.isSubContractor = false;
                this.searchData.isProducer = true;
            }
        },
        async sendData() {
            let paramsIn = {};
            if (this.accountType === 'LEGAL') {
                paramsIn = {
                    self: this,
                    sendData: {
                        type: this.accountType,
                        inn: this.searchData.inn,
                        kpp: this.searchData.kpp,
                        ogrn: this.searchData.ogrn,
                        isSubContractor: this.searchData.isSubContractor,
                        isProducer: this.searchData.isProducer,
                    },
                };
            } else if (this.accountType === 'INDIVIDUAL') {
                paramsIn = {
                    self: this,
                    sendData: {
                        type: this.accountType,
                        inn: this.searchData.inn,
                        ogrn: this.searchData.ogrn,
                        isSubContractor: this.searchData.isSubContractor,
                        isProducer: this.searchData.isProducer,
                    },
                };
            }

            try {
                this.$modal.hide('create_yur_subcontractor');
                await newApi('addSubcontractors', paramsIn);
                this.$emit('reload');
            } catch (err) {
                console.error(err);
                this.$modal.hide('create_yur_subcontractor');
            }
        },
        checkDublData() {
            const listToSearch = this.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.SUBCONTRACTOR
                ? this.subcontractors : this.producers;
            const index = listToSearch.findIndex((item) => {
                const searchInn = this.searchData.inn;
                const searhcKpp = this.searchData.kpp;
                const searchOgrn = this.searchData.ogrn;
                const { inn, kpp, ogrn } = item;

                if (
                    inn == searchInn
          && ((kpp && kpp == searhcKpp) || !kpp)
          && ((ogrn && ogrn == searchOgrn) || !ogrn)
                ) {
                    return true;
                }
                return false;
            });

            this.checkFree = true;

            if (index !== -1) {
                this.checkFree = false;
                this.hasCheck = true;
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.form_block {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 1px #f2f4f5
}
</style>
