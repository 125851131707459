<template>
    <div class="main-layout">
        <loader />

        <section class="main-layout__header">
            <top-header />
            <bottom-header />
        </section>

        <section
            class="main-layout__content wrapper mb-20"
            :class="{'main-layout__content--mt': showTechnicalWorkWarning }"
        >
            <router-view :key="$route.path" />
        </section>

        <the-footer />
    </div>
</template>

<script>
import { appParams, getAppConfig } from '@/utils/appConfig';
import Loader from '@/views/components/shared/Loader.vue';
import TopHeader from '@/views/components/shared/TopHeader.vue';
import BottomHeader from '@/views/components/shared/BottomHeader.vue';
import TheFooter from '@/views/components/shared/TheFooter.vue';

export default {
    components: {
        Loader,
        TopHeader,
        BottomHeader,
        TheFooter,
    },

    data() {
        return {
            showTechnicalWorkWarning: false,
        };
    },

    mounted() {
        this.showTechnicalWorkWarning = getAppConfig(appParams.SHOW_TECHNICAL_WORK_WARNING, false);
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.main-layout {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 1fr;

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }

    &__content {
        margin-top: 175px;

        &--mt {
            margin-top: 235px;
        }
    }
}
</style>
