<template>
    <footer class="footer wrapper">
        <div class="container">
            <div class="flex justify-space-between align-center">
                <ul class="flex justify-space-between align-center">
                    <li class="footer__list mr-20">
                        <a
                            href="https://lukoil.ru/Company/LegalInformation/PersonalDataProcessingPolicy"
                            target="_blank"
                        >
                            {{ $t('Обработка персональных данных') }}
                        </a>
                    </li>

                    <li class="footer__list mr-20">
                        <router-link to="/faq">
                            {{ $t('supportTextFaq') }}
                        </router-link>
                    </li>

                    <li class="footer__list">
                        <a href="https://lukoil.ru/Company/contacts" target="_blank">
                            {{ $t('Контакты') }}
                        </a>
                    </li>
                </ul>

                <div class="footer__text">
                    <p>&copy; {{ nowYear }} {{ $t('ЛУКОЙЛ') }}</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'TheFooter',

    data() {
        return {
            nowYear: '',
        };
    },

    created() {
        this.nowYear = this.$moment().format('YYYY');
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.footer {
    width: 100%;
    padding: 40px 0;
    background-color: $black;

    &__list {
        text-transform: uppercase;

        & > a:hover {
            color: $primary;
        }
    }

    &__text {
       color: $white;
    }
}
</style>
