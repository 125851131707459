<template>
    <div v-if="textInfo && showWarning" v-html="textInfo" />
</template>

<script>
import { appParams, getAppConfig } from '@/utils/appConfig';
import { UNIVERSAL_FORMAT_DATE } from '@/constant';
import { newApi } from '@/utils/new-api';

const USN_XML = 'UsnXML'; // Декларация по УСН за последний отчетный период
const UsnPDF = 'UsnPDF';

const NAL_PRIB = 'IncomeTaxReturnQuarterXML'; // Декларация по налогу на прибыль организаций за последний налоговый период
const VAT_XML = 'VatXML'; // VatXML -- Декларация по НДС за последний отчетный период
const ESHN_XM = 'EshnXML'; // EshnXM Декларация по ЕСХН за последний отчетный период
const EshnPDF = 'EshnPDF';

export default {
    name: 'WarningInformationFile',

    props: {
        typeDoc: {},
        defaultDate: { type: String },
        registrationDateProp: { type: String },
    },

    data() {
        return {
            testData: null,
            registrationDate: null,
            showWarning: false,
        };
    },

    computed: {
        dateNowConvert() {
            if (this.defaultDate) {
                return this.defaultDate;
            }
            return this.$moment().format(UNIVERSAL_FORMAT_DATE);
        },
        dateCreate() {
            return this.$moment(this.registrationDate, 'YYYY-MM-DDTHH:mm:ss').format(
                UNIVERSAL_FORMAT_DATE,
            );
        },
        textInfo() {
            const { code } = this.typeDoc;
            const dateNow = this.dateNowConvert
                ? this.$moment(this.dateNowConvert, UNIVERSAL_FORMAT_DATE)
                : this.$moment();
            const nowYear = dateNow.format('YYYY');
            // console.log("сейчас год", nowYear);
            const dateCreate = this.$moment(this.dateCreate, UNIVERSAL_FORMAT_DATE);
            const basetext = 'На данный момент необходима налоговая декларация за ';
            // console.log(code);
            switch (code) {
            case UsnPDF:
            case USN_XML: {
                // Для организаций, созданных с 1 по 31 декабря предыдущего года, уведомление не появляется.
                if (
                    this.dateIsBetween(
                        dateCreate,
                        `01.12.${nowYear - 1}`,
                        `31.12.${nowYear - 1}`,
                    )
                ) {
                    return '';
                }
                // В случае направления документов после 03.04.2021, необходимо загрузить налоговую декларацию за 2020 год.
                if (this.isAfterDate(dateNow, `30.04.${nowYear}`)) {
                    return `${basetext}<span> ${nowYear - 1} год</span>`;
                }
                // В случае направления документов с 01.01.2022 до 03.04.2022, необходимо загрузить налоговую декларацию также за 2020 год.
                if (this.dateIsBetween(dateCreate, `01.01.${nowYear}`, `30.04.${nowYear}`)) {
                    return `${basetext}<span>${nowYear - 2} год</span>`;
                }
                return '';
            }
            case NAL_PRIB: {
                // Налог на прибыль организаций
                // Для организаций, созданных с 1 по 31 декабря предыдущего года, уведомление не появляется (должна быть техническая возможность загрузить расчет на отчетный период).
                if (
                    this.dateIsBetween(
                        dateCreate,
                        `01.12.${nowYear - 1}`,
                        `31.12.${nowYear - 1}`,
                    )
                ) {
                    return '';
                }

                // В случае направления документов после 01.04.2021, необходимо загрузить налоговую декларацию за 2020 год.

                if (this.isAfterDate(dateNow, `30.04.${nowYear}`)) {
                    return `${basetext}<span>${nowYear - 1} год</span>`;
                }

                // В случае направления документов с 01.01.2022 до 01.04.2022, необходимо загрузить налоговую декларацию за 2020 год.
                if (this.dateIsBetween(dateNow, `01.01.${nowYear}`, `30.04.${nowYear}`)) {
                    return `${basetext}<span>${nowYear - 2} год</span>`;
                }

                return '';
            }
            case VAT_XML: {
                // Налог на добавленную стоимость.
                if (
                    this.dateIsBetween(
                        dateCreate,
                        `01.12.${nowYear - 1}`,
                        `31.12.${nowYear - 1}`,
                    )
                ) {
                    return '';
                }
                // В случае направления документов с 01.05.2021 до 31.07.2021, необходимо загрузить налоговую декларацию за 1 квартал 2021 года.
                if (this.dateIsBetween(dateNow, `01.06.${nowYear}`, `31.08.${nowYear}`)) {
                    return `${basetext}<span>1 квартал ${nowYear} года</span>`;
                }

                // В случае направления документов с 01.08.2021 до 31.10.2021, необходимо загрузить налоговую декларацию за 2 квартал 2021 года.
                if (this.dateIsBetween(dateNow, `01.09.${nowYear}`, `31.11.${nowYear}`)) {
                    return `${basetext}<span>2 квартал ${nowYear} года</span>`;
                }
                // В случае направления документов с 01.11.2021 до 31.01.2022, необходимо загрузить налоговую декларацию за 3 квартал 2021 года .
                if (this.dateIsBetween(dateNow, `01.12.${nowYear}`, `31.12.${nowYear}`)) {
                    return `${basetext}<span>3 квартал ${nowYear} года</span>`;
                }
                if (this.dateIsBetween(dateNow, `01.01.${nowYear}`, `28.02.${nowYear}`)) {
                    return `${basetext}<span>3 квартал ${nowYear - 1} года</span>`;
                }

                // В случае направления документов с 01.02.2022 до 30.04.2022, необходимо загрузить налоговую декларацию за 4 квартал 2021 года.
                if (this.dateIsBetween(dateNow, `01.03.${nowYear}`, `31.05.${nowYear}`)) {
                    return `${basetext}<span>4 квартал ${nowYear - 1} года</span>`;
                }
                return '';
            }
            case EshnPDF:
            case ESHN_XM: {
                if (
                    this.dateIsBetween(
                        dateCreate,
                        `01.12.${nowYear - 1}`,
                        `31.12.${nowYear - 1}`,
                    )
                ) {
                    return '';
                }
                // В случае направления документов после 03.04.2021, необходимо загрузить налоговую декларацию за 2020 год.
                if (this.isAfterDate(dateNow, `30.04.${nowYear}`)) {
                    return `${basetext}<span>${nowYear - 1} год</span>`;
                }
                // В случае направления документов с 01.01.2022 до 03.04.2022, необходимо загрузить налоговую декларацию также за 2020 год.
                if (this.dateIsBetween(dateNow, `01.01.${nowYear}`, `30.04.${nowYear}`)) {
                    return `${basetext}<span>${nowYear - 2} год</span>`;
                }
                return '';
            }
            default:
                return false;
            }
        },
    },

    mounted() {
        this.showWarning = getAppConfig(appParams.SHOW_WARNING_MES, false);
        if (this.showWarning) {
            this.init();
        }
    },

    methods: {
        async init() {
            if (this.registrationDateProp) {
                this.registrationDate = this.registrationDateProp;
                return;
            }
            try {
                const allData = await newApi('getProfileForm', { self: this });
                this.registrationDate = allData?.registrationDate;
            } catch (err) {
                console.error(err);
            }
        },
        /**
         * диавпозон между датами
         */
        dateIsBetween(dNow, d1, d2, format = UNIVERSAL_FORMAT_DATE) {
            return (
                dNow.diff(this.$moment(d1, format), 'd') >= 0
                && this.$moment(d2, format).diff(dNow, 'd') >= 0
            );
        },
        isAfterDate(dNow, d1, format = UNIVERSAL_FORMAT_DATE) {
            return dNow.diff(this.$moment(d1, format), 'd') > 0;
        },
    },
};
</script>
