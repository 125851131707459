export const checkShowPageNoLogin = (to, from, next) => {
    if (to && to.meta && 'showPage' in to.meta) {
        if (to.meta.showPage == false) {
            next('/account');
        } else {
            next();
        }
    } else {
        next();
    }
};
