import Vue from 'vue';

const DEFAULT_ERROR_MESSAGE = 'Неизвестная ошибка, ответ пустой';
const NO_RESPONSE_ERROR_MESSAGE = 'Ошибка нет ответа';
const NETWORK_ERROR_MESSAGE = 'Сервер не отвечает! Соединение потеряно!';
const HANDLER_ERROR_MESSAGE = 'Ошибка в обработчике ошибок!';

function parseErrorMessage(error) {
    if (error.response.data.debugMessage) {
        return error.response.data.debugMessage;
    }

    if (error.response.data.message) {
        return error.response.data.message;
    }

    return DEFAULT_ERROR_MESSAGE;
}

function showModal(modalName = '', params = {}) {
    if (!Vue.prototype) {
        throw new Error('Vue instance error');
    }

    Vue.prototype.$modal.show(modalName, params);
}

export const errorHandler = (infoError) => {
    const { res, title = 'Ошибка' } = infoError;

    try {
        let errorMessage = NO_RESPONSE_ERROR_MESSAGE;
        let errorCode = '';
        let requestUid = '';
        let filesData = [];

        if (res.response?.data) {
            const {
                code, rqUid, files, ...data
            } = res.response.data;
            errorMessage = parseErrorMessage(res);
            errorCode = code || '';
            requestUid = rqUid || '';
            filesData = files || [];
        }

        if (res.message === 'Network Error') {
            errorMessage = NETWORK_ERROR_MESSAGE;
        }

        const modalName = errorCode === 'NOTIFICATION' ? 'MainNotificationModal' : 'ErrorModal';
        const modalParams = errorCode === 'NOTIFICATION'
            ? {
                message: errorMessage,
                requestUid,
                files: filesData,
            }
            : {
                title,
                url: res.config?.url,
                errorMessage,
                requestBody: res.config?.data,
                requestParams: res.config?.params,
                requestUid,
            };

        showModal(modalName, modalParams);
    } catch (e) {
        showModal('ErrorModal', {
            title,
            url: res.config?.url,
            errorMessage: `${HANDLER_ERROR_MESSAGE} ${e}`,
        });
    }
};

