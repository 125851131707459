<template>
    <div class="grid-x grid-margin-x">
        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Укажите тип документа</label>
        </div>

        <div class="large-8 small-8 cell mb-12">
            <input
                v-model="customName"
                type="text"
                class="customName"
                maxlength="255"
                :disabled="disable"
            />
        </div>

        <template v-if="documentsApplicableToAddFiltered">
            <div class="large-12 cell mb-12">
                <DocumentCreateV2Buttons
                    :documents-in-specification="documentsApplicableToAddFiltered"
                    :get-confirmation-dialog-props="getConfirmationDialogProps"
                    @update:documentsUserSelectedToAdd="
                        $emit('update:documentsUserSelectedToAdd', $event)
                    "
                />
            </div>
        </template>
    </div>
</template>

<script>
import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import DocumentUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentUploadForm.vue';

export default {
    name: 'OtherDocumentUiHandler',

    components: {
        DocumentUploadForm,
        DocumentCreateV2Buttons,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },

        selectedDocSpec: {
            // DocumentSpecificationGroupDTO + group property
            type: Object,
        },
    },

    data() {
        return {
            customName: null,
        };
    },

    computed: {
        /**
         * @returns DocumentSpecificationDocDTO with resolved names and additional document attributes (like year, period, etc)
         */
        documentsApplicableToAddFiltered() {
            if (this.customName) {
                return this.selectedDocSpec.documents.map((it) => {
                    const doc = _.cloneDeep(it);
                    doc.name = this.resolvePlaceholders(doc.name);
                    doc.files.forEach((f) => (f.name = this.resolvePlaceholders(f.name)));
                    doc.documentAttributes = {
                        customName: this.customName,
                    };
                    return doc;
                });
            }
            return [];
        },
    },

    methods: {
        resolvePlaceholders(val) {
            return this.customName;
        },

        getConfirmationDialogProps(docSpecification) {
            return {
                nameDoc: docSpecification.name,
                msg: [
                    'В раздел «Иные документы» допускается загрузка типов документов, отличных от поименованных в других разделах.',
                    'Документы, загруженные c нарушением данного правила, рассмотрению не подлежат.',
                ],
                confirmBtnText: 'Продолжить',
                title: 'Внимание!',
            };
        },
    },
};
</script>
