/**
 * для упрощение работы решил сделать обретку над своим сервисом апи
 */
import { newApi } from '@/utils/new-api';

export const serviceApi = {
    acceptActualDocument: async (self, documentTypeCode = 'OwnerChain') => {
        const paramsIn = {
            self,
            sendData: { documentTypeCode },
        };
        return await newApi('actualDocumentAccept', paramsIn);
    },
};
