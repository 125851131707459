<template>
    <ui-modal
        :title="$t('supportText')"
        name="modalSupport"
        width="600"
    >
        <div class="lna-modal">
            <p>
                <span>{{ $t("supportText0") }} </span>
                <a href="mailto:lkk@lukoil.com">lkk@lukoil.com</a>
            </p>
            <p>
                <span>{{ $t("supportText4") }} </span>
                <a href="mailto:akt_luktech@lukoil.com"> akt_luktech@lukoil.com</a>
            </p>
            <p>
                <span>{{ $t("supportText5") }} </span>
                <a href="mailto:lt.as@lukoil.com"> lt.as@lukoil.com</a>
            </p>
            <p>{{ $t("supportText1") }}</p>
            <p>{{ $t("supportText2") }}</p>
            <p>
                <a :href="link" target="_blank" download>
                    {{ $t("supportText3") }}
                </a>
            </p>
            <p v-if="showInstructionPbiot">
                <a href="/pdf/Manual_LK_PBIOT.pdf" target="_blank" download>
                    {{ $t("supportText31") }}
                </a>
            </p>
            <p>
                <a href="https://lukoil.ru/FileSystem/9/641827.pdf" target="_blank" download>
                    {{ $t("personalData") }}
                </a>
            </p>
            <p>
                <a href="https://lukoil.ru/FileSystem/9/641825.pdf" target="_blank" download>
                    {{ $t("confidentialityAgreement") }}
                </a>
            </p>
            <p v-if="showInstructionPbiot">
                <router-link to="/faq">
                    {{ $t("supportTextFaq") }}
                </router-link>
            </p>
        </div>
    </ui-modal>
</template>
<script>
import { MAIN_HOST } from '@/constant';
import { appParams, getAppConfig } from '@/utils/appConfig';

export default {
    name: 'ModalSupport',
    data() {
        return {
            link: '',
            showInstructionPbiot: false,
        };
    },
    created() {
        let mainhost = MAIN_HOST || '/';
        const findSleshEnd = mainhost[mainhost.length - 1];
        if (findSleshEnd !== '/') {
            mainhost += '/';
        }

        this.link = `${mainhost}api/public/files/usermanual?lang=RU`;
        this.showInstructionPbiot = getAppConfig(
            appParams.SHOW_PBIOT_INSTRUCTION,
            false,
        );
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/_variables";

.lna-modal {

  p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  a {
      color: $link;
      text-decoration: underline;
      text-transform: uppercase;
  }
}
</style>
