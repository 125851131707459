<template>
    <div>
        <div class="legal-finance-block__control-wrapper">
            <div class="control-wrapper__finance-input">
                <div class="finance-input__label">Сумма дохода</div>

                <div>
                    <input v-model="totalIncome" class="finance-input__input" type="text" />

                    <div v-if="incomeToolTip" v-tooltip="incomeToolTip" class="finance-input__info">
                        <ui-icon :name="icons.infoBox" width="40" height="40" />
                    </div>
                </div>
            </div>

            <div class="control-wrapper__finance-input">
                <div class="finance-input__label">Сумма налога</div>

                <div>
                    <input
                        v-model="professionalTaxDeduction"
                        type="text"
                        class="finance-input__input"
                    />

                    <div v-if="taxToolTip" v-tooltip="taxToolTip" class="finance-input__info">
                        <ui-icon :name="icons.infoBox" width="40" height="40" />
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div v-if="financeData.length">

                <ve-table
                    :columns="columns"
                    :table-data="financeData"
                    border-x
                />

            </div>
            <div v-else class="empty-data">{{ $t('NoData') }}</div>
        </div>

        <div class="legal-finance-block__footer">
            <ui-button
                :disabled="isDisabledButton"
                type="full"
                class="mr-20"
                @click="reset"
            >
                {{ $t('Сбросить') }}
            </ui-button>

            <ui-button
                :disabled="$v.$invalid"
                type="full"
                @click="save"
            >
                {{ $t('Сохранить') }}
            </ui-button>
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash';
import { required, decimal } from 'vuelidate/lib/validators';

import icons from '@/helpers/icons';

export default {
    name: 'FinanceBlockTable',

    props: {
        financeData: { type: Array },
        year: { type: String | Number | null, required: true },
        incomeToolTip: { type: String },
        taxToolTip: { type: String },
    },

    data() {
        return {
            icons,
            totalIncome: null,
            professionalTaxDeduction: null,
            columns: [
                {
                    key: uniqueId('radioColumn'),
                    title: 'Год',
                    field: 'year',
                },
                {
                    key: uniqueId('totalIncome'),
                    title: 'Доход',
                    field: 'totalIncome',
                },
                {
                    key: uniqueId('radioColumn'),
                    title: 'Вычет',
                    field: 'professionalTaxDeduction',
                },
            ],
        };
    },

    validations: {
        totalIncome: {
            required,
            decimal,
        },
        professionalTaxDeduction: {
            required,
            decimal,
        },
        year: {
            required,
        },
    },

    computed: {
        isDisabledButton() {
            return !this.totalIncome && !this.professionalTaxDeduction;
        },

    },

    created() {
        this.$emit('load-data');
    },

    methods: {
        reset() {
            this.totalIncome = null;
            this.professionalTaxDeduction = null;
        },

        save() {
            this.$emit('save-finance-data', {
                year: this.year,
                totalIncome: Number(this.totalIncome),
                professionalTaxDeduction: Number(this.professionalTaxDeduction),
            });

            this.reset();
        },

    },
};
</script>

<style lang="scss">

.legal-finance-block {
    &__control-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        margin-top: 20px;
    }
}

.control-wrapper {
    &__finance-input {
        display: flex;
        height: 60px;
    }
}

.finance-input {
    &__label {
        margin: 10px 10px 0px 0px;
        font-size: 16px;
    }

    &__input {
        display: block;
        height: 45px;
        border: solid 1px #000000;
    }

    &__info {
        position: relative;
        top: -42px;
        right: -120px;
        cursor: pointer;
        height: 40px;
        width: 40px;
    }
}

.tooltip .tooltip-inner {
    max-width: 400px;
    background-color: #db2b37 !important;
}
</style>
