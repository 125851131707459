import Vue from 'vue';

import { MAIN_HOST } from '../constant';

export function setupConfig(config) {
    config.baseURL = MAIN_HOST;
    config.timeout = 1000 * 60 * 5;
    config.headers = {
        'accept-language':
      localStorage.getItem('lang')
      || process.env.VUE_APP_I18N_LOCALE
      || 'ru',
    };

    const token = Vue.prototype?.$keycloak?.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
}
