<template>
    <div class="grid-x grid-margin-x">
        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Выберите отчетный год</label>
        </div>
        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :value="year"
                :on-change="selectYear"
                :options="optionsYear"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('Выберите отчетный год')"
                :force-disabled="disable"
            />
        </div>

        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Период</label>
        </div>
        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :on-change="selectPeriod"
                :value="period"
                :options="optionPeriod"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('Выберите период')"
                :force-disabled="disable"
            />
        </div>

        <template v-if="documentsApplicableToAddFiltered">
            <div class="large-12 cell mb-12">
                <DocumentCreateV2Buttons
                    :documents-in-specification="documentsApplicableToAddFiltered"
                    @update:documentsUserSelectedToAdd="
                        $emit('update:documentsUserSelectedToAdd', $event)
                    "
                />
            </div>
        </template>
    </div>
</template>

<script>
import SimpleDictionary from '@/views/components/base/SimpleDictionary.vue';
import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import { UNIVERSAL_FORMAT_DATE } from '@/constant';
import {
    optionPeriodFilterByCurrentDate,
    periodCalculateSv,
    replacePeriod,
} from '@/helpers/createDocumentHelper';

export default {
    name: 'CalculateSvDocumentUiHandler',

    components: {
        DocumentCreateV2Buttons,
        SimpleDictionary,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            // DocumentSpecificationGroupDTO + group property
            type: Object,
        },
    },

    data() {
        return {
            year: null,
            period: null,
        };
    },

    computed: {
        dateNow() {
            // для теста
            return this.$moment();
        },

        optionsYear() {
            const nowY = this.$moment().format('YYYY');
            const listYear = [nowY, nowY - 1, nowY - 2, nowY - 3];
            return listYear.map((item, index) => ({
                id: index,
                code: item,
                name: item,
            }));
        },

        optionPeriod() {
            const { year, dateNow } = this;
            return year
                ? optionPeriodFilterByCurrentDate(
                    year.code,
                    dateNow.format(UNIVERSAL_FORMAT_DATE),
                    periodCalculateSv,
                )
                : [];
        },

        /**
         * @returns DocumentSpecificationDocDTO with resolved names and additional document attributes (like year, period, etc)
         */
        documentsApplicableToAddFiltered() {
            if (this.year && this.period) {
                return this.selectedDocSpec.documents.map((it) => {
                    const doc = _.cloneDeep(it);
                    doc.name = this.resolvePlaceholders(doc.name);
                    doc.files.forEach((f) => (f.name = this.resolvePlaceholders(f.name)));
                    doc.documentAttributes = {
                        reportingYear: this.year.code,
                        reportingQuarter: this.period.code,
                    };
                    return doc;
                });
            }
            return [];
        },
    },

    mounted() {
        this.selectYear(this.optionsYear[0]);
    },

    methods: {
        selectYear(val) {
            this.year = val;
            this.period = this.optionPeriod[this.optionPeriod.length - 1];
        },

        selectPeriod(val) {
            this.period = val;
        },

        resolvePlaceholders(val) {
            const { year, period } = this;
            let res = val;
            if (year) {
                res = replacePeriod(res, period.code, year.code);
            }
            return res;
        },
    },
};
</script>
