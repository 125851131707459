<template>
    <div class="upload_file" :class="{ hasFileError: error, composite_file: isCompositeMode }">
        <div v-if="hasCheckBox" class="checkBoxUploadFile" :title="checkBoxAlert">
            <base-checkbox
                :value="valCheck"
                :disabled="disabled"
                @input="$emit('setCheck', $event)"
            />

            <div v-if="showCheckAll" class="checkAll">
                <div class="checkAll__text" @click="$emit('setCheckAll', false)">снять все</div>
            </div>
        </div>
        <label>{{ label }} {{ types && types.minCount > 0 ? '*' : '' }} </label>
        <span v-if="delType" class="upload_file__delete" @click="delType(ugid)" />
        <transition-group name="list">
            <ItemFile
                v-for="file in upLoadFiles"
                :key="file.id"
                :file="file"
                class="list-item"
                :has-delete="hasDelete"
                :has-load="hasLoad"
                @delfile="delfile"
            />
        </transition-group>
        <label class="empty_file">
            <input
                type="file"
                multiple
                :accept="aseptFileFull"
                :size="size * oneMB"
                @change="fileUp"
            />
            <div class="ico_upload" />
            <div class="empty_file_description">
                {{ $t('Перетяните или') }}
                <span>{{ $t('выберите') }} {{ aseptFileFull }}</span>
                {{ $t('файл до') }}
                {{ checkSize(sizeKb) ? `${sizeKb} Кбайт` : `${parseFloat(size).toFixed(0)} Мб` }}.
            </div>
            <div v-if="getWarningText" class="empty_file_description">
                {{ getWarningText }}
            </div>
        </label>
    </div>
</template>

<script>
/* eslint-disable */
import { TYPE_ERRORS, TYPE_DIALOG_TITLE } from '@/constant';
import ItemFile from '@/views/components/base/UploadFile/ItemFile';
import WarningInformationFile from '../WarningInformationFile.vue';
import BaseCheckbox from '@/views/components/base/BaseCheckbox.vue';
import { generateUUID } from '@/helpers/generateUUID';
import clearReactive from '@/helpers/clearReactive';
import { getAppConfig, appParams } from '@/utils/appConfig';

export default {
    name: 'DocumentMultiUploadForm',
    components: {
        ItemFile,
        WarningInformationFile,
        BaseCheckbox,
    },
    props: {
        warningInfo: { type: Array },
        hasWarningInfo: { type: Boolean, default: true },
        isCompositeMode: { type: Boolean, default: false },
        hasFiles: {
            type: Array,
            default: () => {
                return [];
            },
        },
        multiple: { type: Boolean, default: false },
        acceptFile: {
            type: Array,
            default: () => {
                return ['pdf'];
            },
        },
        sizeKb: { type: Number },
        size: {
            //mb
            type: Number,
            default: 1,
        },
        label: {
            type: String,
            default: 'Скан образ Устава со всеми изменениями и дополнениями *',
        },
        delFile: { type: Function },
        types: { type: Object },

        maxCount: { type: Number, default: 99 },
        hasDelete: { type: Boolean, default: true },
        forceDisabled: { type: Boolean, default: false },

        ugid: { type: String, required: true },
        delType: { type: Function },
        errorsData: { type: Array },
        isOnlyShow: { type: Boolean, default: false },
        typeDoc: { type: Object },

        hasLoad: { type: Boolean, default: true },
        isModePBIOT: { type: Boolean, default: false },
        loaderPBIT: { type: Function },
        codePBIOT: { type: String },
        hasCheckBox: { type: Boolean, default: false },
        valCheck: { type: Boolean, default: false },
        disabled: { type: Boolean },
        checkBoxAlert: { type: String, default: 'Обязательный документ' },
    },
    created() {
        this.upLoadFiles = [...[], ...clearReactive(this.hasFiles)];
        this.minFileSize = getAppConfig(appParams.MIN_FILE_SIZE_BYTE);
    },
    data() {
        return {
            files: null,
            drawInput: true,
            upLoadFiles: [],
            oneMB: 1000000,
            minFileSize: 0,
        };
    },
    watch: {
        hasFiles: {
            deep: true,
            handler() {
                this.upLoadFiles = [...[], ...clearReactive(this.hasFiles)];
            },
        },
    },
    computed: {
        getWarningText() {
            if (this.warningInfo && this.codePBIOT) {
                const findText = this.warningInfo.find((item) => item.code == this.codePBIOT);
                if (findText) {
                    return findText.text;
                }
            }
            return false;
        },
        aseptFileFull() {
            let AF = this.acceptFile;
            AF = AF.map((i) => '.' + i);
            return AF.join(',');
        },
        checkfiles() {
            let AF = this.acceptFile;
            AF = AF.map((i) => i.toLowerCase());
            return AF;
        },
        showCheckAll() {
            return getAppConfig(appParams.SHOW_CHECKBOX_SHOW_ALL, false);
        },
    },
    methods: {
        checkSize(sizeKb) {
            return sizeKb && sizeKb < 1024;
        },
        async fileUp(event) {
            this.files = Array.from(event.target.files);
            if (!this.files) {
                return;
            }
            event.target.files.forEach(async (file, index) => {
                try {
                    const fullType = file.type;
                    let findslesh = fullType.indexOf('/');
                    let type = fullType.substr(findslesh + 1, fullType.length);
                    let fullNameFile = file.name;
                    let findPoint = fullNameFile.lastIndexOf('.');
                    let findTypeInName = false;
                    if (findPoint > -1) {
                        let typeInName = fullNameFile.substr(
                            findPoint + 1,
                            fullNameFile.length - findPoint
                        );
                        findTypeInName = this.checkfiles.indexOf(typeInName) < 0;
                    }
                    if (this.checkfiles.indexOf(type) < 0 && findTypeInName) {
                        const msg = this.$t('Файл не соответствует требованиям. Неверный формат.');
                        this.$modal.show('info', {
                            type: TYPE_ERRORS.WARNING,
                            msg: msg,
                            title: TYPE_DIALOG_TITLE.WARNING,
                        });
                        throw new Error('Ошибка формата');
                    }
                    if (event.target.files[0].size < this.minFileSize) {
                        const msg = this.$t(`Файл не соответствует требованиям. Минимальный размер файла ${Number(this.minFileSize)} байт`);
                        this.$modal.show('info', {
                            type: TYPE_ERRORS.WARNING,
                            msg: msg,
                            title: TYPE_DIALOG_TITLE.WARNING,
                        });
                        throw new Error('Ошибка размера');
                    }
                    if (file.size > this.size * this.oneMB) {
                        const msg = this.$t('Файл не соответствует требованиям.');
                        this.$modal.show('info', {
                            type: TYPE_ERRORS.WARNING,
                            msg: msg,
                            title: TYPE_DIALOG_TITLE.WARNING,
                        });
                        throw new Error('Ошибка размера');
                    }
                    const paramsIn = {
                        self: this,
                        file: [file],
                    };
                    let res = await this.loaderPBIT(
                        paramsIn,
                        this.codePBIOT + '_uid_' + generateUUID()
                    );
                    this.upLoadFiles.push(res);

                    if(res) {
                        this.$emit('file-added');
                    }

                } catch (err) {
                    console.error(err);

                    if (!err) {
                        const msg = this.$t('Не смог загрузить файл. Ошибка!');
                        this.$modal.show('info', {
                            type: TYPE_ERRORS.WARNING,
                            msg: msg,
                            title: TYPE_DIALOG_TITLE.WARNING,
                        });
                    }
                }
            });
        },
        delfile(file) {
            this.$emit('deleteFile', file);
            this.upLoadFiles = this.upLoadFiles.filter((item) => item.id !== file.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkBoxUploadFile {
    position: absolute;
    right: 0;
    top: 0;
    background: #ffffff;
    height: 40px;
    width: 40px;
    padding-left: 7px;
}

.checkAll {
    position: absolute;
    transition-duration: 0.2s;
    right: 32px;
    top: 4px;
    font-size: 10px;
    z-index: 99;
    width: 81px;
    text-transform: uppercase;
    background: #000;
    height: 34px;
    padding: 8px;
    opacity: 0;
    &__text {
        color: #fff;
        cursor: pointer;
        font-size: 12px;
    }
}
.checkBoxUploadFile {
    &:hover {
        .checkAll {
            opacity: 1;
        }
    }
}

.ico_upload {
    background: url(~@/assets/img/ico-upload.svg) 0 0/cover;
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto 20px
}

.upload_file {

    &__delete {
        cursor: pointer;
        position: absolute;
        background: url(~@/assets/ico/ico-close-gray.svg);
        right: 24px;
        top: 27px;
        background-size: contain;
        width: 12px;
        height: 12px;
        display: block;
    }
}
</style>
