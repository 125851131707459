<template>
    <div class="trend_top_wrap" :class="classState">
        <div class="text_block">
            <h3>{{ title }}</h3>
            <p>{{ subTitle }}</p>
        </div>

        <div class="button_block">
            <form v-if="fullData.showBtn" target="_blank">
                <ui-button :link="linkTender" type="full btn-link">{{ $t("Перейти в систему") }}</ui-button>
            </form>
        </div>

        <div v-if="fullData.showDate" class="block_date">
            {{ fullData.article }} {{ date }}
        </div>
    </div>
</template>

<script>
import { TENDER_STATUS, COLOR_TENDER_STATUS } from '@/constant';
import { appParams, getAppConfig } from '@/utils/appConfig';

export default {
    props: {
        tenderInfo: { required: true },
        status: { required: true },
    },

    data() {
        return {
            linkTender: 'https://tender.ext.lukoil.com/sap/bc/webdynpro/sap/ztenders',
        };
    },

    computed: {
        classState() {
            return this.fullData.color;
        },
        date() {
            const res = this.tenderInfo && this.tenderInfo.dateTo
                ? this.tenderInfo.dateTo
                : null;
            return res;
        },

        fullData() {
            const { status } = this;

            switch (status) {
            case TENDER_STATUS.PENDING: {
                return {
                    color: COLOR_TENDER_STATUS.PENDING,
                    title: this.$t('Доступ заблокирован'),
                    subtext: this.$t('Идет проверка доступа'),
                    article: '',
                    showBtn: false,
                    showDate: false,
                };
            }
            case TENDER_STATUS.BLOCKED: {
                return {
                    color: COLOR_TENDER_STATUS.BLOCKED,
                    title: this.$t('Доступ заблокирован'),
                    subtext: this.$t(
                        'Для возобновления доступа необходимо произвести оплату',
                    ),
                    article: this.$t('c'),
                    showBtn: false,
                    showDate: true,
                };
            }
            case TENDER_STATUS.OPEN: {
                return {
                    color: COLOR_TENDER_STATUS.OPEN,
                    title: this.$t('Постоянный доступ'),
                    subtext: this.$t('Вам предоставлен постоянный доступ'),
                    article: this.$t('до'),
                    showBtn: true,
                    showDate: true,
                };
            }
            default: {
                return {
                    color: COLOR_TENDER_STATUS.TEMPORARY,
                    title: this.$t('Временный доступ'),
                    subtext: this.$t('Вам предоставлен временный доступ'),
                    article: this.$t('до'),
                    showBtn: true,
                    showDate: true,
                };
            }
            }
        },
        title() {
            return this.fullData.title;
        },
        subTitle() {
            return this.fullData.subtext;
        },
    },

    created() {
        this.linkTender = getAppConfig(appParams.TENDER_LINK);
    },
};
</script>

<style lang="scss" scoped></style>
