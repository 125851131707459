<template>
    <div class="grid-x grid-margin-x">
        <template v-if="existingOwnerChainDoc && existingOwnerChainDoc.isActual">
            <div class="cell mb-12">
                <DocumentUploadForm
                    :label="existingOwnerChainDoc.name"
                    :has-files="[existingOwnerChainDoc.mainFileInfo]"
                    :max-count="1"
                    :has-delete="false"
                />
            </div>

            <div v-if="!existingOwnerChainDoc.isRequiredApprove" class="mb-12 notification-upload">
                <div class="attention-indication">!</div>
                <div>
                    У Вас уже загружен данный документ, повторная загрузка на текущей момент не
                    требуется.<br />Добавление нового документа необходимо только в случае изменения
                    цепочки учредителей или участников/акционеров, включая бенефициарных владельцев
                    (собственников).
                </div>
            </div>

            <template v-if="existingOwnerChainDoc.isRequiredApprove">
                <div class="large-9 cell">
                    <div class="textAccept">
                        Подтвердить актуальность текущего документа или загрузить новый.
                    </div>
                </div>
                <div class="large-3 cell">
                    <ui-button
                        :disabled="approved"
                        type="full"
                        @click="approveDoc"
                    >
                        {{ approveDocText }}
                    </ui-button>
                </div>
                <div class="cell mb-12">&nbsp;</div>
            </template>
        </template>

        <div class="cell mb-12">
            <DocumentCreateV2Buttons
                :documents-in-specification="selectedDocSpec.documents"
                :get-confirmation-dialog-props="getConfirmationDialogProps"
                @update:documentsUserSelectedToAdd="
                    $emit('update:documentsUserSelectedToAdd', $event)
                "
            >
                <template #fileDescription>
                    <div class="msgPdfInfo">
                        Документ необходимо заполнять <b>в объеме достаточном для анализа</b> с учетом особенностей владения юридическим лицом.
                    </div>
                </template>
                <template #fileFooter>
                    <owner-chain-documents />
                </template>
            </DocumentCreateV2Buttons>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import OwnerChainDocuments from '@/views/components/documents/DocumentUploadForm/OwnerChainDocuments.vue';
import DocumentUploadForm from '@/views/components/documents/DocumentUploadForm/DocumentUploadForm.vue';
import { serviceApi } from '@/utils/serviceApi';
import { TYPE_DIALOG_TITLE, TYPE_ERRORS } from '@/constant';

export default {
    name: 'OwnerChainDocumentUiHandler',

    components: {
        DocumentUploadForm,
        OwnerChainDocuments,
        DocumentCreateV2Buttons,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            // DocumentSpecificationGroupDTO + group property
            type: Object,
        },
    },

    data() {
        return {
            approved: false,
        };
    },

    computed: {
        ...mapGetters(['existingDocumentInfoList']),

        existingOwnerChainDoc() {
            const existingDocs = this.existingDocumentInfoList(this.selectedDocSpec.code);
            return _.isEmpty(existingDocs) ? null : existingDocs[0];
        },

        approveDocText() {
            return this.approved ? 'Документ актуален' : 'Подтвердить';
        },
    },

    methods: {
        async approveDoc() {
            try {
                await serviceApi.acceptActualDocument(this);
                const msg = 'Текущий документ подтвержден! Загружать новый документ не требуется.';
                this.$modal.show('info', {
                    type: TYPE_ERRORS.SUCCESS,
                    msg,
                    title: TYPE_DIALOG_TITLE.SUCCESS,
                });
                this.approved = true;
            } catch (e) {
                console.error(e);
            }
        },

        getConfirmationDialogProps(docSpecification) {
            if (this.existingOwnerChainDoc?.isActual) {
                return {
                    nameDoc: docSpecification.name,
                    msg: [
                        'Для загрузки нового документа необходимо подтвердить изменение в текущей цепочке собственников, включая бенефициаров (в том числе конечных)',
                    ],
                    confirmBtnText: 'Подтвердить',
                    dopClass: '',
                    isCenterMode: true,
                };
            }
            return null;
        },
    },
};
</script>

<style scoped lang="scss">
.notification-upload {
    display: flex;
    align-items: center;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
}
.attention-indication {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    min-width: 45px;
    height: 45px;
    border: 2px solid #da2b36;
    border-radius: 50%;
    background-color: #ffe1ba;
    font-size: 26px;
    color: #da2b36;
}
.textAccept {
    padding-top: 16px;
    font: normal bold 14px/2 OpenSansBold, sans-serif;
}
</style>
