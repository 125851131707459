<template>
    <ui-modal
        :title="$t('Внимание')"
        name="confirm_delete"
        width="500"
        @before-open="beforeOpen"
    >
        <div>
            <span v-if="isSubcontractor">
                Вы действительно хотите исключить данного контрагента из перечня
                предполагаемых субподрядчиков? <br />
                В случае наличия активного тендера с указанным субподрядчиком
                процедура исключения будет отменена. <br />
                Проверка на наличие активного тендера может занять до 24 часов
            </span>

            <span v-if="isProducer">
                Вы действительно хотите исключить данного контрагента из перечня
                предполагаемых производителей? <br />
                В случае наличия активного тендера с указанным производителем
                процедура исключения будет отменена. <br />
                Проверка на наличие активного тендера может занять до 24 часов
            </span>
        </div>

        <div class="actions">
            <ui-button type="full" class="mr-20" @click="$modal.hide('confirm_delete')">
                {{ $t("Отменить") }}
            </ui-button>

            <ui-button type="full" @click="deleteConfirm()">
                {{ $t("Удалить") }}
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import { SUBCONTRACTOR_LINK_TYPE } from '@/constant';

export default {
    data() {
        return {
            SUBCONTRACTOR_LINK_TYPE,
            subcontractorLinkType: '',
            resultFunction: () => {},
        };
    },

    computed: {
        isProducer() {
            return this.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.PRODUCER;
        },
        isSubcontractor() {
            return (
                this.subcontractorLinkType === SUBCONTRACTOR_LINK_TYPE.SUBCONTRACTOR
            );
        },
    },

    methods: {
        beforeOpen(event) {
            const { subcontractorLinkType, resultFunction } = event.params;
            this.subcontractorLinkType = subcontractorLinkType;
            this.resultFunction = resultFunction;
        },
        deleteConfirm() {
            this.$modal.hide('confirm_delete');
            this.resultFunction();
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
