<template>
    <div class="greyBox clearfix">
        <div class="greyBox__title">
            {{ labelText }}
        </div>

        <div class="greyBox__years">
            <div
                v-for="(yq, index) in quest.children"
                :key="index"
                class="greyBox__years__el"
            >
                <label>{{ yq.name
                }}<input v-model="yq.value" type="number" :tabindex="yq.tabindex" /></label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YearInfo',

    props: {
        labelText: {
            type: String,
            default: 'Количество несчастных случаев со смертельным исходом за последние три года',
        },

        quest: {
            required: true,
        },
    },
};
</script>

<style lang='scss' scoped>
.greyBox {
    background: #f5f7f9;
    height: 142px;
    margin-bottom: 16px;
    float: left;
    clear: both;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    &__title {
        font-family: OpenSansRegular,sans-serif;;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.17px;
        margin-bottom: 24px;
        margin-top: 24px;
        color: #000000;
        clear: both;
    }
    &__years {
        clear: both;
        display: flex;
        &__el {
            margin-right: 64px;
            label {
                display: flex;
                align-items: center;
                input {
                    margin-left: 16px;

                    background: #ffffff;
                    border: 1px solid #000000;
                    clear: both;
                    font-family: OpenSansRegular,sans-serif;;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 9px;
                    width: 132px;
                    height: 42px;
                    letter-spacing: 0.3px;

                    color: #989898;
                }
            }
        }
    }
}

.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    overflow:hidden;
}

.clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}
</style>
