import { setupConfig } from './httpClientConfig';
import store from '../store/index';

const actionScope = 'loader';

export function setupInterceptors(httpClient) {
    httpClient.interceptors.request.use(
        (config) => {
            setupConfig(config);
            store.dispatch(`${actionScope}/pending`);
            return config;
        },
        (error) => {
            store.dispatch(`${actionScope}/done`);
            return Promise.reject(error);
        },
    );
    httpClient.interceptors.response.use(
        (res) => {
            store.dispatch(`${actionScope}/done`);
            return res;
        },
        (error) => {
            store.dispatch(`${actionScope}/done`);
            return Promise.reject(error);
        },
    );
}
