var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-content",attrs:{"data-tab-content":"","data-cy":"notres"}},[_c('BaseShowText',{attrs:{"label":"Уникальный код в справочнике КССС","value":_vm.allData && _vm.allData.code ? _vm.allData.code : ''}}),_c('BaseShowText',{attrs:{"label":"Полное наименование контрагента","value":_vm.allData && _vm.allData.name ? _vm.allData.name : ''}}),_c('BaseShowText',{attrs:{"label":"Tax ID (код налогоплательщика)","value":_vm.regData && _vm.regData.taxId ? _vm.regData.taxId : null}}),_c('BaseShowText',{attrs:{"label":"Код регистрации","value":_vm.regData && _vm.regData.registrationCode
            ? _vm.regData.registrationCode
            : null}}),_c('UploadForm',{attrs:{"label":_vm.$t('Устав (учредительный договор), содержащий информацию о полном и кратком наименованиях и юридическом адресе организации'),"add-file":_vm.addFile,"single-file":true,"has-files":_vm.regData && 'charterInfo' in _vm.regData && _vm.regData.charterInfo
            ? [_vm.regData.charterInfo]
            : [],"max-count":1,"size":10,"has-delete":false,"force-disabled":_vm.blockInput}}),_c('UploadForm',{attrs:{"label":_vm.$t('Свидетельство регистрации юридического лица'),"add-file":_vm.addFile,"single-file":true,"has-files":_vm.regData && 'certificateOfRegistration2' in _vm.regData && _vm.regData.certificateOfRegistration2
            ? [_vm.regData.certificateOfRegistration2]
            : [],"max-count":1,"size":10,"has-delete":false,"force-disabled":_vm.blockInput}}),_c('UploadForm',{attrs:{"label":_vm.$t('Свидетельство постановки на учет в налоговом органе'),"add-file":_vm.addFile,"single-file":true,"has-files":_vm.regData && 'certificateOfRegistration1' in _vm.regData && _vm.regData.certificateOfRegistration1
            ? [_vm.regData.certificateOfRegistration1]
            : [],"max-count":1,"size":10,"has-delete":false,"force-disabled":_vm.blockInput}}),_c('UploadForm',{attrs:{"label":_vm.$t('Письмо на официальном бланке организации, содержащее данные о банковских реквизитах, а так же информацию об адресах, наименованиях, регистрационных и налоговых кодах'),"add-file":_vm.addFile,"single-file":true,"has-files":_vm.regData && 'companyForm' in _vm.regData && _vm.regData.companyForm
            ? [_vm.regData.companyForm]
            : [],"max-count":1,"size":10,"has-delete":false,"force-disabled":_vm.blockInput}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }