<template>
    <section class="page-header wrapper">
        <div class="container">
            <div
                v-if="backFun"
                class="page-header__title pointer"
                @click="backFun"
            >
                {{ title }}
            </div>

            <div
                v-else-if="linkBack"
                class="flex align-center"
            >
                <router-link
                    :to="linkBack"
                    class="mr-10 pointer"
                >
                    <img
                        src="~@/assets/img/return.svg"
                        width="25px"
                        height="25px"
                    />
                </router-link>

                <div class="page-header__title">{{ title }}</div>
            </div>

            <div v-else-if="htmlTitle" class="page-header__title" v-html="title" />

            <div v-else class="page-header__title">{{ title }}</div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageHeader',

    props: {
        title: {
            type: String,
            default: 'Н/Д',
        },

        backFun: {
            type: Function,
        },

        linkBack: {
            type: String,
            default: '',
        },

        htmlTitle: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        profile() {
            return this.$store.state.user.profile;
        },
    },
};
</script>

<style lang='scss' scoped>
@import "@/assets/styles/common/_variables";

.page-header {
    background: $grey-300;
    padding: 40px 0;

    &__title {
        font-family: CuprumBold, sans-serif;
        font-size: 28px;
        text-transform: uppercase;
    }
}
</style>
