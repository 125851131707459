import { render, staticRenderFns } from "./ModalSupport.vue?vue&type=template&id=734b429a&scoped=true"
import script from "./ModalSupport.vue?vue&type=script&lang=js"
export * from "./ModalSupport.vue?vue&type=script&lang=js"
import style0 from "./ModalSupport.vue?vue&type=style&index=0&id=734b429a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734b429a",
  null
  
)

export default component.exports