<template>
    <section :class="['ui-button__wrapper', typeClasses]">
        <div
            v-if="counterNumber"
            class="ui-button__counter"
        >
            {{ counterNumber }}
        </div>

        <component
            :is="currentTeg"
            v-bind="linkAttr"
            :class="[{ 'text-decoration': link }, baseClassName, typeClasses]"
            :disabled="disabled"
            :target="target"
            :type="nativeType"
            v-on="$listeners"
        >
            <div
                v-tooltip="tooltip"
                class="ui-button__content"
            >
                <ui-icon
                    v-if="icon"
                    :class="{
                        'ui-button__icon': $slots.default,
                    }"
                    :name="currentIconName"
                    :width="icon?.width"
                    :height="icon?.height"
                    :color="icon?.color || '#db2b37'"
                />

                <slot />
            </div>
        </component>
    </section>
</template>

<script>
import TypeMixin from '@/mixins/type-mixin';
import icons from '@/helpers/icons';

export default {
    mixins: [TypeMixin],

    props: {
        /**
         *  Альтернатива type для HTML тега button
         */
        nativeType: {
            type: String,
            default: 'button',
        },

        /**
         *  Отображение иконки внутри кнопки
         *  Идентификатор иконки из библиотеки iconify
         */
        icon: {
            type: Object,
            default: null,
        },

        /**
         *  Отключение кнопки
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         *  Режим загрузки
         */
        isLoading: {
            type: Boolean,
            default: false,
        },

        /**
         *  PopUp при наведении
         */
        tooltip: {
            type: String,
            default: null,
        },

        link: {
            type: [String, Object],
            default: '',
        },

        target: {
            type: String,
            default: '_self',
        },

        /**
         *  Счётчик справа от кнопки
         */

        counterNumber: {
            type: [String, Number],
            default: '',
        },
    },

    data() {
        return {
            baseClassName: 'ui-button',
            icons,
        };
    },

    computed: {
        currentTeg() {
            if (!this.link) {
                return 'button';
            }

            return this.isRouterLink ? 'router-link' : 'a';
        },

        currentIconName() {
            return this.icons[this.icon.name] ?? '';
        },

        linkAttr() {
            return {
                [this.isRouterLink ? 'to' : 'href']: this.link,
            };
        },

        isRouterLink() {
            return typeof this.link === 'object';
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/styles/common/_variables';

.ui-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: CuprumBold, sans-serif;
    font-size: 18px;
    height: 48px;
    padding: 0 20px 0;
    color: $white;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: $primary;
    transition: all 0.264s;
    cursor: pointer;

    &:disabled {
        background-color: $disabled;
        cursor: not-allowed;
    }

    &__wrapper {
        position: relative;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__counter {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: CuprumBold, sans-serif;
        font-size: 16px;
        color: $primary;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $primary;
        z-index: 100;
    }

    & .icon,
    &__icon {
        margin-right: 10px;
        fill: currentColor;
    }

    &--icon-button {
        height: 25px;
        padding: 0;
        background-color: transparent;
    }

    &--full {
        width: 100%;
    }

    &--dropdown-btn-size {
        width: 250px;
    }

    &--large {
        padding: 0 30px;
    }

    &--table {
        height: 35px;
        font-size: 16px;
        padding: 0 15px;
    }

    &--abort {
        background-color: $black;
    }

    &--btn-link {
        background-color: $white;
        color: $primary;
    }
}
</style>
