import { render, staticRenderFns } from "./registration-legal-entity.vue?vue&type=template&id=1266c367&scoped=true"
import script from "./registration-legal-entity.vue?vue&type=script&lang=js"
export * from "./registration-legal-entity.vue?vue&type=script&lang=js"
import style0 from "./registration-legal-entity.vue?vue&type=style&index=0&id=1266c367&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1266c367",
  null
  
)

export default component.exports