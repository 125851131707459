<template>
    <div class="worksheet_info_item">
        <div class="worksheet_info_item__label" v-html="$t(label)" />
        <div class="worksheet_info_item__text">{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: 'BaseShowText',
    props: {
        label: { type: String, requred: true },
        value: { type: [String, Number], requred: true },
    },
};
</script>
