<template>
    <div class="balance-sheet-documents grid-x grid-margin-x">
        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Выберите отчетный год</label>
        </div>
        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :value="year"
                :on-change="selectYear"
                :options="optionsYear"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('Выберите отчетный год')"
                :force-disabled="disable"
            />
        </div>

        <div class="large-4 small-4 cell">
            <label class="labelSimpleSelector">Выберите тип бухгалтерской отчетности</label>
        </div>
        <div class="large-8 small-8 cell mb-12">
            <SimpleDictionary
                dop-class="selectorDocument"
                :on-change="selectAccountingReportType"
                :value="accountingReportType"
                :options="optionsAccountingReportType"
                :error-text="$t('Необходимо выбрать')"
                :placeholder="$t('Выберите тип бухгалтерской отчетности')"
                :force-disabled="disable"
            />
        </div>

        <template v-if="documentsApplicableToAddFiltered">
            <div class="large-12 cell mb-12">
                <DocumentCreateV2Buttons
                    :documents-in-specification="documentsApplicableToAddFiltered"
                    @update:documentsUserSelectedToAdd="
                        $emit('update:documentsUserSelectedToAdd', $event)
                    "
                >
                    <template #uploadRule>
                        <div class="balance-sheet-documents__info_wrap">
                            <div
                                v-for="( { text }, idx ) in additionalNotifications"
                                :key="idx"
                                v-html="accountingReportsNotifications[text]"
                            />
                        </div>
                    </template>
                </DocumentCreateV2Buttons>
            </div>
        </template>
    </div>
</template>

<script>
import { accountingReportsNotifications } from '@/notificationTemplates/documentNotifications';
import SimpleDictionary from '@/views/components/base/SimpleDictionary.vue';
import DocumentCreateV2Buttons from '@/views/components/documents/DocumentCreateV2Buttons.vue';
import { DocumentTypeEnum } from '@/constant';
import { replaceYear } from '@/helpers/createDocumentHelper';

export default {
    name: 'BalanceSheetDocumentUiHandler',

    components: {
        DocumentCreateV2Buttons,
        SimpleDictionary,
    },

    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        selectedDocSpec: {
            type: Object,
        },
    },

    data() {
        return {
            year: null,
            accountingReportType: null,
            accountingReportsNotifications,
        };
    },

    computed: {
        additionalNotifications() {
            return this.selectedDocSpec?.bottomNotificationBar.content ?? [];
        },

        optionsYear() {
            const nowY = this.$moment().format('YYYY');
            const listYear = [nowY - 1, nowY - 2, nowY - 3];
            return listYear.map((item, index) => ({
                id: index,
                code: item,
                name: item,
            }));
        },

        optionsAccountingReportType() {
            return [
                {
                    id: 0,
                    code: DocumentTypeEnum.BalanceSheetQuarter,
                    name: 'Типовая',
                },
                {
                    id: 1,
                    code: DocumentTypeEnum.SimpleBalanceSheetQuarter,
                    name: 'Упрощенная',
                },
            ];
        },

        /**
         * @returns DocumentSpecificationDocDTO with resolved names and additional document attributes (like year, period, etc)
         */
        documentsApplicableToAddFiltered() {
            const docTypeCodesToInclude = [];
            if (this.accountingReportType) {
                docTypeCodesToInclude.push(this.accountingReportType.code);
            }
            return this.selectedDocSpec.documents
                .filter((it) => _.includes(docTypeCodesToInclude, it.code))
                .map((it) => {
                    const doc = _.cloneDeep(it);
                    doc.name = this.resolvePlaceholders(doc.name);
                    doc.files.forEach((f) => (f.name = this.resolvePlaceholders(f.name)));
                    doc.documentAttributes = {
                        reportingYear: this.year.code,
                    };
                    return doc;
                });
        },
    },

    mounted() {
        this.selectYear(this.optionsYear[0]);
    },

    methods: {
        selectYear(val) {
            this.year = val;
            this.accountingReportType = this.optionsAccountingReportType[0];
        },

        selectAccountingReportType(val) {
            this.accountingReportType = val;
        },

        resolvePlaceholders(val) {
            const { year } = this;

            return year ? replaceYear(val, year?.code) : val;
        },
    },
};
</script>
