<template>
    <div class="large-12 cell">
        <div class="grid-x grid-margin-x">
            <div class="large-3 cell">
                <BaseCheckbox
                    :label="$t('Сначала новые задачи')"
                    name="postconfirm"
                    :value="isNew"
                    @input="setNew"
                />
            </div>
            <div class="large-3 cell">
                <BaseCheckbox
                    :label="$t('На проверке')"
                    name="postconfirm"
                    :value="isProgres"
                    @input="setProgres"
                />
            </div>
            <div class="large-3 cell">
                <BaseCheckbox
                    :label="$t('Принято')"
                    name="postconfirm"
                    :value="isAccept"
                    @input="setAccept"
                />
            </div>
            <div class="large-3 cell">
                <BaseCheckbox
                    :label="$t('Отклонено')"
                    name="postconfirm"
                    :value="isReject"
                    @input="setReject"
                />
            </div>
            <div class="large-12 cell">
                <div class="separateDocs" />
                <input
                    type="text"
                    placeholder="Поиск"
                    class="simpleBigInpit"
                    :value="search"
                    @input="setSearch"
                />
            </div>
            <div class="large-12 cell">
                <div class="separateDocs" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isNew: { type: Boolean },
        isAccept: { type: Boolean },
        isReject: { type: Boolean },
        isProgres: { type: Boolean },
        search: { type: String },
        setNew: { type: Function },
        setAccept: { type: Function },
        setReject: { type: Function },
        setProgres: { type: Function },
        setSearch: { type: Function },
    },
};
</script>

<style lang="scss" scoped>
.simpleBigInpit {
  background: #fff;
  height: 48px;
  border: solid 1px #000;
  color: #000;
  display: block;
  width: 100%;
  padding: 14px;
  font: 16px/0.56 OpenSansRegular, sans-serif;
}

.separateDocs {
    margin-bottom: 32px;
}
</style>
