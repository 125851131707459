<template>
    <div :id="idTab" class="tabs-panel" :class="{ 'is-active': isActive }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TabItem',
    props: {
        idTab: { type: String, default: 'panel1' },
        isActive: { type: Boolean, default: false },
    },
};
</script>
