<template>
    <div :class="classesComp">
        <label
            v-if="label"
            :for="name"
        >
            {{ $t(label) }}
            <span v-if="hasIndex">{{ dopIndex }}</span>
        </label>

        <span
            v-if="checkHas"
            :class="{ 'check-ico': !checkVal, 'check-ico-green': checkVal }"
            :title="checkText"
        />

        <span
            v-if="type == 'password'"
            class="eye-ico"
            :class="{ 'eye-sl': showPass, iconHasLabel: label }"
            @click="showPass = !showPass"
        />

        <input
            v-if="!textarea && !maskCheck"
            :id="name"
            :type="convtype"
            :name="name"
            :placeholder="$t(placeholder)"
            :value="value"
            v-bind="$attrs"
            v-on="listeners"
            @blur="unfocus"
        />

        <input
            v-if="!textarea && maskCheck"
            :id="name"
            v-mask="maskCheck"
            :type="convtype"
            :name="name"
            :placeholder="$t(placeholder)"
            :value="value"
            v-bind="$attrs"
            v-on="listeners"
            @blur="unfocus"
        />

        <textarea
            v-if="textarea"
            :id="name"
            :type="convtype"
            :name="name"
            :placeholder="$t(placeholder)"
            :value="value"
            v-bind="$attrs"
            :rows="rows"
            :cols="cols"
            v-on="listeners"
        />

        <transition name="fade" mode="out-in">
            <div v-if="hasError" class="has-error">
                <p>{{ hasTraslate ? $t(errorText) : errorText }}</p>
                <p v-if="forceError">
                    {{ hasTraslate ? $t(forceTextError) : forceTextError }}
                </p>
                <p v-if="dopErrorText">
                    {{ hasTraslate ? $t(dopErrorText) : dopErrorText }}
                </p>
            </div>
        </transition>

        <transition v-if="showFocusDopInfo" name="fade" mode="out-in">
            <slot v-if="focused" />
        </transition>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: 'BaseInput',

    directives: { mask },

    inheritAttrs: false,

    props: {
        label: { type: String },
        value: [String, Number],
        type: { type: String, default: 'text' },
        name: { type: String, default: 'name' },
        placeholder: { type: String },
        textarea: { type: Boolean, default: false },
        classes: { type: String, default: '' },
        mainClass: { type: String, default: 'input_wrap' },
        errorText: { type: String, default: '' },
        vError: { type: Object },
        maskCheck: { type: String },
        dopErrorText: { type: String },
        checkVal: { type: Boolean, default: false },
        checkHas: { type: Boolean, default: false },
        checkText: { type: String },
        forceError: { type: Boolean, default: false },
        forceTextError: { type: String, default: '' },
        showFocusDopInfo: { type: Boolean, default: false },
        rows: { default: '' },
        cols: { default: '' },
        hasTraslate: { type: Boolean, default: true },
        hasIndex: { type: Boolean, default: false },
        dopIndex: [String, Number],
    },

    data() {
        return {
            touched: false,
            focused: false,
            hadError: false,
            showPass: false,
        };
    },

    computed: {
        convtype() {
            let res = this.type;
            if (this.type == 'password' && this.showPass == true) {
                res = 'text';
            }
            return res;
        },
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur,
            };
        },
        hasError() {
            const { vError } = this;
            if (this.forceError || (vError && vError.$dirty && vError.$error)) {
                return true;
            }
            return false;
        },
        touch() {
            const { vError } = this;
            if (vError && vError.$dirty) {
                return true;
            }
            return false;
        },
        classesComp() {
            let res = `${this.mainClass} ${this.classes}`;
            res += this.hasError ? ' error ' : this.touch ? '  ' : '';
            return res;
        },
    },

    methods: {
        unfocus() {
            if (this.vError) {
                this.vError.$touch();
            }
        },
        updateValue(evt) {
            const { value } = evt.target;
            if (!this.touched && value) {
                this.touched = true;
            }
            this.$emit('input', value);
        },
        onFocus(value) {
            this.focused = true;
            this.$emit('focus', value);
        },
        onBlur(value) {
            this.focused = false;
            this.$emit('blur', value);
        },
    },
};

// success error
</script>

<style lang="scss" scoped>
.eye-ico {
    position: absolute;
    top: 14px;
    right: 14px;
    background-image: url("~@/assets/ico/eye.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 21px;
    cursor: pointer;
    transition: all .5s ease 0s;
    &:hover {
        background-image: url("~@/assets/ico/eye-h.svg");
    }
}

.eye-sl {
    background-image: url("~@/assets/ico/eye-sl.svg");
    &:hover {
        background-image: url("~@/assets/ico/eye-sl-h.svg");
    }
}

.iconHasLabel {
    top: 52px;
}
</style>
