<template>
    <div class="ip_inputs clearfix">
        <div class="inputs_inline priceBlock">
            <label for="in1">{{ labelText }}</label>

            <div class="input_inline_wrap">
                <div class="element-info__input">
                    <div v-if="infoText" v-tooltip="infoText" class="element-info__tooltip">
                        <ui-icon :name="icons.infoBox" width="40" height="40" />
                    </div>
                    <input
                        v-model="inputData"
                        :placeholder="placeholderText"
                        :type="typeDef"
                        :tabindex="tabindex"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import icons from '@/helpers/icons';

export default {
    name: 'OneElementInfo',

    props: {
        labelText: {
            type: String,
            default: 'Списочная численность работающих в организации',
        },
        placeholderText: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: 'Заполните информацию',
        },
        val: {
            required: true,
        },
        typeDef: {
            type: String,
            default: 'number',
        },
        tabindex: {
            type: Number,
        },
    },

    data() {
        return {
            icons,
        };
    },

    computed: {
        inputData: {
            get() {
                return this.val;
            },
            set(val) {
                this.$emit('change', val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.element-info {

  &__tooltip {
        position: relative;
        top: 5px;
        right: -200px;
  }

  &__input {
    display: inline-flex;
  }
}

.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    overflow:hidden;
}

.clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}
</style>
