<template>
    <div v-if="showBlock" class="documentOwnerChain">
        <div class="documentOwnerChain__doc">
            <div class="docIcon__wrap"><div class="docIcon" /></div>
            <a
                href="/documentOwnerChain/manual_OwnerChain.docx"
                target="_blanc"
                title="скачать"
            >Инструкция по заполнению Сведения о цепочке собственников, включая
                бенефициаров (в том числе конечных)</a>
        </div>
        <div class="documentOwnerChain__doc">
            <div class="docIcon__wrap"><div class="docIcon" /></div>
            <a
                href="/documentOwnerChain/example_ownerChain.pdf"
                target="_blanc"
                title="скачать"
            >Образец Сведения о цепочке собственников, включая бенефициаров (в том
                числе конечных)</a>
        </div>
        <div class="documentOwnerChain__doc">
            <div class="docIcon__wrap"><div class="docIcon" /></div>
            <a
                href="/documentOwnerChain/form_ownerChain.xlsx"
                target="_blanc"
                title="скачать"
            >Форма Сведения о цепочке собственников, включая бенефициаров (в том
                числе конечных)</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OwnerChainDocuments',
    props: {
        typeDoc: { type: Object },
    },
    computed: {
        showBlock() {
            if (!this.typeDoc || this.typeDoc.code == 'OwnerChain') {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.docIcon {
    background-image: url(~@/assets/ico/docs.svg);
    background-size: cover;
    display: block;
    width: 24px;
    height: 30px;
}

.documentOwnerChain {
  margin-top: 12px;
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__doc {
    display: flex;
  }
  a{
    text-align: left;
    color: #989898;
    text-decoration: none;
    padding-left: 10px;
  }
}
.docIcon__wrap {
  border: 2px solid #da2b36;
  padding: 21px 23px;
  border-radius: 40px;
  width: 74px;
  height: 74px;
}
</style>
