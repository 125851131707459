<template>
    <div>
        <AccordionBase id="genContractor">
            <AccordionItem
                :has-icon="false"
                :set-show-data="setShowGenContractors"
                :title="'Генподрядчики'"
                :is-active="false"
            >
                <GeneralContractors v-if="showGenContractors" />
            </AccordionItem>
        </AccordionBase>

        <AccordionBase id="subContractor">
            <AccordionItem
                :has-icon="false"
                :set-show-data="setShowSubContractors"
                :title="'Субподрядчики'"
                :is-active="false"
            >
                <SubContractors v-if="showSubContractors" />
            </AccordionItem>
        </AccordionBase>

        <AccordionBase id="producers">
            <AccordionItem
                :has-icon="false"
                :set-show-data="setShowProducers"
                :title="'Производители'"
                :is-active="false"
            >
                <Producers v-if="showProducers" />
            </AccordionItem>
        </AccordionBase>
    </div>
</template>

<script>
import AccordionBase from '@/views/components/base/accordions/AccordionBase.vue';
import AccordionItem from '@/views/components/base/accordions/AccordionItem';

import GeneralContractors from './GeneralContractors.vue';
import SubContractors from './SubContractors.vue';
import Producers from './ProducerTable.vue';

export default {
    name: 'ActualRelationships',

    components: {
        SubContractors,
        GeneralContractors,
        Producers,
        AccordionBase,
        AccordionItem,
    },

    data() {
        return {
            showGenContractors: false,
            showSubContractors: false,
            showProducers: false,
        };
    },

    methods: {
        setShowGenContractors() {
            this.showGenContractors = !this.showGenContractors;
        },

        setShowSubContractors() {
            this.showSubContractors = !this.showSubContractors;
        },

        setShowProducers() {
            this.showProducers = !this.showProducers;
        },
    },
};
</script>
