export default {
    state: {
        files: [],
    },

    mutations: {
        addFiles(state, files) {
            state.files = files;
        },
    },

    getters: {
        getFiles(state) {
            return state.files;
        },
    },

    actions: {
        addFiles({ commit }, files) {
            commit('addFiles', files);
        },
    },
};
