/**
 * генератор массива значений годов
 * @param {Number} firstYear
 * @param {Number} lengthList
 */

const generateListYears = (firstYear, lengthList) => [...Array(lengthList)].map((_, index) => ({
    id: index,
    code: firstYear - index,
    name: firstYear - index,
}));

export default generateListYears;
