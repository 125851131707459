export default {
    dropdown: 'dropdown',
    checkIcon: 'checkIcon',
    closeRounded: 'closeRounded',
    roundClose: 'roundClose',
    alertBox: 'alertBox',
    infoBox: 'infoBox',
    roundFileCopy: 'roundFileCopy',
    informationSlabBox: 'informationSlabBox',
    roundError: 'roundError',
};
